import "./ConvertFunds.css";
import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useCoinContext } from "../../../Context/CoinContext";
import axios from "axios";
import { useSelector } from "react-redux";

function ConvertFunds() {
  const labels = useSelector((state) => state.labels.data);
  const token = localStorage.getItem("token");

  const { exchangeToken } = useCoinContext();

  const [dusts, setDusts] = useState();

  const [selectedCoins, setSelectedCoins] = useState([]);

  const handleCheckboxChange = (currency, balanceInEXT) => {
    setSelectedCoins((prevSelectedCoins) => {
      const isCurrencySelected = prevSelectedCoins.find(
        (coin) => coin.currency === currency
      );

      if (isCurrencySelected) {
        // Eğer zaten seçilmişse, diziden çıkar
        return prevSelectedCoins.filter((coin) => coin.currency !== currency);
      } else {
        // Seçilmemişse, yeni nesneyle ekle
        return [...prevSelectedCoins, { currency, balanceInEXT }];
      }
    });
  };

  const coinsToSend = selectedCoins.map((coin) => coin.currency);

  const fetchDusts = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_REST_URL}/api/convert_exchange_token`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      }
    );
    setDusts(response?.data?.data);
  };

  useEffect(() => {
    fetchDusts();
  }, []);

  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_REST_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Bearer token gönderimi
    },
  });

  const convertFunds = async (dusts) => {
    try {
      const { data } = await instance({
        url: `api/convert_exchange_token`,
        method: "POST",
        data: coinsToSend,
      });

      if (data.status === "Success") {
        setSelectedCoins([]);
        const message = labels?.wallet?.static_conversion?.conversionSuccess;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        fetchDusts();
      }
    } catch (e) {}
  };

  const getTotalBalance = (coins) => {
    const totalBalance = coins.reduce(
      (total, coin) => total + coin.balanceInEXT,
      0
    );
    return totalBalance.toFixed(8);
  };

  return (
    <>
      <Header />

      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Wallet" submenu="WalletConvertFunds" />
        <div className="main-content convert-funds-content">
          <div class="row">
            <div class="col">
              <h4 className="title" style={{ margin: "0px!important" }}>
                {labels?.wallet?.convertFunds?.link.replace(
                  "{{exchangeToken}}",
                  exchangeToken
                )}
              </h4>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead className="w-100">
                <tr>
                  <th
                    style={{
                      width: "18%",
                      maxWidth: "18%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {labels?.tables?.convertFunds?.selectChoices}
                  </th>
                  <th
                    style={{
                      width: "18%",
                      maxWidth: "18%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {labels?.tables?.convertFunds?.coin}
                  </th>
                  <th
                    style={{
                      width: "18%",
                      maxWidth: "18%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {labels?.tables?.convertFunds?.availableBalance}
                  </th>
                  <th
                    style={{
                      width: "18%",
                      maxWidth: "18%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {labels?.tables?.convertFunds?.btcValue}
                  </th>
                  <th
                    style={{
                      width: "18%",
                      maxWidth: "18%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {labels?.tables?.convertFunds?.extValue.replace(
                      "{{token}}",
                      exchangeToken
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <React.Fragment>
                  {dusts?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="date custom-checkbox-wrapper">
                          <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            name={`checkbox-${index}`}
                            onChange={() =>
                              handleCheckboxChange(
                                item.currency,
                                item.balanceInEXT
                              )
                            }
                            checked={selectedCoins.some(
                              (coin) => coin.currency === item.currency
                            )}
                          />
                          <label
                            htmlFor={`checkbox-${index}`}
                            className="custom-checkbox"
                          >
                            {selectedCoins.some(
                              (coin) => coin.currency === item.currency
                            ) && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="check-icon"
                              />
                            )}
                            <p className="mb-0" style={{ marginLeft: 32 }}>
                              {labels?.tables?.convertFunds?.checkBoxLabel}
                            </p>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="pair">{item.currency}</div>
                      </td>
                      <td>{item.balance.toFixed(8)}</td>
                      <td className="truncate">
                        {item.balanceInBTC.toFixed(8)}
                      </td>
                      <td>{item.balanceInEXT.toFixed(8)}</td>
                    </tr>
                  ))}
                </React.Fragment>
              </tbody>
            </table>
          </div>
          <div className="mt-3">
            {dusts?.length < 1 ? (
              <p> {labels?.tables?.convertFunds?.noCurrencies}</p>
            ) : (
              <p class="fw-lighter">
                {labels?.wallet?.convertFunds?.message
                  .replace(
                    "<2>{{numFunds}}</2>",
                    getTotalBalance(selectedCoins)
                  )
                  .replace("{{token}}", exchangeToken)
                  .replace("<1>{{numCurrencies}}</1>", selectedCoins?.length)}
              </p>
            )}

            <button
              onClick={() => convertFunds(selectedCoins)}
              className="btn btn-primary w-100"
              disabled={selectedCoins?.length === 0 ? true : false}
            >
              {labels?.tables?.convertFunds?.button}
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ConvertFunds;
