import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './Overview.css';
import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer/';
 
import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";
import { useModalContext } from '../../../Context/ModalContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass, faTimes, faExclamation } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function OverView() {

  const { selectedTheme, selectedNumberFormat, selectedLanguage } = useModalContext();
  const [editModalVisible, setEditModalVisible] = useState(false);

  const labels = useSelector((state) => state?.labels?.data);

    const [data, setData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [profile, setProfile] = useState();

    const token = localStorage.getItem("token");
    const [hasError, setHasError] = useState(false);


    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
                
          setProfile(data.data)
        } catch (error) {
          setHasError(true);
        }
      };
    
      fetchProfile();
    }, []);

    /*
     * Pagination and Orderby
    */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    // const [sortBy, setSortBy] = useState({ key: null, ascending: true });
    const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });
  
    // Function to sort data based on column key and sort order
    const sortedData = data.sort((a, b) => {
      if (sortBy.key) {
        const valueA = a[sortBy.key];
        const valueB = b[sortBy.key];
        if (valueA < valueB) return sortBy.ascending ? -1 : 1;
        if (valueA > valueB) return sortBy.ascending ? 1 : -1;
      }
      return 0;
    });
  
    // Function to handle column header click for sorting
    const handleSort = (key) => {
      if (sortBy.key === key) {
        setSortBy({ key, ascending: !sortBy.ascending });
      } else {
        setSortBy({ key, ascending: true });
      }
    };
  
    // Function to handle pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  
    /*
     * Pagination and Orderby Ended
    */

 
    useEffect(() => {
 
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserInfo(userInfo)
 
      // Axios ile GET isteği yapalım
      axios.get(`${process.env.REACT_APP_API_REST_URL}/api/LoginHistory`, {
        headers: {
          'Authorization': `Bearer ${token}` // Bearer token gönderimi
        }
      })
      .then(response => {
        setData(response?.data?.data); 
        // console.log(response?.data?.data);
      })
      .catch(error => {
        setHasError(true);
      });
    }, []);  


    const formatDate = (dateString, selectedLanguage) => {
      try {
        const date = new Date(dateString);
        if (isNaN(date)) {
          throw new Error("Invalid date");
        }
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
    
        const locale = selectedLanguage?.code || 'en'; 
        return new Intl.DateTimeFormat(locale, options).format(date);
      } catch (error) {
        return "Invalid date";
      }
    };
    

    
    const toggleEditModal = () => {
      setEditModalVisible((prev) => !prev);
    };


    const [userWithdrawalLimits, setUserWithdrawalLimits] = useState();

    useEffect(() => {
  
      const token = localStorage.getItem("token");
 
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserInfo(userInfo)

      // Axios ile GET isteği yapalım
      axios.get(`${process.env.REACT_APP_API_REST_URL}/api/Get_User_Withdrawal_Limits`, {
        headers: {
          'Authorization': `Bearer ${token}` // Bearer token gönderimi
        }
      })
      .then(response => {
        setUserWithdrawalLimits(response?.data?.data); 
      })
      .catch(error => {
        setHasError(true);
      });
    }, []);  


    const [volumeDiscountLimits, setVolumeDiscountLimits] = useState();

    useEffect(() => {
  
      const token = localStorage.getItem("token");
 
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserInfo(userInfo)
 
      // Axios ile GET isteği yapalım
      axios.get(`${process.env.REACT_APP_API_REST_URL}/api/Get_User_Volume_Discount_Limits`, {
        headers: {
          'Authorization': `Bearer ${token}` // Bearer token gönderimi
        }
      })
      .then(response => {
        setVolumeDiscountLimits(response?.data?.data); 
      })
      .catch(error => {
        setHasError(true);
      });
    }, []);


    const [userVolumeDiscountLimits, setUserVolumeDiscountLimits] = useState();


    const fetchUserVolumeDiscountLimits = async () => {
      const token = localStorage.getItem("token");

 
      const response = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/Get_User_Volume_Discount_Limits`, {
        headers: {
          'Authorization': `Bearer ${token}` // Bearer token gönderimi
        }
      })
     setUserVolumeDiscountLimits(response?.data?.data)
  
    }
  
    useEffect(() => {
      fetchUserVolumeDiscountLimits()
    }, [])

    if (hasError) {
      throw new Error("");
    }

    const lastItem = userVolumeDiscountLimits && userVolumeDiscountLimits.length > 0 
    ? userVolumeDiscountLimits[userVolumeDiscountLimits.length - 1] 
    : null;
    const lastItemCurrency = lastItem?.currency;
  
    const tradedVolumeBase = lastItem?.tradedVolume;
    const tradedVolume = tradedVolumeBase;
  
    // Mevcut ve önceki tier'leri bulmak
    let currentTier = userVolumeDiscountLimits?.find(
      (tier) => tradedVolume < tier.tradedVolumeLimit
    );
  
    const currentTierIndex = userVolumeDiscountLimits 
    ? userVolumeDiscountLimits.indexOf(currentTier)
    : -1;
  
  const previousTier = userVolumeDiscountLimits && currentTierIndex > 0
    ? userVolumeDiscountLimits.slice(0, currentTierIndex).pop()
    : { tradedVolumeLimit: 0 };
  
    // Eğer tüm tier'leri geçtiyse en son tier'de olur
    if (!currentTier) {
      currentTier = lastItem;
    }
  
    // Progress bar için yüzdelik hesaplama
    const progressPercentage =
      ((tradedVolume - previousTier?.tradedVolumeLimit) /
        (currentTier?.tradedVolumeLimit - previousTier?.tradedVolumeLimit)) *
      100;
    

    const renderKYCStatus = () => {
      if (!profile || !profile.kycStatus) {
        return null;
      }
      switch (profile?.kycStatus) {
        case 'Approved':
          return (
            <>
          <div className="verify-box d-flex flex-row">
                <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg> 
              </div>
              <div className='d-flex align-items-center w-100 justify-content-between'>
              <span className='link-body ps-3'>{labels?.account?.accountVerification.statusApproved}</span>
              </div>
            </div>
            </>
          );
        case 'Never Submitted':
          return (
            <>
              <div className="verify-box d-flex flex-row">
                <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg> 
              </div>
              <div className='d-flex align-items-center w-100 justify-content-between'>
              <Link to="/account/verification" className="link-body ps-2">{labels?.account?.accountVerification.statusNew}</Link>
              </div>
            </div>
            </>
          );
        case 'Pending':
          return <div className="verify-box d-flex flex-row">
                <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg> 
              </div>
              <div className='d-flex align-items-center w-100 justify-content-between'>
              <span className='link-body ps-3'>{labels?.account?.accountVerification.statusPending}</span>
              <FontAwesomeIcon icon={faHourglass} className="text-success" /> 
              </div>
            </div>
        case 'Rejected':
          return <>
              <div className="verify-box d-flex flex-row">
                <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg> 
              </div>
              <div className='d-flex align-items-center w-100 justify-content-between'>
              <span className='link-body ps-3'>{labels?.account?.accountVerification.statusRejected}</span>
              <FontAwesomeIcon icon={faTimes} className="text-danger" /> 
              </div>
            </div>
          </>
        case 'Request Info':
          return <>
                        <div className="verify-box d-flex flex-row">
                <div className="head">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM12 7C12 7.53043 11.7893 8.03914 11.4142 8.41421C11.0391 8.78929 10.5304 9 10 9C9.46957 9 8.96086 8.78929 8.58579 8.41421C8.21071 8.03914 8 7.53043 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21071 9.46957 5 10 5C10.5304 5 11.0391 5.21071 11.4142 5.58579C11.7893 5.96086 12 6.46957 12 7ZM10 11C9.0426 10.9998 8.10528 11.2745 7.29942 11.7914C6.49356 12.3083 5.85304 13.0457 5.454 13.916C6.01668 14.5706 6.71427 15.0958 7.49894 15.4555C8.28362 15.8152 9.13681 16.0009 10 16C10.8632 16.0009 11.7164 15.8152 12.5011 15.4555C13.2857 15.0958 13.9833 14.5706 14.546 13.916C14.147 13.0457 13.5064 12.3083 12.7006 11.7914C11.8947 11.2745 10.9574 10.9998 10 11Z" fill="#0E9F6E" />
                </svg> 
              </div>
              <div className='d-flex align-items-center w-100 justify-content-between'>
              <span className='link-body ps-3'>{labels?.account?.accountVerification.statusRequestInfo}</span>
              <FontAwesomeIcon icon={faExclamation} className="text-info" /> 
              </div>
            </div>
          </>
        default:
          return <div className="verify-box">
          <FontAwesomeIcon icon={faExclamation} className="text-danger" /> 
            <span className='link-body ps-2'>Unknown KYC Status.</span>
          </div>
      }
    };
        
  return (
    <>
  <Header />


{/* Main */}
<section className="main-page">
  <SideBar mainmenu="Account" submenu="AccountOverview" />
  <div className="main-content">
  <div className="main-content">
        <div className="row">
          <div className="col-lg-7">
            <div className="avatar-box">
              <div className="picture"><img src="/images/materials/tomas.jpg" alt="" /></div>
              <div className="text">
                <h3>{userInfo?.firstName} {userInfo?.middleName} {userInfo?.lastName}</h3>
                <h5>{userInfo?.email}</h5>
                <ul>
                  <li>
                    <p>{labels?.forms?.fiatWithdrawalManual.accounttype}</p>
                    <span>{labels?.generics?.regular}</span>
                  </li>
                  <li>
                    <p>{labels?.account?.overview.joinedOn}</p>
                    <span>{formatDate(userInfo?.joinedOn, selectedLanguage)}</span>
                  </li>
                </ul>
                {/*
                <button className="btn btn-edit-profile d-flex align-items-center gap-2" onClick={toggleEditModal} >
                  <img src={selectedTheme.name === "dark" ? "/images/icons/pencil-alt.svg" : "/images/icons/pencil-alt-black.svg"} alt="" /> 
                  <span>Edit Profile</span>
                </button>
                */}
              
              <div className='d-flex'>
              <Link className="btn btn-edit-profile d-flex align-items-center gap-2" to="/account/change-password">
                  <img src={selectedTheme.name === "dark" ? "/images/icons/pencil-alt.svg" : "/images/icons/pencil-alt-black.svg"} alt="" /> 
                  <span>{labels?.forms?.changePassword.title}</span>
              </Link>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            {renderKYCStatus()}

            {userWithdrawalLimits?.map((item, index) => {
                const progressPercentage = item.limit ? (item.withdrawn / item.limit) * 100 : 0;
              return(
            <div key={index} className='verify-box mt-3'>
            <p className="withdrawal-limit-text mb-1">{labels?.wallet?.withdrawals?.withdrawalLimitStatus}: <b> {formatNumberWithComma(item?.withdrawn, selectedNumberFormat)} / {formatNumberWithComma(item?.limit, selectedNumberFormat)} {item.currency}</b></p>
          <div className="progress" style={{ height: '20px' }}>
                      <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progressPercentage}%` }}
                      aria-valuenow={progressPercentage}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      >
                        {progressPercentage.toFixed(0)}%
                      </div>
                    </div>
          </div>
              )})}

{lastItem && (
<>
<div className="content-box content-text">
    <div className="d-flex justify-content-between">
  <span className="small">
    {tradedVolume} {lastItem.currency} (
    {labels?.account?.tradingDiscount?.discountValue.replace(
      "{{percent}}",
      Number(tradedVolume) === 0 ? 0 : currentTier.discount
    )}
    )
  </span>
  <span className="small">
    {currentTier.tradedVolumeLimit} {lastItem.currency}
  </span>
</div>


      <div className="progress" style={{ height: "20px" }}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={progressPercentage}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <div className="d-flex justify-content-between">
        <span className="small">
          {previousTier ? (
            <>
              {labels?.tables?.feeDiscount?.tier}{" "}
              {userVolumeDiscountLimits.indexOf(currentTier)}
            </>
          ) : (
            "0"
          )}
        </span>
        <span className="small">
          {currentTier ? (
            <>
              {labels?.tables?.feeDiscount?.tier}{" "}
              {userVolumeDiscountLimits.indexOf(currentTier) + 1}
            </>
          ) : (
            "No Current Tier"
          )}
        </span>
      </div>
    </div>
</>
)}

          </div>
        </div>
        <div className="content-box">
          <h4 className="tt">{labels?.account?.overview.loginHistory}</h4> 
          <div> 
            </div> 
          <div className="table-responsive">
            <table className="table">
              <thead className="w-100"> 
                <tr>
                  <th style={{width:'12%'}} className="text-uppercase">{labels?.tables?.deviceWhitelisting.device}</th>
                  <th style={{width:'18%'}} onClick={() => handleSort('startedOn')} className="text-uppercase">
                    {sortBy.key==="startedOn" ? <u>{labels?.tables?.loginHistory.startedOn}</u> : labels?.tables?.loginHistory.startedOn } 
                    {sortBy.key==="startedOn" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="startedOn" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('ip')} className="text-uppercase">
                    {sortBy.key==="ip" ? <u>{labels?.tables?.loginHistory.ipAddress}</u> : labels?.tables?.loginHistory.ipAddress }
                    {sortBy.key==="ip" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="ip" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('os')} className="text-uppercase">
                    {sortBy.key==="os" ? <u>{labels?.tables?.loginHistory.os}</u> : labels?.tables?.loginHistory.os }
                    {sortBy.key==="os" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="os" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('browser')} className="text-uppercase">
                    {sortBy.key==="browser" ? <u>{labels?.tables?.loginHistory.browser}</u> : labels?.tables?.loginHistory.browser }
                    {sortBy.key==="browser" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="browser" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%'}} onClick={() => handleSort('location')} className="text-uppercase">
                    {sortBy.key==="location" ? <u>{labels?.tables?.loginHistory.location}</u> : labels?.tables?.loginHistory.location }
                    {sortBy.key==="location" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="location" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                </tr>
              </thead>
              <tbody>
  
              {
              currentItems.length>0 ?  

              currentItems.map((item, index) => (
                <tr key={index}> 
                  <td style={{ width: '12%' }}>
                    <div className="pair">
                      {item.os === "iOS" || item.os === "android" ? (
                        <>
                        <img src={selectedTheme.name === "dark" ? "/images/icons/phone-white.png" : "/images/icons/phone-black.png"} />
                        </>
                      ) : (
                        <>
                         <img src={selectedTheme.name === "dark" ? "/images/icons/desktop-computer.svg" : "/images/icons/desktop-computer-black.svg"} alt="" />
                        </>
                      )}
                      
                      {item.os === "iOS" || item.os === "android" ? "Phone" : "PC"}
                    </div>
                  </td>
                  <td>
                    <div className="date d-flex flex-column">
                      { splitDateTime(item.startedOn).date }
                      <span className="ms-0">{ splitDateTime(item.startedOn).time }</span>
                    </div>
                  </td>
                  <td style={{ color: '#0E9F6E', width: '18%' }}>
  <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-${item.ip}`}>{item.ip}</Tooltip>}
  >
    <span
      style={{
        display: 'inline-block',
        maxWidth: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        cursor: 'pointer', 
      }}
    >
      {item.ip}
    </span>
  </OverlayTrigger>
</td>
                  <td>
                  {item.os}
                  </td>
                  <td>
                  {item.browser}
                  </td>
                  <td>
                  {item.location && item.location.trim().startsWith(',') ? item.location.trim().slice(1) : item.location}
                  </td>
                </tr>
                ))
                :
                <>
                <tr key="1" style={{height:200}}> 
                    <td colSpan={6} align="center">
                    <img src="/images/loading.gif" width={50}/>
                    <br /><br />
                    <span style={{color:'#ccc'}}>{labels?.generics?.loading}</span>   
                    </td>
                </tr>
                </>
                
              }
  
              </tbody>
            </table>
          </div>
          <ul className="pagination"> 
          
          <li>
            {currentPage > 1 ? (
              <Link onClick={() => setCurrentPage(currentPage - 1)}>{labels?.pagination?.prev}</Link>
              ) : (
              <Link  className="disabled" style={{color:'#555'}}>{labels?.pagination?.prev}</Link>
            )}
          </li>
          <li>
            {indexOfLastItem < data?.length ? (
              <Link onClick={() => setCurrentPage(currentPage + 1)}>{labels?.pagination?.next}</Link>
              ) : (
              <Link className="disabled" style={{color:'#555'}}>{labels?.pagination?.next}</Link>
            )}
          </li>

      </ul>
        </div>
      </div>
  </div> 
      </section>
 <Footer />
    </>
  );
}
 

export default OverView;
