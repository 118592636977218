  import React, { useState, useEffect } from "react";
  import Modal from 'react-bootstrap/Modal';
  import Button from 'react-bootstrap/Button';
  import { toast } from "react-toastify";
  import { useSelector } from "react-redux";

  const ApiModal = ({ showModal1, handleCloseModal1, addApi }) => {
      const labels = useSelector((state) => state.labels.data);

      const [ipAddress, setIpAddress] = useState('');
      const [keyType, setKeyType] = useState('trade');
      const [twoFactorAuthKey, setTwoFactorAuthKey] = useState('');

      const safeCharactersRegex = /^[a-zA-Z0-9\s.,]*$/;


      const handleIpAddressChange = (e) => {
        
        const value = e.target.value;
        if (!safeCharactersRegex.test(value)) {
          return;
        } else {
          setIpAddress(e.target.value);
        }
      };

      const handleTwoFactorChange = (e) => {
        // Yalnızca harf ve rakamları kabul et
        const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        
        // Değeri güncelle
        setTwoFactorAuthKey(filteredValue);
      };
  
    const addApiFunction = () => {

      if (ipAddress === null || ipAddress === '') {
        const message = labels?.forms?.createApiKey?.ip?.placeholder;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        return false;
      }

      if (twoFactorAuthKey === null || twoFactorAuthKey === '') {
        const message = labels?.messages?.invalid2fa;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        return false;
      }
  
      let reqBody = {
          IpAddresses:ipAddress,
          keyType:keyType,
          twoFactorAuthKey:twoFactorAuthKey
        }
        addApi(reqBody);  

        const delayedFunction = () => {
          setIpAddress('');
          setTwoFactorAuthKey('');
        };
    
        // Set a timeout for 2 seconds and call the delayed function
        const timeoutId = setTimeout(delayedFunction, 2000);
  
    }
      
    useEffect(() => {  
      }, []);

    return (
      <Modal show={showModal1} onHide={handleCloseModal1} className="add-api-modal">
        <Modal.Header closeButton>
          <Modal.Title>{labels?.forms?.createApiKey?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
  
        <div className='row' style={{paddingBottom:'15px', paddingTop:'15px'}}>
          <div className='col-md-12'>
              <div className="form-group">
                  <label for="usr">{labels?.tables?.walletOverview?.currency}:</label>
                  <select className="form-control" onChange={(e) => setKeyType(e.target.value)}> 
                    <option value={"trade"}>Trade</option>
                    <option value={"readonly"}>Read Only</option> 
                  </select>
              </div>
          </div>
        </div>

        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group">
              <label for="usr">{labels?.forms?.createApiKey?.ip?.placeholder}:</label>
              <input 
              type="text" 
              className="form-control"
              value={ipAddress}
              onChange={handleIpAddressChange}
              />
          </div>
        </div>
        </div>
  
        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group">
              <label for="usr">2FA:</label>
              <input 
              type="text" 
              className="form-control"
              value={twoFactorAuthKey}
              onChange={handleTwoFactorChange}
              />
          </div>
        </div>
        </div>
  
        <div className='row' style={{paddingBottom:'15px'}}>
          <div className='col-md-12'> 
          <div className="form-group"> 
            <button class="btn button-danger" type="button" style={{width:'100%'}} onClick={addApiFunction}>
            {labels?.forms?.createApiKey?.button}
            </button> 
          </div>
        </div>
        </div>
  
        </Modal.Body>
        <Modal.Footer> 
          <Button variant="secondary" onClick={handleCloseModal1}>
            {labels?.buttons?.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  export default ApiModal;
