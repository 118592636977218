import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingsService from "../../Api/settings";

const initialState = {
  exchangeCommonSettings: [],
  tradingPairStats: {
    latestTradePrice: null,
  },
};

export const getSettings = createAsyncThunk(
  "get/settings",
  async (_, thunkAPI) => {
    try {
      return await settingsService.getSettings();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    setLatestTradePrice: (state, action) => {
      state.tradingPairStats.latestTradePrice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.exchangeCommonSettings = action?.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      });
  },
});

export const { setLatestTradePrice } = exchangeSlice.actions;
export default exchangeSlice.reducer;
