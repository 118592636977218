import snsWebSdk from "@sumsub/websdk";
import React, { useEffect, useState } from "react";
import "./Verification.css";
import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglass,
  faCheckCircle,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import KYCOnfido from "./KYCOnfido";
import { Dropdown, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Tabs, Tab, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { COUNTRY_CODES_WITH_FLAG } from "../../../Common/CountryData";

function Verification() {
  const labels = useSelector((state) => state?.labels?.data);

  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [settings, setSettings] = useState();

  const token = localStorage.getItem("token");

  const [profile, setProfile] = useState();

  const [kycFormData, setKycFormData] = useState();

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_REST_URL}/api/GetSettings`
        );
        const data = await response.json();

        setSettings(data.data);
      } catch (error) {}
    };

    fetchSettings();
  }, []);

  const [userKycLevel, setUserKycLevel] = useState();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_REST_URL}/api/GetProfile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        setProfile(data.data);
        setUserKycLevel(data?.data?.kycApprovedLevel);
      } catch (error) {}
    };

    fetchProfile();
  }, []);

  const [accessToken, setAccessToken] = useState(null);

  // useEffect(() => {
  const fetchAccessToken = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/KYC_OnSite_AccessToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.status === "Success") {
        setAccessToken(data.data.accessToken);
        setIsScriptLoaded(true);
      }
    } catch (error) {}
  };

  const launchWebSdk = (
    accessToken,
    applicantEmail,
    applicantPhone,
    customI18nMessages
  ) => {
    let snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => getNewAccessToken())
      .withConf({
        lang: "en",
        email: applicantEmail,
        phone: applicantPhone,
        i18n: customI18nMessages,
        uiConf: {
          customCss: "https://url.com/styles.css", // Example URL, replace with actual URL if needed
        },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on("idCheck.stepCompleted", (payload) => {})
      .on("idCheck.onError", (error) => {})
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  };

  useEffect(() => {
    if (isScriptLoaded && accessToken) {
      launchWebSdk(accessToken, "", "", "");
    }
  }, [isScriptLoaded, accessToken]);

  const getNewAccessToken = () => {
    // Implement your function to get a new access token from your backend
    return axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/KYC_OnSite_AccessToken`,
        { force: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response.data.data.accessToken);
  };

  const [formValues, setFormValues] = useState({});
  const [formData, setFormData] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const [userKyc, setUserKyc] = useState();
  const [userKycPresent, setUserKycPresent] = useState(false);
  const [kycFormValues, setKycFormValues] = useState({});

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_REST_URL}/api/GOKYC_Get_Kyc_form`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setFormData(data?.data);
        const initialFormValues = {};

        // Tüm fieldName anahtarlarını boş değerlerle ekle
        data?.data?.fieldsList?.forEach((field) => {
          initialFormValues[field.fieldName] = "";
        });
        setKycFormValues(initialFormValues); // Başlangıç değerlerini ata
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    // Fetch form data on component mount
    if (settings?.kyc.enable_GoKYC === "True") {
      fetchForm();
    }
  }, [token, settings?.kyc.enable_GoKYC]);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_REST_URL}/api/GOKYC_Get_User_KYC`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        // Kullanıcı KYC verisini ayarla
        setUserKyc(data?.data);

        // userKyc verisinin dolu olup olmadığını kontrol et
        if (data?.data && data.data.length > 0) {
          setUserKycPresent(true);
          setKycFormData(data?.data);
          const initialValues = {};
          data.data.forEach((field) => {
            initialValues[field.fieldName] = field.value;
          });
          setKycFormValues(initialValues);
        } else {
          setUserKycPresent(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    // Komponent ilk yüklendiğinde ve settings'de GoKYC etkinse veriyi al
    if (settings?.kyc.enable_GoKYC === "True") {
      fetchForm();
    }
  }, [token, settings?.kyc.enable_GoKYC]);

  useEffect(() => {}, [kycFormValues]);

  const handleInputChange = (e, fieldName) => {
    const { name, value, type, files } = e.target;

    const safeCharactersRegex = /^[a-zA-Z0-9\s.,@]*$/;

    if (type === "file") {
      const file = files[0];

      // File type validation
      if (
        file &&
        !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        const message = labels?.general?.fileTypeError;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        e.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setKycFormValues((prevValues) => ({
          ...prevValues,
          [fieldName]: reader.result,
        }));
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      // Metin doğrulaması
      if (type === "text" && !safeCharactersRegex.test(value)) {
        return;
      }

      setKycFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // KYC'den gelen değerleri formValues'a ekle
    const mergedFormValues = { ...kycFormValues, ...formValues };

    const errors = {};

    formData?.fieldsList?.forEach((field) => {
      // Kullanıcının KYC seviyesine göre zorunlu alanları kontrol et
      if (
        field.mandatory &&
        !mergedFormValues[field.fieldName] &&
        field.level === Number(userKycLevel) + 1 // Sadece tam olarak bu seviyeyi kontrol et
      ) {
        errors[field.fieldName] = true;
      }
    });

    const countryCode = mergedFormValues["MobilePhone_countryCode"] || "";
    const phoneNumber = mergedFormValues["MobilePhone"] || "";
    const fullPhoneNumber = `${countryCode}${phoneNumber.replace(/\s/g, "")}`;

    const payload = {
      ServiceProviderName: formData.serviceProviderName,
      FieldsList: formData.fieldsList.map((field) => {
        const fieldValue =
          field.fieldName === "MobilePhone"
            ? fullPhoneNumber
            : mergedFormValues[field.fieldName] || "";
        return {
          FieldName: field.fieldName,
          Value: fieldValue,
          FieldsList: null,
          IsIgnored: false,
        };
      }),
    };

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_REST_URL
          }/api/GOKYC_Submit_KYC_Form?level=${Number(userKycLevel) + 1}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const result = await response.json();

          if (result.status === "Success") {
            const messageKey = result.message;
            const getDynamicMessage = (key) => {
              return labels?.messages?.[key] || key;
            };
            const dynamicMessage = getDynamicMessage(messageKey);

            toast.success(dynamicMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
        }
      } catch (error) {
        setHasError(true);
      }
    }
  };

  if (hasError) {
    throw new Error("");
  }

  const maxLevel = Math.max(
    ...(formData?.fieldsList?.map((field) => field.level) || [])
  );

  const defaultTabKey =
    profile?.kycApprovedLevel && profile?.kycApprovedLevel !== "0"
      ? `kyc${profile?.kycApprovedLevel}`
      : "kyc1";

  const [activeKey, setActiveKey] = useState(defaultTabKey);

  useEffect(() => {
    setActiveKey(defaultTabKey);
  }, [defaultTabKey]);

  const currentLevel = parseInt(activeKey.replace("kyc", ""), 10);
  const kycApprovedLevel = Number(profile?.kycApprovedLevel);

  const phoneWithoutCountryCode = kycFormValues?.MobilePhone
    ? kycFormValues?.MobilePhone?.replace(/^\+90(5\d{9})$/, "$1") // Türkiye için özel durum
    : kycFormValues?.MobilePhone?.replace(/^\+\d{1,3}/, "") || ""; // Diğer tüm ülkeler için genel çözüm

  const [isKycTabsVisible, setIsKycTabsVisible] = useState(false);

  const renderKYCStatus = () => {
    if (!profile || !profile.kycStatus) {
      return null;
    }

    if (settings?.kyc.enable_GoKYC === "False") {
      switch (profile?.kycStatus) {
        case "Approved":
          return (
            <div className="verify-box">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success"
                size="4x"
              />
              <span className="kyc-info-box-text">
                Account {profile?.kycStatus}
              </span>
            </div>
          );
        case "Never Submitted":
          return (
            <>
              {(settings?.kyc.webSDK_Provider_Name === "sumsub" ||
                settings?.kyc.webSDK_Provider_Name === "jumio") && (
                <div
                  id="sumsub-websdk-container"
                  style={{ minHeight: "500px" }}
                ></div>
              )}
              {settings?.kyc.webSDK_Provider_Name === "onfido" && (
                <KYCOnfido token={token} kyc={settings?.kyc} />
              )}
            </>
          );
        case "Pending":
          return (
            <div className="verify-box">
              <FontAwesomeIcon
                icon={faHourglass}
                className="text-success"
                size="4x"
              />
              <span className="kyc-info-box-text">
                {labels?.account?.accountVerification.pending}
              </span>
            </div>
          );
        case "Rejected":
        case "Request Info":
          return (
            <div
              id="sumsub-websdk-container"
              style={{ minHeight: "500px" }}
            ></div>
          );
        default:
          return <></>;
      }
    } else if (settings?.kyc.enable_GoKYC === "True") {
      return (
        <>
          {profile?.kycStatus === "Approved" && userKycLevel > 2 && (
            <div className="verify-box">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success"
                size="4x"
              />
              <span className="kyc-info-box-text">
                {labels?.account?.accountVerification.approved}
              </span>
            </div>
          )}

          {profile?.kycStatus === "Pending" && (
            <div className="verify-box">
              <FontAwesomeIcon
                icon={faHourglass}
                className="text-success"
                size="4x"
              />
              <span className="kyc-info-box-text">
                {labels?.account?.accountVerification.pending}
              </span>
            </div>
          )}

          {(profile?.kycStatus === "Approved" ||
            profile?.kycStatus === "Never Submitted" ||
            profile?.kycStatus === "Request Info" ||
            (profile?.kycStatus === "Rejected" && isKycTabsVisible)) &&
            (profile?.kycApprovedLevel === "0" ||
              profile?.kycApprovedLevel === "1" ||
              profile?.kycApprovedLevel === "2" ||
              profile?.kycApprovedLevel === null) && (
              <>
                {profile?.kycStatus === "Request Info" && (
                  <div className="mb-3">
                    <div className="verify-box d-flex flex-column">
                      <span className="kyc-info-box-text">
                        {labels?.account?.accountVerification?.requestInfo}
                      </span>
                      <span className="kyc-info-box-text">
                        {profile?.kycRequestInfo}
                      </span>
                    </div>
                  </div>
                )}

                {/* Generated tabs */}
                <Container className="ps-lg-0 mb-5">
                  <Tabs
                    id="kyc-tabs"
                    className="verification-tabs mb-3 nav-pills border-0"
                    activeKey={activeKey}
                    onSelect={(key) => setActiveKey(key)}
                  >
                    {Array.from({ length: maxLevel }, (_, index) => (
                      <Tab
                        eventKey={`kyc${index + 1}`}
                        title={labels?.account?.accountVerification?.kycLevel.replace(
                          "{{level}}",
                          `${index + 1}`
                        )}
                        key={index + 1}
                        className="nav-link"
                      >
                        {kycApprovedLevel >= index + 1 ? (
                          <div className="verify-box">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success"
                              size="4x"
                            />
                            <span className="kyc-info-box-text">
                              {labels?.account?.accountVerification?.verificationMsg.replace(
                                "{{level}}",
                                kycApprovedLevel
                              )}
                            </span>
                          </div>
                        ) : (
                          <>
                            {/* Form alanları */}
                            <form
                              onSubmit={handleSubmit}
                              className="custom-form custom-kyc-form"
                            >
                              {formData?.fieldsList
                                ?.filter(
                                  (field) => field.level === currentLevel
                                ) // Seçili tabın seviyesine göre filtrele
                                .map((field, index) => (
                                  <div key={index} className="form-group mt-3">
                                    <label htmlFor={field.fieldName}>
                                      {labels?.kyc?.[field.fieldName]?.label}
                                    </label>

                                    {/* Dropdown alanları */}
                                    {field.inputType === "DropDown" &&
                                    typeof field.options === "object" ? (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="outline-secondary"
                                          id={`dropdown-${field.fieldName}`}
                                          className={
                                            validationErrors[field.fieldName]
                                              ? "is-invalid"
                                              : ""
                                          }
                                        >
                                          {kycFormValues[field.fieldName]
                                            ? Object.keys(field.options)
                                                .find(
                                                  (country) =>
                                                    field.options[country] ===
                                                    kycFormValues[
                                                      field.fieldName
                                                    ]
                                                )
                                                ?.charAt(0)
                                                .toUpperCase() +
                                              Object.keys(field.options)
                                                .find(
                                                  (country) =>
                                                    field.options[country] ===
                                                    kycFormValues[
                                                      field.fieldName
                                                    ]
                                                )
                                                ?.slice(1)
                                                .toLowerCase()
                                            : "Select an option"}
                                          <svg
                                            className="ms-1"
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 320 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                          </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          {Object.entries(field.options).map(
                                            ([country, code]) => (
                                              <Dropdown.Item
                                                key={code}
                                                onClick={() =>
                                                  handleInputChange(
                                                    {
                                                      target: {
                                                        name: field.fieldName,
                                                        value: code,
                                                      },
                                                    },
                                                    field.fieldName
                                                  )
                                                }
                                              >
                                                {country
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  country
                                                    .slice(1)
                                                    .toLowerCase()}
                                              </Dropdown.Item>
                                            )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : field.inputType === "RadioButton" &&
                                      typeof field.options === "object" ? (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="outline-secondary"
                                          id={`radiobutton-${field.fieldName}`}
                                          className={
                                            validationErrors[field.fieldName]
                                              ? "is-invalid"
                                              : ""
                                          }
                                        >
                                          {kycFormValues[field.fieldName]
                                            ? (() => {
                                                const selectedKey = Object.keys(
                                                  field.options
                                                ).find(
                                                  (item) =>
                                                    field.options[item] ===
                                                    kycFormValues[
                                                      field.fieldName
                                                    ]
                                                );

                                                const formattedSelectedKey =
                                                  selectedKey
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                  selectedKey.slice(1);

                                                return selectedKey
                                                  ? labels?.kyc?.[
                                                      field.fieldName
                                                    ]?.options?.[
                                                      formattedSelectedKey
                                                    ] ||
                                                      labels?.kyc?.[
                                                        field.fieldName
                                                      ]?.options?.[
                                                        formattedSelectedKey
                                                      ]
                                                  : labels?.forms?.common
                                                      ?.select;
                                              })()
                                            : labels?.forms?.common?.select}
                                          <svg
                                            className="ms-1"
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 320 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                          </svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {Object.entries(field.options).map(
                                            ([item, code]) => (
                                              <Dropdown.Item
                                                key={code}
                                                onClick={() =>
                                                  handleInputChange(
                                                    {
                                                      target: {
                                                        name: field.fieldName,
                                                        value: code,
                                                      },
                                                    },
                                                    field.fieldName
                                                  )
                                                }
                                              >
                                                {
                                                  labels?.kyc?.[field.fieldName]
                                                    ?.options?.[code]
                                                }
                                              </Dropdown.Item>
                                            )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : field.fieldName === "MobilePhone" ? (
                                      <div>
                                        <div className="input-group">
                                          {/* Ülke kodu dropdown */}
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="outline-secondary"
                                              id={`dropdown-${field.fieldName}-countryCode`}
                                              className={
                                                validationErrors[
                                                  `${field.fieldName}_countryCode`
                                                ]
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                            >
                                              {/* MobilePhone'un ülke kodunu ayır ve seçili göster */}
                                              {COUNTRY_CODES_WITH_FLAG?.find(
                                                (country) =>
                                                  kycFormValues?.MobilePhone?.startsWith(
                                                    country.dial_code
                                                  )
                                              )?.dial_code ||
                                                "Select country code"}
                                              <svg
                                                className="ms-1"
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 320 512"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4-9.4 9.4-24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                              </svg>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                              style={{
                                                maxHeight: "300px",
                                                overflowY: "scroll",
                                              }}
                                            >
                                              {COUNTRY_CODES_WITH_FLAG?.map(
                                                (country) => (
                                                  <Dropdown.Item
                                                    key={country.code}
                                                    onClick={() =>
                                                      handleInputChange(
                                                        {
                                                          target: {
                                                            name: field.fieldName,
                                                            value: `${
                                                              country.dial_code
                                                            }${kycFormValues.MobilePhone.replace(
                                                              /^\+\d+/,
                                                              ""
                                                            )}`,
                                                          },
                                                        },
                                                        field.fieldName
                                                      )
                                                    }
                                                  >
                                                    {country.name} (
                                                    {country.dial_code})
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>

                                          {/* Telefon numarasını ülke kodu olmadan göster */}
                                          <Form.Control
                                            type="tel"
                                            className={
                                              validationErrors[field.fieldName]
                                                ? "is-invalid"
                                                : ""
                                            }
                                            name={field.fieldName}
                                            value={phoneWithoutCountryCode}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                field.fieldName
                                              )
                                            }
                                            placeholder="Phone number"
                                            id={field.fieldName}
                                          />
                                        </div>

                                        {/* Validation mesajı */}
                                        {validationErrors[field.fieldName] && (
                                          <div className="invalid-feedback">
                                            {
                                              labels?.forms?.validations
                                                ?.required
                                            }
                                          </div>
                                        )}
                                      </div>
                                    ) : field.inputType === "File" ? (
                                      <>
                                        <Form.Control
                                          type="file"
                                          accept="image/png, image/jpeg, image/jpg"
                                          className={
                                            validationErrors[field.fieldName]
                                              ? "is-invalid"
                                              : ""
                                          }
                                          name={field.fieldName}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              field.fieldName
                                            )
                                          }
                                          //  required={field.mandatory}
                                          id={field.fieldName}
                                        />
                                        <img
                                          src={kycFormValues[field.fieldName]}
                                          width={200}
                                          height={200}
                                          className="mt-3"
                                        />
                                      </>
                                    ) : (
                                      // Diğer input türleri
                                      <>
                                        <Form.Control
                                          type={
                                            field.inputType === "Date"
                                              ? "date"
                                              : field.inputType === "Number"
                                              ? "number"
                                              : "text"
                                          }
                                          className={
                                            validationErrors[field.fieldName]
                                              ? "is-invalid"
                                              : ""
                                          }
                                          name={field.fieldName}
                                          value={
                                            kycFormValues[field.fieldName] || ""
                                          }
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              field.fieldName
                                            )
                                          }
                                          placeholder={field.description}
                                        />
                                      </>
                                    )}
                                    {validationErrors[field.fieldName] && (
                                      <div className="invalid-feedback">
                                        {labels?.forms?.validations?.required}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              <Button
                                type="submit"
                                className="btn btn-primary mt-5 hover01"
                              >
                                {labels?.buttons?.submit}
                              </Button>
                            </form>
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tabs>
                </Container>
              </>
            )}

          {profile?.kycStatus === "Rejected" && !isKycTabsVisible && (
            <>
              <h3 className="text-danger text-center">
                {labels?.account?.accountVerification?.tips?.headerFailed}
              </h3>
              <div className="verify-box d-flex flex-column">
                <span className="kyc-info-box-text">
                  {labels?.account?.accountVerification?.rejectionReason}
                </span>
                <span className="kyc-info-box-text">
                  {profile?.kycRejectReason}
                </span>
                <div className="verification-tips">
                  <p>
                    {labels?.account?.accountVerification?.tips?.errorMessage}
                  </p>
                  <ul>
                    <li>{labels?.account?.accountVerification?.tips?.vpn}</li>
                    <li>
                      {labels?.account?.accountVerification?.tips?.public}
                    </li>
                    <li>
                      {labels?.account?.accountVerification?.tips?.completed}
                    </li>
                  </ul>
                </div>
              </div>
              <button
                className="btn btn-danger mt-3 w-100"
                onClick={() => setIsKycTabsVisible(true)}
              >
                {labels?.account?.accountVerification?.tryAgain}
              </button>
            </>
          )}
        </>
      );
    }
    fetchAccessToken();
  };

  return (
    <>
      <Header />
      <section className="main-page kyc-page">
        <SideBar mainmenu="Account" submenu="AccountVerification" />
        {profile && (
          <div className="main-content">
            <h4 className="title mb-3">
              {labels?.account?.verification?.link}
            </h4>
            {renderKYCStatus()}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default Verification;
