import axios from 'axios';
 
export const getBalance = async () => {
 
    const token = localStorage.getItem("token");
    const reqBody = { currency: 'ALL' }

    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
      reqBody,
      {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
      }
    ).catch(error => {
    });

    return response;
 
}

   
 


 

