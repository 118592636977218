import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Register.css";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
import CustomModal from "../../../Components/CustomModal/";
import { isValidEmail, validateString } from "../../../Common/Helpers";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { COUNTRY_CODES_WITH_FLAG } from "../../../Common/CountryData";

function Register() {
  const labels = useSelector((state) => state?.labels?.data);

  const navigate = useNavigate();
  // Check if a token exists in local storage
  const token = localStorage.getItem("token");

  // If token exists, redirect to the main page
  React.useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [middlename, setMiddlename] = useState();
  const [email, setEmail] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [message, setMessage] = useState();
  const [countryCode, setCountryCode] = useState([]);
  const [referralCode, setReferralCode] = useState("");

  const [isRegisterFormVisible, setIsRegisterFormVisible] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const validateEmailFormat = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidChars = /[<>{}\[\]]/;

    return emailRegex.test(input) && !invalidChars.test(input);
  };

  const handleEmailChange = (e) => {
    let value = e.target.value;
    const sanitizedValue = value.replace(/[<>{}\[\]'"\\;()]/g, "");

    setEmail(sanitizedValue);

    if (!validateEmailFormat(sanitizedValue)) {
      setError((prev) => ({
        ...prev,
        email: labels?.forms?.validations?.general,
      }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  };

  const handleFirstnameChange = (e) => {
    const value = e.target.value;
    let result = value.replace(/[^a-z]/gi, "");

    // Enforce max length of 20 characters
    if (result.length > 35) {
      result = result.slice(0, 35);
    }

    setFirstname(result);
  };

  const handleLastnameChange = (e) => {
    const value = e.target.value;
    let result = value.replace(/[^a-z]/gi, "");

    // Enforce max length of 20 characters
    if (result.length > 35) {
      result = result.slice(0, 35);
    }

    setLastname(result);
  };

  const handleReferralCodeChange = (e) => {
    const value = e.target.value;
    let result = value.replace(/[^a-z0-9]/gi, ""); // Only letters and numbers
    setReferralCode(result);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const checkRegister = async () => {
    setError({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
      checkbox: "",
    });

    let hasError = false;

    if (!firstname) {
      setError((prev) => ({
        ...prev,
        firstname: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (!lastname) {
      setError((prev) => ({
        ...prev,
        lastname: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (!email) {
      setError((prev) => ({
        ...prev,
        email: labels?.forms?.validations?.required,
      }));
      hasError = true;
    } else if (!validateEmailFormat(email)) {
      setError((prev) => ({
        ...prev,
        email: labels?.forms?.validations?.general,
      }));
      hasError = true;
    }

    if (selectedCountry.length === 0) {
      setError((prev) => ({
        ...prev,
        country: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (!password) {
      setError((prev) => ({
        ...prev,
        password: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (!confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: labels?.forms?.validations?.required,
      }));
      hasError = true;
    } else if (password !== confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: labels?.forms?.validations?.passwordMatch,
      }));
      hasError = true;
    }

    if (!isChecked) {
      setError((prev) => ({
        ...prev,
        checkbox: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (hasError) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/SignUp`,
        {
          country: selectedCountry,
          email: email,
          firstname: firstname,
          lastname: lastname,
          middlename: middlename,
          password: password,
          referralId: referralCode,
        }
      );

      if (response?.data?.status === "Success") {
        setIsRegisterFormVisible(false);
      } else {
        const messageKey = response?.data?.message;
        const getDynamicMessage = (key) => {
          return labels?.messages?.[key] || key;
        };
        const dynamicMessage = getDynamicMessage(messageKey);
        toast.error(dynamicMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  useEffect(() => {
    let myArray = [];
    COUNTRY_CODES_WITH_FLAG.map((item) => {
      const label = `(${item.dial_code}) ${item.name}`;
      myArray.push({ label: label, value: item.code, dial: item.dial_code });
    });
    setCountryCode(myArray);
  }, []);

  return (
    <>
      <Header />

      <CustomModal
        show={show}
        handleClose={handleClose}
        title="Register"
        message={message}
      />

      {/* Register */}
      <section className="login">
        <div className="container">
          <div className="row row-reset justify-content-center">
            <div className="col-lg-4">
              <div className="picture">
                <img
                  className="bg"
                  src="images/materials/signup_banner.png"
                  alt=""
                />
                <img className="logo" src="images/logo.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              {isRegisterFormVisible && !localStorage.getItem("token") ? (
                <form className="sign-up">
                  <h3>{labels?.form?.create}</h3>
                  <div className="input-container">
                    <label htmlFor="first-name">
                      {labels?.form?.firstName}
                    </label>
                    <input
                      type="text"
                      placeholder={
                        labels?.forms?.signUp?.firstName?.placeholder
                      }
                      value={firstname}
                      onChange={handleFirstnameChange}
                      className={`form-control ${
                        error.firstname ? "is-invalid" : ""
                      }`}
                    />
                    {error.firstname && (
                      <div className="invalid-feedback">{error.firstname}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="last-name">{labels?.form?.lastName}</label>
                    <input
                      type="text"
                      placeholder={labels?.forms?.signUp?.lastName?.placeholder}
                      value={lastname}
                      onChange={handleLastnameChange}
                      className={`form-control ${
                        error.lastname ? "is-invalid" : ""
                      }`}
                    />
                    {error.lastname && (
                      <div className="invalid-feedback">{error.lastname}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="your-email">{labels?.form?.email}</label>
                    <input
                      type="text"
                      placeholder={
                        labels?.forms?.signUp?.emailAddress?.placeholder
                      }
                      value={email}
                      onChange={handleEmailChange}
                      className={`form-control ${
                        error.email ? "is-invalid" : ""
                      }`}
                    />
                    {error.email && (
                      <div className="invalid-feedback">{error.email}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="country">{labels?.form?.country}</label>
                    <select
                      name="country"
                      id="country"
                      style={{ padding: "12px 18px 12px" }}
                      onChange={handleCountryChange}
                      value={selectedCountry}
                      className={`form-control ${
                        error.country ? "is-invalid" : ""
                      }`}
                    >
                      <option value="">
                        {labels?.forms?.signUp?.selectCountry?.placeholder}
                      </option>
                      {countryCode.map((country, index) => (
                        <option key={index} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    {error.country && (
                      <div className="invalid-feedback">{error.country}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="password">{labels?.form?.password}</label>
                    <input
                      type="password"
                      placeholder="••••••••••"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`form-control ${
                        error.password ? "is-invalid" : ""
                      }`}
                    />
                    {error.password && (
                      <div className="invalid-feedback">{error.password}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="password-confirm">
                      {labels?.forms?.signUp?.confirmPassword?.placeholder}
                    </label>
                    <input
                      type="password"
                      placeholder="••••••••••"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={`form-control ${
                        error.confirmPassword ? "is-invalid" : ""
                      }`}
                    />
                    {error.confirmPassword && (
                      <div className="invalid-feedback">
                        {error.confirmPassword}
                      </div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="referral-code">
                      {labels?.forms?.signUp?.refferalCode?.placeholder}
                    </label>
                    <input
                      type="text"
                      placeholder={
                        labels?.forms?.signUp?.refferalCode?.placeholder
                      }
                      value={referralCode}
                      onChange={handleReferralCodeChange}
                    />
                  </div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="check"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="check">
                      <p>
                        {labels?.forms?.signUp?.agree?.label.replace(
                          "{{exchangeName}}",
                          "LedgerBytes"
                        )}
                      </p>
                    </label>
                    {error.checkbox && (
                      <div className="invalid-feedback">{error.checkbox}</div>
                    )}
                  </div>
                  <input
                    type="button"
                    value={labels?.buttons?.signUp}
                    className="button-style-01"
                    onClick={checkRegister}
                  />
                  <p className="login-link">
                    {labels?.form?.signUpConfirmation}{" "}
                    <a href="/login">{labels?.form?.loginHere}</a>
                  </p>
                </form>
              ) : (
                <>
                  {localStorage.getItem("token") ? (
                    <>
                      <h5
                        style={{ color: "#ddd", paddingTop: 200 }}
                        align="center"
                      >
                        You are already logged in!
                      </h5>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div
                          className="col-md-12 mx-auto"
                          style={{ paddingTop: 100, color: "#ccc" }}
                        >
                          <h3 align="center">Registration Successfull!</h3>
                          <br />
                          <br />
                          <h6 align="center">
                            Please check your email for confirmation.
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Register End */}

      <Footer />
    </>
  );
}

export default Register;
