import axios from "axios";

const getOrderHistory = async (data) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_REST_URL}/api/OrderHistory?side=ALL&page=${data?.orderHistoryPage}&count=${data?.pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage?.getItem("token"),
      },
    }
  );
  return response?.data;
};

const placeOrder = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_REST_URL}/api/PlaceOrder`,
    data,
    {
      headers: {
        Authorization: "Bearer " + localStorage?.getItem("token"),
      },
    }
  );
  return response?.data;
};

const cancelOrder = async (data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_REST_URL}/api/CancelOrder`,
    data,
    {
      headers: {
        Authorization: "Bearer " + localStorage?.getItem("token"),
      },
    }
  );
  return response?.data;
};

const orderService = {
  getOrderHistory,
  placeOrder,
  cancelOrder,
};

export default orderService;
