import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import ErrorBoundaryWrapper from "./Utils/ErrorBoundaryWrapper";
import { ModalProvider } from "./Context/ModalContext";
import { CoinProvider } from "./Context/CoinContext";
import { OrderProvider } from "./Context/OrderContext";
import { WebSocketProvider } from "./Context/WebSocketContext";

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
  // <React.StrictMode>
  <ErrorBoundaryWrapper>
    <Provider store={store}>
      <CoinProvider>
        <WebSocketProvider>
          <ModalProvider>
            <OrderProvider>
              <App />
            </OrderProvider>
          </ModalProvider>
        </WebSocketProvider>
      </CoinProvider>
    </Provider>
  </ErrorBoundaryWrapper>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
