import React, { useState, useEffect, useContext } from "react";
import "./Trade.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { formatNumberWithComma } from "../../../Common/Helpers";

import { useCoinContext } from "../../../Context/CoinContext";
import { useModalContext } from "../../../Context/ModalContext";
import { useSelector } from "react-redux";

export const TradeDropdown = ({
  isSearching,
  setIsSearching,
  coinsData,
  setCoinsData,
  coin,
  increasingData,
  searchWord,
  setSearchWord,
  tableShowData,
  setTableShowData,
  pairs,
  selectedPair,
  handleItemClick,
  primaryCoin,
  dropdownVisible,
  setDropdownVisible,
}) => {
  const labels = useSelector((state) => state?.labels?.data);

  const { selectedNumberFormat } = useModalContext();
  const {
    defaultPair,
    addFavoriteCoin,
    removeFavoriteCoin,
    isFavorite,
    favoriteCoins,
    newCoin,
    setNewCoin,
    MARKET,
    SETMARKET,
    activeMarket,
    setActiveMarket,
    defaultCoin,
  } = useCoinContext();

  const [favoritesShowData, setFavoritesShowData] = useState();

  const [isFavoritesActive, setIsFavoritesActive] = useState(false);

  const handleFavoriteClick = (coin) => {
    if (isFavorite(coin)) {
      removeFavoriteCoin(coin);
    } else {
      addFavoriteCoin(coin);
    }
  };

  const [activeTab, setActiveTab] = useState("favorites");

  useEffect(() => {
    if (!selectedPair) {
      setActiveTab("USDT");
    }
  }, [defaultPair]);

  const handleSelect = (item) => {
    const newBase = item.base;
    const newQuote = item.quote;

    setActiveMarket(item.quote);
    setNewCoin(item.base);
    setActiveTab(item);

    window.history.replaceState({}, "", `/trade/${newBase}-${newQuote}`);

    setDropdownVisible(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setSearchWord("");
    if (tab === "favorites") {
      setIsFavoritesActive(true);
    } else {
      setIsFavoritesActive(false);
    }
  };

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

    if (isFavoritesActive) {
      if (newKeyword) {
        setIsSearching(true);
        let tmpSearchWord = newKeyword.toUpperCase();
        let filteredShowArray = favoriteCoins?.filter(
          (item) =>
            item.base.includes(tmpSearchWord) ||
            item.quote.includes(tmpSearchWord)
        );
        setFavoritesShowData(filteredShowArray);
      } else {
        setIsSearching(false);
        setFavoritesShowData(favoriteCoins);
      }
    } else {
      if (newKeyword) {
        setIsSearching(true);
        let tmpSearchWord = newKeyword.toUpperCase();
        let filteredShowArray = tableShowData.filter(
          (item) =>
            item.base.includes(tmpSearchWord) ||
            item.quote.includes(tmpSearchWord)
        );
        setTableShowData(filteredShowArray);
      } else {
        setIsSearching(false);
      }
    }
  };

  return (
    <>
      <div className="dropdown trade-header-dropdown position-relative d-flex border-end custom-border-color border-secondary">
        <button
          className="btn btn-transparent coin-name d-flex align-items-center dropdown-toggle"
          type="button"
          onClick={() => setDropdownVisible(!dropdownVisible)}
        >
          <span style={{ minWidth: 90 }} className="text-start">
            {coin && coin.base && coin.quote ? (
              <>
                {coin.base} / {coin.quote}
              </>
            ) : primaryCoin && primaryCoin.base && primaryCoin.quote ? (
              <>
                {primaryCoin.base} / {primaryCoin.quote}
              </>
            ) : (
              <>... / ...</>
            )}
          </span>

          <img src="/images/icons/caret-down.svg" alt="" className="ps-2" />
        </button>
        <ul
          className={
            dropdownVisible
              ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
              : "drop-menu position-absolute ps-3 d-none"
          }
        >
          <div className="coin-slider px-3">
            <Swiper
              slidesPerView={4}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              className="mySwiper mt-0"
            >
              {increasingData.map((inc, index) => (
                <SwiperSlide key={index}>
                  <div className="coin-item d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={`/images/coins/${inc.base?.toLowerCase()}.svg`}
                        alt=""
                        width={24}
                        height={24}
                      />
                      <span className="increase-coin-base">{inc.base}</span>
                    </div>
                    <div className="d-flex flex-column align-items-start pt-2">
                      <span className="increase-coin-price">{inc.price}</span>
                      <span className="text-xs text-success">
                        {inc.change_in_price}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <ul className="d-flex">
            <li
              onClick={() => handleTabClick("favorites")}
              className={
                isFavoritesActive
                  ? "pair-card selected d-flex align-items-center"
                  : "pair-card d-flex align-items-center"
              }
            >
              <svg
                className="fav-icon me-1"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.23928 2.34166C7.47928 1.60486 8.52168 1.60486 8.76088 2.34166L9.61688 4.97526C9.66917 5.13565 9.77084 5.27541 9.90733 5.37455C10.0438 5.47369 10.2082 5.52714 10.3769 5.52726H13.1465C13.9217 5.52726 14.2433 6.51926 13.6169 6.97526L11.3769 8.60246C11.2401 8.70169 11.1383 8.84167 11.0859 9.00234C11.0336 9.16302 11.0335 9.33613 11.0857 9.49686L11.9417 12.1305C12.1817 12.8673 11.3377 13.4809 10.7097 13.0249L8.46968 11.3977C8.33304 11.2985 8.16852 11.245 7.99968 11.245C7.83083 11.245 7.66631 11.2985 7.52968 11.3977L5.28968 13.0249C4.66248 13.4809 3.81928 12.8673 4.05848 12.1305L4.91448 9.49686C4.96662 9.33613 4.96652 9.16302 4.91421 9.00234C4.8619 8.84167 4.76005 8.70169 4.62328 8.60246L2.38408 6.97606C1.75768 6.52006 2.08008 5.52806 2.85448 5.52806H5.62328C5.79211 5.52811 5.95664 5.47473 6.0933 5.37558C6.22995 5.27643 6.33174 5.13658 6.38408 4.97606L7.24008 2.34246L7.23928 2.34166Z"
                  fill="#FACA15"
                />
              </svg>
              <span>{labels?.tables?.coinTracker?.favorites}</span>
            </li>
            {pairs?.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  handleItemClick(item);
                  setIsFavoritesActive(false);
                  setActiveTab(item);
                  setSearchWord("");
                }}
                className={
                  (selectedPair === item || activeTab === item) &&
                  activeTab !== "favorites"
                    ? "pair-card selected"
                    : "pair-card"
                }
              >
                {item}
              </li>
            ))}
          </ul>
          <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
            <img
              src="/images/icons/search.svg"
              alt=""
              className="search-icon"
            />
            <input
              type="text"
              placeholder="Search"
              value={searchWord}
              onChange={handleSearch}
            />
          </div>

          {isFavoritesActive ? (
            <>
              {searchWord === null || searchWord === "" ? (
                <>
                  {favoriteCoins?.map((item, index) => (
                    <li key={index} className="coin-card">
                      <button
                        className="btn bg-transparent add-favorite-btn"
                        onClick={() => handleFavoriteClick(item)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={25}
                          height={24}
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M11.3587 3.51249C11.7187 2.40729 13.2823 2.40729 13.6411 3.51249L14.9251 7.46289C15.0035 7.70348 15.156 7.91311 15.3608 8.06182C15.5655 8.21053 15.812 8.29071 16.0651 8.29089H20.2195C21.3823 8.29089 21.8647 9.77889 20.9251 10.4629L17.5651 12.9037C17.3599 13.0525 17.2071 13.2625 17.1287 13.5035C17.0502 13.7445 17.0501 14.0042 17.1283 14.2453L18.4123 18.1957C18.7723 19.3009 17.5063 20.2213 16.5643 19.5373L13.2043 17.0965C12.9993 16.9477 12.7525 16.8676 12.4993 16.8676C12.246 16.8676 11.9992 16.9477 11.7943 17.0965L8.43427 19.5373C7.49347 20.2213 6.22867 19.3009 6.58747 18.1957L7.87147 14.2453C7.94968 14.0042 7.94954 13.7445 7.87107 13.5035C7.7926 13.2625 7.63983 13.0525 7.43467 12.9037L4.07587 10.4641C3.13627 9.78009 3.61987 8.29209 4.78147 8.29209H8.93467C9.18793 8.29216 9.43471 8.2121 9.6397 8.06337C9.84469 7.91464 9.99736 7.70487 10.0759 7.46409L11.3599 3.51369L11.3587 3.51249Z"
                            fill={isFavorite(item) ? "#FACA15" : "#374151"}
                          />
                        </svg>
                      </button>
                      <button
                        className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                        onClick={() => handleSelect(item)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={`/images/coins/${item?.base?.toLowerCase()}.svg`}
                            alt=""
                            width={24}
                            height={24}
                          />
                          <div className="d-flex flex-column">
                            <span className="base-quote">
                              {item?.base}/{item.quote}
                            </span>
                            <span className="pair text-xs text-muted">
                              {formatNumberWithComma(
                                item.base_volume,
                                selectedNumberFormat
                              )}{" "}
                              {selectedPair}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <span className="volume">
                            {formatNumberWithComma(
                              item.base_volume,
                              selectedNumberFormat
                            )}
                          </span>
                          <span
                            className={
                              item?.change_in_price > 0
                                ? "text-xs text-success"
                                : "text-xs text-danger"
                            }
                          >
                            {parseFloat(item.change_in_price).toFixed(2)}%
                          </span>
                        </div>
                      </button>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  {favoritesShowData.map((item, index) => (
                    <li key={index} className="coin-card">
                      <button
                        className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                        onClick={() => handleSelect(item)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src={`/images/coins/${item?.base?.toLowerCase()}.svg`}
                            alt=""
                            width={24}
                            height={24}
                          />
                          <div className="d-flex flex-column">
                            <span className="base-quote">
                              {item?.base}/{item.quote}
                            </span>
                            <span className="pair text-xs text-muted">
                              {formatNumberWithComma(
                                item.base_volume,
                                selectedNumberFormat
                              )}{" "}
                              {selectedPair}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <span className="volume">
                            {formatNumberWithComma(
                              item.base_volume,
                              selectedNumberFormat
                            )}
                          </span>
                          <span
                            className={
                              item?.change_in_price > 0
                                ? "text-xs text-success"
                                : "text-xs text-danger"
                            }
                          >
                            {parseFloat(item.change_in_price).toFixed(2)}%
                          </span>
                        </div>
                      </button>
                    </li>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {tableShowData?.map((item, index) => (
                <li key={index} className="coin-card">
                  <button
                    className="btn bg-transparent add-favorite-btn"
                    onClick={() => handleFavoriteClick(item)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={24}
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M11.3587 3.51249C11.7187 2.40729 13.2823 2.40729 13.6411 3.51249L14.9251 7.46289C15.0035 7.70348 15.156 7.91311 15.3608 8.06182C15.5655 8.21053 15.812 8.29071 16.0651 8.29089H20.2195C21.3823 8.29089 21.8647 9.77889 20.9251 10.4629L17.5651 12.9037C17.3599 13.0525 17.2071 13.2625 17.1287 13.5035C17.0502 13.7445 17.0501 14.0042 17.1283 14.2453L18.4123 18.1957C18.7723 19.3009 17.5063 20.2213 16.5643 19.5373L13.2043 17.0965C12.9993 16.9477 12.7525 16.8676 12.4993 16.8676C12.246 16.8676 11.9992 16.9477 11.7943 17.0965L8.43427 19.5373C7.49347 20.2213 6.22867 19.3009 6.58747 18.1957L7.87147 14.2453C7.94968 14.0042 7.94954 13.7445 7.87107 13.5035C7.7926 13.2625 7.63983 13.0525 7.43467 12.9037L4.07587 10.4641C3.13627 9.78009 3.61987 8.29209 4.78147 8.29209H8.93467C9.18793 8.29216 9.43471 8.2121 9.6397 8.06337C9.84469 7.91464 9.99736 7.70487 10.0759 7.46409L11.3599 3.51369L11.3587 3.51249Z"
                        fill={isFavorite(item) ? "#FACA15" : "#374151"}
                      />
                    </svg>
                  </button>
                  <button
                    className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                    onClick={() => handleSelect(item)}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={`/images/coins/${item?.base?.toLowerCase()}.svg`}
                        alt=""
                        width={24}
                        height={24}
                      />
                      <div className="d-flex flex-column">
                        <span className="base-quote">
                          {item?.base}/{item.quote}
                        </span>
                        <span className="pair text-xs text-muted">
                          {formatNumberWithComma(
                            item.base_volume,
                            selectedNumberFormat
                          )}{" "}
                          {selectedPair}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span className="volume">
                        {formatNumberWithComma(
                          item.base_volume,
                          selectedNumberFormat
                        )}
                      </span>
                      <span
                        className={
                          item?.change_in_price > 0
                            ? "text-xs text-success"
                            : "text-xs text-danger"
                        }
                      >
                        {parseFloat(item.change_in_price).toFixed(2)}%
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>
    </>
  );
};
