import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./Header.css";
import { formatNumberWithComma } from "../../Common/Helpers";
import { useModalContext } from "../../Context/ModalContext";
import { useCoinContext } from "../../Context/CoinContext";
import axios from "axios";
import { ErrorBoundary } from "react-error-boundary";

export const Header = ({
  coin,
  setCoin,
  primaryCoin,
  dropdownVisible,
  setDropdownVisible,
  pairs,
  selectedPair,
  handleItemClick,
  tableShowData,
  handleSearch,
  searchWord,
  MARKET,
  SETMARKET,
}) => {
  const {
    toggleSettingsModal,
    selectedTheme,
    setSelectedTheme,
    selectedLanguage,
    setSelectedLanguage,
  } = useModalContext();
  const { newCoin, activeMarket } = useCoinContext();

  const [languageList, setLanguageList] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/language_list`)
      .then((response) => {
        setLanguageList(response?.data?.data);
      })
      .catch((error) => {
        setHasError(true);
      });
  }, []);

  if (hasError) {
    throw new Error("");
  }

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const themes = [
    {
      id: 1,
      name: "dark",
      icon: "/images/icons/moon.svg",
    },
    {
      id: 2,
      name: "light",
      icon: "/images/icons/moon.svg",
    },
  ];

  const handleSelectTheme = (theme) => {
    setSelectedTheme(theme);
  };

  const timerInitialValue = 900;
  const [timer, setTimer] = useState(timerInitialValue);
  const navigate = useNavigate();

  let isInstitutional = false;
  if (process.env.REACT_APP_IS_INSTITUTIONAL === "true") {
    isInstitutional = true;
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const resetTimer = () => {
        setTimer(timerInitialValue);
      };

      const handleUserActivity = () => {
        resetTimer();
      };

      // Set up event listeners for user activity
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);

      const intervalId = setInterval(() => {
        let tmpTimer = timer;
        tmpTimer--;
        // console.log(tmpTimer);
        setTimer(tmpTimer);
        if (timer < 1) {
          resetTimer();
          navigate("/logout");
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const labels = useSelector((state) => state.labels.data);
  const status = useSelector((state) => state.status);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  const [userInfo, setUserInfo] = useState("");

  const dropdownRef = useRef(null);
  const targetDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (
      targetDropdownRef.current &&
      !targetDropdownRef.current.contains(event.target)
    ) {
      setIsOpenProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownProfile = () => {
    setIsOpenProfile(!isOpenProfile);
  };

  const buttonClassName = `dropdown-toggle${isOpen ? " show" : ""}`;
  const ulClassName = `dropdown-menu${isOpen ? " show" : ""}`;
  const ulStyle = isOpen
    ? {
        position: "absolute",
        inset: "0px auto auto 0px",
        margin: "0px",
        transform: "translate3d(0px, 25.5px, 0px)",
      }
    : {};

  const buttonProfileClassName = `dropdown-toggle${
    isOpenProfile ? " show" : ""
  }`;
  const ulProfileClassName = `dropdown-menu${isOpenProfile ? " show" : ""}`;
  const ulProfileStyle = isOpenProfile
    ? {
        position: "absolute",
        inset: "0px auto auto 0px",
        margin: "0px",
        transform: "translate3d(-0.5px, 36px, 0px)",
      }
    : {};

  useEffect(() => {
    const jsonObject = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(jsonObject);
  }, []);

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  useEffect(() => {
    setIsMobileMenuVisible(false);
  }, [location.pathname]);

  const handleSelect = (item) => {
    SETMARKET(item.quote);

    setCoin(item);

    setDropdownVisible(false);
  };

  if (localStorage.getItem("token")) {
    return (
      <>
        {/* Header */}
        <div
          className={
            isMobileMenuVisible
              ? "d-lg-none mobile-menu open"
              : "d-lg-none mobile-menu"
          }
        >
          <Link to="/" className="logo">
            <img
              className="destop-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
          </Link>
          <button
            className="button-close-menu"
            onClick={() => setIsMobileMenuVisible(false)}
          >
            <img src="/images/icons/x.svg" alt="" />
          </button>
          <ul className="menu mt-4">
            <li>
              <Link to="/">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/home-black.svg"
                      : "/images/icons/home.svg"
                  }
                  alt=""
                />
                <span>Home</span>
              </Link>
            </li>
            <li className={currentPath.startsWith("/trade") ? "selected" : ""}>
              <Link
                to={
                  location.pathname.startsWith("/trade")
                    ? location.pathname
                    : `/trade/${newCoin}-${activeMarket}`
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!location.pathname.startsWith("/trade")) {
                    window.history.replaceState(
                      {},
                      "",
                      `/trade/${newCoin}-${activeMarket}`
                    );
                    navigate(`/trade/${newCoin}-${activeMarket}`);
                  }
                }}
              >
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/chart-bar-black.svg"
                      : "/images/icons/chart-bar.svg"
                  }
                  alt=""
                />
                <span>{labels?.navbar?.links?.trade}</span>
              </Link>
            </li>
            {isInstitutional ? (
              <li>
                <Link to="/insta-trade">
                  <img
                    src={
                      selectedTheme?.name === "light"
                        ? "/images/icons/fire-black.svg"
                        : "/images/icons/fire.svg"
                    }
                    alt=""
                  />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">{labels?.generics?.new}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {/* <li>
              <Link to="#" className="button-download">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/download-black.svg"
                      : "/images/icons/download.svg"
                  }
                  alt=""
                />
                {labels?.generics?.downloadApp}
              </Link>
            </li> */}

            <li>
              <Link to="/account/overview" className="button-download">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill={selectedTheme?.name === "light" ? "black" : "white"}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9996 10.8C12.9544 10.8 13.8701 10.4207 14.5452 9.74556C15.2203 9.07043 15.5996 8.15476 15.5996 7.19998C15.5996 6.2452 15.2203 5.32952 14.5452 4.65439C13.8701 3.97926 12.9544 3.59998 11.9996 3.59998C11.0448 3.59998 10.1292 3.97926 9.45402 4.65439C8.77889 5.32952 8.39961 6.2452 8.39961 7.19998C8.39961 8.15476 8.77889 9.07043 9.45402 9.74556C10.1292 10.4207 11.0448 10.8 11.9996 10.8ZM3.59961 21.6C3.59961 20.4969 3.81688 19.4046 4.23902 18.3854C4.66116 17.3663 5.2799 16.4403 6.05991 15.6603C6.83992 14.8803 7.76593 14.2615 8.78507 13.8394C9.8042 13.4172 10.8965 13.2 11.9996 13.2C13.1027 13.2 14.195 13.4172 15.2141 13.8394C16.2333 14.2615 17.1593 14.8803 17.9393 15.6603C18.7193 16.4403 19.3381 17.3663 19.7602 18.3854C20.1823 19.4046 20.3996 20.4969 20.3996 21.6H3.59961Z"
                    fill={selectedTheme?.name === "light" ? "black" : "white"}
                  />
                </svg>

                <span>{labels?.navbar?.links?.account}</span>
              </Link>
            </li>

            <li>
              <Link to="/order/open-orders" className="button-download">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.60033 6C9.28207 6 8.97685 6.12643 8.7518 6.35147C8.52676 6.57652 8.40033 6.88174 8.40033 7.2C8.40033 7.51826 8.52676 7.82348 8.7518 8.04853C8.97685 8.27357 9.28207 8.4 9.60033 8.4H16.3035L14.7519 9.9516C14.5333 10.1779 14.4124 10.481 14.4151 10.7957C14.4179 11.1103 14.5441 11.4113 14.7665 11.6338C14.989 11.8563 15.29 11.9825 15.6046 11.9852C15.9193 11.9879 16.2224 11.867 16.4487 11.6484L20.0487 8.0484C20.2737 7.82337 20.4001 7.5182 20.4001 7.2C20.4001 6.8818 20.2737 6.57663 20.0487 6.3516L16.4487 2.7516C16.338 2.63699 16.2056 2.54557 16.0592 2.48268C15.9128 2.41979 15.7553 2.38668 15.596 2.3853C15.4367 2.38391 15.2787 2.41428 15.1312 2.47461C14.9837 2.53495 14.8497 2.62405 14.7371 2.73673C14.6244 2.8494 14.5353 2.98338 14.4749 3.13085C14.4146 3.27833 14.3842 3.43635 14.3856 3.59568C14.387 3.75502 14.4201 3.91248 14.483 4.05889C14.5459 4.20529 14.6373 4.3377 14.7519 4.4484L16.3035 6H9.60033ZM14.4003 18C14.7186 18 15.0238 17.8736 15.2489 17.6485C15.4739 17.4235 15.6003 17.1183 15.6003 16.8C15.6003 16.4817 15.4739 16.1765 15.2489 15.9515C15.0238 15.7264 14.7186 15.6 14.4003 15.6H7.69713L9.24873 14.0484C9.36334 13.9377 9.45476 13.8053 9.51765 13.6589C9.58054 13.5125 9.61365 13.355 9.61503 13.1957C9.61642 13.0363 9.58605 12.8783 9.52572 12.7309C9.46538 12.5834 9.37628 12.4494 9.2636 12.3367C9.15093 12.2241 9.01695 12.135 8.86947 12.0746C8.722 12.0143 8.56398 11.9839 8.40465 11.9853C8.24531 11.9867 8.08785 12.0198 7.94144 12.0827C7.79504 12.1456 7.66263 12.237 7.55193 12.3516L3.95193 15.9516C3.72696 16.1766 3.60059 16.4818 3.60059 16.8C3.60059 17.1182 3.72696 17.4234 3.95193 17.6484L7.55193 21.2484C7.77825 21.467 8.08138 21.5879 8.39601 21.5852C8.71065 21.5825 9.01162 21.4563 9.23411 21.2338C9.4566 21.0113 9.5828 20.7103 9.58554 20.3957C9.58827 20.081 9.46732 19.7779 9.24873 19.5516L7.69713 18H14.4003Z"
                    fill={selectedTheme?.name === "light" ? "black" : "white"}
                  />
                </svg>
                <span>{labels?.navbar?.links?.orders}</span>
              </Link>
            </li>

            <li>
              <Link to="/wallet/overview" className="button-download">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 20 20"
                  fill={selectedTheme?.name === "light" ? "black" : "white"}
                >
                  <g clipPath="url(#clip0_690_1956)">
                    <path
                      d="M15.8333 2.5H4.16667C3.062 2.50132 2.00296 2.94073 1.22185 3.72185C0.440735 4.50296 0.00132321 5.562 0 6.66667H20C19.9987 5.562 19.5593 4.50296 18.7782 3.72185C17.997 2.94073 16.938 2.50132 15.8333 2.5Z"
                      fill={selectedTheme?.name === "light" ? "black" : "white"}
                    />
                    <path
                      d="M0 13.3332C0.00132321 14.4379 0.440735 15.4969 1.22185 16.2781C2.00296 17.0592 3.062 17.4986 4.16667 17.4999H15.8333C16.938 17.4986 17.997 17.0592 18.7782 16.2781C19.5593 15.4969 19.9987 14.4379 20 13.3332V8.33325H0V13.3332ZM5.83333 12.9166C5.83333 13.1638 5.76002 13.4055 5.62267 13.611C5.48532 13.8166 5.2901 13.9768 5.06169 14.0714C4.83328 14.166 4.58195 14.1908 4.33947 14.1426C4.09699 14.0943 3.87427 13.9753 3.69945 13.8005C3.52463 13.6256 3.40558 13.4029 3.35735 13.1604C3.30912 12.918 3.33387 12.6666 3.42848 12.4382C3.52309 12.2098 3.68331 12.0146 3.88887 11.8772C4.09443 11.7399 4.33611 11.6666 4.58333 11.6666C4.91485 11.6666 5.2328 11.7983 5.46722 12.0327C5.70164 12.2671 5.83333 12.5851 5.83333 12.9166Z"
                      fill={selectedTheme?.name === "light" ? "black" : "white"}
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_690_1956">
                      <rect width={20} height={20} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{labels?.navbar?.links?.wallet}</span>
              </Link>
            </li>
            <li>
              <button
                onClick={toggleSettingsModal}
                className="btn btn-transparent button-setting"
              >
                <img src="/images/icons/settings.svg" alt="" />
                <span className="ms-2">{labels?.settings?.title}</span>
              </button>
            </li>
          </ul>
          <div className="actions d-flex flex-column">
            <Link to="/logout" className="button-logOut mobil-show">
              {labels?.navbar?.links?.logout}
            </Link>
          </div>
        </div>
        <header>
          <Link
            to="#"
            className="menu-trigger"
            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
          >
            <span />
            <span />
            <span />
          </Link>
          <Link to="/" className="logo index-logo">
            <img
              className="destop-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
            <img
              className="mobil-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
          </Link>
          <nav>
            <ul className="menu">
              <li className={currentPath === "/" ? "selected" : ""}>
                <Link to="/">
                  <img
                    src={
                      selectedTheme?.name === "light" && currentPath !== "/"
                        ? "/images/icons/home-black.svg"
                        : "/images/icons/home.svg"
                    }
                    alt=""
                  />
                  <span>{labels?.navbar?.links?.home}</span>
                </Link>
              </li>
              {!isInstitutional ? (
                <li
                  className={currentPath.startsWith("/trade") ? "selected" : ""}
                >
                  <Link
                    to={
                      location.pathname.startsWith("/trade")
                        ? location.pathname
                        : `/trade/${newCoin}-${activeMarket}`
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (!location.pathname.startsWith("/trade")) {
                        window.history.replaceState(
                          {},
                          "",
                          `/trade/${newCoin}-${activeMarket}`
                        );
                        navigate(`/trade/${newCoin}-${activeMarket}`);
                      }
                    }}
                  >
                    <img
                      src={
                        selectedTheme?.name === "light" &&
                        currentPath !== "/trade"
                          ? "/images/icons/trade-black.svg"
                          : "/images/icons/trade.svg"
                      }
                      alt=""
                    />
                    <span>{labels?.navbar?.links?.trade}</span>
                  </Link>
                </li>
              ) : (
                <></>
              )}

              {isInstitutional ? (
                <li
                  className={currentPath === "/insta-trade" ? "selected" : ""}
                >
                  <Link to="/insta-trade">
                    <img
                      src={
                        selectedTheme?.name === "light" &&
                        currentPath !== "/insta-trade"
                          ? "/images/icons/fire-black.svg"
                          : "/images/icons/fire.svg"
                      }
                      alt=""
                    />
                    <span>{labels?.navbar?.links?.instaTrade}</span>
                    <span className="new-btn">{labels?.generics?.new}</span>
                  </Link>
                </li>
              ) : (
                <></>
              )}

              {!isInstitutional && (
                <li className={currentPath === "/e-otc" ? "selected" : ""}>
                  <Link to="/e-otc">
                    <img src="/images/icons/trade.svg" alt="" />
                    <span>E OTC</span>
                  </Link>
                </li>
              )}

              {/* <Link to="#" className="button-download mobil-show">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/download-black.svg"
                      : "/images/icons/download.svg"
                  }
                  alt=""
                />
                {labels?.generics?.downloadApp}
              </Link> */}
              <Link to="/logout" className="button-logOut mobil-show">
                {labels?.navbar?.links?.logout}
              </Link>
            </ul>
          </nav>

          <div className={currentPath === "/trade" ? "" : "d-none"}>
            <div className="dropdown position-relative d-lg-none d-md-none d-flex">
              <button
                className="btn btn-transparent text-white d-flex align-items-center dropdown-toggle"
                type="button"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                {coin ? (
                  <>
                    {coin.base} / {coin.quote}
                  </>
                ) : (
                  <>
                    {primaryCoin?.base} / {primaryCoin?.quote}
                  </>
                )}

                <img
                  src="/images/icons/caret-down.svg"
                  alt=""
                  className="ps-2"
                />
              </button>
              <ul
                className={
                  dropdownVisible
                    ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
                    : "drop-menu position-absolute ps-3 d-none"
                }
              >
                <ul className="d-flex">
                  {pairs?.map((item) => (
                    <li
                      key={item}
                      onClick={() => handleItemClick(item)}
                      className={
                        selectedPair === item
                          ? "pair-card selected"
                          : "pair-card"
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
                  <img
                    src="/images/icons/search.svg"
                    alt=""
                    className="search-icon"
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchWord}
                    onChange={handleSearch}
                  />
                </div>

                {tableShowData?.map((item, index) => (
                  <li key={index} className="coin-card">
                    <button
                      className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                      onClick={() => handleSelect(item)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={`/images/coins/${item?.base?.toLowerCase()}.svg`}
                          alt=""
                          width={24}
                          height={24}
                        />
                        <div className="d-flex flex-column">
                          <span>
                            {item?.base}/{item.quote}
                          </span>
                          <span className="text-xs text-muted">
                            {formatNumberWithComma(item.base_volume)}{" "}
                            {selectedPair}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span>{formatNumberWithComma(item.base_volume)}</span>
                        <span
                          className={
                            item?.change_in_price > 0
                              ? "text-xs text-success"
                              : "text-xs text-danger"
                          }
                        >
                          {parseFloat(item.change_in_price).toFixed(2)}%
                        </span>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="actions">
            <Link to="/logout" className="button-logOut">
              {labels?.navbar?.links?.logout}
            </Link>
            {/* <Link to="#" className="button-download">
              <img
                src={
                  selectedTheme?.name === "light"
                    ? "/images/icons/download-black.svg"
                    : "/images/icons/download.svg"
                }
                alt=""
              />
              {labels?.generics?.downloadApp}
            </Link> */}
            {/* <Link to="#" className="button-bell d-none d-lg-flex">
              <img src="/images/icons/bell.svg" alt="" />
            </Link> */}

            {themes.map((theme, index) => (
              <button
                onClick={() => handleSelectTheme(theme)}
                className="btn btn-transparent button-moon"
                key={index}
                style={{
                  display: selectedTheme.name === theme.name ? "none" : "",
                }}
              >
                {theme.name === "dark" ? (
                  <img src="/images/icons/moon.svg" alt="" />
                ) : (
                  <img src="/images/icons/sun.png" alt="" />
                )}
              </button>
            ))}

            <button
              onClick={toggleSettingsModal}
              className="btn btn-transparent button-setting d-none d-lg-flex"
            >
              <img src="/images/icons/settings.svg" alt="" />
            </button>

            <div className="dropdown d-none d-lg-flex" ref={dropdownRef}>
              <button
                className={buttonClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={toggleDropdown}
              >
                {selectedLanguage.code === "df" && (
                  <span className="text-capitalize">
                    {selectedLanguage.language}
                  </span>
                )}
                <img
                  src={
                    selectedLanguage.code === "en"
                      ? "../images/flag/us.svg"
                      : selectedLanguage.code === "tr"
                      ? "../images/flag/tr.svg"
                      : ""
                  }
                  alt=""
                  width={20}
                  height={15}
                  style={{
                    display: selectedLanguage.code === "df" ? "none" : "",
                  }}
                />
                <span className="text-capitalize">
                  {selectedLanguage.language}
                </span>
              </button>

              <ul
                className={ulClassName}
                style={ulStyle}
                data-popper-placement="bottom-start"
              >
                {languageList?.map((language, index) => (
                  <li
                    key={index}
                    style={{ display: language.code === "df" ? "none" : "" }}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleSelectLanguage(language);
                        toggleDropdown();
                      }}
                    >
                      {" "}
                      <img
                        src={
                          language.code === "en"
                            ? "../images/flag/us.svg"
                            : language.code === "tr"
                            ? "../images/flag/tr.svg"
                            : ""
                        }
                        alt=""
                        style={{
                          display: language.code === "df" ? "none" : "",
                        }}
                      />
                      <span className="text-capitalize">
                        {language.language}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="dropdown users d-none d-lg-block"
              ref={targetDropdownRef}
            >
              <button
                className={buttonProfileClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpenProfile}
                onClick={toggleDropdownProfile}
              >
                <img src="/images/materials/avatar.png" alt="" />
                <span>{userInfo?.firstName}</span>
              </button>
              <ul
                className={ulProfileClassName}
                style={ulProfileStyle}
                data-popper-placement="bottom-start"
              >
                <li>
                  <Link to="/account/overview" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/user-circle.svg" alt="" />
                    {labels?.navbar?.links?.account}
                  </Link>
                </li>
                <li>
                  <Link to="/order/open-orders" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/switch-horizontal.svg" alt="" />
                    {labels?.navbar?.links?.orders}
                  </Link>
                </li>
                <li>
                  <Link to="/wallet/overview" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/wallet.svg" alt="" />
                    {labels?.navbar?.links?.wallet}
                  </Link>
                </li>
                <li>
                  <a onClick={toggleSettingsModal} className="dropdown-item">
                    <img src="/images/icons/settings.svg" alt="" />
                    {labels?.settings?.title}
                  </a>
                </li>
                <li>
                  <Link to="/logout" className="dropdown-item">
                    {" "}
                    <img src="/images/icons/logout.svg" alt="" />
                    {labels?.navbar?.links?.logout}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        {/* Header End */}
      </>
    );
  } else {
    return (
      <>
        {/* Header */}
        <div
          className={
            isMobileMenuVisible
              ? "d-lg-none mobile-menu open"
              : "d-lg-none mobile-menu"
          }
        >
          <Link to="/" className="logo">
            <img
              className="destop-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
          </Link>
          <button
            className="button-close-menu"
            onClick={() => setIsMobileMenuVisible(false)}
          >
            <img src="/images/icons/x.svg" alt="" />
          </button>
          <ul className="menu mt-4">
            <li>
              <Link to="/">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/home-black.svg"
                      : "/images/icons/home.svg"
                  }
                  alt=""
                />
                <span>Home</span>
              </Link>
            </li>
            <li className={currentPath.startsWith("/trade") ? "selected" : ""}>
              <Link
                to={
                  location.pathname.startsWith("/trade")
                    ? location.pathname
                    : `/trade/${newCoin}-${activeMarket}`
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!location.pathname.startsWith("/trade")) {
                    window.history.replaceState(
                      {},
                      "",
                      `/trade/${newCoin}-${activeMarket}`
                    );
                    navigate(`/trade/${newCoin}-${activeMarket}`);
                  }
                }}
              >
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/chart-bar-black.svg"
                      : "/images/icons/chart-bar.svg"
                  }
                  alt=""
                />
                <span>{labels?.navbar?.links?.trade}</span>
              </Link>
            </li>
            {isInstitutional ? (
              <li>
                <Link to="/insta-trade">
                  <img
                    src={
                      selectedTheme?.name === "light"
                        ? "/images/icons/fire-black.svg"
                        : "/images/icons/fire.svg"
                    }
                    alt=""
                  />
                  <span>{labels?.navbar?.links?.instaTrade}</span>
                  <span className="new-btn">{labels?.generics?.new}</span>
                </Link>
              </li>
            ) : (
              <></>
            )}
            {/* <li>
              <Link to="#" className="button-download">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/download-black.svg"
                      : "/images/icons/download.svg"
                  }
                  alt=""
                />
                {labels?.generics?.downloadApp}
              </Link>
            </li> */}
            <li>
              <button
                onClick={toggleSettingsModal}
                className="btn btn-transparent button-setting"
              >
                <img src="/images/icons/settings.svg" alt="" />
                <span className="ms-2">{labels?.settings?.title}</span>
              </button>
            </li>
          </ul>
          <div className="actions d-flex flex-column">
            <Link to="/login" className="button-login mt-3 mb-2">
              {labels?.navbar?.links?.login}
            </Link>
            <Link to="/register" className="button-signUp">
              {labels?.navbar?.links?.signup}
            </Link>
          </div>
        </div>
        <header>
          <Link
            to="#"
            className="menu-trigger"
            onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
          >
            <span />
            <span />
            <span />
          </Link>
          <Link to="/" className="logo">
            <img
              className="destop-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
            <img
              className="mobil-logo"
              src={
                selectedTheme?.name === "light"
                  ? "/images/logo-black.svg"
                  : "/images/logo.svg"
              }
              alt=""
            />
          </Link>

          <div className={currentPath === "/trade" ? "" : "d-none"}>
            <div className="dropdown position-relative d-lg-none d-md-none d-flex">
              <button
                className="btn btn-transparent text-white d-flex align-items-center dropdown-toggle"
                type="button"
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                {coin ? (
                  <>
                    {coin.base} / {coin.quote}
                  </>
                ) : (
                  <>
                    {primaryCoin?.base} / {primaryCoin?.quote}
                  </>
                )}

                <img
                  src="/images/icons/caret-down.svg"
                  alt=""
                  className="ps-2"
                />
              </button>
              <ul
                className={
                  dropdownVisible
                    ? "drop-menu position-absolute py-2 d-flex flex-column gap-2 border custom-border-color border-secondary"
                    : "drop-menu position-absolute ps-3 d-none"
                }
              >
                <ul className="d-flex">
                  {pairs?.map((item) => (
                    <li
                      key={item}
                      onClick={() => handleItemClick(item)}
                      className={
                        selectedPair === item
                          ? "pair-card selected"
                          : "pair-card"
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="dropdown-searchbox position-relative px-3 mb-2 mt-3">
                  <img
                    src="/images/icons/search.svg"
                    alt=""
                    className="search-icon"
                  />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchWord}
                    onChange={handleSearch}
                  />
                </div>

                {tableShowData?.map((item, index) => (
                  <li key={index} className="coin-card">
                    <button
                      className="d-flex justify-content-between btn dropdown-item rounded-0 px-3 py-1 text-white"
                      onClick={() => handleSelect(item)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={`/images/coins/${item?.base?.toLowerCase()}.svg`}
                          alt=""
                          width={24}
                          height={24}
                        />
                        <div className="d-flex flex-column">
                          <span>
                            {item?.base}/{item.quote}
                          </span>
                          <span className="text-xs text-muted">
                            {formatNumberWithComma(item.base_volume)}{" "}
                            {selectedPair}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span>{formatNumberWithComma(item.base_volume)}</span>
                        <span
                          className={
                            item?.change_in_price > 0
                              ? "text-xs text-success"
                              : "text-xs text-danger"
                          }
                        >
                          {parseFloat(item.change_in_price).toFixed(2)}%
                        </span>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <nav>
            <ul className="menu">
              <li className={currentPath === "/" ? "selected" : ""}>
                <Link to="/">
                  <img
                    src={
                      selectedTheme?.name === "light" && currentPath !== "/"
                        ? "/images/icons/home-black.svg"
                        : "/images/icons/home.svg"
                    }
                    alt=""
                  />
                  <span>Home</span>
                </Link>
              </li>
              <li
                className={currentPath.startsWith("/trade") ? "selected" : ""}
              >
                <Link
                  to={
                    location.pathname.startsWith("/trade")
                      ? location.pathname
                      : `/trade/${newCoin}-${activeMarket}`
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (!location.pathname.startsWith("/trade")) {
                      window.history.replaceState(
                        {},
                        "",
                        `/trade/${newCoin}-${activeMarket}`
                      );
                      navigate(`/trade/${newCoin}-${activeMarket}`);
                    }
                  }}
                >
                  <img
                    src={
                      selectedTheme?.name === "light" &&
                      currentPath !== "/trade"
                        ? "/images/icons/chart-bar-black.svg"
                        : "/images/icons/chart-bar.svg"
                    }
                    alt=""
                  />
                  <span>{labels?.navbar?.links?.trade}</span>
                </Link>
              </li>
              {isInstitutional ? (
                <li
                  className={currentPath === "/insta-trade" ? "selected" : ""}
                >
                  <Link to="/insta-trade">
                    <img
                      src={
                        selectedTheme?.name === "light" &&
                        currentPath !== "/insta-trade"
                          ? "/images/icons/fire-black.svg"
                          : "/images/icons/fire.svg"
                      }
                      alt=""
                    />
                    <span>{labels?.navbar?.links?.instaTrade}</span>
                    <span className="new-btn">{labels?.generics?.new}</span>
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {/* <Link to="#" className="button-download mobil-show">
                <img
                  src={
                    selectedTheme?.name === "light"
                      ? "/images/icons/download-black.svg"
                      : "/images/icons/download.svg"
                  }
                  alt=""
                />
                {labels?.generics?.downloadApp}
              </Link> */}
            </ul>
          </nav>
          <div className="actions">
            <Link to="/login" className="button-login d-none d-lg-flex">
              {labels?.navbar?.links?.login}
            </Link>
            <Link to="/register" className="button-signUp d-none d-lg-flex">
              {labels?.navbar?.links?.signup}
            </Link>
            {/* <Link to="#" className="button-download">
              <img
                src={
                  selectedTheme?.name === "light"
                    ? "/images/icons/download-black.svg"
                    : "/images/icons/download.svg"
                }
                alt=""
              />
              {labels?.generics?.downloadApp}
            </Link> */}
            <button
              onClick={toggleSettingsModal}
              className="btn btn-transparent button-setting d-none d-lg-flex"
            >
              <img src="/images/icons/settings.svg" alt="" />
            </button>
            <div className="d-flex d-lg-none">
              {themes.map((theme, index) => (
                <button
                  onClick={() => handleSelectTheme(theme)}
                  className="btn btn-transparent button-moon"
                  key={index}
                  style={{
                    display: selectedTheme.name === theme.name ? "none" : "",
                  }}
                >
                  {theme.name === "dark" ? (
                    <img src="/images/icons/moon.svg" alt="" />
                  ) : (
                    <img src="/images/icons/sun.png" alt="" />
                  )}
                </button>
              ))}
            </div>
            <div className="dropdown d-none d-lg-block">
              <button
                className={buttonClassName}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isOpen}
                onClick={toggleDropdown}
              >
                {selectedLanguage.code === "df" && (
                  <span className="text-capitalize">
                    {selectedLanguage.language}
                  </span>
                )}
                <img
                  src={
                    selectedLanguage.code === "en"
                      ? "../images/flag/us.svg"
                      : selectedLanguage.code === "tr"
                      ? "../images/flag/tr.svg"
                      : ""
                  }
                  alt=""
                  width={20}
                  height={15}
                  style={{
                    display: selectedLanguage.code === "df" ? "none" : "",
                  }}
                />
                <span className="text-capitalize">
                  {selectedLanguage.language}
                </span>
              </button>

              <ul
                className={ulClassName}
                style={ulStyle}
                data-popper-placement="bottom-start"
              >
                {languageList?.map((language, index) => (
                  <li
                    key={index}
                    style={{ display: language.code === "df" ? "none" : "" }}
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleSelectLanguage(language);
                        toggleDropdown();
                      }}
                    >
                      {" "}
                      <img
                        src={
                          language.code === "en"
                            ? "../images/flag/us.svg"
                            : language.code === "tr"
                            ? "../images/flag/tr.svg"
                            : ""
                        }
                        alt=""
                        style={{
                          display: language.code === "df" ? "none" : "",
                        }}
                      />
                      <span className="text-capitalize">
                        {language.language}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        {/* HeaderWithOutAuth End */}
      </>
    );
  }
};

export default Header;
