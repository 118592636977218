import { Link } from "react-router-dom";
import "./LoginVerification.css";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
function LoginVerification() {
  return (
    <>
      <Header />

      {/* Login */}
      <section className="login">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="text">
                <ul>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Get started quickly
                    </h5>
                    <p>
                      Integrate with developer-friendly APIs or choose low-code
                      or pre-built solutions.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Support any business model
                    </h5>
                    <p>
                      Host code that you don't want to share with the world in
                      private.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Join millions of businesses
                    </h5>
                    <p>
                      We are trusted by ambitious startups and enterprises of
                      every size.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <form>
                <h4>Login</h4>
                <div className="input-container">
                  <label htmlFor="password">
                    Enter your one time code from your email
                  </label>
                  <input type="password" placeholder="••••••••••" />
                </div>
                <div className="countdown">
                  Time Remaining <div id="countdown" />
                </div>
                <button className="button-style-01">Resend the code </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Login End */}

      <Footer />
    </>
  );
}

export default LoginVerification;
