import "./SettingsModal.css";
import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from "../Dropdown";
import { useModalContext } from "../../Context/ModalContext";
import { useCoinContext } from "../../Context/CoinContext";
import { useSelector } from 'react-redux';
import axios from "axios";

export default function SettingsModal() {
  const { 
    settingsModalVisible, 
    toggleSettingsModal, 
    selectedLanguage, 
    setSelectedLanguage, 
    selectedNumberFormat, 
    setSelectedNumberFormat, 
    selectedFiatEstimation, 
    setSelectedFiatEstimation,
    selectedTheme,
    setSelectedTheme
  } = useModalContext();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const labels = useSelector(state => state.labels.data);

  const { fiatList } = useCoinContext();



  const [languageList, setLanguageList] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/language_list`)
      .then((response) => {
        setLanguageList(response?.data?.data);
      })
      .catch((error) => {
      setHasError(true);
      });
  }, []); 

  if (hasError) {
    throw new Error("");
  }




  const numberFormats = [
    { id: 1, name: labels?.settings?.numberFormatOptions?.df, short: "default" },
    { id: 2, name: labels?.settings?.numberFormatOptions?.period, short: "period" },
    { id: 3, name: labels?.settings?.numberFormatOptions?.comma, short: "comma" },
  ]

  const fiatEstimations = fiatList.map((currency, index) => ({
    id: index + 1, 
    name: currency, 
  }));

  const themes = [
    { id: 1, name: "dark", icon: "/images/icons/moon.svg" },
    { id: 2, name: "light", icon: "/images/icons/moon.svg" },
  ];

  const handleSelectLanguage = (language) => setSelectedLanguage(language);
  const handleSelectNumberFormat = (numberFormat) => setSelectedNumberFormat(numberFormat);
  const handleSelectFiatEstimation = (fiatEstimation) => setSelectedFiatEstimation(fiatEstimation);
  const handleSelectTheme = (theme) => setSelectedTheme(theme);
  const toggleDropdown = (dropdown) => setActiveDropdown(activeDropdown === dropdown ? null : dropdown);

  return (
    <Modal show={settingsModalVisible} onHide={toggleSettingsModal} centered className="modal settings-modal">
      <Modal.Header closeButton>
        <Modal.Title>{labels?.settings?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown 
          title={labels?.settings?.theme}
          options={themes}
          selectedOption={selectedTheme}
          onSelectOption={handleSelectTheme}
          dropdownVisible={activeDropdown === "theme"}
          onToggleDropdown={() => toggleDropdown("theme")}
        />
        
        <Dropdown 
          title={labels?.settings?.language}
          options={languageList}
          selectedOption={selectedLanguage}
          onSelectOption={handleSelectLanguage}
          dropdownVisible={activeDropdown === "language"}
          onToggleDropdown={() => toggleDropdown("language")}
        />

        <Dropdown 
          title={labels?.settings?.numberFormat}
          options={numberFormats}
          selectedOption={selectedNumberFormat}
          onSelectOption={handleSelectNumberFormat}
          dropdownVisible={activeDropdown === "numberFormat"}
          onToggleDropdown={() => toggleDropdown("numberFormat")}
        />

        <Dropdown 
          title={labels?.settings?.fiat}
          options={fiatEstimations}
          selectedOption={selectedFiatEstimation}
          onSelectOption={handleSelectFiatEstimation}
          dropdownVisible={activeDropdown === "fiatEstimation"}
          onToggleDropdown={() => toggleDropdown("fiatEstimation")}
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0 px-4">
        <Button onClick={toggleSettingsModal} className="btn settings-modal-action-btn w-100">
        {labels?.socialTrade?.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
