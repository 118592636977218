import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import raw from "../../../Common/public.pem";

import "./ForgetPassword.css";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";

import { JSEncrypt } from "jsencrypt";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

let pemContents = "";

fetch(raw)
  .then((r) => r.text())
  .then((text) => {
    pemContents = text;
  });

function ForgetPassword() {
  const labels = useSelector((state) => state.labels.data);

  const [email, setEmail] = useState();
  const [mainFormVisible, setMainFormVisible] = useState(true);
  const [processFormVisible, setProcessFormVisible] = useState(false);
  const [resultFormVisible, setResultFormVisible] = useState(false);

  const [emailToken, setEmailToken] = useState();
  const [emailOtp, setEmailOtp] = useState();

  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  var encrypt = new JSEncrypt();

  const encryptStringWithRsaPublicKey = function (toEncrypt) {
    let publicKey = `-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgBd+cTblFj92AaZJpTUAnlg
ZRN/Ng6WyqnNL1PgrihZHTo5yCrLWdp/P7wvKZZbUeI3U+rfrEqLhJ3FCjt1ofgA
xc4l4RTTV8eenFsG6+aN5FmfhgSt08yRCwVj2SwGIFX+CL1la/JOyw9bzPx70xLK
FrjskVLI96rSfAcStNhVGy1PaJrt5vHnYs+7Fc2QHgctyH5E3jzqRpNyfwEG3cBC
dTEmBMSoqIkcXAvQpKL8GsttECZBKPjA9rqYu87Ph5ivmjQWLNmdht7/AyQVDlLd
Z7JWKVzIZXuYgcT839ktN27cyOXyfAI/qT72mdgMLu2KEbnkKoguzKkQ0iaYE811
lrm/n6jVp7QvAQ2Gnf1qOO6fq0RA9GmwjKR+cDKdkfRbKjCOLpEpPiJtg5jLD6jP
gQwQlnbNON58ocSp8pvyR1nlD68RrfdzgjNi9QtPjVvOGQxmGuVya/MAb2v3CabQ
5EB39PN9BjdkdZDOnBgawR+aTNCf1ytrhDp9pVineoP5x9zzh8cq1xz5V4uoSidr
220cpglZ+d7tyZc5yF6syEl6h+2lB6PljGx1gZOct+zWbbZSePwnVOTaiZpbAHps
5srrY6LdHlQOLPBvGSutg9yAivynUtBtz0a4c2+pxWCGrOVdYy9QLFh8diKnpI2k
Ohfz/J7CoyZna3kxn/n9nwIDAQAB
-----END PUBLIC KEY-----`;

    var buffer = Buffer.from(toEncrypt, "utf16le");
    encrypt.setPublicKey(publicKey);
    var encrypted = encrypt.encrypt(toEncrypt);
    return encrypted.toString("base64");
  };

  const validateEmailFormat = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidChars = /[<>{}\[\]'"\\;()]/;

    return emailRegex.test(input) && !invalidChars.test(input);
  };

  const handleEmailChange = (e) => {
    let value = e.target.value;
    const sanitizedValue = value.replace(/[<>{}\[\]'"\\;()]/g, "");

    setEmail(sanitizedValue);

    if (!validateEmailFormat(sanitizedValue)) {
      setError((prev) => ({
        ...prev,
        email: labels?.forms?.validations?.general,
      }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  };

  const [error, setError] = useState({
    email: "",
  });

  const resetPassword = async () => {
    if (!email) {
      setError((prev) => ({
        ...prev,
        email: labels?.forms?.validations?.required,
      }));
      return false;
    }

    if (!validateEmailFormat(email)) {
      const message = labels?.forms?.validations?.general;
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }

    const requestBody = {
      email: email,
    };

    try {
      // const response = await axios.post(`${process.env.REACT_APP_API_REST_URL}/api/forgot-password-otp`, requestBody);
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/ForgotPassword`,
        requestBody
      );

      setMainFormVisible(false);
      setProcessFormVisible(true);
      setResultFormVisible(false);

      setEmailToken(response?.data?.data?.emailToken);
    } catch (error) {}
  };

  const doResetPassword = async () => {
    const encrypted = encryptStringWithRsaPublicKey(password);

    const requestBody = {
      email: email,
      // email_otp: emailOtp,
      // email_token: emailToken,
      // new_password: encrypted,
      // sms_otp: "",
      // sms_token: ""
    };

    try {
      // const response = await axios.post(`${process.env.REACT_APP_API_REST_URL}/api/forgot-password`, requestBody);
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/ForgotPassword`,
        requestBody
      );

      // setMainFormVisible(false);
      // setProcessFormVisible(false);
      // setResultFormVisible(true);

      setMainFormVisible(false);
      setProcessFormVisible(true);
      setResultFormVisible(false);
    } catch (error) {}
  };

  // https://codesandbox.io/p/sandbox/rsa-encryption-example-eih3j?file=%2Fpublic%2Findex.html%3A6%2C35

  const testPass = async () => {
    let tmpPass = encryptStringWithRsaPublicKey("OnurNizam78!1");

    const requestBody = {
      email: "onur.nizam@gmail.com",
      dvc_id: "4f6adf88-ea80-494d-95ff-33e2ebe1a0a8",
      // password:encryptStringWithRsaPublicKey("OnurNizam78!1"),
      password:
        "EUlnsMzD9dnTjydc+/Ni1uXHOE+hISLHV8oJK/VmDbIaHJfLTc/xTRDMyxOj01+BM9Z8R0YQcEYkNnlv7PlHDlVu/cwBIacTmwA6Jc7z3dTo4yf+Y3vbuHeGZoyZu2aKQIRpgWlHT8yHwb1K5dgPoGyhds9S+DX9e126LjZ9u2XfJiubn6M3Tumh0k0A7usfVo088h5LUGMQEww11hstpo8NidUUpliwt9AaQLM92bTiOOgqTK7AavSBT3j0LuEEPY7fICGlmzLE5wQ4tJqQjizB74NNgHrNdFNhW8g0z0aR/n+2LxPQLkjvCG5iQRaFQSiZB0WzRaXd3FjycYJO+S6Ula7UWBRg5F2UY/JnIo4vFcz9mm4NVe+KmS4KH8V+xgFKeb1ftes8T4rO9t41hbDbDPq0ZnidtLGAlZxXSx3aYNCcGsW8Rx/72J7kr3BJVzQapiHkhv+JJ94X3BuWKMiNd9J4+VbTGMXImOlgPcuB1yWWoCLXMttjI1hNmkZmzvbEj4WfNl+WudMp1177TB4/Doohz0RyUAjJqWf4jNrI+w4bC0fPwxTLYE2Pe6UtAUGM1ZFIufpw21a7tBdI4NHjvOfz8nNMpTvKKdMswD/Dg610yXBw2vhG4rY3yKeJdO2D2o1Lyamfbn1QuSVs0wYhgW0RhyVzIAB6y0pFf1Q=", //encryptStringWithRsaPublicKey("OnurNizam78!1")
      //password:"epXE21Pqca13+zpiJk8Ga5u8sutgWf3rTXVLpUFmWowOfy1Wlzey+CgZc32QWzLz7hpgbLOtSOiQWJqdmc3fXA=="
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_REST_URL}/api/AuthenticateUser/v2`,
      requestBody
    );
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />

      {/* Login */}
      <section className="login">
        <div className="container">
          <div className="row row-reset justify-content-center">
            <div className="col-lg-4">
              <div className="picture">
                <img
                  className="bg"
                  src="images/materials/signup_banner.png"
                  alt=""
                />
                <img className="logo" src="images/logo.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              {mainFormVisible ? (
                <form className="sign-up">
                  <h3>{labels?.forms?.resetPassword?.title}</h3>
                  <div className="input-container">
                    <label htmlFor="your-email">
                      {labels?.forms?.resetPassword?.email?.placeholder}
                    </label>
                    <input
                      type="text"
                      placeholder="name@example.com"
                      value={email}
                      onChange={handleEmailChange}
                      className={`form-control ${
                        error.email ? "is-invalid" : ""
                      }`}
                    />
                    {error.email && (
                      <div className="invalid-feedback">{error.email}</div>
                    )}
                  </div>
                  <input
                    type="button"
                    value={labels?.forms?.resetPassword?.title}
                    className="button-style-01"
                    onClick={() => resetPassword()}
                  />
                </form>
              ) : (
                ""
              )}

              {processFormVisible ? (
                // <form className="sign-up">
                //   <h3>Reset your password</h3>

                //   <div className="input-container">
                //     <label htmlFor="password">New Password</label>
                //     <input type="password" placeholder="••••••••••" onChange={e=>setPassword(e.target.value)} />
                //   </div>
                //   <div className="input-container">
                //     <label htmlFor="password-confirm">Confirm New Password</label>
                //     <input type="password" placeholder="••••••••••" onChange={e=>setConfirmPassword(e.target.value)} />
                //   </div>
                //   <div className="input-container">
                //     <label htmlFor="code">Email OTP</label>
                //     <input type="password" placeholder="••••••••••" onChange={e=>setEmailOtp(e.target.value)} />
                //   </div>

                //   <input type="button" value="Reset Password" className="button-style-01" onClick={()=>doResetPassword()} />
                // </form>
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h5 align="center" style={{ color: "#ccc" }}>
                    {labels?.form?.verificationLinkSent}
                  </h5>
                </>
              ) : (
                ""
              )}

              {resultFormVisible ? (
                <form className="sign-up">
                  <h3>{labels?.messages?.successfulPasswordChange}</h3>
                  <br />
                  <br />
                  <h6 align="center">
                    <Link to="/login">{labels?.form?.loginHere}</Link>
                  </h6>
                </form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Login End */}

      <Footer />
    </>
  );
}

export default ForgetPassword;
