import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
import CustomModal from "../../../Components/CustomModal/";
import { isValidEmail, validateString } from "../../../Common/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../../../Features/Session/SessionSlice";
import "./Login.css";
import crypto from "crypto-browserify";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useModalContext } from "../../../Context/ModalContext";
import raw from "../../../Utils/publicKey.pem";

const initialTime = 1 * 60;

function Login() {
  const labels = useSelector((state) => state.labels.data);
  const [pemContents, setPemContents] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const { setAdditionalInformation } = useModalContext();

  const [otpButtonActive, setOtpButtonActive] = useState();

  React.useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [hashedPassword, setHashedPassword] = useState("");
  const [message, setMessage] = useState();
  const [tempAuthToken, setTempAuthToken] = useState();
  const [smsCode, setSmsCode] = useState();
  const [refreshCondition, setRefreshCondition] = useState(0);
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
  const [isLoginVerificationFormVisible, setIsLoginVerificationFormVisible] =
    useState(false);
  const [verificationType, setVerificationType] = useState();
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const [isDeviceVerificationRequired, setIsDeviceVerificationRequired] =
    useState();
  const [deviceOtp, setDeviceOtp] = useState();

  let deviceId = localStorage.getItem("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("deviceId", deviceId);
  }

  const handleInputSmsChange = (e) => {
    // Accept only numeric characters
    const numericInput = e.target.value.replace(/\D/g, "");
    setSmsCode(numericInput);
  };

  useEffect(() => {
    if (refreshCondition == 1) {
      // If the condition is met, reload the page
      //   window.location.reload();
      if (timeLeft === 0) {
        setRefreshCondition(0);
        setOtpButtonActive(true);
        setTimeLeft(initialTime);
        // send email otp after 1 minute
        if (!isDeviceVerificationRequired) {
          checkLogin();
        }
      } else {
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
      }
    }
  }, [refreshCondition, timeLeft]); // Dependency array to watch for changes in 'someCondition'

  useEffect(() => {
    if (isLoginVerificationFormVisible) {
      // Exit early when we reach 0
      if (timeLeft === 0) {
        setRefreshCondition(1);
      } else {
        const intervalId = setInterval(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);

        // Clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
      }
    }
  }, [timeLeft, isLoginVerificationFormVisible]);

  // Format time left into minutes:seconds
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    // Pad seconds with a zero if it's less than 10
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const [deviceOtpVerificationSuccess, setDeviceOtpVerificationSuccess] =
    useState(false);

  const verifyDeviceOtp = async () => {
    const token = localStorage.getItem("token");

    const requestBody = {
      grant_type: "password",
      username: tempAuthToken,
      password: smsCode,
      dvc_otp: deviceOtp,
      dvc_id: deviceId,
    };

    var formBody = [];
    for (var property in requestBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(requestBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/token/v2`,
        formBody
      );
      if (response?.data?.success) {
        setDeviceOtpVerificationSuccess(true);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkLoginVerification = async () => {
    //  if (isDeviceVerificationRequired) {
    //    verifyDeviceOtp();
    //  }

    const requestBody = {
      grant_type: "password",
      username: tempAuthToken,
      password: smsCode,
      dvc_otp: deviceOtp,
      dvc_id: deviceId,
    };

    var formBody = [];
    for (var property in requestBody) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(requestBody[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/token/v2`,
        formBody
      );

      if (response?.data?.access_token) {
        localStorage.setItem("token", response?.data?.access_token);

        const response2 = await axios.get(
          `${process.env.REACT_APP_API_REST_URL}/api/GetProfile`,
          {
            headers: {
              Authorization: `Bearer ${response?.data?.access_token}`,
            },
          }
        );

        localStorage.setItem("userInfo", JSON.stringify(response2.data.data));

        // dispatch(login(response?.data?.access_token));
        dispatch(
          login({
            userToken: response.data.access_token,
            userProfile: JSON.stringify(response2.data.data),
          })
        );

        // ask for additional fields

        try {
          const additionalFieldsResponse = await axios.get(
            `${process.env.REACT_APP_API_REST_URL}/api/Customer_AddtionalFields`,
            {
              headers: {
                Authorization: `Bearer ${response?.data?.access_token}`,
              },
            }
          );

          setAdditionalInformation(additionalFieldsResponse.data.data);
          if (additionalFieldsResponse.data.data.length > 0) {
            navigate("/additional-information-required");
            window.location.reload();
          } else {
            navigate("/home", { replace: true });
            window.location.reload();
          }
        } catch (additionalFieldsError) {
          //   console.error("Error fetching additional fields", additionalFieldsError);
          toast.error("", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        //  const message = error?.response?.data?.error_description
        toast.error("", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      const messageKey = error?.response?.data?.error;
      const getDynamicMessage = (key) => {
        return labels?.messages?.[key] || key;
      };
      const dynamicMessage = getDynamicMessage(messageKey);
      toast.error(dynamicMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const validateInputFormat = (input) => {
    const invalidChars = /[<>{}[\]();'"\\`~!@#$%^&*+=|]/;
    return !invalidChars.test(input);
  };

  const validateEmailFormat = (input) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidChars = /[<>{}\[\]]/;

    return emailRegex.test(input) && !invalidChars.test(input);
  };

  const handleUsernameChange = (e) => {
    let value = e.target.value;
    const sanitizedValue = value.replace(/[<>{}\[\]'"\\;()]/g, "");

    setUsername(sanitizedValue);

    if (!validateEmailFormat(sanitizedValue)) {
      setError((prev) => ({
        ...prev,
        username: labels?.forms?.validations?.general,
      }));
    } else {
      setError((prev) => ({ ...prev, username: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setError((prev) => ({
        ...prev,
        password: labels?.forms?.validations?.required,
      }));
    } else {
      setError((prev) => ({ ...prev, password: "" }));
    }
  };

  const checkLogin = async () => {
    setError({ username: "", password: "" });
    let hasError = false;

    if (!username) {
      setError((prev) => ({
        ...prev,
        username: labels?.forms?.validations?.required,
      }));
      hasError = true;
    } else if (!validateEmailFormat(username)) {
      setError((prev) => ({
        ...prev,
        username: labels?.forms?.validations?.general,
      }));
      hasError = true;
    }

    if (!password) {
      setError((prev) => ({
        ...prev,
        password: labels?.forms?.validations?.required,
      }));
      hasError = true;
    }

    if (hasError) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/AuthenticateUser/v2`,
        {
          dvc_id: deviceId,
          email: username,
          password: hashedPassword,
          mobile_session: true,
        }
      );

      if (response?.data?.status === "Success") {
        setTempAuthToken(response?.data?.data?.tempAuthToken || "");
        setVerificationType(response?.data?.data?.twoFAMehtod || "Email");
        setIsDeviceVerificationRequired(
          response?.data?.data?.deviceVerificationRequired
        );
        setIsLoginFormVisible(false);
        setIsLoginVerificationFormVisible(true);
      } else {
        // setError(prev => ({ ...prev, username: "Wrong email or password!" }));
        const message = labels?.messages?.SignIn_Authentication_Failed;
        toast.error(message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Login error:", error);
      setError((prev) => ({
        ...prev,
        username: "An error occurred. Please try again.",
      }));
    }
  };

  useEffect(() => {
    async function getPubKey() {
      try {
        const response = await fetch(raw);
        const text = await response.text();
        setPemContents(text);
      } catch (error) {
        console.error("Error fetching public key:", error);
      }
    }

    getPubKey();
  }, []);

  useEffect(() => {
    const pubKeyPem = pemContents;

    try {
      const buffer = Buffer.from(password, "utf16le");

      const encrypted = crypto.publicEncrypt(pubKeyPem, buffer);

      const encryptedBase64 = encrypted.toString("base64");

      setHashedPassword(encryptedBase64);
    } catch (error) {
      console.error("Error while encrypting:", error);
    }
  }, [password, pemContents]);

  const requestDeviceOtp = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_REST_URL +
          "/api/Request_Device_Verification_OTP",
        {
          dvc_id: deviceId,
          email: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOtpButtonActive(false);
      const messageKey = response.data.message;
      const getDynamicMessage = (key) => {
        return labels?.messages?.[key] || key;
      };
      const dynamicMessage = getDynamicMessage(messageKey);
      toast.success(dynamicMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error("Error requesting OTP:", error);
    }
  };

  const [isDeviceOtpValid, setIsDeviceOtpValid] = useState(false);
  const [hasDeviceOtpBlurred, setHasDeviceOtpBlurred] = useState(false);

  const [isSmsCodeValid, setIsSmsCodeValid] = useState(false);
  const [hasSmsCodeBlurred, setHasSmsCodeBlurred] = useState(false);

  useEffect(() => {
    setIsDeviceOtpValid(deviceOtp?.length >= 6);
  }, [deviceOtp]);

  useEffect(() => {
    setIsSmsCodeValid(smsCode?.length >= 6);
  }, [smsCode]);

  useEffect(() => {
    if (isDeviceVerificationRequired === false && smsCode?.length === 6) {
      checkLoginVerification();
    }
  }, [smsCode]);

  useEffect(() => {
    if (deviceOtp?.length === 6 && smsCode?.length === 6) {
      checkLoginVerification();
    }
  }, [deviceOtp, smsCode]);

  return (
    <>
      <Header />

      <CustomModal
        show={show}
        handleClose={handleClose}
        title="Login"
        message={message}
      />

      {/* Login */}
      <section className="login">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="text">
                <ul>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Get started quickly
                    </h5>
                    <p>
                      Integrate with developer-friendly APIs or choose low-code
                      or pre-built solutions.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Support any business model
                    </h5>
                    <p>
                      Host code that you don't want to share with the world in
                      private.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <img src="images/icons/check-circle.svg" alt="" />
                      Join millions of businesses
                    </h5>
                    <p>
                      We are trusted by ambitious startups and enterprises of
                      every size.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              {isLoginFormVisible && !localStorage.getItem("token") ? (
                <form>
                  <h4>{labels?.forms?.login?.title}</h4>
                  <div className="input-container">
                    <label htmlFor="email">{labels?.form?.email}</label>
                    <input
                      type="text"
                      placeholder={labels?.forms?.login?.email.placeholder}
                      onChange={handleUsernameChange}
                      value={username}
                      className={`form-control ${
                        error.username ? "is-invalid" : ""
                      }`}
                    />
                    {error.username && (
                      <div className="invalid-feedback">{error.username}</div>
                    )}
                  </div>
                  <div className="input-container">
                    <label htmlFor="password">{labels?.form?.password}</label>
                    <input
                      type="password"
                      placeholder={labels?.forms?.login?.password.placeholder}
                      onChange={handlePasswordChange}
                      className={`form-control ${
                        error.password ? "is-invalid" : ""
                      }`}
                    />
                    {error.password && (
                      <div className="invalid-feedback">{error.password}</div>
                    )}
                  </div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id="check"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />

                    {/* <label htmlFor="check">
                    <p>  By signing up, you are creating a Sendinblue account, and you agree to Flowbite鈥檚 <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                    </p>
                  </label> */}
                  </div>
                  <input
                    type="button"
                    value={labels?.forms?.login?.button}
                    className="button-style-01"
                    onClick={() => checkLogin()}
                  />

                  <a href="/register" className="link">
                    {labels?.forms?.signUp?.title}
                  </a>
                  <a href="/forget-password" className="link">
                    {labels?.buttons?.forgotPassword}
                  </a>
                </form>
              ) : (
                <>
                  {/* <h5 style={{color:'#ddd'}} align='center'>You are already logged in!</h5> */}
                </>
              )}

              {isLoginVerificationFormVisible ? (
                <form>
                  <h4>{labels?.forms?.login?.title}</h4>
                  <div className="input-container">
                    {verificationType !== "Email" ? (
                      <>
                        <label htmlFor="password">
                          {labels?.forms?.twoFaLogin?.googleAuth?.placeholder
                            .replace(
                              "$t(multiFactorAuth.length)",
                              labels?.multiFactorAuth?.length
                            )
                            .replace(
                              "$t(multiFactorAuth.name)",
                              labels?.multiFactorAuth?.Google
                            )}
                        </label>
                      </>
                    ) : (
                      <>
                        <label htmlFor="password">
                          {labels?.forms?.twoFaLogin?.emailAuth?.placeholder}
                        </label>
                      </>
                    )}

                    <input
                      type="password"
                      placeholder={labels?.forms?.login.password.placeholder}
                      value={smsCode}
                      onChange={handleInputSmsChange}
                      onBlur={() => setHasSmsCodeBlurred(true)}
                    />

                    {!isSmsCodeValid && hasSmsCodeBlurred && (
                      <div className="invalid-feedback d-block mt-0">
                        {smsCode === null || smsCode === undefined ? (
                          <>{labels?.forms?.validations?.required}</>
                        ) : (
                          <>
                            {labels?.forms?.validations?.min.replace(
                              "{{length}}",
                              labels?.multiFactorAuth?.length
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="countdown">
                    {labels?.forms?.twoFaLogin?.timeRemaining.replace(
                      "{{timeRemaining}}",
                      formatTime()
                    )}

                    <div id="countdown" />
                  </div>

                  <>
                    {isDeviceVerificationRequired === true && (
                      <>
                        <div className="form-group device-otp-form-group flex-column flex-lg-row flex-md-row">
                          <div className="input-field">
                            <label htmlFor="password" className="d-none">
                              {
                                labels?.forms?.twoFaLogin?.deviceAuth
                                  ?.placeholder
                              }
                            </label>
                            <input
                              type="text"
                              placeholder={
                                labels?.forms?.twoFaLogin?.deviceAuth
                                  ?.placeholder
                              }
                              onChange={(e) => setDeviceOtp(e.target.value)}
                              onBlur={() => setHasDeviceOtpBlurred(true)}
                              className="form-control"
                              value={deviceOtp}
                              autoComplete={false}
                            />
                          </div>
                          <div className="button-field">
                            <button
                              className="btn btn-primary"
                              disabled={
                                otpButtonActive === false ? true : false
                              }
                              onClick={requestDeviceOtp}
                            >
                              {labels?.forms?.twoFaLogin?.requestDeviceOtp}
                            </button>
                          </div>
                        </div>
                        {!isDeviceOtpValid && hasDeviceOtpBlurred && (
                          <div className="invalid-feedback d-block mt-0">
                            {deviceOtp === null || deviceOtp === undefined ? (
                              <>{labels?.forms?.validations?.required}</>
                            ) : (
                              <>
                                {labels?.forms?.validations?.min.replace(
                                  "{{length}}",
                                  labels?.multiFactorAuth?.length
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>

                  {/*
                <input
                  type="button"
                  value="Verify"
                  className="button-style-01"
                  onClick={() => checkLoginVerification()}
                />
            */}
                </form>
              ) : (
                ""
              )}

              {localStorage.getItem("token") ? (
                <h5 style={{ color: "#ddd" }} align="center">
                  {labels?.generics?.alreadyLoggedIn}
                </h5>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Login End */}

      <Footer />
    </>
  );
}

export default Login;
