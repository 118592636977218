import React, { Fragment } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { Message } from 'react-bulma-components';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const DepthChart = ({ tradingPair, depthChartData, isActive = true }) => {
  const labels = useSelector((state) => state.labels.data);

  if (!isActive) {
    return null;
  }


  const modifiedDepthChartData = depthChartData.map(dataPoint => ({
    ...dataPoint,
    askTotalVolume: dataPoint.askTotalVolume,
    bidTotalVolume: dataPoint.bidTotalVolume
  }));

  const maxVolumes = modifiedDepthChartData?.reduce(
    (acc, item) => {
      acc.maxBidVolume = Math.max(acc.maxBidVolume, item.bidTotalVolume || 0); 
      acc.maxAskVolume = Math.max(acc.maxAskVolume, item.askTotalVolume || 0); 
      return acc;
    },
    { maxBidVolume: 0, maxAskVolume: 0 } 
  );
  
  
  

  return (
    <div style={{ width: '100%', height: 400 }} className="depth-chart-container">
      <ResponsiveContainer>
        {!_.isEmpty(modifiedDepthChartData) ? (
          <ComposedChart
            data={modifiedDepthChartData}
            margin={{ top: 10, right: -10, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor='#db2327' 
                  stopOpacity={0.9}
                />
                <stop
                  offset="95%"
                  stopColor='#db2327'
                  stopOpacity={0.2}
                />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor='#26bf22' 
                  stopOpacity={0.9}
                />
                <stop
                  offset="95%"
                  stopColor='#26bf22'
                  stopOpacity={0.2}
                />
              </linearGradient>
            </defs>
            <XAxis dataKey="price" tick={{ fontSize: 10 }} />
            <YAxis domain={[0,  'dataMax']} yAxisId={0} width={40} tick={{ fontSize: 10 }} />
            <YAxis
  dataKey="volume"
  yAxisId={1}
  orientation="right"
  width={40}
  tick={{ fontSize: 10 }}
  //domain={[0, 'dataMax']}
  //tickFormatter={(value) => value * 10}
/>
            <Bar
              dataKey="volume"
              fill="#000"
              opacity={0.75}
              yAxisId={1}
            />
                        <Area
              type="step"
              dataKey="bidTotalVolume"
              stroke='#26bf22'         
              fillOpacity={1}
              fill="url(#colorPv)"
              yAxisId={0}
            />
            <Area
              type="step"
              dataKey="askTotalVolume" 
              stroke="#db2327"          
              fillOpacity={1}
              fill="url(#colorUv)"
              yAxisId={0}
            />
            <Tooltip content={<CustomToolTip tradingPair={tradingPair} />} />
          </ComposedChart>
        ) : (
          <div style={{ height: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <p>{labels?.generics?.noData}</p>
            </div>
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
};

// CustomToolTip component 
const CustomToolTip = ({ payload, tradingPair }) => {
  const data = payload && payload[0] ? payload[0].payload : null;

  const labels = useSelector(state => state.labels.data);

  return (
    <Fragment>
      {data && (
        <Message
          size="small"
          color={data.askTotalVolume ? 'danger' : 'success'}
        >
          <Message.Body
            style={{ 
              backgroundColor: '#fff', 
              borderLeftColor: data.bidTotalVolume ? 'rgb(38, 191, 34)' : '#db2327', 
              borderLeftWidth: data.bidTotalVolume ? 5 : 0, 
              borderLeftStyle: 'solid', 
              borderRightColor: data.askTotalVolume ? '#db2327' : '', 
              borderRightWidth: data.askTotalVolume ? 5 : 0, 
              borderRightStyle: 'solid',
              paddingLeft: 15,
              paddingRight: 10,
              borderRadius: 7,
              paddingTop: 10,
              paddingBottom: 5
            }}
            className={classNames({
              'message-border-right': data.bidTotalVolume,
            })}
          >
            <p>
              {labels?.exchange?.depthChart?.price}: {data.price} {tradingPair.quoteCurrency}
            </p>
            <p>
              {labels?.exchange?.depthChart?.totalVolume}:{' '}
              {data.askTotalVolume ? data.askTotalVolume : data.bidTotalVolume}{' '}
              {tradingPair.baseCurrency}
            </p>
            <p>
              {labels?.exchange?.depthChart?.volume}: {data.volume} {tradingPair.baseCurrency}
            </p>
          </Message.Body>
        </Message>
      )}
    </Fragment>
  );
};

export default DepthChart;
