import { ToastContainer, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Banks.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import CustomModal from "../../../Components/CustomModal/";
import AddBankModal from "./AddBankModal";

import axios from "axios";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function Banks() {
  const labels = useSelector((state) => state.labels.data);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [is2FaEnabled, setIs2FaEnabled] = useState(false);
  const [data, setData] = useState([]);
  const [tempToken, setTempToken] = useState();
  const [deleteDeviceId, setDeleteDeviceId] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAddBank, setShowAddBank] = useState(false);
  const handleCloseAddBank = () => setShowAddBank(false);
  const handleShowAddBank = () => setShowAddBank(true);
  const [isOtpTimerRunning, setIsOtpTimerRunning] = useState(null);

  const [hasError, setHasError] = useState(false);

  const addBank = async () => {
    handleShowAddBank();
  };

  const handleOkButtonClick = async () => {
    try {
      let reqBody = {
        id: deleteDeviceId,
      };

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Delete_Fiat_Account`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((error) => {
          const message = labels?.errorPages?.generalError;
          toast.error(message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });

      if (response?.data?.status == "Success") {
        const message = labels?.messages?.Success_General;
        toast.success(message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setHasError(true);
    }

    handleClose();
  };

  const deleteItem = async (id) => {
    setDeleteDeviceId(id);
    handleShow();
  };

  const sendOptFunction = async (returnObj) => {
    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Request-otp-fiat-customer-accounts`,
          returnObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((error) => {
          const message = labels?.errorPages?.generalError;
          toast.error(message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });

      if (response?.data?.status == "Success") {
        const message = labels?.wallet?.banks?.otpSuccessMessage;
        toast.success(message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOtpTimerRunning(true);
        setTimeout(() => {
          setIsOtpTimerRunning(false) // 1 dakika sonra butonu tekrar aktif et
        }, 60000); // 60,000 ms = 1 dakika
      }
      setTempToken(response?.data?.data?.temp_token);
    } catch (error) {
      setHasError(true);
    }
  };


  const [hasBankAdded, setHasBankAdded] = useState(false);

  const handleOkBankFunction = async (returnObj) => {
    try {
      returnObj.emailtoken = tempToken;

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Add_Fiat_CustomerAccount`,
          returnObj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((error) => {
          throw new Error("");
        });

      if (response?.data?.status == "Success") {
        const message = labels?.messages?.Success_General;
        toast.success("👌 " + message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleCloseAddBank();
        setHasBankAdded(true);
      } else {
        const message = labels?.messages?.[response?.data?.message].replace("$t(multiFactorAuth.name)",
        labels?.multiFactorAuth?.Google);
        toast.error(message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

    } catch (error) {
      setHasError(true);
    }
  };

  const check2Fa = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setIs2FaEnabled(response?.data?.data?.is2FAEnabled);
      })
      .catch((error) => {
        setHasError(true);
      });
  };

  useEffect(() => {
    check2Fa();
    setIsLoading(true);

    // Axios GET
    axios
      .get(
        `${process.env.REACT_APP_API_REST_URL}/api/Get_Fiat_CustomerAccounts`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setHasError(true);
      });
  }, [show, showAddBank]);


  if (hasError) {
    throw new Error("");
  }

  return (
    <>
      <Header />

      <CustomModal
        show={show}
        handleClose={handleClose}
        title={labels?.buttons?.delete}
        message={labels?.account?.deviceWhitelisting?.warningMessageOptions}
        handleOk={handleOkButtonClick}
      />

      <AddBankModal
        showAddBank={showAddBank}
        handleCloseAddBank={handleCloseAddBank}
        title={labels?.wallet?.banks?.addBanks}
        handleOkBank={handleOkBankFunction}
        sendOtp={sendOptFunction}
        is2FaEnabled={is2FaEnabled}
        hasBankAdded={hasBankAdded}
        isOtpTimerRunning={isOtpTimerRunning}
      />

      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Wallet" submenu="WalletBanks" />
        <div className="main-content">
          {/* <h4 className="title">Banks</h4> */}

          <div class="row">
            <div class="col">
              <h4 className="title" style={{ margin: "0px!important" }}>
                {labels?.wallet?.banks?.link}
              </h4>
            </div>
            <div class="col-auto d-flex align-items-center">
              <button className="btn btn-primary" onClick={() => addBank()}>
                {labels?.wallet?.banks?.addBanks}
              </button>
            </div>
          </div>

          <div className="mt5">
            <div className="table-responsive table-banks">
              <table className="table">
                <thead className="w-100">
                  <tr>
                    <th style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}> {labels?.wallet?.banks?.accountNumber}</th>
                    <th style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>{labels?.wallet?.banks?.accountType}</th>
                    <th                       style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>{labels?.wallet?.banks?.accountCurrency} </th>
                    <th
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {labels?.wallet?.banks?.bankRoutingCode}
                    </th>
                    <th>{labels?.wallet?.banks?.bankName}</th>
                    <th>{labels?.wallet?.banks?.swiftCode}</th>
                    <th>{labels?.wallet?.banks?.action}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          
                        <OverlayTrigger
    placement="top"
    overlay={<Tooltip id={`tooltip-${item.accountNumber}`}>{item.accountNumber}</Tooltip>}
  >
    <span
      style={{
        display: 'inline-block',
        maxWidth: '90%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        cursor: 'pointer', 
      }}
    >
      {item.accountNumber}
    </span>
  </OverlayTrigger>
                          </td>
                        <td>{item.accountType}</td>
                        <td>{item?.accountCurrency}</td>
                        <td>{item.bankRoutingCode}</td>
                        <td>{item.bankName}</td>
                        <td>{item.swiftCode}</td>

                        <td className="column-action">
                          <button
                            className="button-danger px-0"
                            onClick={() => deleteItem(item.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.20039 1.6001C7.05186 1.60018 6.90629 1.6416 6.77997 1.71974C6.65365 1.79787 6.55158 1.90963 6.48519 2.0425L5.90599 3.2001H3.20039C2.98822 3.2001 2.78473 3.28438 2.63471 3.43441C2.48468 3.58444 2.40039 3.78792 2.40039 4.0001C2.40039 4.21227 2.48468 4.41575 2.63471 4.56578C2.78473 4.71581 2.98822 4.8001 3.20039 4.8001V12.8001C3.20039 13.2244 3.36896 13.6314 3.66902 13.9315C3.96908 14.2315 4.37604 14.4001 4.80039 14.4001H11.2004C11.6247 14.4001 12.0317 14.2315 12.3318 13.9315C12.6318 13.6314 12.8004 13.2244 12.8004 12.8001V4.8001C13.0126 4.8001 13.216 4.71581 13.3661 4.56578C13.5161 4.41575 13.6004 4.21227 13.6004 4.0001C13.6004 3.78792 13.5161 3.58444 13.3661 3.43441C13.216 3.28438 13.0126 3.2001 12.8004 3.2001H10.0948L9.51559 2.0425C9.4492 1.90963 9.34713 1.79787 9.22081 1.71974C9.0945 1.6416 8.94892 1.60018 8.80039 1.6001H7.20039ZM5.60039 6.4001C5.60039 6.18792 5.68468 5.98444 5.83471 5.83441C5.98473 5.68438 6.18822 5.6001 6.40039 5.6001C6.61256 5.6001 6.81605 5.68438 6.96608 5.83441C7.11611 5.98444 7.20039 6.18792 7.20039 6.4001V11.2001C7.20039 11.4123 7.11611 11.6158 6.96608 11.7658C6.81605 11.9158 6.61256 12.0001 6.40039 12.0001C6.18822 12.0001 5.98473 11.9158 5.83471 11.7658C5.68468 11.6158 5.60039 11.4123 5.60039 11.2001V6.4001ZM9.60039 5.6001C9.38822 5.6001 9.18473 5.68438 9.03471 5.83441C8.88468 5.98444 8.80039 6.18792 8.80039 6.4001V11.2001C8.80039 11.4123 8.88468 11.6158 9.03471 11.7658C9.18473 11.9158 9.38822 12.0001 9.60039 12.0001C9.81256 12.0001 10.016 11.9158 10.1661 11.7658C10.3161 11.6158 10.4004 11.4123 10.4004 11.2001V6.4001C10.4004 6.18792 10.3161 5.98444 10.1661 5.83441C10.016 5.68438 9.81256 5.6001 9.60039 5.6001Z"
                                fill="white"
                              />
                            </svg>
                            {labels?.buttons?.delete}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {isLoading ? (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <img src="/images/loading.gif" width={50} />
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.loading}</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.noData}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Banks;
