import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";


export default function StaticConversionModal({
  visible,
  toggleStaticConversionModal, 
  from,
  to,
  rate
}) {
  const labels = useSelector((state) => state.labels.data);

  const [quantity, setQuantity] = useState(0);

  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;
  
    if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      setQuantity(inputValue);
    }
  };


  const convertCoin = async () => {
    const url = `${process.env.REACT_APP_API_REST_URL}/api/convert_coin_static`;

    const token = localStorage.getItem("token");
    
    const payload = {
      fromCurrency: from,
      toCurrency: to,
      quantity: quantity
    };
    
    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.data.status === "Success") {
        const message = labels?.wallet?.static_conversion?.conversionSuccess;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        const messageKey = response.data.message; 
        const getDynamicMessage = (key) => {
          return labels?.messages?.[key] || key; 
         };
         const dynamicMessage = getDynamicMessage(messageKey);
        toast.error(dynamicMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    
    } catch (error) {
    }
  };
  
  


  return (
    <Modal show={visible} onHide={toggleStaticConversionModal} centered className="modal settings-modal static-conversion-modal">
      <Modal.Header closeButton>
        <Modal.Title className="fs-4">
          {labels?.wallet?.staticConversion?.convertTo
            .replace("{{from}}", from)
            .replace("{{to}}", to)
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3">
          <div>
            <label for="amount">{labels?.wallet?.staticConversion?.enterAmount}</label>
            <input name="amount" id="amount" type="text" value={quantity} onChange={handleQuantityChange} className="form-control mt-2" />
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center align-items-start w-100 py-2">
            <div>
              <span className="fw-lighter">                
                {labels?.wallet?.staticConversion?.youConvert
                .replace("{{quantity}}", quantity)
                .replace("{{currency}}", from)
                }
                </span>
            </div>
            <div>
              <span className="fw-lighter">
                {labels?.wallet?.staticConversion?.youGet
                .replace("{{quantity}}", quantity * rate)
                .replace("{{currency}}", to)
                }
              </span>
            </div>
          </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer border-0 px-4">
      <Button variant="outline-danger" onClick={toggleStaticConversionModal} style={{ minWidth: 150, color: 'white' }}>
        {labels?.wallet?.staticConversion?.cancel}
        </Button>
        <Button className="btn" onClick={convertCoin}>
        {labels?.wallet?.staticConversion?.confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
