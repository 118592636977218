import axios from "axios";

const getMarketSummary = async () => {
  const response = await axios.get(
    `${process?.env?.REACT_APP_API_REST_URL}/market/get-market-summary`
  );
  return response?.data;
};

const marketService = {
  getMarketSummary,
};

export default marketService;
