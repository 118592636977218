
import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Table } from '@react-pdf/renderer';

import { formatNumberWithComma } from "../Common/Helpers";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    marginBottom: 10,
    borderBottom: '1 solid black',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    padding: 5,
    paddingLeft: 10,
    fontSize: 8, // Adjust font size
  },
  headerText: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: 6, // Adjust font size
  },
  listItem: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    padding: 5,
    paddingLeft: 10,
    size:5,
    fontSize: 6, // Adjust font size
  },
  listItemText: {
   marginLeft: 10,
  },
  firstChildText: {
    marginLeft: 0, 
  },
});


const isISODate = (dateString) => {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,7})?Z$/;
  return isoDatePattern.test(dateString);
};


const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  } catch {
    return dateString;
  }
};

const PdfDocument = ({ data, headers }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text>PDF Export</Text>
        </View>

        <View style={styles.tableHeader}>
          {headers.map((header, index) => (
            <Text key={index} style={[styles.headerText, { flex: 1 }]}>
              {header}
            </Text>
          ))}
        </View>
 

        {data.map((item, index) => (
          <View key={index} style={styles.listItem}>
            {headers.map((header, headerIndex) => (
              <Text key={headerIndex}
              style={[
                styles.listItemText,
                { flex: 1 },
                headerIndex === 0 ? styles.firstChildText : null // Apply special style to the first child
              ]}
              >

                {item[header] !== null && item[header] !== undefined ? 
                  (isISODate(item[header]) ? formatDate(item[header]) : 
                    (typeof item[header] === 'number' ? item[header].toLocaleString() : item[header])
                  ) 
                  : ""}
              </Text>
            ))}
          </View>
        ))}

 
      </View>
    </Page>
  </Document>
);

const PdfGenerator = ({ data, headers }) => {
 
  return (
    <div>
   
      <PDFDownloadLink className='btn btn-primary' document={<PdfDocument data={data} headers={headers} />} fileName="exported_data.pdf">
        {({ blob, url, loading, error }) =>
          'Export PDF'
        }
      </PDFDownloadLink> 
    </div>
  );
};

export default PdfGenerator;

