import React, { createContext, useState, useEffect, useContext } from "react";

export const CoinContext = createContext();

export const CoinProvider = ({ children }) => {
  const [favoriteCoins, setFavoriteCoins] = useState(() => {
    const savedFavorites = localStorage.getItem("favoriteCoins");
    return savedFavorites ? JSON.parse(savedFavorites) : [];
  });


  const [enableDustConversion, setEnableDustConversion] = useState();
  const [fiatList, setFiatList] = useState([]);
  const [markets, setMarkets] = useState([]);

  const [coinsData, setCoinsData] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [primaryCoin, setPrimaryCoin] = useState();


  const [newCoin, setNewCoin] = useState("");
  const [MARKET, SETMARKET] = useState("");
  const [activeMarket, setActiveMarket] = useState("");
  const [defaultPair, setDefaultPair] = useState();

  const [exchangeToken, setExchangeToken] = useState("");

  const [depositEnabledList, setDepositEnabledList] = useState([]);
  const [withdrawalEnabledList, setWithdrawalEnabledList] = useState([]);
  const [tradeEnabledList, setTradeEnabledList] = useState([]);

  const [latestTradePrice, setLatestTradePrice] = useState();


  useEffect(() => {
    localStorage.setItem("favoriteCoins", JSON.stringify(favoriteCoins));
  }, [favoriteCoins]);


  useEffect(() => {
    const fetchDefaultPair = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/GetSettings`);
        const data = await response.json();
        const defaultPair = data.data.default_Pair.split("_");
        setNewCoin(defaultPair[0]);
        SETMARKET(defaultPair[1]);
        setDefaultPair(defaultPair[1]);
        setActiveMarket(defaultPair[1])
        setEnableDustConversion(data.data.enable_DustConversion);
        const currencyArray = data.data.fiat_List?.split(',')
        setFiatList(currencyArray);
        setExchangeToken(data.data.tdM_Token_Name)
        setMarkets(data.data.markets)
        localStorage.setItem("defaultPair", JSON.stringify(defaultPair));
      } catch (error) {
      }
    };
  
    fetchDefaultPair();
  }, []);


  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchDepositEnabledList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/CurrencySettings`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = await response.json();
  
        setDepositEnabledList(
          data.data
            .filter((x) => x.depositEnabled)
            .map((x) => x.shortName)
        );
        setWithdrawalEnabledList(
          data.data
            .filter((x) => x.withdrawalEnabled)
            .map((x) => x.shortName)
        );
        setTradeEnabledList(
          data.data
            .filter((x) => x.tradeEnabled)
            .map((x) => x.shortName)
        );
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };
  
    fetchDepositEnabledList();
  }, []);
  



  const addFavoriteCoin = (coin) => {
    setFavoriteCoins((prevFavorites) => [...prevFavorites, coin]);
  };

  const removeFavoriteCoin = (coin) => {
    setFavoriteCoins((prevFavorites) =>
      prevFavorites.filter(
        (fav) => fav.base !== coin.base || fav.quote !== coin.quote
      )
    );
  };

  const isFavorite = (coin) => {
    return favoriteCoins.some(
      (fav) => fav.base === coin.base && fav.quote === coin.quote
    );
  };

  return (
    <CoinContext.Provider
      value={{
        favoriteCoins,
        addFavoriteCoin,
        removeFavoriteCoin,
        isFavorite,

        primaryCoin,
        setPrimaryCoin,
        newCoin,
        setNewCoin,
        MARKET,
        SETMARKET,
        activeMarket,
        setActiveMarket,
        defaultPair,
        pairs,
        setPairs,
        coinsData,
        setCoinsData,
        exchangeToken,
        enableDustConversion,
        fiatList,
        depositEnabledList,
        setDepositEnabledList,
        withdrawalEnabledList,
        tradeEnabledList,

        latestTradePrice,
        setLatestTradePrice,

        markets
      }}
    >
      {children}
    </CoinContext.Provider>
  );
};

export const useCoinContext = () => {
  return useContext(CoinContext);
};
