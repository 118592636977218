import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const accountCurrenciesConst = {
  AED: "AED",
  EUR: "EURO",
  GBP: "GBP",
  ISO: "ISO",
  IDR: "Indonesian Rupiah",
  PKR: "PKR",
  SAMPL: "SAMPLE BASKET",
  TRY: "TURK LIRASI",
  USD: "USD",
};

const accountTypeConst = {
  Savings: "Savings",
  Checking: "Checking",
};

const AddBankModal = ({
  showAddBank,
  handleCloseAddBank,
  title,
  handleOkBank,
  sendOtp,
  is2FaEnabled,
  hasBankAdded,
  isOtpTimerRunning
}) => {
  const labels = useSelector((state) => state.labels.data);
  const [accountNumber, setAccountNumber] = useState("");
  const [currency, setCurrency] = useState("0");
  const [accountType, setAccountType] = useState("Savings");
  const [bankName, setBankName] = useState("");
  const [routingCode, setRoutingCode] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [gAuth, setGAuth] = useState("");

  const [isOtpDisabled, setIsOtpDisabled] = useState(false);

  const [errors, setErrors] = useState({
    currency: false,
    accountNumber: false,
    bankName: false,
    routingCode: false,
    swiftCode: false,
    emailOtp: false,
    gAuth: false
  });
  
  const safeCharactersRegex = /^[a-zA-Z0-9\s.,]*$/;

  const handleInputChange = (setter) => (e) => {
    const { value } = e.target;
    if (!safeCharactersRegex.test(value)) {
      return;
    }
    setter(value);
  };

  let sendOtpFunctionStatus = false;
  let handleOkBankFunctionStatus = false;


  if (
    currency != "0" &&
    accountNumber &&
    bankName &&
    routingCode &&
    swiftCode &&
    emailOtp
  ) {
    handleOkBankFunctionStatus = true;
    sendOtpFunctionStatus = true;
  } else if (
    currency != "0" &&
    accountNumber &&
    bankName &&
    swiftCode &&
    routingCode
  ) {
    sendOtpFunctionStatus = true;
  }


  const validateInputs = () => {
    const newErrors = {
      currency: currency === "0",
      accountNumber: !accountNumber,
      bankName: !bankName,
      routingCode: !routingCode,
      swiftCode: !swiftCode,
      emailOtp: !emailOtp,
      gAuth: is2FaEnabled ? !gAuth : false,
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const sendOtpFunction = () => {
      const returnObj = {
        AccountNumber: accountNumber,
        AccountCurrency: currency,
        accountType: accountType,
        BankName: bankName,
        type: "email",
      };
      sendOtp(returnObj);

      setIsOtpDisabled(true);
      setTimeout(() => {
        setIsOtpDisabled(false);
      }, 60000); 
  };

  const handleOkBankFunction = () => {
    if (validateInputs()) {
      const returnObj = {
        AccountNumber: accountNumber,
        AccountCurrency: currency,
        AccountType: accountType,
        BankName: bankName,
        emailotp: emailOtp,
        BankRoutingCode: routingCode,
        SwiftCode: swiftCode,
        gauth_code: gAuth,
        smsotp: "",
      };
      handleOkBank(returnObj);
    }
  };


  // Function to reset the form fields
    const resetFormFields = () => {
      setAccountNumber("");
      setCurrency("0");
      setAccountType("Savings");
      setBankName("");
      setRoutingCode("");
      setSwiftCode("");
      setEmailOtp("");
      setGAuth("");
      setErrors({
        currency: false,
        accountNumber: false,
        bankName: false,
        routingCode: false,
        swiftCode: false,
        emailOtp: false,
        gAuth: false
      });
    };

    useEffect(() => {
      resetFormFields();
      setIsOtpDisabled(false);
    }, [hasBankAdded])

  return (
    <Modal
      show={showAddBank}
      onHide={handleCloseAddBank}
      className="add-bank-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* {message} */}

        <div
          className="row"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.accountCurrency}:</label>
              <select
                className={`form-control ${errors.currency ? "is-invalid" : ""}`}
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="0">{labels?.forms?.common?.select}</option>
                {Object.entries(accountCurrenciesConst).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value} ({key})
                  </option>
                ))}
              </select>
              {errors.currency && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}

            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.accountType}:</label>
              <select
                className="form-control"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
              >
                {Object.entries(accountTypeConst).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value} ({key})
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.accountNumber}:</label>
              <input
                type="text"
                className={`form-control ${errors.accountNumber ? "is-invalid" : ""}`}
                value={accountNumber}
                onChange={handleInputChange(setAccountNumber)}
              />
              {errors.accountNumber && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.bankName}:</label>
              <input
                type="text"
                className={`form-control ${errors.bankName ? "is-invalid" : ""}`}
                value={bankName}
                onChange={handleInputChange(setBankName)}
              />
              {errors.bankName && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.bankRoutingCode}:</label>
              <input
                type="text"
                className={`form-control ${errors.routingCode ? "is-invalid" : ""}`}
                value={routingCode}
                onChange={handleInputChange(setRoutingCode)}
              />
               {errors.routingCode && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">{labels?.wallet?.banks?.swiftCode}:</label>
              <input
                type="text"
                className={`form-control ${errors.swiftCode ? "is-invalid" : ""}`}
                value={swiftCode}
                onChange={handleInputChange(setSwiftCode)}
              />
              {errors.swiftCode && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="form-group">
            <label for="usr">
              {
                labels?.forms?.fiatWithdrawalManual?.emailandSMSVerificationCode
                  ?.placeholder
              }
              :
            </label>
            <div class="input-group mb-3">
              <input
                type="text"
                className={`form-control ${errors.emailOtp ? "is-invalid" : ""}`}
                onChange={handleInputChange(setEmailOtp)}
              />

              <div class="input-group-append">
                <button
                  className={isOtpTimerRunning ? "btn btn-primary disabled" : "btn btn-primary"}
                  type="button"
                  onClick={sendOtpFunction}
                  disabled={isOtpDisabled || !sendOtpFunctionStatus}
                  style={{
                    borderRadius: "0 0.25rem 0.25rem 0",
                    backgroundColor: sendOtpFunctionStatus ? "" : "#dadada",
                    color: sendOtpFunctionStatus ? "" : "#222",
                  }}
                >
                  {labels?.wallet?.banks?.requestOtp}
                </button>
              </div>

              {errors.emailOtp && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}


            </div>
          </div>
        </div>

        {is2FaEnabled ? (
          <div className="row" style={{ paddingBottom: "15px" }}>
            <div className="col-md-12">
              <div className="form-group">
                <label for="usr">{labels?.multiFactorAuth?.Google}:</label>
                <input
                  type="text"
                  className={`form-control ${errors.gAuth ? "is-invalid" : ""}`}
                  value={gAuth}
                  onChange={handleInputChange(setGAuth)}

                />
                  {errors.gAuth && <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <button
                class="btn btn-primary"
                type="button"
                style={{ width: "100%" }}
                onClick={handleOkBankFunction}
              >
                {labels?.wallet?.banks?.addBanks}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddBank}>
          {labels?.buttons?.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddBankModal;
