import { Link } from "react-router-dom";
import "./Footer.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModalContext } from "../../Context/ModalContext";
import { getSettings } from "../../Features/Exchange/ExchangeSlice";
import { useCoinContext } from "../../Context/CoinContext";

export default function Footer() {
  const { selectedTheme } = useModalContext();
  const dispatch = useDispatch();
  const labels = useSelector((state) => state.labels.data);
  const { exchangeCommonSettings } = useSelector((state) => state?.exchange);
  const advisoryText = labels?.footer?.advisory;
  const { exchangeToken } = useCoinContext();
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  const renderTextWithLinks = (text) => {
    return text?.split(/(<\d+>.*?<\/\d+>)/g).map((part, index) => {
      const linkMatches = part.match(/<(\d+)>(.*?)<\/\d+>/);
      if (linkMatches) {
        const linkNumber = linkMatches[1];
        const linkText = linkMatches[2];

        let href;
        switch (linkNumber) {
          case "1":
            href =
              "https://files.consumerfinance.gov/f/201408_cfpb_consumer-advisory_virtual-currencies.pdf";
            break;
          case "3":
            href =
              "https://www.cftc.gov/sites/default/files/idc/groups/public/@customerprotection/documents/file/customeradvisory_urvct121517.pdf";
            break;
          case "5":
            href =
              "https://www.investor.gov/additional-resources/news-alerts/alerts-bulletins/investor-alert-bitcoin-other-virtual-currency";
            break;
          case "7":
            href =
              "http://www.finra.org/investors/alerts/bitcoin-more-bit-risky";
            break;
          default:
            href = "#";
        }

        return (
          <a key={index} href={href} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  return (
    <>
      {/* Footer */}
      <footer className="footer">
        <div className="container">
          <button className="toggle-button">
            <img
              src={
                selectedTheme?.name === "light"
                  ? "/images/icons/chevron-up-black.svg"
                  : "/images/icons/chevron-up.svg"
              }
              alt=""
            />
          </button>
          <div className="head">
            {labels?.footer?.copyright?.company
              .replace(
                "{{exchangeName}}",
                exchangeCommonSettings?.data?.exchangeName
              )
              .replace("{{year}}", "2024")}
            <br />
            {labels?.footer?.copyright?.rights}
          </div>
          <div className="content">
            <p>{renderTextWithLinks(advisoryText)}</p>
            <p>
              {labels?.footer?.legal.replace(
                "{{exchangeName}}",
                exchangeCommonSettings?.data?.exchangeName
              )}
            </p>
          </div>
          <ul className="tags">
            <li>
              <Link to="/currencies">{labels?.footer?.currencies}</Link>
            </li>
            <li>
              <Link to="/asset-status">{labels?.footer?.assetStatus}</Link>
            </li>
            <li>
              <Link to="/exchange-fees">{labels?.footer?.fees}</Link>
            </li>
            <li>
              <Link to="/trading-rules">{labels?.footer?.tradingRules}</Link>
            </li>
            <li>
              <Link to="/api-documentation">
                {labels?.footer?.apiDocumentation}
              </Link>
            </li>
            <li>
              <Link to="/support">{labels?.footer?.support}</Link>
            </li>
          </ul>
        </div>
      </footer>
      {/* Footer End */}
    </>
  );
}
