import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchLabels = createAsyncThunk(
  "labels/fetchLabels",
  async (languageCode) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_REST_URL}/api/language?code=${languageCode}&namespace=translation`
    );
    if (response?.data?.Data) {
      let returnData = JSON.parse(response?.data?.Data);
      // console.log(returnData['translation']);
      return returnData["translation"];
    }
  }
);

const labelsSlice = createSlice({
  name: "labels",
  initialState: { data: {}, status: "idle", error: null },
  reducers: {},
  extraReducers: {
    [fetchLabels.pending]: (state) => {
      state.status = "loading";
    },
    [fetchLabels.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchLabels.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export default labelsSlice.reducer;
