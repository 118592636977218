import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./WhitelistedDevices.css";
import { formatDate } from "../../../Common/Helpers";

import CustomModal from "../../../Components/CustomModal/";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import axios from "axios";

import { useModalContext } from "../../../Context/ModalContext";
import { useSelector } from "react-redux";

function WhitelistedDevices() {
  const labels = useSelector((state) => state.labels.data);

  const { selectedTheme } = useModalContext();

  const [searchQuery, setSearchQuery] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [deleteDeviceId, setDeleteDeviceId] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState({ key: "date", ascending: false });

  const token = localStorage.getItem("token");

  const handleOkButtonClick = async () => {

    try {
      let reqBody = {
        id: deleteDeviceId,
      };

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/delete-whitelisted-device`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((error) => {
          console.error("Post Error:", error);
        });
    } catch (error) {
    }
    handleClose();
  };

  const deleteWhitelistedDevice = async (id) => {
    setDeleteDeviceId(id);
    handleShow();
  };

  const getDeviceList = async () => {
    // Axios ile GET isteği yapalım
    await axios
      .get(
        `${process.env.REACT_APP_API_REST_URL}/api/list-whitelisted-devices`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        setData(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getDeviceList();
  }, [show]);

  // Function to handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const filteredCurrentItems = currentItems?.filter(
    (item) =>
      item.deviceID.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.browser.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ip.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.os.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.location?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = (e) => {
    let newKeyword = e.target.value;

    // Sanitize input by removing potentially harmful characters
    newKeyword = newKeyword.replace(/[^a-zA-Z0-9.:-]/g, ''); // Allows alphanumeric characters, dots, colons, and dashes
    setSearchQuery(newKeyword);
  }

  return (
    <>
      <Header />

      <CustomModal
        show={show}
        handleClose={handleClose}
        title={labels?.tables?.deviceWhitelisting?.delete}
        message={labels?.tables?.apiKeys?.confirmDeleteKey}
        handleOk={handleOkButtonClick}
      />

      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Account" submenu="AccountWhitelistedDevices" />

        <div className="main-content">
          <h4 className="title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M12.1387 14.5H12.78H15C15.3978 14.5 15.7794 14.342 16.0607 14.0607C16.342 13.7794 16.5 13.3978 16.5 13V5C16.5 4.60218 16.342 4.22064 16.0607 3.93934C15.7794 3.65804 15.3978 3.5 15 3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V13C3.5 13.3978 3.65804 13.7794 3.93934 14.0607C4.22064 14.342 4.60218 14.5 5 14.5H7.22H7.86134L7.7049 15.122L7.5819 15.611L7.54821 15.7449L7.45055 15.8426L6.64661 16.6465C6.64659 16.6465 6.64657 16.6465 6.64655 16.6466C6.57668 16.7165 6.52909 16.8055 6.50981 16.9025C6.49053 16.9995 6.50043 17.1 6.53827 17.1913C6.5761 17.2827 6.64017 17.3608 6.72238 17.4157C6.80455 17.4706 6.90116 17.5 7 17.5L12.1387 14.5ZM12.1387 14.5L12.2951 15.122M12.1387 14.5L12.2951 15.122M12.2951 15.122L12.4181 15.611L12.4518 15.7449M12.2951 15.122L12.4518 15.7449M12.4518 15.7449L12.5494 15.8426M12.4518 15.7449L12.5494 15.8426M12.5494 15.8426L13.3534 16.6465M12.5494 15.8426L13.3534 16.6465M13.3534 16.6465C13.3534 16.6465 13.3534 16.6465 13.3534 16.6466M13.3534 16.6465L13.3534 16.6466M13.3534 16.6466C13.4233 16.7165 13.4709 16.8055 13.4902 16.9025M13.3534 16.6466L13.4902 16.9025M13.4902 16.9025C13.5095 16.9995 13.4996 17.1 13.4617 17.1913M13.4902 16.9025L13.4617 17.1913M13.4617 17.1913C13.4239 17.2827 13.3598 17.3608 13.2776 17.4157M13.4617 17.1913L13.2776 17.4157M13.2776 17.4157C13.1954 17.4706 13.0988 17.5 13 17.5M13.2776 17.4157L13 17.5M13 17.5C13 17.5 12.9999 17.5 12.9999 17.5M13 17.5H12.9999M12.9999 17.5H7.00011H12.9999ZM4.5 12V12.5H5H8.771H15H15.5V12V5V4.5H15H5H4.5V5V12Z"
                stroke="white"
              />
            </svg>
            {labels?.account?.deviceWhitelisting?.title}
          </h4>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="box-item">
                <form className="search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                      stroke="#9CA3AF"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <input type="text" placeholder="Search" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={14}
                    viewBox="0 0 13 14"
                    fill="none"
                  >
                    <path
                      d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                      fill="#111928"
                      stroke="#9CA3AF"
                    />
                  </svg>
                </form>
              </div>
            </div>
          </div> */}
          <div className="content-box mt5">
            <form className="search mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={18}
                height={18}
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                  stroke="#9CA3AF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <input
                type="text"
                placeholder={labels?.forms?.common?.search}
                value={searchQuery}
                onChange={handleSearch}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={13}
                height={14}
                viewBox="0 0 13 14"
                fill="none"
              >
                <path
                  d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                  fill="#111928"
                  stroke="#9CA3AF"
                />
              </svg>
            </form>

            <div className="table-responsive">
              <table className="table">
                <thead className="w-100">
                  <tr>
                    <th>{labels?.tables?.deviceWhitelisting?.addedOn}</th>
                    <th>{labels?.tables?.deviceWhitelisting?.device}</th>
                    <th>{labels?.tables?.deviceWhitelisting?.browser}</th>
                    <th>{labels?.tables?.deviceWhitelisting?.ip}</th>
                    <th>{labels?.tables?.deviceWhitelisting?.os}</th>
                    <th className="text-center">
                      {labels?.tables?.deviceWhitelisting?.delete}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCurrentItems?.length > 0 ? (
                    filteredCurrentItems?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="date">
                            {formatDate(item?.addedOn)}
                            <span></span>
                          </div>
                        </td>
                        <td>
                          <div className="pair">
                            <img
                              src={
                                selectedTheme.name === "dark"
                                  ? "/images/icons/desktop-computer.svg"
                                  : "/images/icons/desktop-computer-black.svg"
                              }
                              alt=""
                            />
                            {item.deviceID}
                          </div>
                        </td>
                        <td>{item.browser}</td>
                        <td
                          style={{ color: "#0E9F6E" }}
                          className="column-truncated"
                        >
                          {item.ip}
                        </td>
                        <td>{item.os}</td>
                        <td>
                          {item.deviceID === "this" ? (
                            <></>
                          ) : (
                            <button className="button-danger" onClick={() => deleteWhitelistedDevice(item.id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.20039 1.6001C7.05186 1.60018 6.90629 1.6416 6.77997 1.71974C6.65365 1.79787 6.55158 1.90963 6.48519 2.0425L5.90599 3.2001H3.20039C2.98822 3.2001 2.78473 3.28438 2.63471 3.43441C2.48468 3.58444 2.40039 3.78792 2.40039 4.0001C2.40039 4.21227 2.48468 4.41575 2.63471 4.56578C2.78473 4.71581 2.98822 4.8001 3.20039 4.8001V12.8001C3.20039 13.2244 3.36896 13.6314 3.66902 13.9315C3.96908 14.2315 4.37604 14.4001 4.80039 14.4001H11.2004C11.6247 14.4001 12.0317 14.2315 12.3318 13.9315C12.6318 13.6314 12.8004 13.2244 12.8004 12.8001V4.8001C13.0126 4.8001 13.216 4.71581 13.3661 4.56578C13.5161 4.41575 13.6004 4.21227 13.6004 4.0001C13.6004 3.78792 13.5161 3.58444 13.3661 3.43441C13.216 3.28438 13.0126 3.2001 12.8004 3.2001H10.0948L9.51559 2.0425C9.4492 1.90963 9.34713 1.79787 9.22081 1.71974C9.0945 1.6416 8.94892 1.60018 8.80039 1.6001H7.20039ZM5.60039 6.4001C5.60039 6.18792 5.68468 5.98444 5.83471 5.83441C5.98473 5.68438 6.18822 5.6001 6.40039 5.6001C6.61256 5.6001 6.81605 5.68438 6.96608 5.83441C7.11611 5.98444 7.20039 6.18792 7.20039 6.4001V11.2001C7.20039 11.4123 7.11611 11.6158 6.96608 11.7658C6.81605 11.9158 6.61256 12.0001 6.40039 12.0001C6.18822 12.0001 5.98473 11.9158 5.83471 11.7658C5.68468 11.6158 5.60039 11.4123 5.60039 11.2001V6.4001ZM9.60039 5.6001C9.38822 5.6001 9.18473 5.68438 9.03471 5.83441C8.88468 5.98444 8.80039 6.18792 8.80039 6.4001V11.2001C8.80039 11.4123 8.88468 11.6158 9.03471 11.7658C9.18473 11.9158 9.38822 12.0001 9.60039 12.0001C9.81256 12.0001 10.016 11.9158 10.1661 11.7658C10.3161 11.6158 10.4004 11.4123 10.4004 11.2001V6.4001C10.4004 6.18792 10.3161 5.98444 10.1661 5.83441C10.016 5.68438 9.81256 5.6001 9.60039 5.6001Z"
                                fill="white"
                              />
                            </svg>
                            {labels?.tables?.deviceWhitelisting?.delete}
                          </button>
                          )}

                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {isLoading ? (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <img src="/images/loading.gif" width={50} />
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.loading}</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>
                              {" "}
                              {
                                labels?.tables?.deviceWhitelisting
                                  ?.noDeviceWhitelisted
                              }
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <ul className="pagination">
              <li>
                {currentPage > 1 ? (
                  <Link onClick={() => setCurrentPage(currentPage - 1)}>
                    {labels?.pagination?.prev}
                  </Link>
                ) : (
                  <Link className="disabled" style={{ color: "#555" }}>
                    {labels?.pagination?.prev}
                  </Link>
                )}
              </li>
              <li>
                {indexOfLastItem < data?.length ? (
                  <Link onClick={() => setCurrentPage(currentPage + 1)}>
                    {labels?.pagination?.next}
                  </Link>
                ) : (
                  <Link className="disabled" style={{ color: "#555" }}>
                    {labels?.pagination?.next}
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default WhitelistedDevices;
