import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "../../Api/orderService";

const initialState = {
  orderHistory: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
};

export const getOrderHistory = createAsyncThunk(
  "get/order-history",
  async (data, thunkAPI) => {
    try {
      return await orderService.getOrderHistory(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const placeOrder = createAsyncThunk(
  "order/place",
  async (data, thunkAPI) => {
    try {
      return await orderService.placeOrder(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "order/cancel",
  async (data, thunkAPI) => {
    try {
      return await orderService.cancelOrder(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderHistory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.orderHistory = action?.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      })
      .addCase(placeOrder.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      })
      .addCase(cancelOrder.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      });
  },
});

export default orderSlice.reducer;
