import React, { useContext } from 'react';
import historyProvider from './historyProvider';
import streamProvider from './streamProvider';
// import { store } from 'redux/store';

const supportedResolutions = ['1', '5', '15', '60', '240', 'D', 'W'];

const config = {
  supported_resolutions: supportedResolutions,
};

const datafeed = {
  onReady: cb => {
    // console.log('=====onReady running');
    setTimeout(() => cb(config), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // console.log('====Search Symbols running');
  },
  resolveSymbol: (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) => {
    // expects a symbolInfo object in response
    // console.log('======resolveSymbol running');
     // console.log('resolveSymbol:',{symbolName})
    // eslint-disable-next-line no-unused-vars


    const exchange = "Custom Exchange";

     const split_data = symbolName.split(/[:/]/);
    // console.log({split_data})
    const symbol_stub = {
      name: symbolName,
      description: symbolName,
      type: 'crypto',
      session: '24x7',
      timezone: 'Etc/UTC',
      ticker: symbolName,
      exchange: exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      intraday_multipliers: [
        '1',
        '5',
        '15',
        '60',
        '240',
        '1440',
        '10080',
        '43800',
      ],
      supported_resolution: supportedResolutions,
      volume_precision: 8,
      data_status: 'streaming',
      // has_empty_bars: true,
    };

    // if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
    //   symbol_stub.pricescale = 100
    // }

    setTimeout(function() {
      onSymbolResolvedCallback(symbol_stub);
      // console.log('Resolving that symbol....', symbol_stub);
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) => {

    try {

        await historyProvider.getBars(
          symbolInfo,
          resolution,
          periodParams,
          onHistoryCallback,
          onErrorCallback
        );

    } catch (e) {
      onErrorCallback(e);
    }
    // Need to handle error here.
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
  ) => {
    // console.log('=====subscribeBars runnning');
    streamProvider.subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
    ); 
  },
  unsubscribeBars: subscriberUID => {
    // console.log('=====unsubscribeBars running');
   streamProvider.unsubscribeBars(subscriberUID);
  }
};

export default datafeed;
