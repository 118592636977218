import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tradeService from "../../Api/tradeService";

const initialState = {
  tradeHistory: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
};

export const getTradeHistory = createAsyncThunk(
  "get/trade-history",
  async (data, thunkAPI) => {
    try {
      return await tradeService.getTradeHistory(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTradeHistory.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getTradeHistory.fulfilled, (state, action) => {
        state.tradeHistory = action?.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getTradeHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      });
  },
});

export default tradeSlice.reducer;
