import axios from "axios";

const getTradeHistory = async (data) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_REST_URL}/api/TradeHistory?side=ALL&pair=ALL&page=${data?.tradeHistoryPage}&count=${data?.pageSize}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage?.getItem("token"),
      },
    }
  );
  return response?.data;
};

const tradeService = {
  getTradeHistory,
};

export default tradeService;
