import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import marketService from "../../Api/marketService";

const initialState = {
  marketSummary: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
};

export const getMarketSummary = createAsyncThunk(
  "get/market-summary",
  async (thunkAPI) => {
    try {
      return await marketService.getMarketSummary();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMarketSummary.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getMarketSummary.fulfilled, (state, action) => {
        state.marketSummary = action?.payload;
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(getMarketSummary.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.error =
          action?.payload?.code === "ERR_NETWORK"
            ? action?.payload?.message
            : action?.payload?.response?.data?.message;
      });
  },
});

export default marketSlice.reducer;
