import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const AddAddressModal = ({
  showAddAddress,
  handleCloseAddAddress,
  title,
  marketData,
  sendOtp,
  handleOkAddress,
  is2FaEnabled,
  isSuccess,
  isOtpTimerRunning
}) => {
  const [currency, setCurrency] = useState("0");
  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [gAuth, setGAuth] = useState("");

  const safeCharactersRegex = /^[a-zA-Z0-9\s.,]*$/;

  const handleInputChange = (setter) => (e) => {
    const { value } = e.target;
    if (!safeCharactersRegex.test(value)) {
      return;
    }
    setter(value);
  };

  let sendOtpFunctionStatus = false;
  let handleOkAddressFunctionStatus = false;

  if (currency !== "0" && label && address && emailOtp) {
    handleOkAddressFunctionStatus = true;
    sendOtpFunctionStatus = true;
  } else if (currency !== "0" && label && address) {
    sendOtpFunctionStatus = true;
  }

  const sendOtpFunction = () => {
    let returnObj = {
      Currency: currency,
      Address: address,
      DT_Memo: "",
      type: "email",
    };
    sendOtp(returnObj);
  };

  const handleOkAddressFunction = () => {
    let returnObj = {
      Currency: currency,
      Label: label,
      Address: address,
      gauth_code: gAuth,
      DT_Memo: "",
      smsotp: "",
      emailotp: emailOtp,
    };
    handleOkAddress(returnObj);

    const delayedFunction = () => {
      // setLabel("");
      // setAddress("");
      // setGAuth("");
      // setEmailOtp("");
    };

    // Set a timeout for 2 seconds and call the delayed function
    const timeoutId = setTimeout(delayedFunction, 2000);
  };

  const labels = useSelector((state) => state.labels.data);

  useEffect(() => {
    if (isSuccess) {
      setLabel("");
      setAddress("");
      setGAuth("");
      setEmailOtp("");
    }
  }, [isSuccess]);

  return (
    <Modal
      show={showAddAddress}
      onHide={handleCloseAddAddress}
      className="address-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row" style={{ paddingBottom: "15px", paddingTop: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="currency">{labels?.wallet?.addressBook?.currency}:</label>
              <select
                className="form-control address-input"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="0">{labels?.forms?.common?.select}</option>
                {marketData.map((item, index) => (
                  <option key={index} value={item.currency}>
                    {item.currency}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="label">{labels?.wallet?.addressBook?.label}:</label>
              <input
                type="text"
                className="form-control address-input"
                value={label}
                onChange={handleInputChange(setLabel)}
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                className="form-control address-input"
                value={address}
                onChange={handleInputChange(setAddress)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group">
            <label htmlFor="emailOtp">
              {labels?.forms?.fiatWithdrawalManual?.emailandSMSVerificationCode?.placeholder}
              :
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control address-input"
                value={emailOtp}
                onChange={handleInputChange(setEmailOtp)}
              />
              <div className="input-group-append">
                <button
                  className={isOtpTimerRunning ? "btn btn-primary disabled" : "btn btn-primary"}                            type="button"
                  disabled={!sendOtpFunctionStatus}
                  style={{
                    borderRadius: "0 0.25rem 0.25rem 0",
                    backgroundColor: sendOtpFunctionStatus ? "" : "#dadada",
                    color: sendOtpFunctionStatus ? "" : "#222",
                  }}
                  onClick={sendOtpFunction}
                >
                  {labels?.wallet?.banks?.requestOtp}
                </button>
              </div>
            </div>
          </div>
        </div>

        {is2FaEnabled && (
          <div className="row" style={{ paddingBottom: "15px" }}>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="gAuth">{labels?.multiFactorAuth?.Google}:</label>
                <input
                  type="text"
                  className="form-control address-input"
                  value={gAuth}
                  onChange={handleInputChange(setGAuth)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <button
                className="btn btn-primary"
                type="button"
                style={{
                  width: "100%",
                  backgroundColor: handleOkAddressFunctionStatus ? "" : "#dadada",
                  color: handleOkAddressFunctionStatus ? "" : "#222",
                }}
                disabled={!sendOtpFunctionStatus}
                onClick={handleOkAddressFunction}
              >
                {labels?.wallet?.addressBook?.addAddress}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddAddress}>
          {labels?.tables?.myOffers?.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAddressModal;
