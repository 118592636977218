import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './AssetStatus.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';
import { useSelector } from "react-redux";
import axios from "axios";
import CoinInformation from "../../../Common/CoinInformation.json";
import { useCoinContext } from "../../../Context/CoinContext";
function AssetStatus() {
  const labels = useSelector((state) => state.labels.data);
  const [isLoading, setIsLoading] = useState(true);
  const [tableShowData, setTableShowData] = useState([]);
  const token = localStorage.getItem("token");
  const { depositEnabledList, withdrawalEnabledList, tradeEnabledList } = useCoinContext();

  const [hasError, setHasError] = useState(false);


  const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });
  // Function to handle column header click for sorting
  const handleSort = (key) => {
    if (sortBy.key === key) {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      setSortBy({ key, ascending: true });
    }
  };

  const [tradeSettings, setTradeSettings] = useState();

  const getSettings = async () => {
    await axios
    .get(
      `${process.env.REACT_APP_API_REST_URL}/api/GetSettings`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      }
    )
    .then((response) => {
      setTradeSettings(response.data.data.trade_setting)
    })
    .catch((error) => {
      setHasError(true)
    });
  }



  const getBalance = async () => {
    getSettings();
    let tmpTotalInUsdt = 0;
    let tmpTotalInBtc = 0;
    await axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
        { currency: "ALL" },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        
        const { coinListAll } = getBalanceCoinData(response.data.data);

  

        let tmpCoinListAll = coinListAll;

        const sortedCoinListAll = tmpCoinListAll.sort(
          (a, b) => b.balance - a.balance
        );

        setTableShowData(sortedCoinListAll)
        setIsLoading(false);
      })
      .catch((error) => {
        setHasError(true)
        setIsLoading(false);
      });
  };


  const getBalanceCoinData = (data) => {

    // let marketDataResponse = await getCurrencySettings();

    const serviceData = data ? [...data] : [];
 
    const coinInformation = CoinInformation;
    let coinListAll = [];
    
    for (let i = 0; i < serviceData.length; i++) {

      const coinName = serviceData[i].currency;
      
      if (coinInformation[coinName]?.ShortName) {
        serviceData[i].ShortName = coinInformation[coinName].ShortName;
        serviceData[i].LongName = coinInformation[coinName].LongName;
        coinListAll.push(serviceData[i]);
      }
        else {
          serviceData[i].ShortName = serviceData[i].currency;
          serviceData[i].LongName = serviceData[i].currency;
          coinListAll.push(serviceData[i]);
     }
    }
    return { coinListAll };

  };

  useEffect(() => {
    getBalance();
  }, []);

  if (hasError) {
    throw new Error("");
  }

  return (
    <>
    <Header />
    <PriceBar /> 
    <section className="main-page">
      <div className="main-content">
        <h4 className="title">{labels?.footer?.assetStatus}</h4>
        <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("base")}
                    >
                      {sortBy.key === "asset_name" ? (
                        <u>{labels?.tables?.assetStatus?.name}</u>
                      ) : (
                        labels?.tables?.assetStatus?.name
                      )}
                      {sortBy.key === "asset_name" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "asset_name" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("symbol")}
                    >
                      {sortBy.key === "symbol" ? (
                        <u>{labels?.tables?.assetStatus?.ticker}</u>
                      ) : (
                        labels?.tables?.assetStatus?.ticker
                      )}
                      {sortBy.key === "symbol" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "symbol" &&
                      !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("deposits")}
                    >
                      {sortBy.key === "deposits" ? (
                        <u>{labels?.tables?.assetStatus?.deposits}</u>
                      ) : (
                        labels?.tables?.assetStatus?.deposits
                      )}
                      {sortBy.key === "deposits" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "deposits" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("withdrawals")}
                    >
                      {sortBy.key === "withdrawals" ? (
                        <u>{labels?.tables?.assetStatus?.withdrawals}</u>
                      ) : (
                        labels?.tables?.assetStatus?.withdrawals
                      )}
                      {sortBy.key === "withdrawals" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "withdrawals" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("trade")}
                    >
                      {sortBy.key === "trade" ? (
                        <u>{labels?.tables?.assetStatus?.trade}</u>
                      ) : (
                        labels?.tables?.assetStatus?.trade
                      )}
                      {sortBy.key === "trade" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "trade" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableShowData?.length > 0 ? (
                    tableShowData.map((item, index) => {

                      const findTradeSetting = (coin, market) => {
                        const setting = tradeSettings?.find(
                          (item) => item.coinName === coin && item.marketName === market
                        );
                        
                        return setting ? setting.tradeEnabled : null;
                      };
                      
                      const tradeEnabledStatus = findTradeSetting(item.ShortName, "USDT");

                      return (
                      <tr key={item.id}>
                        <td>
                          <div className="pair">
                            <img
                              src={`/images/coins/${item?.currency?.toLowerCase()}.svg`}
                              alt=""
                            />
                            {item?.currency}
                          </div>
                        </td>
                        <td>
                        {item?.ShortName}
                        </td>
                        <td>
                          {depositEnabledList?.includes(item.ShortName) ? 
                            <p className="text-success">{labels?.generics?.enabled}</p> 
                            : (
                            <p className="text-danger">{labels?.generics?.disabled}</p>
                          )}
                        </td>
                        <td>
                        {withdrawalEnabledList?.includes(item.ShortName) ? 
                            <p className="text-success">{labels?.generics?.enabled}</p> 
                            : (
                            <p className="text-danger">{labels?.generics?.disabled}</p>
                          )}
                        </td>
                        <td>
                        {tradeEnabledList?.includes(item.ShortName) ? 
                            <p className="text-success">{labels?.generics?.enabled}</p> 
                            : (
                            <p className="text-danger">{labels?.generics?.disabled}</p>
                          )}
                        </td>
                      </tr>
                    )})
                  ) : (
                    <>
                      {isLoading ? (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <img src="/images/loading.gif" width={50} />
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.loading}</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.noData}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </section>

<Footer />
    </>
  );

}

export default AssetStatus;
