import React from 'react';
import { useSelector } from "react-redux";

function SelectWithdrawalType({ value, onChange }) {
  const labels = useSelector((state) => state.labels.data);

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <select
      className="form-control"
      onChange={handleOnChange}
    >
      <option value="SELECT">{labels?.forms?.fiatWithdrawalManual?.paymentMethod}</option>
      <option value="bankTransfer" selected={value === "bankTransfer"}>{labels?.forms?.fiatDeposit?.bank}</option>
    </select>
  )
}

export default SelectWithdrawalType;
