import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules'

export const CoinSlider = ({ data }) => {
  
  const [myVar, setMyVar] = useState(null);
  
  useEffect(() => {
  }, []);
  
  return (
    <section className="marquee">
    <div className="container">
    <div className="swiper-marquee coin-slider">
<Swiper
  spaceBetween={30}
  slidesPerView={'8'}
  loop={true}
  autoplay={{
    delay: 2500,
    disableOnInteraction: false,
  }}
  breakpoints={{
    320: {
      slidesPerView: 2, // For mobile devices (width < 480px)
    },
    480: {
      slidesPerView: 2, // For devices larger than 480px
    },
    768: {
      slidesPerView: 3, // For devices larger than 768px
    },
    1024: {
      slidesPerView: 4, // For devices larger than 1024px
    },
    1280: {
      slidesPerView: 6, // For devices larger than 1280px
    },
    1440: {
      slidesPerView: 8, // Default or larger screens
    },
  }}
  modules={[Autoplay]}
  className="mt-0"
>
  {data?.filter((item) => item.change_in_price !== 0).map((item, index) => (
    <SwiperSlide key={index}>
      <a href="#">
        {item.base}/{item.quote}
        <span className={item.change_in_price > 0 ? 'up' : 'down'}>
          {item.change_in_price.toFixed(2)}%
        </span>
      </a>
    </SwiperSlide>
  ))}
</Swiper>
</div>
    </div>
  </section>
  )
}
