import React from "react";
import { Button } from "react-bootstrap";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        color: "red",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h4>Seems like something went wrong!</h4>
      {/* {process.env.NODE_ENV !== "production" && (
        <p className="errorTxt">Error: {error.message}</p>
      )} */}
      <Button variant="danger" onClick={resetErrorBoundary}>
        Refresh
      </Button>
    </div>
  );
}

function ErrorBoundaryWrapper({ children }) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundaryWrapper;
