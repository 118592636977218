import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const IpModal = ({ showModal1, handleCloseModal1, setShowConsentModal, ipAddress, setIpAddress }) => {
  const labels = useSelector((state) => state.labels.data);
  const [emptyError, setEmptyError] = useState(false);


  const handleIpAddressChange = (e) => {
    let inputValue = e.target.value;
  
    // Filter out potentially dangerous characters using a whitelist approach
    const sanitizedValue = inputValue.replace(/[^0-9a-fA-F:.]/g, ""); // Allows only numbers, dots, colons, and hex characters
  
    setIpAddress(sanitizedValue);
  
    if (sanitizedValue === "") {
      setEmptyError(true);
    } else {
      setEmptyError(false);
    }
  };

  const isValidIPAddress = (ipAddress) => {
    // Regular expression pattern to match IPv4 address format
    const ipv4Pattern = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;

    // Regular expression pattern to match IPv6 address format
    const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

    // Check if the string matches either IPv4 or IPv6 pattern
    return ipv4Pattern.test(ipAddress) || ipv6Pattern.test(ipAddress);
  };

  const verifyIpFunction = () => {
    if (!ipAddress.trim()) {
      setEmptyError(true);
      return;
    }
    let ipCheck = isValidIPAddress(ipAddress);

    if (!ipCheck) {
      const message = labels?.account?.["ip-whitelisting"]?.inputIPvalidation
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      setShowConsentModal(true);
    }
  };

  return (
    <Modal show={showModal1} onHide={handleCloseModal1}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {/* {labels?.account?.["ip-whitelisting"]?.headingIPAddress} */}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br />
        {/* {" You will be logged out after operation."} */}
        <br />
        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <label for="usr">
                {labels?.tables?.["ip-whitelisting"]?.cidr}
              </label>
              <input
                type="text"
                className="form-control address-input"
                value={ipAddress}
                onChange={(e) => handleIpAddressChange(e)}
                required
              />
              <div className={emptyError ? "invalid-feedback d-block" : "invalid-feedback"}>
                {labels?.forms?.validations?.required}
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <div className="form-group">
              <button
                class="btn button-danger"
                type="button"
                style={{ width: "100%" }}
                onClick={verifyIpFunction}
              >
                {labels?.account?.["ip-whitelisting"]?.addIPButton}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal1}>
        {labels?.buttons?.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IpModal;
