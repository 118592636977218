import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./Trade.css";
import { formatNumberWithComma } from "../../../Common/Helpers";  
import { useModalContext } from "../../../Context/ModalContext";
import { useCoinContext } from "../../../Context/CoinContext";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../Context/WebSocketContext";

export const RecentTrades = ({ coinsData, coin, primaryCoin, MARKET, activeMarket }) => {

  const labels = useSelector((state) => state?.labels?.data);
  const { selectedNumberFormat } = useModalContext();
  const { latestTradePrice, latestTradeType, trades, asks, bids } = useContext(WebSocketContext);

  return (
    <div className="border-top custom-border-color border-secondary px-2">
    <div className="table-responsive table-fixed-width overflow-x-hidden mt-2">
      <h2 className="recent-trades-title fs-5">{labels?.generics?.recentTrades}</h2>
      <div className="table-recent-trades-wrapper">
      <table className="table table-sm table-no-border mb-1">
        <thead>
          <tr className="small">
            <th className="text-muted px-0 small">{labels?.exchange?.price}</th>
            <th className="text-muted small">
            {labels?.exchange?.size}[
                {((coin?.base || primaryCoin?.base || "...").slice(0, 3))}
              ]  
            </th>            
            <th className="text-muted small">{labels?.exchange?.time}</th>
          </tr>
        </thead>
        
        <tbody className="mb-2">
          {trades?.map((trade, index) => {
            const timestamp = trade?.timestamp;
            const date = new Date(timestamp);

            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            const formattedTime = `${hours}:${minutes}:${seconds}`;

            return (
            <tr key={index} className="border-0 small">
                <td className={trade?.execution_side === 'SELL' ? "text-danger border-0 px-0" : "text-success border-0 px-0"}>{formatNumberWithComma(trade?.rate, selectedNumberFormat)}</td>
                <td className="border-0">{formatNumberWithComma(trade?.volume, selectedNumberFormat)}</td>
                <td className="border-0">{formattedTime}</td>
            </tr>
             )})}
          </tbody> 
      </table>
      </div>
    </div>
    </div>
  )
}
