import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ConsentModal = ({ show, setShowConsentModal, addIp, ipAddress }) => {
  const labels = useSelector((state) => state.labels.data);
  const navigate = useNavigate();


  const addIpFunction = () => {
    let reqBody = {
      cidr: ipAddress + "/32",
      type: "Login",
    };
    addIp(reqBody);
  };

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {/* {labels?.account?.["ip-whitelisting"]?.headingIPAddress} */}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br />
        <br />
        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <p>{labels?.account?.["ip-whitelisting"]?.warningMessage}</p>
          </div>
        </div>

        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-12">
            <p>{labels?.account?.["ip-whitelisting"]?.warningMessageOptions}</p>
          </div>
        </div>


        <div className="row" style={{ paddingBottom: "15px" }}>
          <div className="col-md-6">
            <div className="form-group">
              <button
                class="btn button-danger"
                type="button"
                style={{ width: "100%" }}
                onClick={addIpFunction}
              >
                {labels?.account?.["ip-whitelisting"]?.yes}
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <button
                class="btn button-danger"
                type="button"
                style={{ width: "100%" }}
                onClick={() => setShowConsentModal(false)}
              >
                {labels?.account?.["ip-whitelisting"]?.no}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConsentModal;
