import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { Link } from "react-router-dom";
import "./TradeHistory.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";
import ExportExcelButton from "../../../Common/ExportExcelButton";
import DownloadPDFButton from "../../../Common/DownloadDynPDFButton";
import CsvExporter from "../../../Common/CsvExporter";
import axios from "axios";
import { useModalContext } from "../../../Context/ModalContext";
import { WebSocketContext } from '../../../Context/WebSocketContext';
import { useSelector } from "react-redux";


function TradeHistory() {
  const labels = useSelector((state) => state.labels.data);

  const { selectedNumberFormat } = useModalContext();
  const { MKData } = useContext(WebSocketContext);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);

  const [searchWord, setSearchWord] = useState();
  const [tableShowData, setTableShowData] = useState([]);

  const [coinsData, setCoinsData] = useState(MKData);
  const [pairs, setPairs] = useState();
  const [selectedPair, setSelectedPair] = useState();

  const [isSearchingCoins, setIsSearchingCoins] = useState(false);

  useEffect(() => {
    if (!isSearchingCoins) {
      setCoinsData(MKData)
    }
  }, [MKData])


  const [coinDropdownVisible, setCoinDropdownVisible] = useState(false);
const [selectedCoin, setSelectedCoin] = useState();

const [currencyPair, setCurrencyPair] = useState();

const handleSelectCoin = (coin) => {
  setSelectedCoin(coin.base)
  setSelectedPair(coin.quote)
  setCurrencyPair(`${coin.base}-${coin.quote}`);
  setCoinDropdownVisible(false)
}




/*
     * Pagination and Orderby
    */

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(10);
// const [sortBy, setSortBy] = useState({ key: null, ascending: true });
const [sortBy, setSortBy] = useState({ key: "date", ascending: false });

// Function to sort data based on column key and sort order
const sortedData = useMemo(() => {
  return tableShowData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });
}, [tableShowData, sortBy]);


// Function to handle column header click for sorting
const handleSort = (key) => {
  if (sortBy.key === key) {
    setSortBy({ key, ascending: !sortBy.ascending });
  } else {
    setSortBy({ key, ascending: true });
  }
};



const totalItems = sortedData.length;
const totalPages = Math.ceil(totalItems / itemsPerPage);

useEffect(() => {
  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(totalPages); 
  }
}, [itemsPerPage, totalItems, totalPages]);


let indexOfLastItem = currentPage * itemsPerPage;

if (indexOfLastItem > totalItems) {
  indexOfLastItem = totalItems;
}

let indexOfFirstItem = indexOfLastItem - itemsPerPage;

if (indexOfFirstItem < 0) {
  indexOfFirstItem = 0;
}

const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);


const updatedCurrentItems = currentItems.map(item => ({
  ...item,
  currencyPair: `${item.trade}-${item.market}`
}));



/*
 * Pagination and Orderby Ended
*/

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword;
 
        let filteredShowArray = tableShowData.filter(item => 
          item.trade.toLocaleLowerCase('en-US').includes(tmpSearchWord.toLocaleLowerCase('en-US'))
        );

        setTableShowData(filteredShowArray);      
      } 

  }

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // Bearer token'i burada değiştirin
    const token = localStorage.getItem("token");

    const largeCount = 1000; 
    let url = `${process.env.REACT_APP_API_REST_URL}/api/TradeHistory?side=ALL&pair=ALL&page=1&count=${largeCount}`;

 
      // Axios ile GET isteği yapalım
      axios
        .get(
          url,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Bearer token gönderimi
            },
          }
        )
        .then((response) => {
          setData(response?.data?.data?.rows);

          let latestData = response?.data?.data?.rows;

          const updatedLatestData = latestData?.map(item => ({
            ...item,
            currencyPair: `${item.trade}-${item.market}`
          }));

          if (searchWord) { 
            if (currencyPair) {
              let updatedArray = updatedLatestData.filter(item => item.currencyPair === currencyPair);
  
              let filteredShowArray = updatedArray.filter(item => 
                item.currencyPair.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
              );  
              setTableShowData(filteredShowArray);
              setIsLoading(false);
  
            } else {
              let filteredShowArray = updatedLatestData.filter(item => 
                item.currencyPair.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
              );  
              setTableShowData(filteredShowArray);
              setIsLoading(false);
            }
  
          } else {
            if (currencyPair) {
              let filteredItems = updatedLatestData.filter(item => item.currencyPair === currencyPair);
              setTableShowData(filteredItems);
            } else {
              setTableShowData(latestData)
            }

            setIsLoading(false);
          }

        })
        .catch((error) => {
          setHasError(true);
        });
 
  }, [searchWord, currencyPair]);

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };




const tableHeaders = [
  "date",
  "trade",
  "market",
  "side",
  "rate",
  "serviceCharge",
  "volume",
  "amount",
];

const [isOpen, setIsOpen] = useState(false);
const toggleDropdown = () => {
  setIsOpen(!isOpen);
}

const [numberOptionDropdownVisible, setNumberOptionDropdownVisible] = useState(false);
const [selectedNumberToShow, setSelectedNumberToShow] = useState(10);
const numberOptions = [10, 20, 50, 100]

const handleSelectNumberOption = (option) => {
  setSelectedNumberToShow(option);
  setItemsPerPage(option)
  setNumberOptionDropdownVisible(false)
}

const [coinSearchWord, setCoinSearchWord] = useState("")

const handleCoinSearch = (e) => {
  let newKeyword = e.target.value;
  setIsSearchingCoins(true);
  setCoinSearchWord(newKeyword);
}

const filteredCoinsData = coinsData?.filter(coin =>
  coin.base.toLowerCase().includes(coinSearchWord.toLowerCase()) ||
  coin.quote.toLowerCase().includes(coinSearchWord.toLowerCase())
);

if (hasError) {
  throw new Error("");
}


  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Orders" submenu="OrdersTradeHistory" />
        <div className="main-content">
          <h4 className="title">{labels?.orders?.tradeHistory?.link}</h4>
          <div className="row mb-3 mb-lg-0">
          <div className="col-lg-10 mb-0 mb-lg-3">

          <form className="form-flex"> 
              <div className="search">
                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                  <path d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z" stroke="#9CA3AF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <input
                    type="text"
                    placeholder={labels?.forms?.common?.search}
                    value={searchWord}
                    onChange={handleSearch}
                  />
                <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} viewBox="0 0 13 14" fill="none">
                  <path d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583" fill="#111928" stroke="#9CA3AF" />
                </svg>
              </div>
 
            </form>
          </div>
          <div className="col-lg-2 dropdown">
            <button
                onClick={toggleDropdown}
                className="btn btn-primary"
                style={{
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                  height: 47
                }}
                disabled={tableShowData.length === 0 ? true : false}
              >
                {labels?.buttons?.exportData}
              </button>

              {isOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "20",
                    zIndex: "999",
                  }}
                  className="export-dropdown"
                >
                  <DownloadPDFButton
                    data={tableShowData}
                    headers={tableHeaders}
                  />
                  <div style={{ paddingBottom: "5px" }} />
                  <CsvExporter data={tableShowData} />
                  <div style={{ paddingBottom: "5px" }} />
                  <ExportExcelButton
                    data={tableShowData}
                    filename="exported_data"
                  />
                </div>
              )}
            </div>
        </div>  
        <div className="row mb20">
        <div className="col-lg-10">
            <div className="d-flex gap-2 justify-content-start">
          <div className="buyorder-dropdown position-relative" style={{ minWidth: 80 }}>
                  <button
                    type="button"
                    className="text-start"
                    onClick={() => setNumberOptionDropdownVisible(!numberOptionDropdownVisible)}
                    style={{ height: 47, marginTop: 0 }}
                  >
                    {selectedNumberToShow}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  <ul className={numberOptionDropdownVisible ? "buyorder-options bg-surface position-absolute show overflow-hidden" : "buyorder-options bg-surface position-absolute"}>
                    {numberOptions.map((item, index) => (
                    <li key={index}
                    className={selectedNumberToShow === item ? "selected" : "" }
                    >
                      <button type="button" onClick={() => handleSelectNumberOption(item)}
                      className={selectedNumberToShow === item ? "active" : "" }
                      >
                        {item}
                      </button>
                    </li>
                    ))}
                  </ul>
                </div>
                <div className="buyorder-dropdown position-relative" style={{ minWidth: 139 }}>
                  <button
                    type="button"
                    className="text-start"
                    onClick={() => setCoinDropdownVisible(!coinDropdownVisible)}
                    style={{ height: 47, marginTop: 0 }}
                  >
                    {selectedCoin && selectedPair ? (
                      <>
                        {selectedCoin} / {selectedPair}
                      </>
                    ) : (
                      labels?.tables?.offers?.all
                    )}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  
                  <ul style={{ minWidth: 179, height: 365, overflow: 'scroll' }} className={coinDropdownVisible ? "buyorder-options bg-surface position-absolute show" : "buyorder-options bg-surface position-absolute"}>
                  <form className="form-flex"> 
                        <div className="search" style={{ maxWidth: '100%', borderBottomLeftRadius: 0, borderBottomRightRadius: 0, border: 0, borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#4B5563' }}>
                          <input
                              type="text"
                              placeholder={labels?.forms?.common?.search}
                              value={coinSearchWord}
                              onChange={handleCoinSearch}
                            />
                          <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} viewBox="0 0 13 14" fill="none">
                            <path d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583" fill="#111928" stroke="#9CA3AF" />
                          </svg>
                        </div>
                      </form>

                      {coinSearchWord === "" ? (
                        <>
                    <li
                    className={currencyPair === null ? "selected" : "" }
                    >
                      <button type="button" onClick={() => {setCurrencyPair(null); setSelectedCoin(null); setSelectedPair(null); setCoinDropdownVisible(false)}}
                      className={currencyPair === null ? "active" : "" }
                      >
                        {labels?.tables?.offers?.all}
                      </button>
                    </li>
                   {coinsData?.map((item, index) => (
                    <li key={index}
                    className={selectedCoin === item.base && selectedPair === item.quote ? "selected" : "" }
                    >
                      <button type="button" onClick={() => handleSelectCoin(item)}
                      className={selectedCoin === item.base && selectedPair === item.quote ? "active" : "" }
                      >
                        {item.base}/{item.quote}
                      </button>
                    </li>
                    ))}
                        </>
                      ) : (
                        <>
                    <li
                    className={currencyPair === null ? "selected" : "" }
                    >
                      <button type="button" onClick={() => {setCurrencyPair(null); setSelectedCoin(null); setSelectedPair(null); setCoinDropdownVisible(false)}}
                      className={currencyPair === null ? "active" : "" }
                      >
                        {labels?.tables?.offers?.all}
                      </button>
                    </li>
                     {filteredCoinsData?.map((item, index) => (
                    <li key={index}
                    className={selectedCoin === item.base && selectedPair === item.quote ? "selected" : "" }
                    >
                      <button type="button" onClick={() => handleSelectCoin(item)}
                      className={selectedCoin === item.base && selectedPair === item.quote ? "active" : "" }
                      >
                        {item.base}/{item.quote}
                      </button>
                    </li>
                    ))}
                        </>
                      )}


                  </ul>
                </div>
                </div>

          </div>
        </div>
          <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead className="w-100">
                  <tr>
                  <th style={{width:'8%', maxWidth:'8%', overflow: 'hidden'}} ></th>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('date')}>
                    {sortBy.key==="date" ? <u>{labels?.tables?.tradeHistory?.date}</u> : labels?.tables?.tradeHistory?.date }
                    {sortBy.key==="date" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="date" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('trade')}>
                    {sortBy.key==="trade" ? <u>{labels?.tables?.tradeHistory?.pair}</u> : labels?.tables?.tradeHistory?.pair }
                    {sortBy.key==="trade" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="trade" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('side')}>
                    {sortBy.key==="side" ? <u>{labels?.tables?.tradeHistory?.date}</u> : labels?.tables?.tradeHistory?.type }
                    {sortBy.key==="side" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="side" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
    
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('rate')}>
                    {sortBy.key==="rate" ? <u>{labels?.tables?.tradeHistory?.date}</u> : labels?.tables?.tradeHistory?.price }
                    {sortBy.key==="rate" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="rate" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th> 
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('serviceCharge')}>
                    {sortBy.key==="serviceCharge" ? <u>{labels?.tables?.tradeHistory?.size}</u> : labels?.tables?.tradeHistory?.size }
                    {sortBy.key==="serviceCharge" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="serviceCharge" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('volume')}>
                    {sortBy.key==="volume" ? <u>{labels?.tables?.tradeHistory?.date}</u> : labels?.tables?.tradeHistory?.volume }
                    {sortBy.key==="volume" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="volume" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('amount')}>
                    {sortBy.key==="amount" ? <u>{labels?.tables?.tradeHistory?.amount}</u> : labels?.tables?.tradeHistory?.amount }
                    {sortBy.key==="amount" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="amount" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                  </tr>
                </thead>
                <tbody>
                {updatedCurrentItems.length>0 ?  
  updatedCurrentItems.map((item, index) => {
                  const actionType= item.side.toLocaleLowerCase();
                  return (
                  <React.Fragment key={index}>
                    <tr>
                    <td style={{ width: 60 }}>
                    <button onClick={() => toggleRow(index)} className="bg-transparent border-0">
                      <span className={expandedRows[index] ? "chevron-rotated" : "chevron"}>
                        <img src="/images/icons/chevron-down.svg" />
                      </span>
                    </button>
                  </td>
                      <td>
                        <div className="date">
                          {splitDateTime(item.date).date}

                          <span> {splitDateTime(item.date).time}</span>
                        </div>
                      </td>
                      <td>
                        <div className="pair">
                          <img
                            src={`/images/coins/${item.trade.toLowerCase()}.svg`}
                            alt=""
                          />
                          {item.currencyPair}
                        </div>
                      </td>
                      <td className={
                        item.side === "BUY"
                          ? "text-success"
                          : "text-danger"
                      }>
                        {labels?.forms?.postOffer?.[actionType].toUpperCase()}
                      </td>

                      <td>
                        <div className="price">
                          {formatNumberWithComma(item.rate, selectedNumberFormat)}
                        </div>
                      </td>
                      <td>{formatNumberWithComma(item.serviceCharge, selectedNumberFormat)}</td>
                      <td>{formatNumberWithComma(item.volume, selectedNumberFormat)}</td>
                      <td>{formatNumberWithComma(item.amount, selectedNumberFormat)}</td>
                    </tr>
                    {expandedRows[index] && (
                  <tr className="table-accordion-wrapper">
                    <td colSpan="10">
                      <div className="accordion-content">
                        <span>Order ID: {item.orderId}</span>
                      </div>
                    </td>
                  </tr>
                )}
                  </React.Fragment>
)})
                  
                  :
                  <>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>{labels?.generics?.loading}</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>{labels?.generics?.noData}</span>   
                      </td>
                  </tr>
                  }
                  </>
                  
        }
                
                </tbody>
              </table>
            </div>
            <ul className="pagination"> 
          
            <li>
  {currentPage > 1 ? (
    <Link onClick={() => setCurrentPage(currentPage - 1)}>{labels?.pagination?.prev}</Link>
  ) : (
    <Link className="disabled" style={{ color: '#555' }}>{labels?.pagination?.prev}</Link>
  )}
</li>

<li>
  {currentPage < Math.ceil(totalItems / itemsPerPage) ? (
    <Link onClick={() => setCurrentPage(currentPage + 1)}>{labels?.pagination?.next}</Link>
  ) : (
    <Link className="disabled" style={{ color: '#555' }}>{labels?.pagination?.next}</Link>
  )}
</li>


      </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TradeHistory;
