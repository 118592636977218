import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./InstaTrades.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";

import { useModalContext } from "../../../Context/ModalContext";
import { useSelector } from "react-redux";


import axios from "axios";

function InstaTrades() {
  const labels = useSelector((state) => state.labels.data);

  const { selectedNumberFormat } = useModalContext();

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);

  const [searchWord, setSearchWord] = useState();
  const [tableShowData, setTableShowData] = useState([]);

/*
     * Pagination and Orderby
    */

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage] = useState(10);
// const [sortBy, setSortBy] = useState({ key: null, ascending: true });
const [sortBy, setSortBy] = useState({ key: "requestedOn", ascending: false });

// Function to sort data based on column key and sort order
const sortedData = tableShowData.sort((a, b) => {
  if (sortBy.key) {
    const valueA = a[sortBy.key];
    const valueB = b[sortBy.key];
    if (valueA < valueB) return sortBy.ascending ? -1 : 1;
    if (valueA > valueB) return sortBy.ascending ? 1 : -1;
  }
  return 0;
});

// Function to handle column header click for sorting
const handleSort = (key) => {
  if (sortBy.key === key) {
    setSortBy({ key, ascending: !sortBy.ascending });
  } else {
    setSortBy({ key, ascending: true });
  }
};

// Function to handle pagination
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

/*
 * Pagination and Orderby Ended
*/

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword;
 
        let filteredShowArray = tableShowData.filter(item => 
          item.base.toLocaleLowerCase('en-US').includes(tmpSearchWord.toLocaleLowerCase('en-US'))
        );

        setTableShowData(filteredShowArray);      
      } 

  }

  const [hasError, setHasError] = useState(false);

  const getInstaTradeData = () => {
    
    const dataToSend = {
      key: "dummyValue",
    };

    // Axios POST
    axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/get_insta_trades`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi,
            "Content-Type": "application/json", // Set the content type as needed
          },
        }
      )
      .then((response) => {
       
        setData(response?.data?.data); 
 
        let latestData = response?.data?.data;

        if (searchWord) { 
          let filteredShowArray = latestData.filter(item => 
            item.currencyPair.toLocaleLowerCase('en-US').includes(searchWord.toLocaleLowerCase('en-US'))
          );  
          setTableShowData(filteredShowArray);
          setIsLoading(false);
        } else {
          setTableShowData(latestData);
          setIsLoading(false);
        }

      })
      .catch((error) => {
        setHasError(true);
      });

  }

  useEffect(() => {
    setIsLoading(true);
    
    getInstaTradeData();
     // Set up an interval to call getInstaTradeData every second
     const intervalId = setInterval(() => {
      getInstaTradeData();
    }, 1500);

  // Clean up function to clear the interval when component unmounts
  return () => {
      clearInterval(intervalId);
  };

  }, []);

  if (hasError) {
    throw new Error("");
  }






  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Orders" submenu="OrdersInstaTrades" />
        <div className="main-content">
          <h4 className="title">{labels?.wallet?.instaTrade?.link}</h4>
          <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('requestedOn')}>
                    {sortBy.key==="requestedOn" ? <u>{labels?.tables?.instaTradeHistory?.date}</u> : labels?.tables?.instaTradeHistory?.date }
                    {sortBy.key==="requestedOn" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="requestedOn" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('quoteAmount')}>
                    {sortBy.key==="quoteAmount" ? <u>{labels?.tables?.instaTradeHistory?.purchased}</u> : labels?.tables?.instaTradeHistory?.purchased }
                    {sortBy.key==="quoteAmount" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="quoteAmount" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>

                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('baseAmount')}>
                    {sortBy.key==="baseAmount" ? <u>{labels?.tables?.instaTradeHistory?.price}</u> : labels?.tables?.instaTradeHistory?.price }
                    {sortBy.key==="baseAmount" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="baseAmount" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
                   


                
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('rate')}>
                    {sortBy.key==="rate" ? <u>{labels?.tables?.instaTradeHistory?.rate}</u> : labels?.tables?.instaTradeHistory?.status }
                    {sortBy.key==="rate" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="rate" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
         
                  <th style={{width:'18%', maxWidth:'18%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} onClick={() => handleSort('commission')}>
                    {sortBy.key==="commission" ? <u>{labels?.tables?.instaTradeHistory?.status}</u> : labels?.tables?.instaTradeHistory?.status }
                    {sortBy.key==="commission" && sortBy.ascending ? <b>&nbsp; &#9650;</b> : <></>}
                    {sortBy.key==="commission" && !sortBy.ascending ? <b>&nbsp; &#9660;</b> : <></>}
                  </th>
        
                  </tr>
                </thead>
                <tbody>
                {currentItems.length>0 ?  
  currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>
                        <div className="date">
                          <time className="d-block">{splitDateTime(item.requestedOn).date}</time>
                          <span className="d-block ms-0"> {splitDateTime(item.requestedOn).time}</span>
                        </div>
                      </td>
                      <td>{formatNumberWithComma(item.quoteAmount, selectedNumberFormat)} {item.quoteCurrency}</td>
                      <td>{formatNumberWithComma(item.baseAmount, selectedNumberFormat)}  {item.baseCurrency}</td>
                      <td>{formatNumberWithComma(item.rate, selectedNumberFormat)}</td>
                      <td className={item.status === true ? "text-success" : "text-danger"}>
                        {item.status === true ? (
                        <>{labels?.tables?.instaTradeHistory?.complete}</>
                      ) : (
                        <>{labels?.tables?.instaTradeHistory?.pendingOrCancelled}</>
                      )}
                      </td>
                    </tr>
                  ))
                  :
                  <>
                  { isLoading ? 
                    <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center">
                      <img src="/images/loading.gif" width={50}/>
                      <br /><br />
                      <span style={{color:'#ccc'}}>{labels?.generics?.loading}</span>   
                      </td>
                  </tr>
                  : 
                  <tr key="1" style={{height:200}}> 
                      <td colSpan={7} align="center"> 
                      <br /><br />
                      <span style={{color:'#ccc'}}>{labels?.generics?.noData}</span>   
                      </td>
                  </tr>
                  }
                  </>
                  
                }
               
                </tbody>
              </table>
            </div>

          <ul className="pagination"> 
            <li>
            {currentPage > 1 ? (
              <Link onClick={() => setCurrentPage(currentPage - 1)}>{labels?.pagination?.prev}</Link>
              ) : (
              <Link className="disabled" style={{color:'#555'}}>{labels?.pagination?.prev}</Link>
            )}
          </li>
          <li>
            {itemsPerPage <= currentItems.length ? (
              <Link onClick={() => {setCurrentPage(currentPage + 1)}}>{labels?.pagination?.next}</Link>
              ) : (
              <Link className="disabled" style={{color:'#555'}}>{labels?.pagination?.next}</Link>
            )}
          </li>
          </ul>


          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InstaTrades;
