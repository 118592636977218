import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Header from '../../../Components/Header';
import SideBar from '../../../Components/SideBar';
import Footer from '../../../Components/Footer';
import './ChangePassword.css';
import { useNavigate } from 'react-router-dom'; 
import { useSelector } from "react-redux";
import { useModalContext } from "../../../Context/ModalContext";

function ChangePassword() {
  const navigate = useNavigate(); 
  const labels = useSelector((state) => state.labels.data);
  const { selectedTheme } = useModalContext();

  const [hasError, setHasError] = useState(false);


  const [profile, setProfile] = useState();
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/GetProfile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
              
        setProfile(data.data)
      } catch (error) {
        setHasError(true);
      }
    };
  
    fetchProfile();
  }, []);


  if (hasError) {
    throw new Error("");
  }


  const token = localStorage.getItem("token");

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [otp, setOtp] = useState('');


  const [isOldPasswordValid, setIsOldPasswordValid] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);

  
  const [hasOldPasswordBlurred, setHasOldPasswordBlurred] = useState(false);
  const [hasNewPasswordBlurred, setHasNewPasswordBlurred] = useState(false);
  const [hasConfirmPasswordBlurred, setHasConfirmPasswordBlurred] = useState(false);
  const [hasOtpBlurred, setHasOtpBlurred] = useState(false);

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d_!@#$%^&*]{8,}$/;
  const safeCharactersRegex = /^[a-zA-Z0-9\s.,]*$/;


  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (!safeCharactersRegex.test(value)) {
      return;
    } else {
      setOtp(e.target.value);
    }
  };


  useEffect(() => {
    setIsOldPasswordValid(oldPassword !== "");
  }, [oldPassword]);

  useEffect(() => {
    setIsNewPasswordValid(passwordRegex.test(newPassword));
  }, [newPassword]);

  useEffect(() => {
    setIsConfirmPasswordValid(newPassword === confirmNewPassword && confirmNewPassword !== '');
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    setIsOtpValid(safeCharactersRegex.test(otp) && otp.length >= 6);
  }, [otp]);


  const onSubmit = async (e) => {
    e.preventDefault();
  
    if (!isOldPasswordValid || !isNewPasswordValid || !isConfirmPasswordValid || !isOtpValid) {
      setHasOldPasswordBlurred(true);
      setHasNewPasswordBlurred(true);
      setHasConfirmPasswordBlurred(true);
      setHasOtpBlurred(true);
      return;
    } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/ChangePassword`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Bearer token gönderimi
          },
          body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword,
            otp: otp
          }),
        });
  
        const result = await response.json();
  
        if (result.status === "Success") {
          const messageKey = result.data; 
          const getDynamicMessage = (key) => {
            return labels?.messages?.[key] || key; 
           };
           const dynamicMessage = getDynamicMessage(messageKey);
          toast.success(dynamicMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.removeItem('token'); 

          navigate('/login'); 

        } else if (result.status === "Error") {
          const message = labels?.messages?.[result?.message].replace("$t(multiFactorAuth.name)",
          labels?.multiFactorAuth?.Google);
          toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        setHasError(true)
      }
    }
  };


  const [emailCodeRequestSent, setEmailCodeRequestSent] = useState(false);

  const requestChangePasswordOTP = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/RequestChangePasswordOTP`, {
        method: "POST", 
        headers: {
          "Content-Type": "application/json", 
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({
          email: profile?.email, 
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const messageKey = data.message; 
        const getDynamicMessage = (key) => {
          return labels?.messages?.[key] || key; 
         };
         const dynamicMessage = getDynamicMessage(messageKey);
        toast.success(dynamicMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setEmailCodeRequestSent(true);
      } else {
      }
    } catch (error) {
      setHasError(true);
    }
  }; 
  

  return (
    <>
      <Header />
      <section className="main-page">
        <SideBar mainmenu="Account" submenu="AccountChangePassword" />

        <div className="main-content">
          <h4 className="title">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path d="M15 7.00008C15.5304 7.00008 16.0391 7.21079 16.4142 7.58586C16.7893 7.96093 17 8.46964 17 9.00008M21 9.00008C21.0003 9.93725 20.781 10.8615 20.3598 11.6986C19.9386 12.5358 19.3271 13.2627 18.5744 13.821C17.8216 14.3792 16.9486 14.7535 16.0252 14.9136C15.1018 15.0737 14.1538 15.0153 13.257 14.7431L11 17.0001H9V19.0001H7V21.0001H4C3.73478 21.0001 3.48043 20.8947 3.29289 20.7072C3.10536 20.5196 3 20.2653 3 20.0001V17.4141C3.00006 17.1489 3.10545 16.8946 3.293 16.7071L9.257 10.7431C9.00745 9.91809 8.93857 9.04902 9.05504 8.19502C9.17152 7.34102 9.47062 6.52215 9.93199 5.79412C10.3934 5.0661 11.0062 4.44602 11.7287 3.97609C12.4512 3.50617 13.2665 3.19743 14.1191 3.07088C14.9716 2.94434 15.8415 3.00296 16.6693 3.24276C17.4972 3.48256 18.2637 3.89791 18.9166 4.46054C19.5696 5.02317 20.0936 5.71987 20.4531 6.50322C20.8127 7.28656 20.9992 8.13817 21 9.00008Z" 
            stroke={selectedTheme?.name === "dark" ? "white" : "black"} 
            strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          {labels?.forms?.changePassword.title}
          </h4>



          {profile?.is2FAEnabled === true ? (
            <form onSubmit={(e) => onSubmit(e)} className="change-password-form" style={{ maxWidth: 580 }}>
            <div className="form-group">
          <label htmlFor="old-password">{labels?.forms?.changePassword?.oldPassword.placeholder}</label>
          <input
            className={`form-control mt-2 ${isOldPasswordValid ? 'is-valid' : (hasOldPasswordBlurred && 'is-invalid')}`}
            type="password"
            id="old-password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            onBlur={() => setHasOldPasswordBlurred(true)}
            placeholder={labels?.forms?.changePassword?.oldPassword.placeholder}
          />
          {!isOldPasswordValid && hasOldPasswordBlurred && (
            <div className="invalid-feedback">
              {labels?.forms?.validations?.required}
            </div>
          )}
        </div>
  
        <div className="form-group mt-3">
          <label htmlFor="new-password">{labels?.forms?.changePassword?.newPassword.placeholder}</label>
          <input
            className={`form-control mt-2 ${isNewPasswordValid ? 'is-valid' : (hasNewPasswordBlurred && 'is-invalid')}`}
            type="password"
            id="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onBlur={() => setHasNewPasswordBlurred(true)}
            placeholder={labels?.forms?.changePassword.newPassword.placeholder}
          />
          {!isNewPasswordValid && hasNewPasswordBlurred && (
            <div className="invalid-feedback">
              {newPassword === "" ? (
                <>
                {labels?.forms?.validations?.required}
                </>
              ) : (
                <>
                  {labels?.forms?.validations?.password}
                </>
              )}
            </div>
          )}
        </div>
  
        <div className="form-group mt-3">
          <label htmlFor="confirm-password">{labels?.forms?.changePassword?.newPasswordConfirm.placeholder}</label>
          <input
            className={`form-control mt-2 ${isConfirmPasswordValid ? 'is-valid' : (hasConfirmPasswordBlurred && 'is-invalid')}`}
            type="password"
            id="confirm-password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            onBlur={() => setHasConfirmPasswordBlurred(true)}
            placeholder={labels?.forms?.changePassword.newPasswordConfirm.placeholder}
          />
          {!isConfirmPasswordValid && hasConfirmPasswordBlurred && (
            <div className="invalid-feedback">
              {labels?.forms?.validations?.passwordMatch}
            </div>
          )}
        </div>
  
  
        <div className="form-group mt-3">
          <label htmlFor="otp">{labels?.forms?.changePassword.otp.gAuthPlaceholder.replace(
            "$t(multiFactorAuth.name)", labels?.multiFactorAuth?.Google
          )}</label>
          <input
            className={`form-control mt-2 ${isOtpValid ? 'is-valid' : (hasOtpBlurred && 'is-invalid')}`}
            type="text"
            id="otp"
            value={otp}
            onChange={handleOtpChange}
            onBlur={() => setHasOtpBlurred(true)}
            placeholder={labels?.forms?.changePassword.otp.gAuthPlaceholder.replace(
              "$t(multiFactorAuth.name)", labels?.multiFactorAuth?.Google
            )}
          />
          {!isOtpValid && hasOtpBlurred && (
            <div className="invalid-feedback">
              {otp === "" ? (
                <>
                {labels?.forms?.validations?.required}
                </>
              ) : otp.length < labels?.multiFactorAuth?.length ? (
                <>
                  {labels?.forms?.validations?.min.replace(
                        "{{length}}", labels?.multiFactorAuth?.length
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
  
  
              <button type="submit" className="btn btn-primary mt-5">{labels?.forms?.changePassword.title}</button>
            </form>
          ) : profile?.is2FAEnabled === false ? (
            <>
            {!emailCodeRequestSent ? (
              <>
              <div className="change-password-content">
              <Link to="/" className="logo mb-3 d-flex">
              <img className="destop-logo"
                   src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"}
                   alt="" />
            </Link>
                <div className='form-group'>
                  <h5>{labels?.forms?.changePassword?.title}</h5>
                  <p>{labels?.forms?.changePassword?.otpEmail}</p>
                </div>
                <button onClick={requestChangePasswordOTP} className="btn btn-danger">{labels?.forms?.changePassword?.requestOtp}</button>
              </div>
              </>
            ) : (
              <form onSubmit={(e) => onSubmit(e)} className="change-password-form" style={{ maxWidth: 580 }}>
              <div className="form-group">
            <label htmlFor="old-password">{labels?.forms?.changePassword?.oldPassword.placeholder}</label>
            <input
              className={`form-control mt-2 ${isOldPasswordValid ? 'is-valid' : (hasOldPasswordBlurred && 'is-invalid')}`}
              type="password"
              id="old-password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              onBlur={() => setHasOldPasswordBlurred(true)}
              placeholder={labels?.forms?.changePassword?.oldPassword.placeholder}
            />
            {!isOldPasswordValid && hasOldPasswordBlurred && (
              <div className="invalid-feedback">
                {labels?.forms?.validations?.required}
              </div>
            )}
          </div>
    
          <div className="form-group mt-3">
            <label htmlFor="new-password">{labels?.forms?.changePassword?.newPassword.placeholder}</label>
            <input
              className={`form-control mt-2 ${isNewPasswordValid ? 'is-valid' : (hasNewPasswordBlurred && 'is-invalid')}`}
              type="password"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={() => setHasNewPasswordBlurred(true)}
              placeholder={labels?.forms?.changePassword.newPassword.placeholder}
            />
            {!isNewPasswordValid && hasNewPasswordBlurred && (
              <div className="invalid-feedback">
                {newPassword === "" ? (
                  <>
                  {labels?.forms?.validations?.required}
                  </>
                ) : (
                  <>
                    {labels?.forms?.validations?.password}
                  </>
                )}
              </div>
            )}
          </div>
    
          <div className="form-group mt-3">
            <label htmlFor="confirm-password">{labels?.forms?.changePassword?.newPasswordConfirm.placeholder}</label>
            <input
              className={`form-control mt-2 ${isConfirmPasswordValid ? 'is-valid' : (hasConfirmPasswordBlurred && 'is-invalid')}`}
              type="password"
              id="confirm-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              onBlur={() => setHasConfirmPasswordBlurred(true)}
              placeholder={labels?.forms?.changePassword.newPasswordConfirm.placeholder}
            />
            {!isConfirmPasswordValid && hasConfirmPasswordBlurred && (
              <div className="invalid-feedback">
                {labels?.forms?.validations?.passwordMatch}
              </div>
            )}
          </div>
    
  
          <div className="form-group mt-3">
          <label htmlFor="otp">{labels?.forms?.changePassword?.otp.emailPlaceholder}</label>
          <input
            className={`form-control mt-2 ${isOtpValid ? 'is-valid' : (hasOtpBlurred && 'is-invalid')}`}
            type="text"
            id="otp"
            value={otp}
            onChange={handleOtpChange}
            onBlur={() => setHasOtpBlurred(true)}
            placeholder={labels?.forms?.changePassword?.otp.emailPlaceholder}
          />
          {!isOtpValid && hasOtpBlurred && (
            <div className="invalid-feedback">
              {otp === "" ? (
                <>
                {labels?.forms?.validations?.required}
                </>
              ) : otp.length < labels?.multiFactorAuth?.length ? (
                <>
                  {labels?.forms?.validations?.min.replace(
                        "{{length}}", labels?.multiFactorAuth?.length
                      )}
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>


          <div className='form-group mt-3'>
            <div className='pb-2'>
            <label>{labels?.forms?.changePassword?.otpNotWorking}</label>
            </div>
          <button onClick={requestChangePasswordOTP} className="btn btn-outline-danger">{labels?.forms?.changePassword?.requestNewOtp}</button>
          </div>
                <button type="submit" className="btn btn-primary mt-5">{labels?.forms?.changePassword.title}</button>
              </form>
            )}

            </>
          ) : (
            <></>
          )}

        </div>
      </section>
      <Footer />
    </>
  );
}

export default ChangePassword;
