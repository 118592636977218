import React, { useState } from "react";
import "./Trade.css";
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { useModalContext } from "../../../Context/ModalContext";
 
export const PercentageSlider = ({ setActiveProcess, activeProcess, color, percentage, setPercentage, name, sliding, setSliding }) => {

  const { selectedTheme } = useModalContext();


  const handleSliderChange = (value) => {
   setPercentage(value);
   setSliding(!sliding)
   setActiveProcess(activeProcess)
 };
 
   const didOnBeforeChangeTrigger = () => {
   };
 
   const didOnAfterChangeTrigger = (value) => {
     setPercentage(value)
   };
 
 
   const [isTooltipVisible, setIsTooltipVisible] = useState(false);
 
 
   const handleMouseOver = () => {
     setIsTooltipVisible(true);
   }
 
   const handleMouseMove = () => {
     alert("move")
   }
 
   const customColor = selectedTheme.name === "dark" ? "#374151" : "#F3F4F6";
  

  return (
    <div className={`slider-container percentage-slider position-relative px-2 my-3 ${name}`}>
    <Slider
          handleRender={(node, handleProps) => {
            return (
              <Tooltip
                overlayInnerStyle={{ minHeight: "auto" }}
                overlay={`${isNaN(percentage) ? 0 : percentage}%`}
                placement="top"
              >
                {node}
              </Tooltip>
            );
          }}
            defaultValue={0}
            min={0}
            max={100}
            value={percentage}
            marks={
              {
                0: {
                  style: {
                    backgroundColor: percentage >= 0 ? color : customColor,
                    width: 15,
                    height: 15, 
                    borderRadius: 15,
                    marginTop: -18
                  },
                  label: <div style={{ marginTop: 14, marginLeft: -13 }}>
                    <span style={{ opacity: 0 }}>0%</span>
                    <span style={{ color: selectedTheme.name === "dark" ? "#fff" : "#000" }}>0%</span>
                    </div>
                },
                25: {
                  style: {
                    backgroundColor: percentage >= 25 ? color : customColor,
                    width: 15,
                    height: 15, 
                    borderRadius: 15,
                    marginTop: -18
                  },
                  label: <div style={{ marginTop: 14, marginLeft: -25 }}>
                    <span style={{ opacity: 0 }}>25%</span>
                    <span style={{ color: selectedTheme.name === "dark" ? "#fff" : "#000" }}>25%</span>
                    </div>
                },
                50: {
                  style: {
                    backgroundColor: percentage >= 50 ? color : customColor,
                    width: 15,
                    height: 15, 
                    borderRadius: 15,
                    marginTop: -18
                  },
                  label: <div style={{ marginTop: 14, marginLeft: -25 }}>
                    <span style={{ opacity: 0 }}>50%</span>
                    <span style={{ color: selectedTheme.name === "dark" ? "#fff" : "#000" }}>50%</span>
                    </div>
                },
                75: {
                  style: {
                    backgroundColor: percentage >= 75 ? color : customColor,
                    width: 15,
                    height: 15, 
                    borderRadius: 15,
                    marginTop: -18
                  },
                  label: <div style={{ marginTop: 14, marginLeft: -25 }}>
                    <span style={{ opacity: 0 }}>75%</span>
                    <span style={{ color: selectedTheme.name === "dark" ? "#fff" : "#000" }}>75%</span>
                    </div>
                },
                100: {
                  style: {
                    backgroundColor: percentage >= 100 ? color : customColor,
                    width: 15,
                    height: 15, 
                    borderRadius: 15,
                    marginTop: -18
                  },
                  label: <div style={{ marginTop: 14, marginLeft: -38 }}>
                    <span style={{ opacity: 0 }}>100%</span>
                    <span style={{ color: selectedTheme.name === "dark" ? "#fff" : "#000" }}>100%</span>
                    </div>
                }
              }}

            onMouseOver={handleMouseOver}
            onMouseMove={handleMouseMove}
            onChange={handleSliderChange} 
            onBeforeChange={didOnBeforeChangeTrigger}
            onAfterChange={didOnAfterChangeTrigger}                
            trackStyle={{ backgroundColor: color, height: 4 }}
            railStyle={{ backgroundColor: selectedTheme.name === "dark" ? "#374151" : "#F3F4F6", height: 4 }}
            handleStyle={{
              borderColor: color,
              borderWidth: 1,
              borderStyle: 'solid',
              height: 15,
              width: 15,
              marginLeft: 0,
              marginTop: -5,
              backgroundColor: selectedTheme.name === "dark" ? "#374151" : "#F3F4F6",
              opacity: 1
            }}
    />
    </div>
  )
};
