import React, { useEffect, createContext, useState, useContext } from "react";
import { useSelector } from 'react-redux';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const labels = useSelector(state => state.labels.data);

  const [themeNo, setThemeNo] = useState(2);

  const [additionalInformation, setAdditionalInformation] = useState(() => {
  const savedAdditionalInformation = localStorage.getItem("additionalInformation");
  return savedAdditionalInformation
  ? JSON.parse(savedAdditionalInformation)
  : {
    
  };
});


  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    return savedLanguage
      ? JSON.parse(savedLanguage)
      : {
          id: 1,
          name: "english",
          code: "en",
        };
  });


  const defaultNumberFormat = {
    id: 1,
    name: labels?.settings?.numberFormatOptions?.df || "Default",
    short: "default"
  };
  const [selectedNumberFormat, setSelectedNumberFormat] = useState(() => {
    const savedNumberFormat = localStorage.getItem("selectedNumberFormat");
    return savedNumberFormat ? JSON.parse(savedNumberFormat) : defaultNumberFormat;
  });

  const [selectedFiatEstimation, setSelectedFiatEstimation] = useState(() => {
    const savedFiatEstimation = localStorage.getItem("selectedFiatEstimation");
    return savedFiatEstimation
      ? JSON.parse(savedFiatEstimation)
      : {
          id: 1,
          name: "EUR",
        };
  });

  const [selectedTheme, setSelectedTheme] = useState(() => {
    const savedTheme = localStorage.getItem("selectedTheme");
    return savedTheme
      ? JSON.parse(savedTheme)
      : {
        id: 1,
        name: "dark",
        icon: "/images/icons/moon.svg",
        };
  });

  useEffect(() => {
    localStorage.setItem("selectedTheme", JSON.stringify(selectedTheme));
  }, [selectedTheme]);

  useEffect(() => {
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
  }, [selectedLanguage]);

  useEffect(() => {
    localStorage.setItem("selectedNumberFormat", JSON.stringify(selectedNumberFormat));
  }, [selectedNumberFormat]);

  useEffect(() => {
    localStorage.setItem("selectedFiatEstimation", JSON.stringify(selectedFiatEstimation));
  }, [selectedFiatEstimation]);

  useEffect(() => {
    localStorage.setItem("additionalInformation", JSON.stringify(additionalInformation));
  }, [additionalInformation]);

  const toggleSettingsModal = () => {
    setSettingsModalVisible((prev) => !prev);
  };


  return (
    <ModalContext.Provider
      value={{
        settingsModalVisible,
        toggleSettingsModal,
        selectedLanguage,
        setSelectedLanguage,
        selectedNumberFormat,
        setSelectedNumberFormat,
        selectedFiatEstimation,
        setSelectedFiatEstimation,
        selectedTheme,
        setSelectedTheme,
        themeNo,
        setThemeNo,
        additionalInformation,
        setAdditionalInformation
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
