import React, { useState, useEffect } from "react";
import { TradeDropdown } from "./TradeDropdown";
import { formatNumberWithComma } from "../../../Common/Helpers";  
import { useModalContext } from "../../../Context/ModalContext";
import { useSelector } from "react-redux";

export const TradeHeader = ({ isSearching, setIsSearching, latestTradeType, setActiveMarket, coin, coinsData, setCoinsData, increasingData, searchWord, setSearchWord, tableShowData, setTableShowData, pairs, selectedPair, handleItemClick, primaryCoin, dropdownVisible, setDropdownVisible }) => {
  
  const labels = useSelector((state) => state?.labels?.data);
  const { selectedNumberFormat } = useModalContext();

  useEffect(() => {}, []);

  return (
    <>
      <div className="row d-lg-block d-none">
        <div className="col-md-12 py-2 border-bottom border-top custom-border-color border-secondary">
          <div className="d-flex items-center justify-start small">
            <TradeDropdown 
              setActiveMarket={setActiveMarket}
              coinsData={coinsData}
              setCoinsData={setCoinsData}
              coin={coin}
              primaryCoin={primaryCoin}
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              pairs={pairs}
              selectedPair={selectedPair}
              handleItemClick={handleItemClick}
              tableShowData={tableShowData}
              setTableShowData={setTableShowData}
              searchWord={searchWord}
              setSearchWord={setSearchWord}
              increasingData={increasingData}
            />
            <div className="trade-header-inner px-4 d-flex align-items-center justify-content-center">
            <span
  style={{ height: '100%', display: 'flex', alignItems: 'center' }}
  className={
    isNaN(parseFloat(coin?.price)) && isNaN(parseFloat(primaryCoin?.price))
      ? "text-muted"
      : latestTradeType === "BUY"
      ? "text-success fw-bold"
      : "text-danger fw-bold"
  }
>

  {isNaN(parseFloat(coin?.price)) && isNaN(parseFloat(primaryCoin?.price))
    ? "..."
    : formatNumberWithComma(coin?.price, selectedNumberFormat) || formatNumberWithComma(primaryCoin?.price, selectedNumberFormat)}
</span>

            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
            <span className="trade-head-title">{labels?.navbar?.analyticsContainer.coinInfo}</span>

              <span className="text-muted">{coin?.base || primaryCoin?.base || "..."}</span>            


              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
            <span className="trade-head-title">{labels?.tables?.coinTracker.change}</span>

              {coin ? (
  <span 
    className={isNaN(parseFloat(coin?.change_in_price)) 
      ? "text-muted" 
      : (coin?.change_in_price > 0 
        ? "text-success" 
        : "text-danger")}
  >
    {isNaN(parseFloat(coin?.change_in_price)) 
      ? "..." 
      : `${coin?.change_in_price > 0 ? '+' : ''}${parseFloat(coin?.change_in_price).toFixed(2)}%`}
  </span>
) : (
  <span 
    className={isNaN(parseFloat(primaryCoin?.change_in_price)) 
      ? "text-muted" 
      : (primaryCoin?.change_in_price > 0 
        ? "text-success" 
        : "text-danger")}
  >
    {isNaN(parseFloat(primaryCoin?.change_in_price)) 
      ? "..." 
      : `${primaryCoin?.change_in_price > 0 ? '+' : ''}${parseFloat(primaryCoin?.change_in_price).toFixed(2)}%`}
  </span>
)}




            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
            <span className="trade-head-title">{labels?.tables?.coinTracker["24hHigh"]}</span>
              
              <span className="text-muted">{formatNumberWithComma(coin?.high_24hr, selectedNumberFormat)|| formatNumberWithComma(primaryCoin?.high_24hr, selectedNumberFormat)|| "..."}</span>            

              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
            <span className="trade-head-title">{labels?.tables?.coinTracker["24hLow"]}</span>


<span className="text-muted">{formatNumberWithComma(coin?.low_24hr, selectedNumberFormat)|| formatNumberWithComma(primaryCoin?.low_24hr, selectedNumberFormat)|| "..."}</span>            

              
              
            </div>
            <div className="px-4 d-flex flex-column align-items-start justify-content-start">
              <span className="trade-head-title">{labels?.tables?.coinTracker?.volume}</span>

                            <span className="text-muted">{formatNumberWithComma(coin?.base_volume, selectedNumberFormat)|| formatNumberWithComma(primaryCoin?.base_volume, selectedNumberFormat)|| "..."}</span>            

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
