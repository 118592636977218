import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Affiliates.css";
import { toast } from "react-toastify";

import axios from "axios";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import { formatNumberWithComma } from "../../../Common/Helpers";

import { useModalContext } from "../../../Context/ModalContext";

import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";

function Affiliates() {
  const labels = useSelector((state) => state.labels.data);

  const [myAffiliateReturn, setMyAffiliateReturn] = useState();
  const [affilateCommissionReturn, setAffilateCommissionReturn] = useState();
  const [affilateSummaryReturn, setAffilateSummaryReturn] = useState();
  const [affilateSummaryStatsReturn, setAffilateSummaryStatsReturn] =
    useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { selectedFiatEstimation } = useModalContext();

  const [fiatPrices, setFiatPrices] = useState();

  const [hasError, setHasError] = useState(false);

  const checkAffiliates = async () => {
    const token = localStorage.getItem("token");

    // Axios GET Request - 1
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/My_Affiliate`, {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      })
      .then((myAffiliate) => {
        setMyAffiliateReturn(myAffiliate?.data);
      })
      .catch((error) => {
        setHasError(true);
      });

    // Axios GET Request - 2
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/Affiliate_Commission`, {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      })
      .then((affiliateCommission) => {
        setAffilateCommissionReturn(affiliateCommission?.data?.data);
      })
      .catch((error) => {
        setHasError(true);
      });

    // Axios GET Request - 3
    const affiliateSummary = await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/Affiliate_Summary`, {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      })
      .then((affiliateSummary) => {
        setAffilateSummaryReturn(affiliateSummary?.data);
      })
      .catch((error) => {
        setHasError(true);
      });

    // Axios GET Request - 4
    await axios
      .get(
        `${process.env.REACT_APP_API_REST_URL}/api/Affiliate_Summary_stats`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((affiliateStats) => {
        setAffilateSummaryStatsReturn(affiliateStats?.data?.data);
      })
      .catch((error) => {
        setHasError(true);
      });
  };

  useEffect(() => {
    checkAffiliates();
  }, []);

  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState();

  const fetchFiatPrices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_REST_URL}/api/get_fiat_price`
      );
      const rateList = response?.data?.data?.rateList;

      setFiatPrices(rateList);

      const selectedCurrency = rateList.find(
        (item) => item.currency === selectedFiatEstimation?.name
      );

      if (selectedCurrency) {
        setSelectedCurrencyRate(selectedCurrency.rate);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(() => {
    fetchFiatPrices();
  }, [selectedFiatEstimation]);

  if (hasError) {
    throw new Error("");
  }

  const [currentPageCommission, setCurrentPageCommission] = useState(1);

  const indexOfLastItemCommission = currentPageCommission * itemsPerPage;
  const indexOfFirstItemCommission = indexOfLastItemCommission - itemsPerPage;
  const currentItemsCommission = affilateCommissionReturn?.slice(
    indexOfFirstItemCommission,
    indexOfLastItemCommission
  );

  // Function to handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = myAffiliateReturn?.data?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error("Invalid date");
      }
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      return date.toLocaleString("en-EN", options);
    } catch (error) {
      console.error("Invalid date:", error);
      return "Invalid date";
    }
  };

  const [copiedKey, setCopiedKey] = useState(null);

  const handleCopyKey = (key) => {
    navigator.clipboard.writeText(key);
    setCopiedKey(key);
    toast.success(labels?.messages?.clipboardCopied, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const totalEarnings = affilateSummaryStatsReturn?.totalEarnings;

  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page">
        <SideBar mainmenu="Account" submenu="AccountAffiliates" />

        <div className="main-content">
          <h4 className="title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15H7M9 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V11C21 10.4696 20.7893 9.96086 20.4142 9.58579C20.0391 9.21071 19.5304 9 19 9H9C8.46957 9 7.96086 9.21071 7.58579 9.58579C7.21071 9.96086 7 10.4696 7 11V17C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19ZM16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16C13.4696 16 12.9609 15.7893 12.5858 15.4142C12.2107 15.0391 12 14.5304 12 14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12C14.5304 12 15.0391 12.2107 15.4142 12.5858C15.7893 12.9609 16 13.4696 16 14Z"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {labels?.account?.affiliates?.title}
          </h4>
          <div className="box-item">
            <div className="row">
              <div className="col-lg-10">
                <div className="copy-v2 d-flex flex-column flex-lg-row">
                  <span> {labels?.account?.affiliates?.referralLink}: </span>
                  <input
                    type="text"
                    className="w-lg-auto w-100"
                    defaultValue={affilateSummaryReturn?.data?.referralLink}
                  />
                  <button
                    onClick={() =>
                      handleCopyKey(affilateSummaryReturn?.data?.referralLink)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M6.3999 2.39998C6.3999 2.1878 6.48419 1.98432 6.63422 1.83429C6.78425 1.68426 6.98773 1.59998 7.1999 1.59998H8.7999C9.01208 1.59998 9.21556 1.68426 9.36559 1.83429C9.51562 1.98432 9.5999 2.1878 9.5999 2.39998C9.5999 2.61215 9.51562 2.81563 9.36559 2.96566C9.21556 3.11569 9.01208 3.19998 8.7999 3.19998H7.1999C6.98773 3.19998 6.78425 3.11569 6.63422 2.96566C6.48419 2.81563 6.3999 2.61215 6.3999 2.39998Z"
                        fill="white"
                      />
                      <path
                        d="M4.79995 2.40002C4.3756 2.40002 3.96864 2.5686 3.66858 2.86865C3.36852 3.16871 3.19995 3.57568 3.19995 4.00002V12.8C3.19995 13.2244 3.36852 13.6313 3.66858 13.9314C3.96864 14.2315 4.3756 14.4 4.79995 14.4H11.2C11.6243 14.4 12.0313 14.2315 12.3313 13.9314C12.6314 13.6313 12.8 13.2244 12.8 12.8V4.00002C12.8 3.57568 12.6314 3.16871 12.3313 2.86865C12.0313 2.5686 11.6243 2.40002 11.2 2.40002C11.2 3.03654 10.9471 3.64699 10.497 4.09708C10.0469 4.54717 9.43647 4.80002 8.79995 4.80002H7.19995C6.56343 4.80002 5.95298 4.54717 5.5029 4.09708C5.05281 3.64699 4.79995 3.03654 4.79995 2.40002Z"
                        fill="white"
                      />
                    </svg>
                    {labels?.buttons?.copy}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="copy-v2 d-flex flex-column flex-lg-row">
                  <span>{labels?.account?.affiliates?.referralCode}:</span>
                  <input
                    type="text"
                    className="w-lg-auto w-100 text-center text-lg-start"
                    defaultValue={affilateSummaryReturn?.data?.referralID}
                  />
                  <button
                    onClick={() =>
                      handleCopyKey(affilateSummaryReturn?.data?.referralID)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M6.3999 2.39998C6.3999 2.1878 6.48419 1.98432 6.63422 1.83429C6.78425 1.68426 6.98773 1.59998 7.1999 1.59998H8.7999C9.01208 1.59998 9.21556 1.68426 9.36559 1.83429C9.51562 1.98432 9.5999 2.1878 9.5999 2.39998C9.5999 2.61215 9.51562 2.81563 9.36559 2.96566C9.21556 3.11569 9.01208 3.19998 8.7999 3.19998H7.1999C6.98773 3.19998 6.78425 3.11569 6.63422 2.96566C6.48419 2.81563 6.3999 2.61215 6.3999 2.39998Z"
                        fill="white"
                      />
                      <path
                        d="M4.79995 2.40002C4.3756 2.40002 3.96864 2.5686 3.66858 2.86865C3.36852 3.16871 3.19995 3.57568 3.19995 4.00002V12.8C3.19995 13.2244 3.36852 13.6313 3.66858 13.9314C3.96864 14.2315 4.3756 14.4 4.79995 14.4H11.2C11.6243 14.4 12.0313 14.2315 12.3313 13.9314C12.6314 13.6313 12.8 13.2244 12.8 12.8V4.00002C12.8 3.57568 12.6314 3.16871 12.3313 2.86865C12.0313 2.5686 11.6243 2.40002 11.2 2.40002C11.2 3.03654 10.9471 3.64699 10.497 4.09708C10.0469 4.54717 9.43647 4.80002 8.79995 4.80002H7.19995C6.56343 4.80002 5.95298 4.54717 5.5029 4.09708C5.05281 3.64699 4.79995 3.03654 4.79995 2.40002Z"
                        fill="white"
                      />
                    </svg>
                    {labels?.buttons?.copy}
                  </button>
                  <div className="tooltip-box">
                    <div className="hh">
                      {labels?.account?.affiliates?.myRefId}
                    </div>
                    <p>{labels?.account?.affiliates?.shareLink}</p>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="tt"> {labels?.account?.affiliates?.tiers}</h4>
            <ul className="earnings-list">
              <li>
                <h5>{labels?.account?.affiliates?.rLevel1}</h5>
                <p>
                  {labels?.account?.affiliates?.feeShare}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_1_Perc} %
                </p>
                <p>
                  {labels?.account?.affiliates?.referrals}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_1}
                </p>
              </li>
              <li>
                <h5>{labels?.account?.affiliates?.rLevel2}</h5>
                <p>
                  {labels?.account?.affiliates?.feeShare}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_2_Perc} %
                </p>
                <p>
                  {labels?.account?.affiliates?.referrals}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_2}
                </p>
              </li>
              <li>
                <h5>{labels?.account?.affiliates?.rLevel3}</h5>
                <p>
                  {labels?.account?.affiliates?.feeShare}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_3_Perc} %
                </p>
                <p>
                  {labels?.account?.affiliates?.referrals}:{" "}
                  {affilateSummaryReturn?.data?.r_Level_3}
                </p>
              </li>
            </ul>

            <h4 className="tt mt-3">
              {labels?.account?.affiliates?.earnings}{" "}
            </h4>
            {affilateSummaryStatsReturn && (
              <ul className="earnings-list">
                <li>
                  <h5>{labels?.account?.affiliates?.allTime}</h5>
                  <p>
                    ~
                    {formatNumberWithComma(totalEarnings?.allTime?.btcValue) ||
                      "0"}{" "}
                    BTC
                  </p>
                  <p>
                    ~{selectedFiatEstimation?.name}{" "}
                    {isNaN(totalEarnings?.allTime?.usdValue) ? (
                      "0"
                    ) : (
                      <>
                        {(
                          totalEarnings?.allTime?.usdValue *
                          selectedCurrencyRate
                        ).toFixed(2) || "0.00"}
                      </>
                    )}
                  </p>
                </li>
                <li>
                  <h5>{labels?.account?.affiliates?.["60d"]}</h5>
                  <p>
                    ~
                    {formatNumberWithComma(totalEarnings?.["60d"]?.btcValue) ||
                      "0"}{" "}
                    BTC
                  </p>
                  <p>
                    ~{selectedFiatEstimation?.name}{" "}
                    {isNaN(totalEarnings?.["60d"]?.usdValue) ? (
                      "0"
                    ) : (
                      <>
                        {(
                          totalEarnings?.["60d"]?.usdValue *
                          selectedCurrencyRate
                        ).toFixed(2) || "0.00"}
                      </>
                    )}
                  </p>
                </li>
                <li>
                  <h5>{labels?.account?.affiliates?.["90d"]}</h5>
                  <p>
                    ~
                    {formatNumberWithComma(totalEarnings?.["90d"]?.btcValue) ||
                      "0"}{" "}
                    BTC
                  </p>
                  <p>
                    ~{selectedFiatEstimation?.name}{" "}
                    {isNaN(totalEarnings?.["90d"]?.usdValue) ? (
                      "0"
                    ) : (
                      <>
                        {(
                          totalEarnings?.["90d"]?.usdValue *
                          selectedCurrencyRate
                        ).toFixed(2) || "0.00"}
                      </>
                    )}
                  </p>
                </li>
                <li>
                  <h5>{labels?.account?.affiliates?.["120d"]}</h5>
                  <p>
                    ~
                    {formatNumberWithComma(totalEarnings?.["120d"]?.btcValue) ||
                      "0"}{" "}
                    BTC
                  </p>
                  <p>
                    ~{selectedFiatEstimation?.name}{" "}
                    {isNaN(totalEarnings?.["120d"]?.usdValue) ? (
                      "0"
                    ) : (
                      <>
                        {(
                          totalEarnings?.["120d"]?.usdValue *
                          selectedCurrencyRate
                        ).toFixed(2) || "0.00"}
                      </>
                    )}
                  </p>
                </li>
              </ul>
            )}
          </div>

          <div className="box-item">
            <div className="table-responsive mt15">
              <table className="table">
                <thead className="w-100">
                  <tr>
                    <th>{labels?.account?.affiliates?.currency}</th>
                    <th>{labels?.account?.affiliates?.allTime}</th>
                    <th>{labels?.account?.affiliates?.["60d"]}</th>
                    <th>{labels?.account?.affiliates?.["90d"]}</th>
                    <th>{labels?.account?.affiliates?.["120d"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {affilateSummaryStatsReturn?.earningsByCurrency ? (
                    Object.entries(
                      affilateSummaryStatsReturn.earningsByCurrency
                    ).map(([currency, data], index) => (
                      <tr key={index}>
                        <td>
                          <img
                            src={`/images/coins/${currency.toLowerCase()}.svg`}
                            alt={currency}
                          />
                          <span className="ms-2">{currency.toUpperCase()}</span>
                        </td>
                        <td>{data.allTime}</td>
                        <td>{data["60d"]}</td>
                        <td>{data["90d"]}</td>
                        <td>{data["120d"]}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">{labels?.generics?.noData}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="box-item p-0 overflow-hidden">
            <Accordion defaultActiveKey={null} className="affiliate-accordion">
              <Accordion.Item eventKey="0" className="border-0">
                <Accordion.Header className="hh-accordion">
                  <h4>{labels?.account?.affiliates?.commission}</h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={28}
                    height={29}
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.5898 18.2898C20.3273 18.5523 19.9712 18.6997 19.6 18.6997C19.2288 18.6997 18.8727 18.5523 18.6102 18.2898L14 13.6796L9.38981 18.2898C9.12577 18.5448 8.77212 18.6859 8.40505 18.6827C8.03797 18.6795 7.68683 18.5323 7.42726 18.2727C7.16769 18.0132 7.02045 17.662 7.01726 17.295C7.01408 16.9279 7.15519 16.5742 7.41021 16.3102L13.0102 10.7102C13.2727 10.4477 13.6288 10.3003 14 10.3003C14.3712 10.3003 14.7273 10.4477 14.9898 10.7102L20.5898 16.3102C20.8523 16.5727 20.9997 16.9288 20.9997 17.3C20.9997 17.6712 20.8523 18.0273 20.5898 18.2898Z"
                      fill="white"
                    />
                  </svg>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive mt15">
                    <table className="table">
                      <thead className="w-100">
                        <tr>
                          <th>{labels?.tables?.commission?.credit}</th>
                          <th>{labels?.tables?.commission?.dot}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItemsCommission?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {item.amount} {item.fromCID_Paid_Curr}
                              </td>
                              <td>{formatDate(item.execDate)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination">
                    <li>
                      {currentPageCommission > 1 ? (
                        <Link
                          onClick={() =>
                            setCurrentPageCommission(currentPageCommission - 1)
                          }
                        >
                          {labels?.pagination?.prev}
                        </Link>
                      ) : (
                        <Link className="disabled">
                          {labels?.pagination?.prev}
                        </Link>
                      )}
                    </li>
                    <li>
                      {indexOfLastItemCommission <
                      affilateCommissionReturn?.data?.length ? (
                        <Link
                          onClick={() =>
                            setCurrentPageCommission(currentPageCommission + 1)
                          }
                        >
                          {labels?.pagination?.next}
                        </Link>
                      ) : (
                        <Link className="disabled">
                          {labels?.pagination?.next}
                        </Link>
                      )}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="box-item p-0 overflow-hidden">
            <Accordion defaultActiveKey={null} className="affiliate-accordion">
              <Accordion.Item eventKey="0" className="border-0">
                <Accordion.Header className="hh-accordion">
                  <h4>{labels?.account?.affiliates?.usersReferred}</h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={28}
                    height={29}
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.5898 18.2898C20.3273 18.5523 19.9712 18.6997 19.6 18.6997C19.2288 18.6997 18.8727 18.5523 18.6102 18.2898L14 13.6796L9.38981 18.2898C9.12577 18.5448 8.77212 18.6859 8.40505 18.6827C8.03797 18.6795 7.68683 18.5323 7.42726 18.2727C7.16769 18.0132 7.02045 17.662 7.01726 17.295C7.01408 16.9279 7.15519 16.5742 7.41021 16.3102L13.0102 10.7102C13.2727 10.4477 13.6288 10.3003 14 10.3003C14.3712 10.3003 14.7273 10.4477 14.9898 10.7102L20.5898 16.3102C20.8523 16.5727 20.9997 16.9288 20.9997 17.3C20.9997 17.6712 20.8523 18.0273 20.5898 18.2898Z"
                      fill="white"
                    />
                  </svg>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="table-responsive mt15">
                    <table className="table">
                      <thead className="w-100">
                        <tr>
                          <th>{labels?.tables?.referrals?.joinedOn}</th>
                          <th>{labels?.tables?.referrals?.userId}</th>
                          <th>{labels?.socialTrade?.alertTypes?.email}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.length > 0 ? (
                          currentItems?.map((item, index) => (
                            <tr key={index}>
                              <td>{formatDate(item?.doj)}</td>
                              <td>{item?.name}</td>
                              <td>{item?.email}</td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <ul className="pagination">
                    <li>
                      {currentPage > 1 ? (
                        <Link onClick={() => setCurrentPage(currentPage - 1)}>
                          {labels?.pagination?.prev}
                        </Link>
                      ) : (
                        <Link className="disabled">
                          {labels?.pagination?.prev}
                        </Link>
                      )}
                    </li>
                    <li>
                      {indexOfLastItem < myAffiliateReturn?.data?.length ? (
                        <Link onClick={() => setCurrentPage(currentPage + 1)}>
                          {labels?.pagination?.next}
                        </Link>
                      ) : (
                        <Link className="disabled">
                          {labels?.pagination?.next}
                        </Link>
                      )}
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Affiliates;
