import React, { useState, useEffect } from 'react';
import axios from 'axios';
const Onfido = require('onfido-sdk-ui');

const KYCOnfido = ({ kyc, token }) => {
  const redirectDelayInSec = 5;
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [providerRedirectUrl, setProviderRedirectUrl] = useState(undefined);
  const [redirectTimer, setRedirectTimer] = useState(undefined);
  const [redirectTimeCountdown, setRedirectTimeCountdown] = useState(redirectDelayInSec);
  const [iFrameInfo, setIFrameInfo] = useState(null);
  const [redirectTimeCountdownInterval, setRedirectTimeCountdownInterval] = useState(null);

  const getIFrameInfo = async () => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_REST_URL}/api/KYC_OnSite_AccessToken`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    
      if (data.status === 'Success') {
        return data.data;
      } else {
        return null; // Returning null if the status is not success
      }
    } catch (error) {
      return null; // Return null on error
    }
  };
  

  // Onfido iFrame'ini başlatmak
  const initializeIFrame = () => {
    if (iFrameInfo) {
      const { token } = iFrameInfo;
      triggerOnfido(token);
    }
  };

  const triggerOnfido = (token) => {
    Onfido.init({
      token: token,
      containerId: 'onfido-mount',
      onComplete: async function (data) {
        try {
          // Make the API call using axios
          await axios.post(`${process.env.REACT_APP_API_REST_URL}/api/KYC_OnSite_SaveApplication`, {
            applicationId: 0,
          }, {
            headers: {
              Authorization: `Bearer ${token}`, // Use Bearer token for authorization
            },
          });
        } catch (error) {
          console.error('Error saving application:', error);
        }
      },
      onError: (data) => {
        if (data.type === 'expired_token') {
          reInitializeOnfido();
        }
      },
      onUserExit: function (userExitCode) {
      },
      steps: ['welcome', 'document', 'face', 'complete'],
    });
  };
  

  const reInitializeOnfido = async () => {
    const newIFrameInfo = await getIFrameInfo();
    setIFrameInfo(newIFrameInfo);
    initializeIFrame();
  };

  useEffect(() => {
    const initialize = async () => {
      const iFrameData = await getIFrameInfo();
      if (iFrameData) {
        setIFrameInfo(iFrameData);
        setIsScriptLoaded(true);

        // Eğer kyc sağlayıcısı 'idnow' ise, yönlendirme yap
        if (kyc?.webSdkProviderName === 'idnow' && iFrameData.idnow_redirect) {
          window.location.href = iFrameData.idnow_redirect;
        } else {
          initializeIFrame();
        }
      }
    };

    initialize();

    return () => {
      // Cleanup işlemleri
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
      if (redirectTimeCountdownInterval) {
        clearInterval(redirectTimeCountdownInterval);
      }
    };
  }, []);

  const handleRedirect = () => {
    if (!redirectTimer) {
      const interval = setInterval(() => {
        setRedirectTimeCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setRedirectTimeCountdownInterval(null);
          }
          return prev - 1;
        });
      }, 1000);
      setRedirectTimeCountdownInterval(interval);

      setRedirectTimer(
        setTimeout(() => {
          window.location.href = providerRedirectUrl;
        }, redirectDelayInSec * 1000)
      );
    }
  };

  return (
    <React.Fragment>
      {!isScriptLoaded && <p className='text-white'>loading...</p>}
      {providerRedirectUrl && (
        <div>
          <div>
            <p>
              {/* Warning message directly */}
              {'KYC işlemi için yönlendirileceksiniz.'}
            </p>
          </div>
          <button
            type="button"
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={handleRedirect}
          >
            {redirectTimer
              ? `KYC'ye başla ${redirectTimeCountdown}`
              : `KYC'ye başla`}
          </button>
        </div>
      )}
      {!providerRedirectUrl && <div id="onfido-mount" />}
    </React.Fragment>
  );
};

export default KYCOnfido;
