import React, { useState, useEffect, useRef } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import './InstaTrade.css';

import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';
import {fetchMarketData, fetchMarketMergedData, fetchMarketMergedInstaData, convertCurrency} from '../../../Components/MarketData';
import {getBalance} from '../../../Components/Wallet';
  
import { useSelector } from 'react-redux';
import { findFullNameOfCoin } from "../../../Common/Helpers";
 
import CustomModal from '../../../Components/CustomModal/';

import axios from "axios";

import { useModalContext } from "../../../Context/ModalContext";
import { useCoinContext } from "../../../Context/CoinContext";
import { toast } from "react-toastify";


function InstaTrade() {

  const { selectedTheme } = useModalContext();

  const { primaryCoin, newCoin, MARKET, activeMarket } = useCoinContext();

  const labels = useSelector((state) => state.labels.data);

    
  const currencySettings = useSelector(state => state.currencySettings.data);

  const [pairs, setPairs] = useState();
  const [pairsAll, setPairsAll] = useState();
  const [pairsOfTarget, setPairsOfTarget] = useState();
  

  const [instaPairs, setInstaPairs] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [isTargetOpen, setTargetIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState('');
  const [selectedTargetItem, setSelectedTargetItem] = useState('');

  const [amount, setAmount] = useState();
  const [predefineAmount, setPredefineAmount] = useState();
  const [changeRatio, setChangeRatio] = useState();
   
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [currentCryptoPrices, setCurrentCryptoPrices] = useState([]);
 
  const [displayDefault, setDisplayDefault] = useState();

  const [userLatestBalance, setUserLatestBalance] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const[message, setMessage] = useState(); 

  const dropdownRef = useRef(null);
  const targetDropdownRef = useRef(null);



  const [userBalanceArray, setUserBalanceArray] = useState();

  const getUserBalance = async () => {
    let userBalance = await getBalance();
    if (userBalance) {
     const userBalanceArr = userBalance.data?.data;
     const sortedUserBalanceArr = [...userBalanceArr].sort((a, b) => b.balance - a.balance);
    setUserLatestBalance(sortedUserBalanceArr);
    setUserBalanceArray(userBalanceArr)
    }
  }


  let coinBalance = userBalanceArray?.find(item => item.currency === selectedItem);
  let pairBalance = userBalanceArray?.find(item => item.currency === selectedTargetItem);

  useEffect(() => {
    coinBalance = userBalanceArray?.find(item => item.currency === selectedItem);
    pairBalance = userBalanceArray?.find(item => item.currency === selectedTargetItem);
  }, [selectedItem, selectedTargetItem, newCoin, activeMarket ])

  useEffect(() => {
    if (!pairsAll || !newCoin || !activeMarket) return;
  
    const isValidPair = pairsAll.some(pair => 
      pair.baseCurrency === newCoin && pair.quoteCurrency === activeMarket
    );
  
    if (isValidPair) {
      setSelectedItem(newCoin);
      setSelectedTargetItem(activeMarket);
    } else {
      setSelectedItem('BTC');
      setSelectedTargetItem('USDT')
    }
    
  }, [newCoin, activeMarket, pairsAll]);
  
  
  function getQuoteCurrencies(baseCurrency) {
    const matchingPairs = pairsAll.filter(pair => pair.baseCurrency === baseCurrency);
    const quoteCurrencies = matchingPairs.map(pair => pair.quoteCurrency);
    return quoteCurrencies;
  }


  const uniqueQuotes = [...new Set(pairsAll?.map(pair => pair.quoteCurrency))];
  const matchedCoins = pairsAll?.filter(pair => pair.quoteCurrency === selectedTargetItem).map(pair => pair.baseCurrency);
  


  const getRateByCurrency = (currency) => {

    // Convert input currency to lowercase for case-insensitive comparison
    const lowerCaseCurrency = currency.toLowerCase();
    
    // Find the object with the matching currency (ignoring case)
    const currencyObject = currentCryptoPrices.find(
      (crypto) => crypto.currency.toLowerCase() === lowerCaseCurrency
    );

    // If the currency is found, return its rate; otherwise, return null or any default value
    return currencyObject ? currencyObject.rate : 0;
  }
 
  const getRatioByCurrency = (currency) => {

    // Convert input currency to lowercase for case-insensitive comparison
    const lowerCaseCurrency = currency.toLowerCase();
    
    // Find the object with the matching currency (ignoring case)
    const currencyObject = currentCryptoPrices.find(
      (crypto) => crypto.currency.toLowerCase() === lowerCaseCurrency
    );

    // If the currency is found, return its rate; otherwise, return null or any default value
    return currencyObject ? currencyObject.priceChangePercent7d : 0;
  }


  const [activePercentage, setActivePercentage] = useState();

  const calculatePredefinedAmount = async () => {
    if (currentCryptoPrices.length > 0) {
      let calculatedResult = 0;
  
      if (selectedItem && selectedTargetItem && currentCryptoPrices) {
        calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, 1, currentCryptoPrices);
  
        const userUSDT = pairBalance?.balance;

        const userBtc = coinBalance?.balance;

  
      //  const maxBTC = userUSDT / calculatedResult;
  
        setPredefineAmount(
          <>
            <ul className="list percentage-list">
              <li 
                className={activePercentage === 10 ? 'active' : ''}
                onClick={() => {
                  setAmount(String(isNaN(userBtc * 0.10) ? 0 : (userBtc * 0.10).toFixed(8)));
                  setActivePercentage(10);
                }}>
                10%
              </li>
              <li 
                className={activePercentage === 25 ? 'active' : ''}
                onClick={() => {
                  setAmount(String(isNaN(userBtc * 0.25) ? 0 : (userBtc * 0.25).toFixed(8)));
                  setActivePercentage(25);
                }}>
                25%
              </li>
              <li 
                className={activePercentage === 50 ? 'active' : ''}
                onClick={() => {
                  setAmount(String(isNaN(userBtc * 0.50) ? 0 : (userBtc * 0.50).toFixed(8)));
                  setActivePercentage(50);
                }}>
                50%
              </li>
              <li 
                className={activePercentage === 75 ? 'active' : ''}
                onClick={() => {
                  setAmount(String(isNaN(userBtc * 0.75) ? 0 : (userBtc * 0.75).toFixed(8)));
                  setActivePercentage(75);
                }}>
                75%
              </li>
              <li 
               className={activePercentage === 100 ? 'active' : ''}
                onClick={() => {
                  setAmount(String(userBtc));
                  setActivePercentage(100);
                }}>
                100%
              </li>
            </ul>
          </>
        );
      }
    } else {
      setPredefineAmount(<p>{labels?.instaTrade?.selectCoin}</p>);
    }
  };

 
  const calculatePredefinedRatio = async () => {
    let ratio = ""; 
  
    if (currentCryptoPrices.length > 0) { 
      if (selectedItem && currentCryptoPrices) { 
        ratio = getRatioByCurrency(selectedItem); // calculate ratio
  
        // if ratio değeri is present, display the button
        if (ratio !== undefined || "") {
          setChangeRatio(
            <>  
              <h4>{labels?.forms?.estimatedFee}</h4>
              <div className="ratio-btn" style={{ backgroundColor: ratio > 0 ? '#DEF7EC' : '#bb4444' }}>
                {ratio} %
              </div> 
            </>
          );
        } else {
          setChangeRatio(null);
        }
      }  
    } else {
      setChangeRatio(null); 
    }
  };
  





  const calculateDisplayPriceFunction = async () => {
    if (currentCryptoPrices.length>0) {
      let calculatedResult=0; 
      if (selectedItem && selectedTargetItem && currentCryptoPrices) {
        calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, 1, currentCryptoPrices);
        setDisplayDefault(
          <>
          <span className="v01">1 {selectedItem} </span>
          <span className="v02">≈ {calculatedResult} {selectedTargetItem}</span>
          </>
        )
      }  
    } else {
      setDisplayDefault(
        <>
          <span className="v01">{labels?.instaTrade?.selectCoin}</span> 
        </>
      )
    }
  }


const calculateTargetPrice = async () => {
  
  if (currentCryptoPrices.length>0) {
    let calculatedResult=0;
    if (selectedItem && selectedTargetItem && amount && currentCryptoPrices) {
      // do not write characters check
      const result = amount.replace(/[^0-9.]/g, '');
      setAmount(result); 
      calculatedResult = await convertCurrency(selectedItem, selectedTargetItem, amount, currentCryptoPrices);
      calculatedResult = calculatedResult*0.99;
      setCalculatedAmount(calculatedResult)
    } else {
      setCalculatedAmount(calculatedResult)
    }
  } else {
    setAmount(""); 
  }
}
 
const instaBuySell = async () => {
  if (amount === '' || parseFloat(amount) <= 0) {
   // setMessage('Insufficient amount');
  //  handleShow();
  const message = labels?.messages?.InsufficientFunds;
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
    return; 
  }

  let reqBody = {
    baseAmount: amount,
    baseCurrency: selectedItem,
    quoteCurrency: selectedTargetItem,
    okx_quote_id: "",
    order_id: ""
  }

  const token = localStorage.getItem("token");
  const response = await axios.post(
    `${process.env.REACT_APP_API_REST_URL}/api/request_insta_trade`,
    reqBody,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    }
  ).catch(error => {
    throw new Error("");
  });

  setMessage(response?.data?.data);
  const message = response?.data?.data;
  //handleShow();
  if (response?.data?.status === "Success") {
    const messageKey = response.data.message; 
    const getDynamicMessage = (key) => {
      return labels?.messages?.[key] || key; 
     };
     const dynamicMessage = getDynamicMessage(messageKey);
    toast.success(dynamicMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  } else {
    const messageKey = response.data.message; 
    const getDynamicMessage = (key) => {
      return labels?.messages?.[key] || key; 
     };
     const dynamicMessage = getDynamicMessage(messageKey);
    toast.error(dynamicMessage, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

}


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleTargetDropdown = () => {
    setTargetIsOpen(!isTargetOpen);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    const result = getQuoteCurrencies(item);
    setPairsOfTarget(result)
  };

  const handleTargetSelect = (item) => {
    setSelectedTargetItem(item);
    setTargetIsOpen(false);
  };

  const handleClickOutside = (event) => { 
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (targetDropdownRef.current && !targetDropdownRef.current.contains(event.target)) {
      setTargetIsOpen(false);
    } 
  }

  const fetchMarketDataFunction = async () => {
    // let latestFiatData = await fetchMarketData();
    // let latestFiatData = await fetchMarketMergedData();

    let latestFiatData = await fetchMarketMergedInstaData();
     
    // console.log(latestFiatData);
    setCurrentCryptoPrices(latestFiatData);
  }

  useEffect(() => {
    
    // Restful Version
    const interval = setInterval(() => {
      fetchMarketDataFunction().catch(console.error); 
    }, 2500); 

    // Websocket Version
    

  },[]);

  /*
  useEffect(() => {
    if (selectedItem === '') {
      setSelectedItem(newCoin);
    }
    if (selectedTargetItem === '') {
      setSelectedTargetItem(activeMarket);
    }

    if (selectedItem !== '' && (selectedItem === selectedTargetItem)) {
      setSelectedItem(matchedCoins[0])
    }
    
    calculateDisplayPriceFunction();
  }, [newCoin, activeMarket, selectedItem, selectedTargetItem, currentCryptoPrices]); */

  useEffect(() => {


    if (selectedItem !== '' && (matchedCoins?.includes(selectedItem) === false)){
      setSelectedItem(matchedCoins[0])
    }
    
    calculateDisplayPriceFunction();
  }, [newCoin, activeMarket, selectedItem, selectedTargetItem, currentCryptoPrices]);



  useEffect(() => {
    calculateTargetPrice();
  },[amount, selectedItem, selectedTargetItem, currentCryptoPrices]);
 
  useEffect(() => {
    calculateDisplayPriceFunction();
    calculatePredefinedAmount();
    calculatePredefinedRatio();
  },[selectedItem, selectedTargetItem, currentCryptoPrices, activePercentage]);
 
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  useEffect(() => {
     


    const fetchData = async () => {
      const response = await axios.get (
        process.env.REACT_APP_API_REST_URL+'/api/get_insta_pairs'
      );
      
      let rawData = response?.data?.data;
         // console.log("insta-pairs:"+ JSON.stringify(rawData) );
      if (rawData) {
        const extractedBaseCurrencies = rawData.map(item => item.baseCurrency);
        setPairs(extractedBaseCurrencies);
        setPairsAll(rawData)
         // console.log("insta-pairs-map:"+ JSON.stringify(extractedBaseCurrencies) );
      }
    }
 
    fetchData();
    getUserBalance();
  }, []);


  const selectedCurrency = pairsAll?.find(
    (item) => item.baseCurrency === selectedItem
  );


  const loginOrRegisterText = labels?.forms?.common?.loginOrRegister || "";
const [beforeLogin, loginText, betweenText, registerText, afterRegister] = loginOrRegisterText
  .split(/<0>|<\/0>|<2>|<\/2>/);

  const displayTradeButton = () => {
    if (localStorage.getItem("token")) { 
      return <button className="button" onClick={() => instaBuySell()}>{labels?.navbar?.links?.trade}</button>
    } else {
      return                    <div className="guest-warn border-0" style={{ height: 52 }}>
      {beforeLogin}
      <a href="/login" className="px-1">{loginText}</a>
      {betweenText}
      <a href="/register" className="px-1">{registerText}</a>
      {afterRegister}
    </div>
    }

  }




          return (
            <>
            <Header />
      
            <CustomModal 
        show={show} 
        handleClose={handleClose} 
        title={labels?.instaTrade?.title}
        message={message}
      />

            <PriceBar />
        {/* Trade Card */}
        <div className="container">
                <div className="trade-card">
                  <div className="head"><img src={selectedTheme.name === "dark" ? "images/icons/fire.svg" : "images/icons/fire-black.svg"} alt="" />
                    {labels?.instaTrade?.title}
                  </div>
                  <div className="you-have">
                    <div className="tt">{labels?.forms?.coinBalance}</div>
                    
                   <ul>
                      <li>{coinBalance?.balance ? coinBalance.balance : 0} {selectedItem}</li>
                      <li>{pairBalance?.balance ? pairBalance.balance : 0} {selectedTargetItem}</li>
                   </ul> 
                    
                  </div>
        
                  <div className="price">
                    {displayDefault}
                  </div>
        
                  
                    {changeRatio}
                  
                  
                  <div className="item">
                    <h2 className="mb-0">{labels?.instaTrade?.amount}</h2>
                    {selectedCurrency ? (
                      <span className="content-text mb-1 d-block">



                        {labels?.instaTrade?.spendBetween.
                        replace("{{min}}", selectedCurrency.minLimit)
                        .replace("{{max}}", selectedCurrency.maxLimit)
                        .replace("{{currency}}", selectedItem)
                        }
                      </span>
                    ) : (
                      <span>{labels?.currencies?.notFound}</span>
                    )}
                    <span> 
                    <img src={`/images/coins/"BTC".toLowerCase()}.svg`} alt="" />
                    </span> 
                      {predefineAmount} 
                    <div className="coin-input"> 
            <div className={`dropdown ${isOpen ? 'show' : ''}`} ref={dropdownRef}>
              <button className={`dropdown-toggle ${isOpen ? 'show' : ''}`} type="button" onClick={toggleDropdown}>
              <img src={`images/coins/${selectedItem.toLowerCase()}.svg`} alt="" />
                {selectedItem}
              </button>
              <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                {matchedCoins?.map((data, index) => (
                  <li key={index} onClick={() => handleSelect(data)}>
                    <Link to="" className="dropdown-item"> 
                      <img src={`images/coins/${data.toLowerCase()}.svg`} alt="" /> 
                      &nbsp;
                      {data}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            
            <input type="text" 
              placeholder={0.00000000000} 
              value={amount} 
              onChange={(e) => setAmount(e.target.value)} 
            />
            
            </div>
            </div>
            <div className="item">
            <h2>{labels?.instaTrade?.receive}</h2>
            <div className="coin-input">
        
            <div className={`dropdown ${isTargetOpen ? 'show' : ''}`} ref={targetDropdownRef}>
              <button className={`dropdown-toggle ${isTargetOpen ? 'show' : ''}`} type="button" onClick={toggleTargetDropdown}>
                <img src={`images/coins/${selectedTargetItem.toLowerCase()}.svg`} alt="" />
                {selectedTargetItem}
              </button>
              <ul className={`dropdown-menu ${isTargetOpen ? 'show' : ''}`}>
                {uniqueQuotes?.map((item, index) => (
                  <li key={index} onClick={() => handleTargetSelect(item)}>
                    <Link to="" className="dropdown-item">
                      <img src={`images/coins/${item.toLowerCase()}.svg`} alt="" /> 
                      &nbsp;
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
        
            <input type="text" placeholder={0.00000000000} value={calculatedAmount} disabled/>
            </div>
            <p>{labels?.instaTrade?.feeText}</p>
            </div>


            {displayTradeButton()}
        
            </div>
            </div>
              {/* Trade Card End */}
        
              <Footer />
              
            </>
          );


}

export default InstaTrade;
