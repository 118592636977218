import { rejects } from "assert";
import { useState, useContext } from "react";


export const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

// Function to validate a string based on given parameters
export const validateString = (str, minLen, maxLen, type) => {
  if (str.length < minLen || str.length > maxLen) {
    return false;
  }

  if (type === 'numeric') {
    const re = /^\d+$/;
    return re.test(str);
  }

  if (type === 'alphanumeric') {
    const re = /^[a-zA-Z0-9!_#@-]+$/;
    return re.test(str);
  }

  return false;
};

// Function to validate password complexity
export const validatePasswordComplexity = (password) => {
  const re = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');
  return re.test(password);
};

export const formatCurrency = (number) => {
  
  let tmpNumber = number.toString();

  if ( tmpNumber.includes("E")) {
    const regularNumber = Number(tmpNumber);
    return regularNumber.toLocaleString('en', { maximumFractionDigits: 20 })
  } else {

    const numericValue = Number(number);

    // Convert the number to a string and split into integer and decimal parts
    const parts = numericValue.toFixed(4).split(".");
    // Format the integer part with thousand separators
    const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Combine the formatted integer part with the decimal part and return
    return formattedInteger + "." + parts[1];
  }
   
}




export const formatNumberWithComma = (number, selectedNumberFormat) => {
  function swapDotsAndCommas(input, format) {
    if (format?.short === 'comma') {
      return input.replace(/[.,]/g, function (match) {
        return match === '.' ? ',' : '.';
      });
    }
    return input; // No swapping needed for 'period'
  }

  if (number === "-1") return "********";
  if (number === undefined || number === null) return "0";

  const isDecimal = number % 1 !== 0; // Check if the number is a decimal

  try {
    if (isDecimal) {
      const parts = number.toString().split('.');
      let integerPart = parts[0] || "0";
      let decimalPart = parts[1] || "0";

      if (parseInt(integerPart, 10) > 1000) {
        decimalPart = decimalPart.substring(0, 2);
      } else if (parseInt(integerPart, 10) > 100) {
        decimalPart = decimalPart.substring(0, 3);
      } else if (parseInt(integerPart, 10) > 10) {
        decimalPart = decimalPart.substring(0, 5);
      } else if (parseInt(integerPart, 10) > 1) {
        decimalPart = decimalPart.substring(0, 6);
      } else {
        decimalPart = decimalPart.substring(0, 8);
      }

      let formattedIntegerPart =
        integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimalPart;
      formattedIntegerPart = formattedIntegerPart.replace(
        /(\.[0-9]*[1-9])0*$/,
        '$1'
      );
      formattedIntegerPart = formattedIntegerPart.replace(/\.?0*$/, '');

      // Swap dots and commas based on the selected format
      formattedIntegerPart = swapDotsAndCommas(
        formattedIntegerPart,
        selectedNumberFormat
      );

      return formattedIntegerPart;
    } else {
      // If it's an integer, format it with commas
      let formattedIntegerPart =
        number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || "0";

      // Swap dots and commas based on the selected format
      formattedIntegerPart = swapDotsAndCommas(
        formattedIntegerPart,
        selectedNumberFormat
      );

      return formattedIntegerPart;
    }
  } catch (error) {
    return "0";
  }
};


export const splitDateTime = (dateTimeString) => {
  const [datePart, timePart] = dateTimeString.split('T');
  const [hourMinuteSecond] = timePart.split('.');

  return {
    date: datePart,
    time: hourMinuteSecond
  };
}

export const splitCoinCurrency = (pair) => {
  const [coin, currency] = pair.split('-');

  return {
    coin: coin,
    currency: currency
  }
}

export const findFullNameOfCoin = (array, shortName) => {
  if (shortName) {
    const lowerCaseShortName = shortName.toLowerCase();
    const item = array.find(element => element.shortName.toLowerCase() === lowerCaseShortName);
    return item ? item.fullName.toLowerCase() : null;
  } else {
    return "empty";
  }

}

export const formatDate = (originalDate) => {
  // Create a Date object from the original date string
  const date = new Date(originalDate);

  // Extract date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  // Return the formatted date string
  return formattedDate;
}

// Orderbook Raw Transactions
export const segregateAndSortTrades = (trades) => {
  // Filter and sort BUY trades
  const buyArray = trades
    .filter(trade => trade.side === "BUY")
    .sort((a, b) => a.rate - b.rate); // Ascending order by rate

  // Filter and sort SELL trades
  const sellArray = trades
    .filter(trade => trade.side === "SELL")
    .sort((a, b) => b.rate - a.rate); // Descending order by rate

  // Return the object containing both arrays
  return {
    buyArray,
    sellArray
  }
}

// Orderbook Final Transactions
export const segregateAndSortTradesUniqueRate = (trades) => {
  // Helper function to merge trades with the same rate
  const mergeTradesByRate = (accumulated, current) => {
    const { rate, amount, ...rest } = current;
    if (!accumulated[rate]) {
      accumulated[rate] = { ...rest, rate, amount };
    } else {
      accumulated[rate].amount += amount; // Accumulate the amount for the same rate
    }
    return accumulated;
  };

  // Filter, merge, and sort BUY trades
  const buyMap = trades
    .filter(trade => trade.side === "BUY")
    .reduce(mergeTradesByRate, {});
  const buyArray = Object.values(buyMap).sort((a, b) => a.rate - b.rate);

  // Filter, merge, and sort SELL trades
  const sellMap = trades
    .filter(trade => trade.side === "SELL")
    .reduce(mergeTradesByRate, {});
  const sellArray = Object.values(sellMap).sort((a, b) => b.rate - a.rate);

  // Return the object containing both arrays
  return {
    buyArray,
    sellArray
  };
}

// Sort Transactions
export const sortTradesByDate = (trades) => {
  // Sort trades by date in descending order
  const sortedTrades = trades.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Return the sorted array
  return sortedTrades;
}
