import { Link, useFetcher } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef, useMemo } from "react";

import { fetchTransactionData } from "../../../Components/MarketData";
import {
  segregateAndSortTrades,
  segregateAndSortTradesUniqueRate,
} from "../../../Common/Helpers.js";

import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";
import axios from "axios";

import { TradeHeader } from "./TradeHeader.js";
import { AnnounceBar } from "./AnnounceBar";
import { BuySellOrder } from "./BuySellOrder";
import { OrderBook } from "./OrderBook";
import { RecentTrades } from "./RecentTrades";
import { Transactions } from "./Transactions";
import { TradingView } from "./TradingView";
import { Portfolio } from "./Portfolio";
import {
  VolumeData,
  IncreasingData,
  DecreasingData,
  TableData,
} from "../../../Common/DummyData";
import { useNavigate } from "react-router-dom";
import { CoinContext } from "../../../Context/CoinContext";
import { useModalContext } from "../../../Context/ModalContext";
import { CoinSlider } from "../../../Components/CoinSlider/CoinSlider";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../Context/WebSocketContext";

import { formatNumberWithComma } from "../../../Common/Helpers";

import DepthChart from "./DepthChart";

import datafeed from "./datafeed";

import _ from "lodash";

import { useDispatch } from "react-redux";
import { setLatestTradePrice } from "../../../Features/Exchange/ExchangeSlice";

function Trade() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pair } = useParams();
  let currentPair = pair;

  const labels = useSelector((state) => state.labels.data);
  // const latestTradePrice = useSelector((state) => state.exchange.tradingPairStats.latestTradePrice);

  const {
    latestTradePrice,
    latestPrices,
    latestTradeType,
    coinsData,
    MKData,
    pairs,
    selectedPair,
    setSelectedPair,
  } = useContext(WebSocketContext);
  const {
    primaryCoin,
    setPrimaryCoin,
    newCoin,
    setNewCoin,
    MARKET,
    SETMARKET,
    activeMarket,
    setActiveMarket,
    setCoinsData,
    defaultPair,
  } = useContext(CoinContext);
  const {
    selectedNumberFormat,
    selectedLanguage,
    selectedFiatEstimation,
    selectedTheme,
  } = useModalContext();

  const [sliderData, setSliderData] = useState(MKData);

  useEffect(() => {
    setSliderData(MKData);
  }, [MKData]);

  const [searchWord, setSearchWord] = useState();
  const [increasingData, setIncreasingData] = useState(IncreasingData);
  const [decreasingData, setDecreasingData] = useState(DecreasingData);
  const [volumeData, setVolumeData] = useState(VolumeData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const pathname = window.location.pathname;
    const slashCount = pathname.split("/").length - 1;

    if (pathname === "/trade/") {
      window.history.replaceState({}, "", `/trade`);
    } else if (slashCount === 1 && newCoin && MARKET) {
      const pair = `${newCoin}-${MARKET}`;
      window.history.replaceState({}, "", `/trade/${pair}`);
    } else if (slashCount === 2) {
      const pairString = pathname.split("/")[2];
      const [base, quote] = pairString.split("-");

      setNewCoin(base.toUpperCase());
      SETMARKET(quote.toUpperCase());
      setActiveMarket(quote.toUpperCase());
    } else {
    }
  }, [navigate, newCoin, MARKET, activeMarket]);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableShowData, setTableShowData] = useState([]);

  const { asks, bids } = useContext(WebSocketContext);

  const [trades, setTrades] = useState([]);
  const [lastPrice, setLastPrice] = useState(null);
  const [averageBuyPrice, setAverageBuyPrice] = useState(null);
  const [averageSellPrice, setAverageSellPrice] = useState(null);

  const [transactions, setTransactions] = useState();

  const [openOrders, setOpenOrders] = useState([]);

  const [averageOfLatestPrices, setAverageOfLatestPrices] = useState(0);

  const calculatedAsks = useMemo(() => {
    return asks?.map((ask, index) => {
      const total = asks
        ?.slice(0, index + 1)
        .reduce((sum, ask) => sum + ask[1], 0);
      return {
        price: ask[0],
        size: formatNumberWithComma(ask[1], selectedNumberFormat),
        total: formatNumberWithComma(total, selectedNumberFormat),
      };
    });
  }, [asks]);

  const calculatedBids = useMemo(() => {
    return bids?.map((bid, index) => {
      const total = bids
        ?.slice(0, index + 1)
        .reduce((sum, bid) => sum + bid[1], 0);
      return {
        price: bid[0],
        size: formatNumberWithComma(bid[1], selectedNumberFormat),
        total: formatNumberWithComma(total, selectedNumberFormat),
      };
    });
  }, [bids]);

  const [convertedPrices, setConvertedPrices] = useState([]);
  const [average, setAverage] = useState(0);

  const [currentCoinRate, setCurrentCoinRate] = useState();
  const [currentCurrencyRate, setCurrentCurrencyRate] = useState();

  const fetchAllCryptoPrices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_REST_URL}/api/get_crypto_price`
      );
      const data = await response.json();
      const priceList = data.data.rateList;

      const currentRate = priceList.find((item) => item.currency === newCoin);
      setCurrentCoinRate(currentRate?.rate);

      return priceList;
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchAllCurrencyRates = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_REST_URL}/api/get_fiat_price`
      );
      const data = await response.json();
      const rateList = data.data.rateList;
      return rateList;
    } catch (error) {
      throw new Error("");
    }
  };
  const getCurrencyRate = async (currency) => {
    const allRates = await fetchAllCurrencyRates();
    const rateData = allRates?.find((rate) => rate.currency === currency);
    setCurrentCurrencyRate(rateData.rate);

    if (rateData) {
      return rateData.rate;
    } else {
      return null;
    }
  };
  const convertPricesToCurrency = async (prices, currency) => {
    const rate = await getCurrencyRate(currency);
    if (rate) {
      return prices?.map((price) => price * rate);
    } else {
      return prices;
    }
  };

  useEffect(() => {
    fetchAllCryptoPrices();

    if (
      selectedFiatEstimation?.name &&
      latestPrices?.length > 0 &&
      selectedFiatEstimation?.name !== "USD"
    ) {
      const fetchConvertedPrices = async () => {
        try {
          const newConvertedPrices = await convertPricesToCurrency(
            latestPrices,
            selectedFiatEstimation?.name
          );
          setConvertedPrices(newConvertedPrices || []);
          const newAverage =
            newConvertedPrices?.reduce((acc, price) => acc + price, 0) /
              newConvertedPrices?.length || 0;
          setAverage(currentCoinRate * currentCurrencyRate);
        } catch (error) {
          throw new Error("");
        }
      };

      fetchConvertedPrices();
    } else if (selectedFiatEstimation?.name === "USD") {
      const average =
        latestPrices.reduce((acc, price) => acc + price, 0) /
        latestPrices.length;
      //setAverage(average)
      setAverage(currentCoinRate * 1);
    }
  }, [selectedFiatEstimation?.name, latestPrices]);

  const filterTableData = (pair) => {
    if (!MKData || !pair) return [];

    let filteredArray = MKData.filter((item) => item.quote === pair);

    /*
    if (searchWord) {
        const tmpSearchWord = searchWord.toUpperCase();
        filteredArray = filteredArray.filter(item => 
            item.base.includes(tmpSearchWord) || item.quote.includes(tmpSearchWord)
        );
    } */

    filteredArray.sort((a, b) => b.price - a.price);
    setTableShowData(filteredArray);

    filteredArray.sort((a, b) => b.base_volume - a.base_volume);
    setVolumeData(filteredArray.slice(0, 3));
    filteredArray.sort((a, b) => b.change_in_price - a.change_in_price);

    setIncreasingData(
      filteredArray.filter((item) => item.change_in_price > 0).slice(0, 5)
    );

    setDecreasingData(filteredArray.slice(-3));
  };

  /*
const searchTableData = (pair) => {
  if (!tableData || !pair) return [];

  let filteredArray = tableData.filter(item => item.quote === pair);
  
  if (searchWord) {
    alert("123")
      const tmpSearchWord = searchWord.toUpperCase();
      filteredArray = filteredArray.filter(
        (item) =>
          item.base.includes(tmpSearchWord) ||
          item.quote.includes(tmpSearchWord)
      );
      filteredArray.sort((a, b) => b.price - a.price);
      setTableShowData(filteredArray);
  } else {
    setTableShowData(tableData.filter(item => item.quote === pair))
  }
};*/

  /*
useEffect(() => {
  searchTableData(selectedPair);
}, [searchWord, tableShowData]);
*/

  /*
useEffect(() => {
  searchTableData(selectedPair);
}, [searchWord]);  */

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!isSearching) {
      if (selectedPair) {
        filterTableData(selectedPair);
      } else {
        filterTableData(defaultPair);
      }
    }
  }, [selectedPair, defaultPair, MKData, isSearching]);

  const handleItemClick = (item) => {
    setSelectedPair(item);
    SETMARKET(item);
  };

  /*
  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

      if (newKeyword) {
        let tmpSearchWord = newKeyword.toUpperCase();
        let filteredShowArray = tableShowData.filter(item => 
          item.base.includes(tmpSearchWord) || item.quote.includes(tmpSearchWord));
        setTableShowData(filteredShowArray);      
      } 
  }  */

  const coin = coinsData?.find(
    (item) => item.base === newCoin && item.quote === activeMarket
  );

  const getBalance = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const reqBody = { currency: "ALL" };

    const response = await axios
      .post(`${process.env.REACT_APP_API_REST_URL}/api/GetBalance`, reqBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        throw new Error("");
      });

    return response;
  };

  const [userBalanceArray, setUserBalanceArray] = useState();

  const getUserBalance = async () => {
    let userBalance = await getBalance();
    if (userBalance) {
      const userBalanceArr = userBalance.data?.data;
      setUserBalanceArray(userBalanceArr);
    }
  };

  const [tradeCompleted, setTradeCompleted] = useState(false);

  useEffect(() => {
    getUserBalance();
  }, [tradeCompleted]);

  // example: USDT
  const pairBalance = userBalanceArray?.find(
    (item) => item.currency === activeMarket
  );
  // example: DOGE
  const coinBalance = userBalanceArray?.find(
    (item) => item.currency === coin?.base
  );

  const symbol = `${newCoin}/${activeMarket}`;
  const tvWidgetRef = useRef(null);
  const [isChartLoading, setIsChartLoading] = useState(false);

  useEffect(() => {
    // Set loading to true when effect is triggered
    setIsChartLoading(true);

    // Clean up any previous widget if it exists
    if (tvWidgetRef.current !== null) {
      tvWidgetRef.current.remove();
    }

    // Set up the widget options
    const widgetOptions = {
      symbol: symbol,
      // symbol: "BTC/USDT",
      // symbol: 'AAPL',
      interval: "60", // adjust the interval as needed
      // interval: '1D',
      container: "tv_chart_container",
      debug: false,
      //  datafeed: dataFeed,
      datafeed: datafeed,
      //   datafeed: new window.Datafeeds.UDFCompatibleDatafeed("https://demo-feed-data.tradingview.com"),
      library_path: "/charting_library/charting_library/",
      locale: selectedLanguage?.code,
      enabled_features: [],
      disabled_features: ["use_localstorage_for_settings"],
      charts_storage_url: "http://saveload.tradingview.com",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      theme: selectedTheme?.name,
    };

    // Create the TradingView widget only if it hasn't been created yet
    if (!tvWidgetRef.current) {
      const tvWidget = new window.TradingView.widget(widgetOptions);

      // Log to check if onChartReady is being called
      tvWidget.onChartReady(() => {
        setIsChartLoading(false); // Set loading to false when chart is ready
      });

      tvWidgetRef.current = tvWidget;
    }

    // Ensure loading is false even if there's an error
    const timeout = setTimeout(() => {
      if (isChartLoading) {
        setIsChartLoading(false); // Force loading to false if timeout is reached
      }
    }, 10000); // Timeout after 10 seconds (adjust as needed)

    // Cleanup function to remove the widget when the component is unmounted or dependencies change
    return () => {
      clearTimeout(timeout); // Clear the timeout on cleanup
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  }, [newCoin, activeMarket, selectedTheme, selectedLanguage]);

  const [activeViewMode, setActiveViewMode] = useState("chart");

  const getDepthChartData = (orderBook, type) => {
    let newOrderBook = orderBook.map((item) => ({
      price: parseFloat(item.price) || 0,
      volume: parseFloat(item.size) || 0,
    }));

    let sortedOrderBook = _.orderBy(
      newOrderBook,
      "price",
      type === "ask" ? "asc" : "desc"
    );

    let cumulativeVolume = 0;

    sortedOrderBook = sortedOrderBook.map((singleItem, i) => {
      cumulativeVolume += singleItem.volume;

      return {
        ...singleItem,
        [`${type}TotalVolume`]: cumulativeVolume,
      };
    });

    return sortedOrderBook;
  };

  const [depthChartData, setDepthChartData] = useState([]);
  const tradingPair = { baseCurrency: newCoin, quoteCurrency: activeMarket };

  const [orderBookAsk, setOrderBookAsk] = useState([]);
  const [orderBookBid, setOrderBookBid] = useState([]);

  useEffect(() => {
    updateDepthChart();
  }, [calculatedAsks, calculatedBids]);

  const fetchOrderBookData = async () => {};

  const updateDepthChart = () => {
    const depthChartAsk = getDepthChartData(calculatedAsks, "ask");
    const depthChartBid = getDepthChartData(calculatedBids, "bid");

    setDepthChartData(_.sortBy([...depthChartBid, ...depthChartAsk], "price"));
  };

  useEffect(() => {
    if (coin?.price) {
      dispatch(setLatestTradePrice(coin.price));
    }
  }, [coin, primaryCoin, dispatch]);

  return (
    <>
      <Header
        coin={coin}
        primaryCoin={primaryCoin}
        dropdownVisible={dropdownVisible}
        setDropdownVisible={setDropdownVisible}
        pairs={pairs}
        selectedPair={selectedPair}
        handleItemClick={handleItemClick}
        tableShowData={tableShowData}
        searchWord={searchWord}
        MARKET={MARKET}
        SETMARKET={SETMARKET}
      />
      <AnnounceBar />

      <CoinSlider data={sliderData} />

      <TradeHeader
        latestTradeType={latestTradeType}
        setActiveMarket={setActiveMarket}
        coinsData={coinsData}
        setCoinsData={setCoinsData}
        coin={coin}
        primaryCoin={primaryCoin}
        dropdownVisible={dropdownVisible}
        setDropdownVisible={setDropdownVisible}
        pairs={pairs}
        selectedPair={selectedPair}
        handleItemClick={handleItemClick}
        tableShowData={tableShowData}
        setTableShowData={setTableShowData}
        searchWord={searchWord}
        setSearchWord={setSearchWord}
        increasingData={increasingData}
        isSearching={isSearching}
        setIsSearching={setIsSearching}
      />

      <div className={dropdownVisible ? "row blured" : "row"}>
        <div className="col-lg-8 pe-lg-0">
          <div className="d-flex gap-2 trading-switcher-wrapper">
            <button
              className={activeViewMode === "chart" ? "active" : ""}
              onClick={() => setActiveViewMode("chart")}
            >
              {labels?.exchange?.chart}
            </button>
            <button
              className={activeViewMode === "depth" ? "active" : ""}
              onClick={() => setActiveViewMode("depth")}
            >
              {labels?.exchange?.depthChart?.title}
            </button>
          </div>

          <div
            id="tv_chart_container"
            style={{
              display:
                isChartLoading || activeViewMode === "depth" ? "none" : "block",
            }}
          />

          <div
            id="depth-chart"
            style={{ display: activeViewMode === "chart" ? "none" : "block" }}
          >
            <DepthChart
              tradingPair={tradingPair}
              depthChartData={depthChartData}
            />
          </div>
        </div>

        <div className="col-lg-2 border-start border-end custom-border-color border-secondary px-lg-0">
          <div className="py-3 px-0 order-book-container">
            <OrderBook
              asks={calculatedAsks}
              bids={calculatedBids}
              coin={coin}
              primaryCoin={primaryCoin}
              latestTradeType={latestTradeType}
              latestTradePrice={latestTradePrice}
              averageOfLatestPrices={average}
            />
            <RecentTrades
              coinsData={coinsData}
              coin={coin}
              primaryCoin={primaryCoin}
              MARKET={MARKET}
              activeMarket={activeMarket}
            />
          </div>
        </div>
        <div className="col-lg-2 ps-lg-0">
          <BuySellOrder
            tradeCompleted={tradeCompleted}
            setTradeCompleted={setTradeCompleted}
            market={MARKET}
            activeMarket={activeMarket}
            coin={newCoin}
            coinPrice={coin?.price}
            pairBalance={pairBalance?.balance}
            coinBalance={coinBalance?.balance}
          />
        </div>
      </div>

      <div className={dropdownVisible ? "row blured" : "row"}>
        <div className="col-lg-10 col-md-12 col-12 px-lg-0">
          <Portfolio
            tradeCompleted={tradeCompleted}
            setTradeCompleted={setTradeCompleted}
            coin={coin}
          />
        </div>
      </div>

      <div className={dropdownVisible ? "row blured" : "row"}>
        <Footer />
      </div>
    </>
  );
}

export default Trade;
