import axios from 'axios';
import moment from 'moment';
import qs from 'qs';


const backendUrl = process.env.REACT_APP_API_REST_URL;

const history = {};

const resolutions = {
  '1': '1',
  '5': '5',
  '15': '15',
  '30': '15',
  '60': '60',
  '240': '240',
  '1D': '1440',
  D: '10080',
};

const historyProvider = {

  history: history,

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback,
  ) => {
    try {
      const { from, to, firstDataRequest } = periodParams;

      /*
      console.log('[getBars]: Method call', {
        symbolInfo,
        firstDataRequest,
        resolution,
        from,
        to,
      }); */

      const limit = 1000;
      const split_symbol = symbolInfo.name.split(/[:/]/);
      const queryString = qs.stringify({
        baseCurrency: split_symbol[0],
        quoteCurrency: split_symbol[1],
        timestamp: firstDataRequest
          ? moment
              .unix(to)
              //.subtract(1, 'm')
              .valueOf()
          : to * 1000,
        interval: resolutions[resolution],
        limit: limit ? limit : 1000,
      });

      const { data } = await axios({
        url: `${backendUrl}/market/get-chart-data?${queryString}`,
        method: 'GET',
      });

     // console.log({data})

      if (data.status === 'Error' || data.data.length === 0) {
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

    
        let bars = data.data.map(bar => {

          delete bar.ID;
  
          return {
            ...bar,
            time: moment(bar.time).valueOf(),
          };
        });
  
        if (firstDataRequest) {
          const lastBar = bars[0];
          history[symbolInfo.name] = { lastBar };
        }

        bars = bars.reverse();

     // console.log(`[getBars]: returned ${bars.length} bar(s)`);

      onHistoryCallback(bars, {
        noData: false,
      });

    } catch (error) {
  //    console.log('[getBars]: Get error', error);
   //   onErrorCallback(error);
   throw new Error("");
    }
  },
};

export default historyProvider;
