import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./Features/Session/SessionSlice";
import labelsReducer from "./Features/Labels/LabelsSlice";
import currencySettingsReducer from "./Features/CurrencySettings/CurrencySettingsSlice";
import walletReducer from "./Features/Wallet/WalletSlice";
import exchangeReducer from "./Features/Exchange/ExchangeSlice";
import marketReducer from "./Features/Market/marketSlice";
import orderReducer from "./Features/Order/orderSlice";
import tradeReducer from "./Features/Trade/tradeSlice";

const store = configureStore({
  reducer: {
    session: sessionReducer,
    labels: labelsReducer,
    currencySettings: currencySettingsReducer,
    wallet: walletReducer,
    exchange: exchangeReducer,
    market: marketReducer,
    order: orderReducer,
    trade: tradeReducer,
  },
});

export default store;
