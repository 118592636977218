import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { CoinContext } from "./CoinContext";

export const WebSocketContext = createContext();

let wsInstance = null;

export const WebSocketProvider = ({ children }) => {
  const token = localStorage.getItem("token");

  const ws = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { primaryCoin, newCoin, activeMarket } = useContext(CoinContext);

  const [socketVars, setSocketVars] = useState(null);

  const getSocketVariables = (coin, primaryCoin, activeMarket) => {
    return {
      SocketVariableOb: `OB.${coin}_${activeMarket}`,
      SocketVariableRT: `RT.${coin}_${activeMarket}`,
      // SocketVariablePO: `PO.${coin}_${activeMarket}`,
    };
  };

  // Global
  const [asks, setAsks] = useState([]);
  const [bids, setBids] = useState([]);
  const [trades, setTrades] = useState([]);
  const [latestTradePrice, setLatestTradePrice] = useState(null);
  const [latestTradeType, setLatestTradeType] = useState(null);
  const [latestPrices, setLatestPrices] = useState(null);
  const [openOrders, setOpenOrders] = useState([]); // for single coin
  const [userOpenOrders, setUserOpenOrders] = useState([]); // general
  const [MKData, setMKData] = useState(null); // coin list
  const [coinsData, setCoinsData] = useState();
  const [pairs, setPairs] = useState();
  const [selectedPair, setSelectedPair] = useState();
  const [volumeData, setVolumeData] = useState([
    {
      id: 1,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 2,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 3,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
  ]);
  const [increasingData, setIncreasingData] = useState([
    {
      id: 1,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 2,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 3,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
  ]);
  const [decreasingData, setDecreasingData] = useState([
    {
      id: 1,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 2,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
    {
      id: 3,
      change_in_price: 0,
      price: 0,
      base: "Loading",
      quote: "....",
    },
  ]);

  useEffect(() => {
    if (newCoin && activeMarket) {
      const socketVariables = getSocketVariables(
        newCoin,
        primaryCoin,
        activeMarket
      );
      setSocketVars(socketVariables);
    }
  }, [newCoin, activeMarket]);

  const subscribeToEvents = () => {
    if (!socketVars) return; // socketVars null ise, fonksiyon çalışmasın

    ws.current.send(
      JSON.stringify({
        method: "subscribe",
        events: [
          socketVars.SocketVariableOb,
          socketVars.SocketVariableRT,
          // socketVars.SocketVariablePO,
          "MK",
        ],
      })
    );

    ws.current.onmessage = (e) => {
      const data = JSON.parse(e.data);

      if (data.method === "login" && data.status === "success") {
        setIsLoggedIn(true);
        subscribeToPO();
      }

      if (data?.event === socketVars.SocketVariableOb) {
        if (data?.data) {
          const { asks: incomingAsks, bids: incomingBids } = data.data;
          setAsks(incomingAsks || []);
          setBids(incomingBids || []);
        }
      } else if (data?.event === socketVars.SocketVariableRT) {
        if (data?.data && data?.data[0]) {
          setTrades([...data.data]);
          setLatestTradePrice(data.data[0].rate);
          setLatestTradeType(data.data[0].execution_side);
          const latest = data.data.slice(0, 10).map((trade) => trade.rate);
          setLatestPrices(latest);
        }
      } else if (data?.event === socketVars.SocketVariablePO) {
        if (data?.data) {
          let openOrderList = [...openOrders];
          data.data.forEach((item) => {
            const index = openOrderList.findIndex(
              (oldOrder) => oldOrder.order_id === item.order_id
            );
            if (item?.status === false) {
              if (index === -1) openOrderList.push(item);
              else openOrderList[index] = item;
            } else {
              if (index > -1) openOrderList.splice(index, 1);
            }
          });
          setOpenOrders(
            openOrderList
              .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
              .filter((item) => item?.base === "BTC") // Örnek
          );
        } else {
          setOpenOrders([]);
        }
      } else if (data?.event === "MK") {
        setMKData(data?.data);

        let dataArray = data?.data;
        setCoinsData(dataArray);
        const uniqueQuotes = [...new Set(dataArray.map((item) => item.quote))];
        setPairs(uniqueQuotes);
        dataArray.sort((a, b) => b.base_volume - a.base_volume);
        setVolumeData(dataArray.slice(0, 3));

        const topThreeHighPositive = dataArray
          .filter((item) => item.change_in_price > 0)
          .slice(0, 3);

        setIncreasingData(topThreeHighPositive);

        const threeLowest = dataArray.slice(-3);
        setDecreasingData(threeLowest);
      } else if (data?.event === "PO.ALL") {
        let receivedOrder = data.data;
        if (!Array.isArray(receivedOrder)) {
          receivedOrder = [receivedOrder];
        }
        setUserOpenOrders((prevOrders) => {
          let updatedOrders = [...prevOrders];

          receivedOrder.forEach((order) => {
            const existingIndex = updatedOrders.findIndex(
              (item) => item.order_id === order.order_id
            );
            if (existingIndex !== -1) {
              updatedOrders.splice(existingIndex, 1);
            }
            updatedOrders.push(order);
          });

          return updatedOrders.filter((order) => order?.status === false);
        });
      }
    };
  };

  const subscribeToPO = () => {
    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          method: "subscribe",
          events: ["PO.ALL"],
        })
      );
    }
  };

  useEffect(() => {
    if (socketVars) {
      if (!wsInstance) {
        wsInstance = new WebSocket(process.env.REACT_APP_API_WS_URL);
      }
      ws.current = wsInstance;

      ws.current.onopen = () => {
        setIsConnected(true);
        ws.current.send(
          JSON.stringify({
            method: "login",
            token: `Bearer ${token}`,
          })
        );
        subscribeToEvents();
      };

      ws.current.onclose = () => {
        setIsConnected(false);
        setIsLoggedIn(false);
        wsInstance = null;
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket Error is:", error);
      };

      return () => {
        ws.current?.close();
        wsInstance = null;
      };
    }
  }, [socketVars]);

  useEffect(() => {
    if (isConnected && isLoggedIn) {
      subscribeToPO();
    }
  }, [isConnected, isLoggedIn]);

  return (
    <WebSocketContext.Provider
      value={{
        isConnected,
        asks,
        bids,
        trades,
        latestTradePrice,
        latestTradeType,
        latestPrices,
        openOrders,
        MKData,
        volumeData,
        increasingData,
        decreasingData,
        coinsData,
        pairs,
        selectedPair,
        setSelectedPair,
        userOpenOrders,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
