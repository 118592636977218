import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

function DepositTransferDetails({changeParam1}) {
  const labels = useSelector((state) => state.labels.data);

  const navigate = useNavigate();

  let token = localStorage.getItem("token");
  let depositMethod = useSelector((state) => state.wallet.selectDepositMethod);
  let depositCurrency = useSelector(
    (state) => state.wallet.selectDepositCurrency
  );

  const [bankDetails, setBankDetails] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);

  const [bankId, setBankId] = useState('');
  const [comment, setComment] = useState('');
  const [requestAmount, setRequestAmount] = useState('');
  const [transactionId, setTransactionId] = useState('');

  const [requestCardAmount, setRequestCardAmount] = useState('');
  const [totalChargeAmount, setTotalChargeAmount] = useState('');

  const [isRequestAmountValid, setIsRequestAmountValid] = useState(false);
  const [isTransactionIdValid, setIsTransactionIdValid] = useState(false);
  const [hasRequestAmountBlurred, setHasRequestAmountBlurred] = useState(false);
  const [hasTransactionIdBlurred, setHasTransactionIdBlurred] = useState(false);

  const [isRequestCardAmountValid, setIsRequestCardAmountValid] = useState(false);
  const [hasRequestCardAmountBlurred, setHasRequestCardAmountBlurred] = useState(false);

  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files[0].name); // Set the uploaded file's name
    }
  };

  useEffect(() => {
    setIsRequestAmountValid(requestAmount !== "");
  }, [requestAmount]);

  useEffect(() => {
    const isValidFormat = /^[a-zA-Z0-9]+$/.test(transactionId);
    
    setIsTransactionIdValid(transactionId !== "" && isValidFormat && transactionId.length >= 6);
  }, [transactionId]);

  useEffect(() => {
    setIsRequestCardAmountValid(requestCardAmount !== "");
  }, [requestCardAmount]);

   
  const requestAmountFunction = (tmpRequestAmount) => {
    // Accept only numeric characters
    const numericInput = tmpRequestAmount.replace(/\D/g, "");
    setRequestAmount(numericInput);
  }

  const requestCardAmountFunction = (tmpRequestCardAmount) => {
    setRequestCardAmount(tmpRequestCardAmount);
    let tmpTotalChargeAmount=0;
    tmpTotalChargeAmount=parseFloat(tmpRequestCardAmount)+1;
    if (isNaN(tmpTotalChargeAmount)) {
        tmpTotalChargeAmount = 0;
    }
    setTotalChargeAmount(tmpTotalChargeAmount);
  }

  const doCardTransferDetail = async (e) => {

    e.preventDefault();

    let reqBody = {
      amount:requestCardAmount,
      comment: "",
      currency:depositCurrency,
      fee:1
  }

  if (!isRequestCardAmountValid) {
    setHasRequestCardAmountBlurred(true)
  } else {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/Add_Fiat_PG_Deposit_Request`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
           
          if (response?.data?.status=="Success") {

            // toast.success(response?.data?.message, {
            //   position: "top-right",
            //   autoClose: 2500,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            // });

            if (response?.data?.data?.redirectURL) {
                let newUrl=response?.data?.data?.redirectURL;
                window.open(newUrl, '_blank');
            }
          }
          else {
            const message = labels?.messages?.Exception_General;
            toast.error(message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

        })
        .catch((error) => {
          const message = labels?.messages?.Exception_General;
          toast.error(message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } catch (error) {
      const message = labels?.messages?.Exception_General;
      toast.error(message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    changeParam1(Math.random());
    return true;
  }

  }

  const doBankTransferDetail = async (e) => {

    e.preventDefault();
  
    let reqBody = {
        BankID:bankId,
        Comments: comment,
        RequestAmount:requestAmount,
        TransactionID:transactionId
    }

    if (!isRequestAmountValid || !isTransactionIdValid) {
      setHasRequestAmountBlurred(true)
      setHasTransactionIdBlurred(true)
    } else {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_API_REST_URL}/api/Add_Fiat_Manual_Deposit_Request`,
            reqBody,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response?.data?.status=="Success") {
              const message = labels?.messages?.Success_General;
              toast.success(message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            else {
              const message = labels?.messages?.Exception_General;
              toast.error(message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
  
          })
          .catch((error) => {
            const message = labels?.messages?.Exception_General;
            toast.error(message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      } catch (error) {
        const message = labels?.messages?.Exception_General;
        toast.error(message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      changeParam1(Math.random());
      return true;
    }
 

  }

  const getBankTransferDetail = async () => {
    try {
      const mRes = await axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/List_Fiat_BanksList/${depositCurrency}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response?.data?.data) {
            setBankDetails(response?.data?.data[0]);
            setBankId(response?.data?.data[0].id)
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
    return true;
  }

  const getCardTransferDetail = async () => {
    try {
      const mRes = await axios
        .get(
          `${process.env.REACT_APP_API_REST_URL}/api/Get_Fiat_PGs?Currency=${depositCurrency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // ..
          if (response?.data?.data) {
            setCardDetails(response?.data?.data[0]);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
    return true;
  };

  const getBankTransferDetailHtml = () => {
    return (
      <>
        <div className="row content-text" style={{ paddingTop: "25px" }}>
          <div className="col-md-6">
            <h2>{labels?.forms?.fiatDeposit?.notice?.title}</h2>
            <hr />
            <p>{labels?.forms?.fiatDeposit?.notice?.note}</p>
            <h2>{labels?.wallet?.deposits?.bankDetails}</h2>
            <p>
              <b>{labels?.forms?.fiatWithdrawalManual?.bankName?.placeholder}</b>
              <br />
              {bankDetails?.bankName}
              <br />
              <br />
              <b>{labels?.forms?.fiatWithdrawalManual?.beneficiaryName?.placeholder}</b>
              <br />
              {bankDetails?.beneficiaryName}
              <br />
              <br />
              <b>{labels?.forms?.fiatWithdrawalManual?.routingcode}</b>
              <br />
              {bankDetails?.bankRoutingCode}
              <br />
              <br />
              <b>{labels?.forms?.fiatWithdrawalManual?.accountnumber}</b>
              <br />
              {bankDetails?.accountNumber}
              <br />
              <br />
              <b>{labels?.forms?.fiatWithdrawalManual?.swiftcode}</b>
              <br />
              {bankDetails?.swiftCode}
              <br />
              <br />
              <b>{labels?.wallet?.deposits?.bankLocation}</b>
              <br />
              {bankDetails?.branchCity} {bankDetails?.branchCountry}
              <br />
              <br />
            </p>
          </div>
          <div className="col-md-6">
            <h2>{labels?.wallet?.deposits?.formTitle}</h2>
            <hr />
            <p>
            {labels?.forms?.fiatDeposit?.formNotice}
            </p>
            <form onSubmit={(e) => doBankTransferDetail(e)}>
            <h2>{labels?.forms?.fiatDeposit?.bank}</h2>
            <div className="form-group">
              <label htmlFor="requestAmount">{labels?.forms?.fiatDeposit?.requestAmount.label}</label>
              <input onBlur={() => setHasRequestAmountBlurred(true)} className={`textbox form-control mt-2 ${isRequestAmountValid ? 'is-valid' : (hasRequestAmountBlurred && 'is-invalid')}`} id="requestAmount" placeholder={labels?.forms?.fiatDeposit?.requestAmount.label} value={requestAmount} onChange={(e) => requestAmountFunction(e.target.value)} />
              <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>
            </div>
            <div className="form-group mt-2">
              <label htmlFor="bankId">{labels?.forms?.fiatDeposit?.transactionId.label}</label>
              <input onBlur={() => setHasTransactionIdBlurred(true)} className={`textbox form-control mt-2 ${isTransactionIdValid ? 'is-valid' : (hasTransactionIdBlurred && 'is-invalid')}`} id="bankId" value={transactionId} placeholder={labels?.forms?.fiatDeposit?.transactionId.label} onChange={(e) => setTransactionId(e.target.value)}/>
              <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>
            </div>
       
            <br />
            {labels?.forms?.fiatDeposit?.comments.label}
            <input className="textbox form-control" value={comment} onChange={(e) => setComment(e.target.value)}/>
            <br />




            {labels?.forms?.fiatDeposit?.proof.label}
            <div className="custom-file-upload">
      <label htmlFor="file-upload" className="custom-file-label">
        {labels?.forms?.common?.browse}
      </label>
      <input
        id="file-upload"
        type="file"
        className="textbox form-control"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <input
        type="text"
        className="textbox form-control"
        value={fileName}
        readOnly
        placeholder={labels?.forms?.common?.noFile}
        style={{ marginLeft: '10px' }}
      />
    </div>
            <br />
            <button 
                className="btn button-danger" 
                type="submit"
                style={{ width: "100%" }}
                >
              {labels?.buttons?.submit}
            </button>
            </form>
          </div>
        </div>
      </>
    );
  };

  const getCardTransferDetaillHtml = () => {
    return (
      <>
        <div className="row content-text" style={{ paddingTop: "25px" }}>
          <div className="col-md-12">
            <h2>{labels?.wallet?.deposits?.formTitle}</h2>
            <br />

            <div className="row">
              <div className="col-md-2">{labels?.forms?.fiatDepositPg?.minDeposit?.label}:&nbsp;{cardDetails?.minTxnAmount}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">{labels?.forms?.fiatDepositPg?.maxDeposit?.label}:&nbsp;{cardDetails?.maxTxnAmount}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">{labels?.forms?.fiatDepositPg?.percentFee?.label}:&nbsp;{cardDetails?.fee_In_Percent}%</div>
              <div className="col-md-2">{labels?.forms?.fiatDepositPg?.minFee?.label}:&nbsp;{cardDetails?.minFee}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
              <div className="col-md-2">{labels?.forms?.fiatDepositPg?.maxFee?.label}:&nbsp;{cardDetails?.maxFee}&nbsp;{depositCurrency ? depositCurrency : ""}</div>
            </div>
            
            <form onSubmit={(e) => doCardTransferDetail(e)}>
            <div className="row" style={{paddingTop:'25px'}}>
              <div className="col-md-12">

              <div className="form-group">
              <label htmlFor="depositAmount">{labels?.forms?.fiatDepositPg?.minDeposit?.label}</label>
              <input onBlur={() => setHasRequestCardAmountBlurred(true)} className={`textbox form-control mt-2 ${isRequestCardAmountValid ? 'is-valid' : (hasRequestCardAmountBlurred && 'is-invalid')}`} id="depositAmount" placeholder={labels?.forms?.fiatDepositPg?.minDeposit?.label}
              value={requestCardAmount} 
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  requestCardAmountFunction(value);
                }
              }}
              />
              <div className="invalid-feedback">{labels?.forms?.validations?.required}</div>
            </div>

<b>{labels?.forms?.fiatDepositPg?.fee?.label}</b>
<br />
1 {depositCurrency}
<br /><br />

<b>{labels?.forms?.fiatDepositPg?.charge?.label}</b>
<br />
{totalChargeAmount} {depositCurrency}
<br /><br />

<b>{labels?.forms?.fiatDepositPg?.disclaimer?.title}</b>
<br />
{labels?.forms?.fiatDepositPg?.disclaimer?.text}<br /><br />
            <button 
                className="btn button-danger" 
                type="submit"
                style={{ width: "100%" }}
                >
              {labels?.buttons?.submit}
            </button>


              </div>
            </div>

            </form>

            
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (depositMethod == "bankTransfer") {
      getBankTransferDetail();
    }
    if (depositMethod == "card") {
      getCardTransferDetail();
    }
  }, [depositMethod, depositCurrency]);

  if (depositMethod && depositMethod == "bankTransfer") {
    return getBankTransferDetailHtml();
  }

  if (depositMethod && depositMethod == "card") {
    return getCardTransferDetaillHtml();
  }
}

export default DepositTransferDetails;
