import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ResetPassword.css";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer/";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function ResetPassword() {
  const labels = useSelector((state) => state.labels.data);
  const { otp } = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const doResetPassword = async () => {
    if (password != passwordAgain) {
      toast.error("Passwords doesn't match", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    try {
      let reqBody = {
        email: email,
        newPassword: password,
        otp: otp,
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_REST_URL}/api/ResetPassword`,
          reqBody,
          {}
        )
        .then((response) => {
          if (response?.data?.status == "Success") {
            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate("/");
          } else {
            toast.error(response?.data?.data, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        })
        .catch((error) => {
          toast.error("Error Occured", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.error("Error:", error);
        });
    } catch (error) {
      toast.error("Error Occured", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />

      {/* Login */}
      <section className="login">
        <div className="container">
          <div className="row row-reset justify-content-center">
            <div className="col-lg-4">
              <div className="picture">
                <img
                  className="bg"
                  src="/images/materials/signup_banner.png"
                  alt=""
                />
                <img className="logo" src="/images/logo.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <form>
                <h3>{labels?.forms?.resetPassword?.title}</h3>
                <div className="input-container">
                  <label htmlFor="your-email">
                    {labels?.forms?.resetPassword?.email?.placeholder}
                  </label>
                  <input
                    type="text"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="password">
                    {labels?.forms?.resetPassword?.newPassword?.placeholder}
                  </label>
                  <input
                    type="password"
                    placeholder="••••••••••"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="password-confirm">
                    {
                      labels?.forms?.resetPassword?.newPasswordConfirm
                        ?.placeholder
                    }
                  </label>
                  <input
                    type="password"
                    placeholder="••••••••••"
                    onChange={(e) => setPasswordAgain(e.target.value)}
                  />
                </div>

                <input
                  type="button"
                  value={labels?.forms?.resetPassword?.title}
                  className="button-style-01"
                  onClick={() => doResetPassword()}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Login End */}

      <Footer />
    </>
  );
}

export default ResetPassword;
