// api.js
import axios from 'axios';
import WebSocket from 'websocket';
import React, { useEffect, useState, useRef } from "react";

export const fetchTransactionData = async () => {
    let serviceResponse = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/TradeHistory?side=ALL&pair=BTC-USDT&page=1&count=20`, {
      headers: {                                                                 
        'Authorization': `Bearer ${localStorage.getItem("token")}` // Bearer token gönderimi
      }
    }).catch((error) => {
      console.error("Error", error);
      return false;
    });
    return serviceResponse?.data?.data?.rows;
}

export const fetchMarketData = async () => {
    // Using axios to make the service call.
    const response = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_crypto_price`);
    return response?.data?.data?.rateList;
}

export const fetchFiatData = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_fiat_price`);
  return response?.data?.data?.rateList;
}

export const getCurrencySettings = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/CurrencySettings`);
  return response?.data?.data;
}

export const fetchMarketMergedInstaData = async () => {

      // Using axios to make the service call.
      let response1 = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_crypto_price`);
      let response1Arr = response1?.data?.data?.rateList

      // Using axios to make the service call.
      let responseInsta = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_insta_pairs`);
      let responseInstaArr = responseInsta?.data?.data
 

            // Function to update response1Arr based on conditions
            const updateResponse1Arr = (response1Arr, responseInstaArr) => {
              return response1Arr.map((item) => {
                const matchingInstaItems = responseInstaArr.filter(
                  (instaItem) =>
                    instaItem.baseCurrency === item.currency && instaItem.quoteCurrency === 'USDT'
                );

                // If there are matching items, choose the one with the highest rate
                if (matchingInstaItems.length > 0) {
                  const highestRateItem = matchingInstaItems.reduce((prev, current) =>
                    prev.rate > current.rate ? prev : current
                  );
                  return { ...item, rate: highestRateItem.rate };
                }

                // If no matching items, return the original item
                return item;
              });
            };

            // Call the function to get the updated array
            const updatedResponse1Arr = updateResponse1Arr(response1Arr, responseInstaArr);
 
  let response2 = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_coin_stats`);
  response2 = response2?.data?.data

  const response2Arr = Object.entries(response2).map(([key, value]) => {
      return { key, value };
  });

    const mergedArray = updatedResponse1Arr.map((item1) => {
      const matchingItem2 = response2Arr.find((item2) => item2.key.toLowerCase() === item1.currency.toLowerCase());
  
      if (matchingItem2) {
        return {
          ...item1,
          priceChangePercent1h: matchingItem2.value.priceChangePercent1h,
          priceChangePercent7d: matchingItem2.value.priceChangePercent7d,
          volume24h:matchingItem2.value.volume24h,
          slug:matchingItem2.value.slug
        };
      } 
      return item1;
    }); 
    return mergedArray; 
}

export const fetchMarketMergedData = async () => {

    // Using axios to make the service call.
    let response1 = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/price`);
    let response1Arr = response1?.data?.data?.rateList
 
    let response2 = await axios.get(`${process.env.REACT_APP_API_REST_URL}/api/get_coin_stats`);
    response2 = response2?.data?.data
 
    const response2Arr = Object.entries(response2).map(([key, value]) => {
        return { key, value };
    });

      const mergedArray = response1Arr.map((item1) => {
        const matchingItem2 = response2Arr.find((item2) => item2.key.toLowerCase() === item1.currency.toLowerCase());
    
        if (matchingItem2) {
          return {
            ...item1,
            priceChangePercent1h: matchingItem2.value.priceChangePercent1h,
            priceChangePercent7d: matchingItem2.value.priceChangePercent7d,
            volume24h:matchingItem2.value.volume24h,
            slug:matchingItem2.value.slug
          };
        } 
        return item1;
      }); 
      return mergedArray; 
}
  

  export const convertCurrency = async (firstCurrency, secondCurrency, initialPrice, currentCryptoPrices) => {
    
    let currentCryptoPricesArr = JSON.stringify(currentCryptoPrices);
    let myArr = JSON.parse(currentCryptoPricesArr)
 
    // console.log(myArr);

    // Helper function to find the rate for a given currency
    function findRate(currency) {
        const currencyObj = myArr.find(item => item.currency === currency);
        return currencyObj ? currencyObj.rate : null;
    }

    // Find the rate of the first currency
    const firstCurrencyRate = findRate(firstCurrency);
    if (firstCurrencyRate === null) {
        // throw new Error(`Rate for ${firstCurrency} not found`);
        return 0;
    }

    // Convert the initial price to fiat (or a standard currency)
    const fiatPrice = initialPrice * firstCurrencyRate;

    // Find the rate of the second currency
    const secondCurrencyRate = findRate(secondCurrency);
    if (secondCurrencyRate === null) {
        // throw new Error(`Rate for ${secondCurrency} not found`);
        return 0;
    }
 
    if (secondCurrency==="USDT") {
      return fiatPrice;
    } else {
      return fiatPrice / secondCurrencyRate;
    }
 
}

 




 
