import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../Features/Session/SessionSlice";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  //const token = useSelector(selectUserToken);
  const [openOrders, setOpenOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const ws = useRef(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      return;
    }

    ws.current = new WebSocket(process.env.REACT_APP_API_WS_URL);

    ws.current.onopen = () => {
      // WebSocket üzerinden yetkilendirme isteği gönder
      ws.current.send(
        JSON.stringify({
          method: "login",
          token: `Bearer ${token}`,
        })
      );

      // Open orders (PO.ALL) olayına abone ol
      ws.current.send(
        JSON.stringify({
          method: "subscribe",
          events: ["PO.ALL"],
        })
      );
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message?.method === "stream" && message?.event === "PO.ALL") {
        let receivedOrder = message.data;

        if (!Array.isArray(receivedOrder)) {
          // Gelen veriyi dizi haline getir
          receivedOrder = [receivedOrder];
        }

        // Open orders durumunu güncelle
        setOpenOrders((prevOrders) => {
          let updatedOrders = [...prevOrders];

          receivedOrder.forEach((order) => {
            const existingIndex = updatedOrders.findIndex(
              (item) => item.order_id === order.order_id
            );

            if (existingIndex !== -1) {
              // Eğer mevcutsa, kaldır (silme işlemi)
              updatedOrders.splice(existingIndex, 1);
            } else if (order.pending !== 0) {
              updatedOrders.push(order);
            }
          });

          // pending === 0 olanları filtreleyerek güncellenmiş listeyi döndür
          return updatedOrders.filter((order) => order.pending !== 0);
        });

        setIsLoading(false);
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    // Cleanup: WebSocket bağlantısını kapat
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [token]);

  return (
    <OrderContext.Provider
      value={{
        openOrders,
        setOpenOrders,
        isLoading,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderContext);
};
