import React, { useState, useEffect, useRef, useMemo } from "react";
import { segregateAndSortTrades } from "../../../Common/Helpers.js";
import "./Trade.css";
import { useFetcher } from "react-router-dom";
import { formatNumberWithComma } from "../../../Common/Helpers";  
import { useModalContext } from "../../../Context/ModalContext.js";
import { useSelector } from "react-redux";


export const OrderBook = ({ asks, bids, coin, primaryCoin,
  latestTradeType,
  latestTradePrice,
  averageOfLatestPrices
 }) => {

  const { selectedNumberFormat, selectedFiatEstimation } = useModalContext();

  const labels = useSelector((state) => state?.labels?.data);

  const tbodyRef = useRef(null);


  function getFiatEstimation() {
    if (selectedFiatEstimation.name === "TRY") {
      return "₺"
    } else if (selectedFiatEstimation.name === "USD") {
      return "$"
    } else {
      return selectedFiatEstimation?.name
    }
  }

  const [isUserScrolling, setIsUserScrolling] = useState(false);
  let scrollTimeout = null;

  useEffect(() => {
    const handleScroll = () => {
      if (tbodyRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tbodyRef.current;
        setIsUserScrolling(scrollTop + clientHeight < scrollHeight - 10);

        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => setIsUserScrolling(false), 3000);
      }
    };

    const tbodyElement = tbodyRef.current;
    tbodyElement.addEventListener('scroll', handleScroll);

    return () => {
      tbodyElement.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) clearTimeout(scrollTimeout);
    };
  }, []);

  useEffect(() => {
    if (!isUserScrolling && tbodyRef.current) {
      tbodyRef.current.scrollTop = tbodyRef.current.scrollHeight;
    }
  }, [asks, isUserScrolling]);


  return (
    <div className="order-book">
      <div className="d-flex justify-content-between flex-col flex-lg-row flex-md-row px-2">
          <div className="d-flex justify-content-between w-100 align-items-center mb-1">
          <h2 className="order-book-title fs-5 mb-0 me-2">{labels?.exchange?.orderBook}</h2>
          <div className="d-flex gap-1">
            <div className="d-flex flex-column gap-1">
              <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
            <div className="d-flex flex-column gap-1">
            <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
            <div className="d-flex flex-column gap-1">
              <span className="book-indicator bg-success"></span>
              <span className="book-indicator bg-danger"></span>
            </div>
          </div>
          </div>
      </div>
      <div className="table-responsive table-fixed-width scrollbar-custom px-2 table-sell-orders">
      <table className="table table-sm table-order-book table-no-border mb-1">
        <thead>
          <tr className="small">
            <th className="text-muted px-0 small">{labels?.exchange?.price}</th>
              <th className="text-muted small">
              {labels?.exchange?.size}[
                {((coin?.base || primaryCoin?.base || "...").slice(0, 3))}
              ]  
              </th>
              <th className="text-muted small">
              {labels?.exchange?.total}[
                {((coin?.base || primaryCoin?.base || "...").slice(0, 3))}
              ] 
              </th>            
          </tr>
        </thead>
        <tbody className="asks-scrollable-content" ref={tbodyRef}>
        {asks?.map((ask, index) => (
          <tr key={index} className="border-0 small">
            <td className="text-danger border-0">{formatNumberWithComma(ask.price, selectedNumberFormat)}</td>
            <td className="border-0 text-lg-end">{formatNumberWithComma(ask.size, selectedNumberFormat)}</td>
            <td className="border-0 text-lg-end">{formatNumberWithComma(ask.total, selectedNumberFormat)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>

    <div className="table-responsive table-fixed-width">
      <table className="table table-sm table-order-book table-no-border mb-1">
      <tbody className="border-top border-bottom custom-border-color border-secondary table-border">
            <tr>
              <td className={latestTradeType === "BUY" ? "text-success text-end pe-2" : "text-danger text-end pe-2"}>
              {formatNumberWithComma(latestTradePrice, selectedNumberFormat)}
            </td>
            <td>
              {getFiatEstimation()} 
              &nbsp;

              {isNaN(averageOfLatestPrices) 
    ? 0 
    : formatNumberWithComma(averageOfLatestPrices, selectedNumberFormat)}
              </td>
            </tr>
        </tbody>
      </table>
    </div>

    <div className="table-responsive table-fixed-width px-2">
      <table className="table table-sm table-order-book table-no-border mb-1 table-buy-orders">
        <tbody>
        {bids?.map((bid, index) => (
          <tr key={index} className="border-0 small">
            <td className="text-success border-0">{formatNumberWithComma(bid.price, selectedNumberFormat)}</td>
            <td className="border-0 text-lg-end">{formatNumberWithComma(bid.size, selectedNumberFormat)}</td>
            <td className="border-0 text-lg-end">{formatNumberWithComma(bid.total, selectedNumberFormat)}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>



    </div>
  )
};
