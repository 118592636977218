import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from "react-redux";

const CustomModal = ({ show, handleClose, title, message, handleOk=false }) => {
  const labels = useSelector((state) => state.labels.data);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {handleOk 
          ? 
          <Button variant="primary" onClick={handleOk}>
          {labels?.account?.deviceWhitelisting?.yes}
          </Button>
          :
          <></>
        }

        <Button variant="secondary" onClick={handleClose}>
        {labels?.account?.deviceWhitelisting?.no}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
