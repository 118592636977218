import React, { useState, useEffect } from "react";
import { PercentageSlider } from "./PercentageSlider";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useModalContext } from "../../../Context/ModalContext";
import { formatNumberWithComma } from "../../../Common/Helpers";
import { useSelector } from 'react-redux';

export const BuySellOrder = ({ coinPrice, tradeCompleted, setTradeCompleted, market, activeMarket, coin, pairBalance, coinBalance }) => {

  const { selectedNumberFormat } = useModalContext();


  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    const jsonObject = JSON.parse(localStorage.getItem("userInfo"));
    setUserInfo(jsonObject);
  }, []);

  const labels = useSelector(state => state.labels.data);


  const tabs = [
    {
      id: 0,
      name: "LIMIT",
    },
    {
      id: 1,
      name: "MARKET",
    },
    {
      id: 2,
      name: "STOP",
    }
  ];





  const orderOptions = [
    {
      id: 0,
      name: labels?.forms?.limitOrder?.goodTillCancelled,
      code: "GTC",
    },
    {
      id: 1,
      name: labels?.forms?.limitOrder?.immediateOrCancel,
      code: "IOC",
    },
    {
      id: 2,
      name: labels?.forms?.limitOrder?.fillOrKill,
      code: "FOK"
    },
    {
      id: 3,
      name: labels?.forms?.limitOrder?.dayOnly,
      code: "DO"
    }
  ]

  const stopOptions = [
    {
      id: 0,
      name: labels?.forms?.stopOrder?.stopLimit,
      code: "STOPLIMIT"
    },
    {
      id: 1,
      name: labels?.forms?.stopOrder?.stopMarket,
      code: "STOPMARKET"
    },
    {
      id: 2,
      name: labels?.forms?.stopOrder?.trailingStop,
      code: "TRAILINGSTOPMARKET"
    }
  ]

  const trailingOptions = [
    {
      id: 0,
      name: labels?.forms?.stopOrder?.absolute
    },
    {
      id: 1,
      name: labels?.forms?.stopOrder?.percentage
    },
  ]


  const [selectedStopOption, setSelectedStopOption] = useState({
    id: 0,
    name: labels?.forms?.stopOrder?.stopLimit,
    code: "STOPLIMIT"
  });
  const [stopOptionDropdownVisible, setStopOptionDropdownVisible] = useState(false);

  const [selectedTrailingOption, setSelectedTrailingOption] = useState(trailingOptions[0]);
  const [trailingOptionDropdownVisible, setTrailingOptionDropdownVisible] = useState(false);

  const [selectedSellTrailingOption, setSelectedSellTrailingOption] = useState(trailingOptions[0]);
  const [trailingSellOptionDropdownVisible, setTrailingSellOptionDropdownVisible] = useState(false);

  const handleSelectTrailingOption = (option) => {
    setSelectedTrailingOption(option);
    setTrailingOptionDropdownVisible(false)
  }

  const handleSelectSellTrailingOption = (option) => {
    setSelectedSellTrailingOption(option);
    setTrailingSellOptionDropdownVisible(false)
  }


  const handleSelectStopOption = (option) => {
    setSelectedStopOption(option);
    setStopOptionDropdownVisible(false)
  }

  const [selectedStopSellOption, setSelectedStopSellOption] = useState({
    id: 0,
    name: labels?.forms?.stopOrder?.stopLimit,
    code: "STOPLIMIT"
  });
  const [stopSellOptionDropdownVisible, setStopSellOptionDropdownVisible] = useState(false);

  const handleSelectStopSellOption = (option) => {
    setSelectedStopSellOption(option);
    setStopSellOptionDropdownVisible(false)
  }


  const [selectedOrderOption, setSelectedOrderOption] = useState(  {
    id: 0,
    name: labels?.forms?.limitOrder?.goodTillCancelled,
    code: "GTC",
  });
  const [selectedSellOrderOption, setSelectedSellOrderOption] = useState({
    id: 0,
    name: labels?.forms?.limitOrder?.goodTillCancelled,
    code: "GTC",
  });

  const [activeTab, setActiveTab] = useState({
    id: 0,
    name: "LIMIT",
  },);


  useEffect(() => {
    if (labels?.forms?.limitOrder || labels?.forms?.stopOrder) {
      setSelectedOrderOption({
        id: 0,
        name: labels.forms.limitOrder.goodTillCancelled,
        code: "GTC",
      });
      setSelectedSellOrderOption({
        id: 0,
        name: labels.forms.limitOrder.goodTillCancelled,
        code: "GTC",
      });
      setSelectedMarketOption({
        id: 0,
        name: labels?.forms?.stopOrder?.volume
      });
      setSelectedSellMarketOption({
        id: 0,
        name: labels?.forms?.stopOrder?.volume
      });
      setSelectedStopSellOption({
        id: 0,
        name: labels?.forms?.stopOrder?.stopLimit,
        code: "STOPLIMIT"
      });
      setSelectedStopOption({
        id: 0,
        name: labels?.forms?.stopOrder?.stopLimit,
        code: "STOPLIMIT"
      });
      setActiveTab(
        {
          id: 0,
          name: "LIMIT",
        },
      )
    }
  }, [labels]); 

  const [orderOptionDropdownVisible, setOrderOptionDropdownVisible] = useState(false);

  const [sellOrderOptionDropdownVisible, setSellOrderOptionDropdownVisible] = useState(false);


  const [buyPercentage, setBuyPercentage] = useState(0);
  const [sellPercentage, setSellPercentage] = useState(0);

  const handleSelectOrderOption = (option) => {
    setSelectedOrderOption(option);
    setOrderOptionDropdownVisible(false)
  }


  const handleSelectSellOrderOption = (option) => {
    setSelectedSellOrderOption(option);
    setSellOrderOptionDropdownVisible(false)
  }




  const [buyFormData, setBuyFormData] = useState({
    price: '',
    amount: '',
    total: ''
  })

  const [stopFormData, setStopFormData] = useState({
    stop: '',
    limit: '',
    amount: '',
    total: '',
    trail: '',
  })

  const [stopSellFormData, setStopSellFormData] = useState({
    stop: '',
    limit: '',
    amount: '',
    total: '',
    trail: ''
  })



  const [isInvalid, setIsInvalid] = useState({
    price: false,
    amount: false
  });


  const [sellIsInvalid, setSellIsInvalid] = useState({
    price: false,
    amount: false
  });

  const [stopIsInvalid, setStopIsInvalid] = useState({
    stop: false,
    limit: false,
    amount: false,
    total: false,
    trail: false
  });

  const [stopSellIsInvalid, setStopSellIsInvalid] = useState({
    stop: false,
    limit: false,
    amount: false,
    total: false,
    trail: false
  });

  const [marketIsInvalid, setMarketIsInvalid] = useState({
    total: false,
    amount: false,
  });

  const [marketSellIsInvalid, setMarketSellIsInvalid] = useState({
    total: false,
    amount: false,
  });

  const [sliding, setSliding] = useState(false);




  const marketOptions = [
    {
      id: 0,
      name: labels?.forms?.stopOrder?.volume
    },
    {
      id: 1,
      name: labels?.forms?.stopOrder?.total
    }
  ]
  const [selectedMarketOption, setSelectedMarketOption] = useState(
    {
      id: 0,
      name: labels?.forms?.stopOrder?.volume
    }
  );




  const handleBuyStopChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setStopFormData((prevData) => {
        const updatedData = { ...prevData, [name]: value };
        if (name !== "total") {
            const calculatedTotal = parseFloat(updatedData.limit) * parseFloat(updatedData.amount);
            let newPercentage = (calculatedTotal / pairBalance) * 100;
            if (newPercentage > 100) {
                newPercentage = 100;
            }
            setBuyPercentage(Math.round(newPercentage));
            return {
                ...updatedData,
                total: calculatedTotal || 0,
            };
        }
        return updatedData;
    });

      setStopIsInvalid({ ...stopIsInvalid, [name]: false });
    }
    if (name === "total") {
      const totalValue = parseFloat(value);
      if (!isNaN(totalValue)) {
        let newPercentage;
        if (totalValue > pairBalance) {
          newPercentage = 100;
        } else {
          newPercentage = (totalValue / pairBalance) * 100;
        }
        setBuyPercentage(Math.round(newPercentage));
      } else {
        setBuyPercentage(0);
      }
    }
  }


  useEffect(() => {
    if (buyPercentage > 0) {
      const newTotal = (pairBalance * buyPercentage) / 100;
      const newAmount = parseFloat(newTotal) / parseFloat(stopFormData.limit);

        setStopFormData((prevData) => ({
          ...prevData,
          total: newTotal.toFixed(6),
          amount: newAmount.toFixed(6),
        }));
    }
  }, [sliding, pairBalance]);


  const handleSellStopChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setStopSellFormData((prevData) => {
        const updatedData = { ...prevData, [name]: value };
        if (name !== "total") {
            const calculatedTotal = parseFloat(updatedData.limit) * parseFloat(updatedData.amount);
            let newPercentage = (calculatedTotal / pairBalance) * 100;
            if (newPercentage > 100) {
                newPercentage = 100;
            }
            setBuyPercentage(Math.round(newPercentage));
            return {
                ...updatedData,
                total: calculatedTotal || 0,
            };
        }
        return updatedData;
    });
      setStopSellIsInvalid({ ...stopSellIsInvalid, [name]: false });
    }
    if (name === "total") {
      const totalValue = parseFloat(value);
      if (!isNaN(totalValue)) {
        let newPercentage;
        if (totalValue > coinBalance) {
          newPercentage = 100;
        } else {
          newPercentage = (totalValue / coinBalance) * 100;
        }
        setSellPercentage(Math.round(newPercentage));
      } else {
        setSellPercentage(0);
      }
    }
  }

  useEffect(() => {
    if (sellPercentage > 0) {
      const newTotal = (coinBalance * sellPercentage) / 100;
      const newAmount = parseFloat(newTotal) / parseFloat(stopSellFormData.limit);

        setStopSellFormData((prevData) => ({
          ...prevData,
          total: newTotal.toFixed(6),
          amount: newAmount.toFixed(6),
        }));
    }
  }, [sliding, coinBalance]);






  const handleBuyChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setBuyFormData((prevData) => {
        const updatedData = { ...prevData, [name]: value };
        if (name !== "total") {
            const calculatedTotal = parseFloat(updatedData.price) * parseFloat(updatedData.amount);
            let newPercentage = (calculatedTotal / pairBalance) * 100;
            if (newPercentage > 100) {
                newPercentage = 100;
            }
            setBuyPercentage(Math.round(newPercentage));
            return {
                ...updatedData,
                total: calculatedTotal || 0,
            };
        }
        return updatedData;
    });

      setIsInvalid({ ...isInvalid, [name]: false });
      setMarketIsInvalid({ ...marketIsInvalid, [name]: false });
    }


    if (activeTab.id === 1 && name === "amount") {
      const total = parseFloat(pairBalance) / parseFloat(coinPrice);

      const valuee = parseFloat(value);
      if (!isNaN(valuee)) {
        let newPercentage;
        if (valuee > total) {
          newPercentage = 100;
        }
          else {
            newPercentage = (valuee / total) * 100;
          }
          setBuyPercentage(Math.round(newPercentage))
      } else {
        setBuyPercentage(0);
      }
    }
    

    if (name === "total") {
      const totalValue = parseFloat(value);
      if (!isNaN(totalValue)) {
        let newPercentage;
        if (totalValue > pairBalance) {
          newPercentage = 100;
        } else {
          newPercentage = (totalValue / pairBalance) * 100;
        }
        setBuyPercentage(Math.round(newPercentage));
      } else {
        setBuyPercentage(0);
      }
    }
  };


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setIsInvalid({ ...isInvalid, [name]: true });
    }
  };

  const handleBlurSell = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setSellIsInvalid({ ...sellIsInvalid, [name]: true });
    }
  };

  const handleBlurStop = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setStopIsInvalid({ ...stopIsInvalid, [name]: true });
    }
  };

  const handleBlurStopSell = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setStopSellIsInvalid({ ...stopSellIsInvalid, [name]: true });
    }
  };


  const handleBlurMarket = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setMarketIsInvalid({ ...marketIsInvalid, [name]: true });
    }
  };

  const handleBlurMarketSell = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setMarketSellIsInvalid({ ...marketSellIsInvalid, [name]: true });
    }
  };



  const [sellFormData, setSellFormData] = useState({
    price: '',
    amount: '',
    total: ''
  })




const [activeProcess, setActiveProcess] = useState();
const [activeProcessOption, setActiveProcessOption] = useState(labels?.forms?.stopOrder?.volume);


const [marketOptionDropdownVisible, setMarketOptionDropdownVisible] = useState(false);
const handleSelectMarketOption = (option) => {
  setSelectedMarketOption(option);
  setMarketOptionDropdownVisible(false)
  setActiveProcessOption(option.name)
}

const [selectedSellMarketOption, setSelectedSellMarketOption] = useState(    {
  id: 0,
  name: labels?.forms?.stopOrder?.volume
});
const [sellMarketOptionDropdownVisible, setSellMarketOptionDropdownVisible] = useState(false);
const handleSelectSellMarketOption = (option) => {
  setSelectedSellMarketOption(option);
  setSellMarketOptionDropdownVisible(false)
  setActiveProcessOption(option.name)
}





  const handleSellChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setSellFormData((prevData) => {
        const updatedData = { ...prevData, [name]: value };

        if (name !== "total") {
          const calculatedTotal = parseFloat(updatedData.price) * parseFloat(updatedData.amount);

          let newPercentage = (calculatedTotal / coinBalance) * 100;
          if (newPercentage > 100) {
            newPercentage = 100;
          }
          setSellPercentage(Math.round(newPercentage));
  
          return {
            ...updatedData,
            total: calculatedTotal || 0,
          };
        }

        return updatedData;
        

      })
      setSellIsInvalid({ ...sellIsInvalid, [name]: false });
      setMarketSellIsInvalid({ ...marketSellIsInvalid, [name]: false });
    }

    if (name === "total") {
      const totalValue = parseFloat(value);
      if (!isNaN(totalValue)) {
        let newPercentage;
        if (totalValue > coinBalance) {
          newPercentage = 100;
        } else {
          newPercentage = (totalValue / coinBalance) * 100;
        }
        setSellPercentage(Math.round(newPercentage));
      } else {
        setSellPercentage(0);
      }
    }
  };



  // Sell
  useEffect(() => {
    if (sellPercentage > 0) {
     const newTotal = (coinBalance * sellPercentage) / 100 * parseFloat(sellFormData.price);
      //const newTotal = (coinBalance * sellPercentage) / 100;
     const newAmount = (coinBalance * sellPercentage) / 100;

      const marketPrice = 1;
      if (activeTab.id === 1 && activeProcessOption === "amount" && activeProcess === "marketSell") {
        const total = (parseFloat(sellPercentage) * parseFloat(coinBalance)) / parseFloat(coinPrice);
        const amount = (coinBalance * sellPercentage) / 100;
        setSellFormData((prevData) => ({
          ...prevData,
          total: total.toFixed(6),
          amount: amount.toFixed(6),
        }));
      } else if (activeTab.id === 1 && activeProcessOption === "total" && activeProcess === "marketSell") {
        const total = (parseFloat(sellPercentage) * parseFloat(coinBalance)) * parseFloat(coinPrice);

        setSellFormData((prevData) => ({
          ...prevData,
          total: total.toFixed(6),
        }));
      } else {
        setSellFormData((prevData) => ({
          ...prevData,
          total: newTotal.toFixed(6),
          amount: newAmount.toFixed(6),
        }));
      }
    }
  }, [sliding, coinBalance]);


  // buy
  useEffect(() => {
    if (buyPercentage > 0) {
      const newTotal = (pairBalance * buyPercentage) / 100;
      const newAmount = parseFloat(newTotal) / parseFloat(buyFormData.price);

      if (activeTab.id === 1 && selectedMarketOption.id === 0) {

      const amount = (parseFloat(buyPercentage) * parseFloat(pairBalance)) / parseFloat(coinPrice);

        setBuyFormData((prevData) => ({
          ...prevData,
          amount: (amount / 100).toFixed(6)
        }));
      } else {
        setBuyFormData((prevData) => ({
          ...prevData,
          total: newTotal.toFixed(6),
          amount: newAmount.toFixed(6),
        }));
      }

    }
  }, [sliding, pairBalance]);





  const marketBuySell = async (e, side) => {

    e.preventDefault();

    const getAmount = () => {
      if (side === "BUY") {
        return buyFormData.amount
      } else {
        return sellFormData.amount
      }
    }

    const getTotal = () => {
      if (side === "BUY") {
        return buyFormData.total
      } else {
        return sellFormData.total
      }
    }

    const total = getTotal();
    
    const amount = getAmount();
    const type = activeTab?.name;

    let reqBody;

    if (side === "BUY") {
      if (!amount) {
        setMarketIsInvalid({
          amount: !amount,
        })};
    } else {
      if (!amount) {
        setMarketSellIsInvalid({
          amount: !amount,
        })};
    }


    if (selectedMarketOption.id === 1) {
      reqBody = {
        amount: total,
        market: market,
        side: side, 
        trade: coin,
        }
    } else {
      reqBody = {
        market: market,
        side: side,
        trade: coin,
        type: type,
        volume: amount,
      } 
    }
  
      const token = localStorage.getItem("token");

      let address;
      if (selectedSellMarketOption.name === labels?.forms?.stopOrder?.volume) {
        address = "PlaceOrder"
      } else {
        address = "PlaceOrder_Priced"
      }
  
      try {
        const response = await axios.post(
        //  `${process.env.REACT_APP_API_REST_URL}/api/PlaceOrder_Priced`, 
        `${process.env.REACT_APP_API_REST_URL}/api/${address}`, 
          reqBody,
          {
            headers: {
              'Authorization': `Bearer ${token}` 
            },
          }
        );

        if (response.data.status === "Error") {
          const messageKey = response.data.message; 
          const getDynamicMessage = (key) => {
            return labels?.messages?.[key] || key; 
           };
           const dynamicMessage = getDynamicMessage(messageKey);
          toast.error(dynamicMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setTradeCompleted(true)
          const messageKey = response.data.message; 
          const getDynamicMessage = (key) => {
            return labels?.messages?.[key] || key; 
           };
           const dynamicMessage = getDynamicMessage(messageKey);
          setTradeCompleted(!tradeCompleted)
          setBuyPercentage(0)
          setSellPercentage(0)
          toast.success(dynamicMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

      } catch(error) {
        throw new Error("");
      }
  }


  const stopBuySell = async (e, side) => {

    e.preventDefault();

    const getAmount = () => {
      if (side === "BUY") {
        return stopFormData.amount
      } else {
        return stopSellFormData.amount
      }
    }

    const getStop = () => {
      if (side === "BUY") {
        return stopFormData.stop
      } else {
        return stopSellFormData.stop
      }
    }
    const getLimit = () => {
      if (side === "BUY") {
        return stopFormData.limit
      } else {
        return stopSellFormData.limit
      }
    }

    const getType = () => {
      if (side === "BUY") {
        return selectedStopOption.code
      } else {
        return selectedStopSellOption.code
      }
    }

    const getTrailingOption = () => {
      if (side === "BUY") {
        if (selectedTrailingOption.id === 1) {
          return true
        } else {
          return false
        }
      } else {
        if (selectedSellTrailingOption.id === 1) {
          return true
        } else {
          return false
        }
      }
    }

    const amount = getAmount();
    const stop = getStop();
    const limit = getLimit();
    const type = getType();
    const isTrailPercentage = getTrailingOption();


    if (side === "BUY") {
      if (!stop || !limit || !amount) {
        setStopIsInvalid({
          stop: !stop,
          limit: !limit,
          amount: !amount,
        });
      }
    } else {
      setStopSellIsInvalid({
        stop: !stop,
        limit: !limit,
        amount: !amount,
      });
    }
  
    let reqBody;

    if (selectedStopOption.code === "STOPMARKET") {
      reqBody = {
        market: market,
        side: side, 
        stop: stop, // Stop
        trade: coin,
        type: type,
        volume: amount // Amount
      }
    } else if (selectedStopOption.code === "TRAILINGSTOPMARKET") {
      reqBody = {
        isTrailInPercentage: isTrailPercentage, // isTrailInPercentage
        market: market,
        side: side, 
        trade: coin,
        trail: stopFormData.trail, // trail
        type: type,
        volume: amount // Amount
      }
    } else {
      reqBody = {
        market: market,
        rate: limit, // Limit
        side: side, 
        stop: stop, // Stop
        trade: coin,
        type: type,
        volume: amount // Amount
      }
    }


    

      const token = localStorage.getItem("token");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_REST_URL}/api/PlaceOrder`, 
          reqBody,
          {
            headers: {
              'Authorization': `Bearer ${token}` 
            },
          }
        );

        if (response.data.status === "Error") {
          const messageKey = response.data.message; 
          const getDynamicMessage = (key) => {
            return labels?.messages?.[key] || key; 
           };
           const dynamicMessage = getDynamicMessage(messageKey);

          toast.error(dynamicMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          const messageKey = response.data.message; 
          const getDynamicMessage = (key) => {
            return labels?.messages?.[key] || key; 
           };
           const dynamicMessage = getDynamicMessage(messageKey);
          setTradeCompleted(!tradeCompleted)
          setBuyPercentage(0)
          setSellPercentage(0)
          toast.success(dynamicMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
    console.error('Post Error:', error.response ? error.response.data : error.message);
      }
  }


  const limitBuySell = async (e, side) => {

    e.preventDefault();
    


    const getOrderOption = () => {
      if (side === "BUY") {
        return selectedOrderOption.code
      } else {
        return selectedSellOrderOption.code
      }
    }

    const getRate = () => {
      if (side === "BUY") {
        return buyFormData.price
      } else {
        return sellFormData.price
      }
    }

    const getAmount = () => {
      if (side === "BUY") {
        return buyFormData.amount
      } else {
        return sellFormData.amount
      }
    }

    const rate = getRate();
    const amount = getAmount();
    const orderOption = getOrderOption();
    const type = activeTab?.name;

    if (side === "BUY") {
      if (!rate || !amount) {
        setIsInvalid({
          price: !rate,
          amount: !amount
        })};
    } else {
      setSellIsInvalid({
        price: !rate,
        amount: !amount
      })
    }



    let reqBody = {
      market: market,
      rate: rate, 
      side: side, 
      timeInForce: orderOption, 
      trade: coin,
      type: type,
      volume: amount
    }




    const token = localStorage.getItem("token");


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/PlaceOrder`, 
        reqBody,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data.status === "Error") {
        const messageKey = response.data.message; 
        const getDynamicMessage = (key) => {
          return labels?.messages?.[key] || key; 
         };
         const dynamicMessage = getDynamicMessage(messageKey);
        toast.error(dynamicMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }); 
      } else if (response.data.status === "Success") {
        
        const messageKey = response.data.message; 
        const getDynamicMessage = (key) => {
          return labels?.messages?.[key] || key; 
         };
         const dynamicMessage = getDynamicMessage(messageKey);


        setTradeCompleted(!tradeCompleted)
        setBuyPercentage(0)
        setSellPercentage(0)
        toast.success(dynamicMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        return;
      }

    } catch (error) {
    }

}


const handleTabClick = (tab) => {
  setActiveTab(tab);
  setBuyPercentage(0);
  setSellPercentage(0);
  setBuyFormData({
    price: '',
    amount: '',
    total: ''
  });
  setSellFormData({
    price: '',
    amount: '',
    total: ''
  });
  setStopFormData({
    stop: '',
    limit: '',
    amount: ''
  });
  setStopSellFormData({
    stop: '',
    limit: '',
    amount: ''
  })
};


useEffect(() => {
  if (labels?.forms?.limitOrder) {
    setSelectedOrderOption({
      id: 0,
      name: labels.forms.limitOrder.goodTillCancelled,
      code: "GTC",
    });
  }
}, [labels]); 



const loginOrRegisterText = labels?.forms?.common?.loginOrRegister || "";
const [beforeLogin, loginText, betweenText, registerText, afterRegister] = loginOrRegisterText
  .split(/<0>|<\/0>|<2>|<\/2>/);



  return (
    <div className="trade-forms-container py-3 px-2 px-lg-2">

      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {tabs.map((tab, index) => {
          const dynamicKey = tab.name.toLowerCase(); 
          const dynamicLabel = labels?.exchange?.[`${dynamicKey}Order`];
            return (
              <li className="nav-item" role="presentation" key={index}>
                <button
                  className={`nav-link rounded-3 py-1 ${activeTab.id === tab.id ? 'active' : ''}`}
                  id={`pills-${tab?.name?.toLowerCase()}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${tab?.name?.toLowerCase()}`}
                  type="button"
                  role="tab"
                  aria-controls={`pills-${tab?.name?.toLowerCase()}`}
                  aria-selected={activeTab.id === tab.id}
                  onClick={() => handleTabClick(tab)}
                >
                  {dynamicLabel}
                </button>
              </li>
            )})}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${activeTab.id === tab.id ? 'show active' : ''}`}
            id={`pills-${tab?.name?.toLowerCase()}`}
            role="tabpanel"
            aria-labelledby={`pills-${tab?.name?.toLowerCase()}-tab`}
            tabIndex={0}
            key={tab.id}
          >
            {tab.name === "LIMIT" && (
              <>
              <form className="buyorder-form needs-validation" noValidate>
                <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                <span className="small fw-light text-xs ps-2">
                  {pairBalance ? 
                    <span className="pe-1">{formatNumberWithComma(pairBalance, selectedNumberFormat)}</span>
                    : 
                  (
                    <span className="pe-1">0</span>
                  )}
                  {activeMarket}
                </span>
                <div className="buyorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.exchange?.price}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={buyFormData.price}
                    onChange={handleBuyChange}
                    name="price"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.00"
                    onBlur={handleBlur}
                    className={`buyorder-input ${isInvalid.price ? 'is-invalid' : ''}`}
                    />  
                </div>
                <div className="buyorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.forms?.limitOrder?.volume}</label>
                  <label className="label-coin position-absolute">{coin}</label>
                  <input 
                    value={isNaN(buyFormData.amount) ? "0" : buyFormData.amount}
                    onChange={handleBuyChange}
                    name="amount"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.000000"
                    onBlur={handleBlur}
                    className={`buyorder-input ${isInvalid.amount ? 'is-invalid' : ''}`}
                  />  
                </div>

                <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="limitBuy"
                  sliding={sliding}
                  setSliding={setSliding}
                  color={"#198754"}
                  percentage={buyPercentage}
                  setPercentage={setBuyPercentage}
                  name="buy"
                />

                <div className="buyorder-inputgroup position-relative relative mt-4">
                  <label className="label-price position-absolute">{labels?.forms?.limitOrder?.total}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={isNaN(buyFormData.total) ? "0" : buyFormData.total}
                    onChange={handleBuyChange}
                    name="total"
                    type="text" 
                    className="buyorder-input" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0"
                  />  
                </div>

                <div className="buyorder-dropdown position-relative">
                  <button
                    type="button"
                    className="text-start"
                    onClick={() => setOrderOptionDropdownVisible(!orderOptionDropdownVisible)}
                  >
                    {selectedOrderOption?.name}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  <ul className={orderOptionDropdownVisible ? "buyorder-options position-absolute show" : "buyorder-options position-absolute"}>
                    {orderOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => handleSelectOrderOption(option)}>
                        {option?.name}
                      </button>
                    </li>
                    ))}
                  </ul>
                </div>

                {userInfo ? (
                  <button 
                    type="submit" 
                    className="btn btn-success buyorder-action"
                    onClick={(e) => limitBuySell(e, 'BUY')}
                    >{labels?.tables?.offers?.buy} {coin}</button>
                ) : (
                  <div className="guest-warn border-0">
                  {beforeLogin}
                  <a href="/login" className="pe-1">{loginText}</a>
                  {betweenText}
                  <a href="/register" className="px-1">{registerText}</a>
                  {afterRegister}
                </div>
                )}
              </form>
              <form className="sellorder-form border-top custom-border-color-dark border-secondary mt-2">
              <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                <span className="small fw-light text-xs ps-2">
                  {coinBalance ? (
                    <span className="pe-1">{formatNumberWithComma(coinBalance, selectedNumberFormat)}</span>
                  ) : (
                    <span className="pe-1">0</span>
                  )}
                 
                  {coin}
                </span>
                <div className="sellorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.exchange?.price}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={sellFormData.price}
                    onChange={handleSellChange}
                    name="price"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.00"
                    onBlur={handleBlurSell}
                    className={`sellorder-input ${sellIsInvalid.price ? 'is-invalid' : ''}`}
                  />  
                </div>
                <div className="sellorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.forms?.limitOrder?.volume}</label>
                  <label className="label-coin position-absolute">{coin}</label>
                  <input 
                    value={isNaN(sellFormData.amount) ? "0" : sellFormData.amount}
                    onChange={handleSellChange}
                    name="amount"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.000000"
                    onBlur={handleBlurSell}
                    className={`sellorder-input ${sellIsInvalid.amount ? 'is-invalid' : ''}`}
                  />  
                </div>


                <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="limitSell"
                  sliding={sliding}
                  setSliding={setSliding}
                  color={"#EB5757"}
                  percentage={sellPercentage}
                  setPercentage={setSellPercentage}
                  name={"sell"}
                />

                <div className="sellorder-inputgroup position-relative relative mt-4">
                  <label className="label-price position-absolute">{labels?.forms?.limitOrder?.total}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={isNaN(sellFormData.total) ? "0" : sellFormData.total}
                    onChange={handleSellChange}
                    name="total"
                    type="text" 
                    className="sellorder-input" 
                    autoComplete="off"
                    step="0.000001"
                  />  
                </div>

                <div className="sellorder-dropdown position-relative">
                  <button
                    type="button"
                    className="text-start"
                    onClick={() => setSellOrderOptionDropdownVisible(!sellOrderOptionDropdownVisible)}
                  >
                    {selectedSellOrderOption?.name}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  <ul className={sellOrderOptionDropdownVisible ? "sellorder-options position-absolute show" : "sellorder-options position-absolute"}>
                    {orderOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => handleSelectSellOrderOption(option)}>
                        {option?.name}
                      </button>
                    </li>
                    ))}

                  </ul>
                </div>
                {userInfo ? (
                  <button 
                    type="submit" 
                    className="btn btn-danger sellorder-action"
                    onClick={(e) => limitBuySell(e, 'SELL')}
                  >{labels?.tables?.offers?.sell} {coin}</button>
                ) : (
                  <div className="guest-warn border-0">
                  {beforeLogin}
                  <a href="/login" className="pe-1">{loginText}</a>
                  {betweenText}
                  <a href="/register" className="px-1">{registerText}</a>
                  {afterRegister}
                </div>
                )}
              </form>
              </>
            )}
            {tab.name === "MARKET" && (
              <>
              <form className="sellorder-form">
                <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                <span className="small fw-light text-xs ps-2">
                {pairBalance ? 
                    <span className="pe-1">{formatNumberWithComma(pairBalance, selectedNumberFormat)}</span>
                    : 
                  (
                    <span className="pe-1">0</span>
                  )}
                   {activeMarket}</span>
                <div className="sellorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.exchange?.price}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={sellFormData.price}
                    onChange={handleSellChange}
                    name="price"
                    type="text" 
                    className="sellorder-input" 
                    placeholder={labels?.forms?.marketOrder?.marketPrice}
                    disabled
                    />  
                </div>

                <div className="buyorder-inputgroup position-relative relative">
                  <div
                    onMouseEnter={() => setMarketOptionDropdownVisible(true)}
                    onMouseLeave={() => setMarketOptionDropdownVisible(false)}
                  >
                  <label className="label-price position-absolute">
                    <button 
                    style={{ zIndex: 10 }}
                      className="bg-transparent border-0 ps-0" 
                      type="button"

                    >
                    {selectedMarketOption.name} <img src="/images/icons/chevron-down.svg" alt="" width={10} height={10} /> 
                    </button>
                  </label>
                  <ul style={{ top: 30, width: 90, left: 20 }}  className={marketOptionDropdownVisible ? "buyorder-options position-absolute show" : "buyorder-options position-absolute"}>
                    {marketOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" className="text-xs" onClick={() => handleSelectMarketOption(option)}>
                        {option.name}
                      </button>
                    </li>
                    ))}
                  </ul>
                  </div>

                  <label className="label-coin position-absolute">
                    {selectedMarketOption.id === 1 ? (
                      <>
                      {activeMarket}
                      </>
                    ) : (
                      <>
                      {coin}
                      </>
                    )}
                  </label>
                  {selectedMarketOption.id === 1 ? (
                    <>
                    <input 
                      value={buyFormData.total}
                      onChange={handleBuyChange}
                      name="total"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0"
                      onBlur={handleBlurMarket}
                      className={`buyorder-input ${marketIsInvalid.total ? 'is-invalid' : ''}`}
                    />  
                  </>
                  ): (
                    <>
                      <input 
                        value={isNaN(buyFormData.amount) ? "0" : buyFormData.amount}
                        onChange={handleBuyChange}
                        name="amount"
                        type="text" 
                        autoComplete="off"
                        step="0.000001"
                        placeholder="0.000000"
                        onBlur={handleBlurMarket}
                        className={`buyorder-input ${marketIsInvalid.amount ? 'is-invalid' : ''}`}
                      />  
                    </>
                  )}
                </div>

                <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="marketBuy"
                  sliding={sliding}
                  setSliding={setSliding}
                  color={"#198754"}
                  percentage={buyPercentage}
                  setPercentage={setBuyPercentage}
                  name="buy"
                />

              <div className="mt-4 mb-3">
              {userInfo ? (
                  <button 
                    type="submit" 
                    className="btn btn-success buyorder-action"
                    onClick={(e) => marketBuySell(e, 'BUY')}
                    >{labels?.tables?.offers?.buy} {coin}</button>
                ) : (
                  <div className="guest-warn border-0">
                  {beforeLogin}
                  <a href="/login" className="pe-1">{loginText}</a>
                  {betweenText}
                  <a href="/register" className="px-1">{registerText}</a>
                  {afterRegister}
                </div>
                )}
              </div>

              </form>
              <form className="sellorder-form border-top custom-border-color-dark border-secondary mt-2">
              <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                <span className="small fw-light text-xs ps-2">
                  {coinBalance ? (
                    <span className="pe-1">{formatNumberWithComma(coinBalance, selectedNumberFormat)}</span>
                  ) : (
                    <span className="pe-1">0</span>
                  )}
                 
                  {coin}
                </span>
                <div className="sellorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.exchange?.price}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={sellFormData.price}
                    onChange={handleSellChange}
                    name="price"
                    type="text" 
                    className="sellorder-input" 
                    placeholder="Market Price"
                    disabled
                    />  
                </div>
                <div className="sellorder-inputgroup position-relative relative">
                  <div
                    onMouseEnter={() => setSellMarketOptionDropdownVisible(true)}
                    onMouseLeave={() => setSellMarketOptionDropdownVisible(false)}
                  >
                  <label className="label-price position-absolute">
                    <button 
                    style={{ zIndex: 10 }}
                      className="bg-transparent border-0 ps-0" 
                      type="button"

                    >
                    {selectedSellMarketOption.name} <img src="/images/icons/chevron-down.svg" alt="" width={10} height={10} /> 
                    </button>
                  </label>
                  <ul style={{ top: 30, width: 90, left: 20 }}  className={sellMarketOptionDropdownVisible ? "sellorder-options position-absolute show" : "sellorder-options position-absolute"}>
                    {marketOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" className="text-xs" onClick={() => handleSelectSellMarketOption(option)}>
                        {option.name}
                      </button>
                    </li>
                    ))}
                  </ul>
                  </div>




                  <label className="label-coin position-absolute">
                    {selectedSellMarketOption.name === "Total" ? (
                      <>
                      {activeMarket}
                      </>
                    ) : (
                      <>
                      {coin}
                      </>
                    )}
                  </label>
                  
                  {selectedSellMarketOption.name === "Total" ? (
                    <>
                    <input 
                      value={sellFormData.total}
                      onChange={handleSellChange}
                      name="total"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0"
                      onBlur={handleBlurMarketSell}
                      className={`sellorder-input ${marketSellIsInvalid.total ? 'is-invalid' : ''}`}
                    />  
                  </>
                  ): (
                    <>
                      <input 
                        value={sellFormData.amount}
                        onChange={handleSellChange}
                        name="amount"
                        type="text" 
                        autoComplete="off"
                        step="0.000001"
                        placeholder="0.000000"
                        onBlur={handleBlurMarketSell}
                        className={`sellorder-input ${marketSellIsInvalid.amount ? 'is-invalid' : ''}`}
                      />  
                    </>
                  )}
                </div>

                <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="marketSell"
                  sliding={sliding}
                  setSliding={setSliding}
                  color={"#EB5757"}
                  percentage={sellPercentage}
                  setPercentage={setSellPercentage}
                  name="sell"
                />

                <div className="mt-4 mb-3">
                {userInfo ? (
                  <button 
                    type="button" 
                    className="btn btn-danger sellorder-action"
                    onClick={(e) => marketBuySell(e, 'SELL')}
                    >{labels?.tables?.offers?.sell} {coin}</button>
                ) : (
                  <div className="guest-warn border-0">
                  {beforeLogin}
                  <a href="/login" className="pe-1">{loginText}</a>
                  {betweenText}
                  <a href="/register" className="px-1">{registerText}</a>
                  {afterRegister}
                </div>
                )}
                </div>

              </form>
              </>
            )}
            {tab.name === "STOP" && (
              <>
                <form className="buyorder-form">
                  <div className="buyorder-dropdown position-relative">
                    <button
                      type="button"
                      className="text-start"
                      onClick={() => setStopOptionDropdownVisible(!stopOptionDropdownVisible)}
                    >
                      {selectedStopOption.name}
                      <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                    </button>
                    <ul className={stopOptionDropdownVisible ? "sellorder-options position-absolute show" : "sellorder-options position-absolute"}>
                      {stopOptions.map((option, index) => (
                      <li key={index}>
                        <button 
                        type="button"
                        onClick={() => handleSelectStopOption(option)}>
                          {option.name}
                        </button>
                      </li>
                      ))}

                    </ul>
                  </div>
                  <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                  <span className="small fw-light text-xs ps-2">
                  {pairBalance ? 
                    <span className="pe-1">{formatNumberWithComma(pairBalance, selectedNumberFormat)}</span>
                    : 
                  (
                    <span className="pe-1">0</span>
                  )}
                     {activeMarket}</span>

                    {(selectedStopOption.id === 0 || selectedStopOption.id === 1) && (
                  <div className="buyorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.forms?.stopOrder?.stopPrice}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={stopFormData.stop}  
                    onChange={handleBuyStopChange}
                    name="stop"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.00"
                    onBlur={handleBlurStop}
                    className={`buyorder-input ${stopIsInvalid.stop ? 'is-invalid' : ''}`}
                  />  
                </div>
                    )}


                  {selectedStopOption.id === 0 && (
                  <div className="buyorder-inputgroup position-relative relative">
                  <label className="label-price position-absolute">{labels?.forms?.stopOrder?.rate}</label>
                  <label className="label-coin position-absolute">{activeMarket}</label>
                  <input 
                    value={stopFormData.limit}  
                    onChange={handleBuyStopChange}
                    name="limit"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0.00"
                    onBlur={handleBlurStop}
                    className={`buyorder-input ${stopIsInvalid.limit ? 'is-invalid' : ''}`}
                  />  
                </div>
                  )}

                  <div className="buyorder-inputgroup position-relative relative">
                    <label className="label-price position-absolute">{labels?.forms?.stopOrder?.volume}</label>
                    <label className="label-coin position-absolute">{coin}</label>
                    <input 
                       value={isNaN(stopFormData.amount) ? "0" : stopFormData.amount}
                      onChange={handleBuyStopChange}
                      name="amount"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0.000000"
                      onBlur={handleBlurStop}
                      className={`buyorder-input ${stopIsInvalid.amount ? 'is-invalid' : ''}`}
                    />  
                  </div>


                  <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="stopBuy"
                    sliding={sliding}
                    setSliding={setSliding}
                    color={"#198754"}
                    percentage={buyPercentage}
                    setPercentage={setBuyPercentage}
                    name="buy"
                  />

                  <div className="buyorder-inputgroup position-relative relative mt-4">
                    <label className="label-price position-absolute">{labels?.forms?.stopOrder?.total}</label>
                    <label className="label-coin position-absolute">{activeMarket}</label>
                    <input 
                      value={isNaN(stopFormData.total) ? "0" : stopFormData.total}
                      onChange={handleBuyStopChange}
                      name="total"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0"
                      onBlur={handleBlurStop}
                      className={`buyorder-input ${stopIsInvalid.total ? 'is-invalid' : ''}`}
                    />  
                  </div>

                  {selectedStopOption.id === 2 && (
                  <>
                  <div className="sellorder-dropdown position-relative">
                  <button
                    type="button"
                    className="text-start small"
                    onClick={() => setTrailingOptionDropdownVisible(!trailingOptionDropdownVisible)}
                  >
                    {selectedTrailingOption.name}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  <ul className={trailingOptionDropdownVisible ? "sellorder-options position-absolute show" : "sellorder-options position-absolute"}>
                    {trailingOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => handleSelectTrailingOption(option)}>
                        {option.name}
                      </button>
                    </li>
                    ))}

                  </ul>
                </div>

                  <div className="buyorder-inputgroup position-relative relative mt-2">
                  <label className="label-price position-absolute">{labels?.forms?.stopOrder?.trail}</label>
                  {selectedTrailingOption.id === 1 ? (
                    <label className="label-coin position-absolute">%</label>
                  ) : (
                    <label className="label-coin position-absolute">{activeMarket}</label>
                  )}
                  
                  <input 
                    value={stopFormData.trail}  
                    onChange={handleBuyStopChange}
                    name="trail"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0"
                    onBlur={handleBlurStop}
                    className={`buyorder-input ${stopIsInvalid.trail ? 'is-invalid' : ''}`}
                  />  
                </div>
                </>
                  )}


                {userInfo ? (
                    <button 
                    type="button" 
                    className="btn btn-success buyorder-action mt-4"
                    onClick={(e) => stopBuySell(e, 'BUY')}
                    >{labels?.tables?.offers?.buy} {coin}</button>
                  ) : (
                    <div className="guest-warn border-0">
                    {beforeLogin}
                    <a href="/login" className="pe-1">{loginText}</a>
                    {betweenText}
                    <a href="/register" className="px-1">{registerText}</a>
                    {afterRegister}
                  </div>
                  )}

                </form>

                <form className="sellorder-form border-top custom-border-color-dark border-secondary mt-2">
                  
                  <span className="text-muted text-xs">{labels?.tables?.offers?.available}:</span>
                <span className="small fw-light text-xs ps-2">
                  {coinBalance ? (
                    <span className="pe-1">{formatNumberWithComma(coinBalance, selectedNumberFormat)}</span>
                  ) : (
                    <span className="pe-1">0</span>
                  )}
                 
                  {coin}
                </span>
                {(selectedStopOption.id === 0 || selectedStopOption.id === 1) && (

                  <div className="sellorder-inputgroup position-relative relative">
                    <label className="label-price position-absolute">{labels?.forms?.stopOrder?.stopPrice}</label>
                    <label className="label-coin position-absolute">{activeMarket}</label>
                    <input 
                      value={stopSellFormData.stop}
                      onChange={handleSellStopChange}
                      name="stop"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0.00"
                      onBlur={handleBlurStopSell}
                      className={`sellorder-input ${stopSellIsInvalid.stop ? 'is-invalid' : ''}`}
                    />  
                  </div>
                )}

                  {selectedStopOption.id === 0 && ( 
                  <div className="sellorder-inputgroup position-relative relative">
                    <label className="label-price position-absolute">{labels?.forms?.stopOrder?.rate}</label>
                    <label className="label-coin position-absolute">{activeMarket}</label>
                    <input 
                      value={stopSellFormData.limit}
                      onChange={handleSellStopChange}
                      name="limit"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0.00"
                      onBlur={handleBlurStopSell}
                      className={`sellorder-input ${stopSellIsInvalid.limit ? 'is-invalid' : ''}`}
                    />  
                  </div>
                  )}

                  <div className="sellorder-inputgroup position-relative relative">
                    <label className="label-price position-absolute">{labels?.forms?.stopOrder?.volume}</label>
                    <label className="label-coin position-absolute">{coin}</label>
                    <input 
                      value={isNaN(stopSellFormData.amount) ? "0" : stopSellFormData.amount}
                      onChange={handleSellStopChange}
                      name="amount"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0.000000"
                      onBlur={handleBlurStopSell}
                      className={`sellorder-input ${stopSellIsInvalid.amount ? 'is-invalid' : ''}`}
                    />  
                  </div>


                  <PercentageSlider 
                    setActiveProcess={setActiveProcess}
                    activeProcess="stopSell"
                    sliding={sliding}
                    setSliding={setSliding}
                    color={"#EB5757"}
                    percentage={sellPercentage}
                    setPercentage={setSellPercentage}
                    name="sell"
                  />

                <div className="buyorder-inputgroup position-relative relative mt-4">
                    <label className="label-price position-absolute">{labels?.forms?.limitOrder?.total}</label>
                    <label className="label-coin position-absolute">{activeMarket}</label>
                    <input 
                      value={stopSellFormData.total}  
                      onChange={handleSellStopChange}
                      name="total"
                      type="text" 
                      autoComplete="off"
                      step="0.000001"
                      placeholder="0"
                      onBlur={handleBlurStopSell}
                      className={`sellorder-input ${stopSellIsInvalid.total ? 'is-invalid' : ''}`}
                    />  
                  </div>


                  {selectedStopOption.id === 2 && (
                  <>
                  <div className="sellorder-dropdown position-relative">
                  <button
                    type="button"
                    className="text-start small"
                    onClick={() => setTrailingSellOptionDropdownVisible(!trailingSellOptionDropdownVisible)}
                  >
                    {selectedSellTrailingOption.name}
                    <img src="/images/icons/chevron-down.svg" alt="" className="ps-2" />
                  </button>
                  <ul className={trailingSellOptionDropdownVisible ? "sellorder-options position-absolute show" : "sellorder-options position-absolute"}>
                    {trailingOptions.map((option, index) => (
                    <li key={index}>
                      <button type="button" onClick={() => handleSelectSellTrailingOption(option)}>
                        {option.name}
                      </button>
                    </li>
                    ))}

                  </ul>
                </div>

                  <div className="buyorder-inputgroup position-relative relative mt-2">
                  <label className="label-price position-absolute">{labels?.forms?.stopOrder?.trail}</label>
                  {selectedSellTrailingOption.id === 1 ? (
                    <label className="label-coin position-absolute">%</label>
                  ) : (
                    <label className="label-coin position-absolute">{activeMarket}</label>
                  )}
                  
                  <input 
                    value={stopSellFormData.trail}  
                    onChange={handleSellStopChange}
                    name="trail"
                    type="text" 
                    autoComplete="off"
                    step="0.000001"
                    placeholder="0"
                    onBlur={handleBlurStop}
                    className={`buyorder-input ${stopIsInvalid.trail ? 'is-invalid' : ''}`}
                  />  
                </div>
                </>
                  )}


                {userInfo ? (
                    <button 
                      type="button" 
                      className="btn btn-danger buyorder-action mt-4"
                      onClick={(e) => stopBuySell(e, 'SELL')}
                      >{labels?.tables?.offers?.sell} {coin}</button>
                  ) : (
                    <div className="guest-warn border-0">
                    {beforeLogin}
                    <a href="/login" className="pe-1">{loginText}</a>
                    {betweenText}
                    <a href="/register" className="px-1">{registerText}</a>
                    {afterRegister}
                  </div>
                  )}

                </form>

              </>

            )}
          </div>
        ))}
      </div>
    </div>
  );
};
