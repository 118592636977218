import React, { useState, useEffect, useContext, useRef } from "react";
import "./Trade.css";
import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";
import CoinInformation from "../../../Common/CoinInformation.json";
import { fetchMarketData } from "../../../Components/MarketData";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useModalContext } from "../../../Context/ModalContext";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../Context/WebSocketContext";

export const Portfolio = ({ tradeCompleted, setTradeCompleted, coin }) => {
  const { selectedNumberFormat, selectedFiatEstimation } = useModalContext();
  const { userOpenOrders } = useContext(WebSocketContext);

  const token = localStorage.getItem("token");

  const ws = useRef(null);

  const labels = useSelector((state) => state.labels.data);

  const tabs = [
    {
      id: 0,
      name: labels?.exchange?.openOrders,
    },
    {
      id: 1,
      name: labels?.exchange?.orderHistory,
    },
    {
      id: 2,
      name: labels?.exchange?.portfolio,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  const [tradeHistoryData, setTradeHistoryData] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchWord, setSearchWord] = useState();

  const [sortBy, setSortBy] = useState({ key: "date", ascending: false });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [hideBalances, setHideBalances] = useState(false);
  const [hideLowBalances, setHideLowBalances] = useState(false);

  const [btcValue, setBtcValue] = useState();
  const [USDTValue, setUSDTValue] = useState();

  const [balanceArr, setBalanceArr] = useState([]);

  const [totalInUsdt, setTotalInUsdt] = useState();
  const [totalInBtc, setTotalInBtc] = useState();

  const [getBalanceActivater, setGetBalanceActivater] = useState(new Date());

  const [initialPrices, setInitialPrices] = useState(() => {
    const storedData = localStorage.getItem("initialPrices");
    return storedData ? JSON.parse(storedData) : [];
  });

  const sortedOpenOrders = userOpenOrders?.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  const sortedTradeHistoryData = tradeHistoryData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortBy((prevSortBy) => ({
      key,
      ascending: prevSortBy.key === key ? !prevSortBy.ascending : true,
    }));
  };

  const sortedPortfolioData = portfolioData.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  const getSortClass = (key) => {
    if (sortBy.key !== key) return "table-sort-icon hidden";
    return sortBy.ascending ? "table-sort-icon" : "table-sort-icon rotated";
  };

  const fetchMarketDataFunction = async () => {
    let latestFiatData = await fetchMarketData();

    const filteredArray = latestFiatData.filter(
      (item) => item.currency === "BTC"
    );
    setBtcValue(filteredArray[0].rate);

    const filteredArray2 = latestFiatData.filter(
      (item) => item.currency === "USDT"
    );
    setUSDTValue(filteredArray2[0].rate);
  };

  const [staticConversions, setStaticConversions] = useState();

  const getSettings = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/GetSettings`, {
        headers: {
          Authorization: `Bearer ${token}`, // Bearer token gönderimi
        },
      })
      .then((response) => {
        setStaticConversions(response.data.data.static_conversions);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
      });
  };

  const getBalance = async () => {
    if (!token) {
      return;
    }
    getSettings();
    let tmpTotalInUsdt = 0;
    let tmpTotalInBtc = 0;
    await axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/api/GetBalance`,
        { currency: "ALL" },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Bearer token gönderimi
          },
        }
      )
      .then((response) => {
        const { coinListAll } = getBalanceCoinData(response.data.data);

        let tmpCoinListAll = coinListAll;

        const sortedCoinListAll = tmpCoinListAll.sort(
          (a, b) => b.balance - a.balance
        );

        if (initialPrices) {
          sortedCoinListAll.forEach((item2) => {
            // Find the corresponding item in Array1
            const correspondingItem1 = initialPrices.find(
              (item1) =>
                item1?.quote === "USDT" && item1?.base === item2?.currency
            );

            if (item2.currency == "USDT") {
              // item2.balance => Crypto Balance
              item2.balanceInUsdt = item2.balance * USDTValue;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt = item2.balanceInTrade * USDTValue;
              item2.balanceInTradeInBtc = item2.balanceInTradeInUsdt / btcValue;

              item2.total = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = item2.total * USDTValue;
              item2.totalInBtc = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt > 0) {
                tmpTotalInUsdt += item2.balanceInUsdt;
              }
              if (item2.balanceInBtc > 0) {
                tmpTotalInBtc += item2.balanceInBtc;
              }
            }

            // If found, calculate and assign myprice to the current item in Array2
            if (correspondingItem1) {
              // item2.balance => Crypto Balance
              item2.balanceInUsdt = correspondingItem1.price * item2.balance;
              item2.balanceInBtc = item2.balanceInUsdt / btcValue;

              // item2.balanceInTrade => Balance in Trade
              item2.balanceInTradeInUsdt =
                correspondingItem1.price * item2.balanceInTrade;
              item2.balanceInTradeInBtc = item2.balanceInTradeInUsdt / btcValue;

              item2.total = item2.balance - item2.balanceInTrade;
              item2.totalInUsdt = correspondingItem1.price * item2.total;
              item2.totalInBtc = item2.totalInUsdt / btcValue;

              if (item2.balanceInUsdt > 0) {
                tmpTotalInUsdt += item2.balanceInUsdt;
              }
              if (item2.balanceInBtc > 0) {
                tmpTotalInBtc += item2.balanceInBtc;
              }
            }
          });
        }

        setTotalInUsdt(tmpTotalInUsdt);
        setTotalInBtc(tmpTotalInBtc);

        setBalanceArr(sortedCoinListAll);
        setPortfolioData(sortedCoinListAll);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("GET isteği hatası:", error);
        setIsLoading(false);
      });
  };

  const getBalanceCoinData = (data) => {
    const serviceData = data ? [...data] : [];

    const coinInformation = CoinInformation;
    let coinListAll = [];

    for (let i = 0; i < serviceData.length; i++) {
      const coinName = serviceData[i].currency;

      if (coinInformation[coinName]?.ShortName) {
        serviceData[i].ShortName = coinInformation[coinName].ShortName;
        serviceData[i].LongName = coinInformation[coinName].LongName;
        coinListAll.push(serviceData[i]);
      } else {
        serviceData[i].ShortName = serviceData[i].currency;
        serviceData[i].LongName = serviceData[i].currency;
        coinListAll.push(serviceData[i]);
      }
    }
    return { coinListAll };
  };

  const changeStatus = () => {
    setGetBalanceActivater(new Date());
  };

  useEffect(() => {
    const myTimer = setInterval(changeStatus, 2500);
    return () => clearInterval(myTimer);
  }, [getBalanceActivater]);

  useEffect(() => {
    localStorage.setItem("initialPrices", JSON.stringify(initialPrices));
  }, [initialPrices]);

  useEffect(() => {
    setIsLoading(true);
    getBalance();
    fetchMarketDataFunction();
    setIsLoading(false);
  }, [getBalanceActivater]);

  useEffect(() => {
    setIsLoading(true);

    const largeCount = 1000;

    // Trade history verilerini al
    const fetchTradeHistory = async () => {
      try {
        if (!token) {
          return;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_REST_URL}/api/TradeHistory?side=ALL&pair=ALL&page=1&count=${largeCount}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const latestData = response?.data?.data?.rows;
        const filteredData = latestData.filter(
          (item) => item.trade === coin?.base && item.market === coin.quote
        );

        setTradeHistoryData(filteredData);
      } catch (error) {
        console.error("GET isteği hatası:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTradeHistory();
  }, [tradeCompleted, coin, token, searchWord]);

  const totalItems = tradeHistoryData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    activeTab.id === 0
      ? sortedOpenOrders?.slice(indexOfFirstItem, indexOfLastItem)
      : sortedTradeHistoryData.slice(indexOfFirstItem, indexOfLastItem);

  let isInstitutional = false;
  if (process.env.REACT_APP_IS_INSTITUTIONAL === "true") {
    isInstitutional = true;
  }

  const [deletedOrderId, setDeletedOrderId] = useState();

  const cancelOrder = async (id, side, pair) => {
    const requestBody = {
      side: side,
      pair: pair,
      orderId: id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_REST_URL}/api/CancelOrder`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status != "Error") {
        const message = labels?.messages?.orderCancelled;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTradeCompleted(!tradeCompleted);
      } else {
        const message = response.data.message;
        toast.error(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("error:", error);
    }
  };

  const improvedFormatNumberWithComma = (number) => {
    if (!number) return "";

    const parts = number.toString().split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";

    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;
  };

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  /*
  const uniqueItems = currentItems?.filter((item, index, self) =>
  index === self.findIndex((t) => t.orderId === item.orderId)
); */

  const [fiatPrices, setFiatPrices] = useState();
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState();

  const fetchFiatPrices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_REST_URL}/api/get_fiat_price`
      );
      const rateList = response?.data?.data?.rateList;

      setFiatPrices(rateList);

      const selectedCurrency = rateList.find(
        (item) => item.currency === selectedFiatEstimation?.name
      );

      if (selectedCurrency) {
        setSelectedCurrencyRate(selectedCurrency.rate);
      }
    } catch (error) {
      console.error("Error fetching fiat prices:", error);
    }
  };

  useEffect(() => {
    fetchFiatPrices();
  }, [selectedFiatEstimation]);

  return (
    <div className="px-1 px-lg-0 px-md-0 pt-3 border-top custom-border-color border-secondary border-end">
      <ul
        className="nav nav-pills ps-0 ps-lg-3 ps-md-3"
        id="pills-tab"
        role="tablist"
      >
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link rounded-3 py-1 px-2 px-lg-3 px-md-2 ${
                activeTab.id === tab.id ? "active" : "text-muted"
              }`}
              id={`pills-${tab?.name?.toLowerCase()}-tab`}
              data-bs-toggle="pill"
              data-bs-target={`#pills-${tab?.name?.toLowerCase()}`}
              type="button"
              role="tab"
              aria-controls={`pills-${tab?.name?.toLowerCase()}`}
              aria-selected={activeTab.id === tab.id}
              onClick={() => handleTabClick(tab)}
            >
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane fade ${
              activeTab.id === tab.id ? "show active" : ""
            }`}
            id={`pills-${tab?.name?.toLowerCase()}`}
            role="tabpanel"
            aria-labelledby={`pills-${tab?.name?.toLowerCase()}-tab`}
            tabIndex={0}
          >
            {tab?.name === labels?.exchange?.openOrders && (
              <div className="table-responsive table-trade-wrapper">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                    <tr>
                      <th>{labels?.tables?.openOrdersTable?.date}</th>
                      <th>{labels?.tables?.openOrdersTable?.pair}</th>
                      <th>{labels?.tables?.openOrdersTable?.type}</th>
                      <th>{labels?.tables?.openOrdersTable?.side}</th>
                      <th>{labels?.tables?.openOrdersTable?.price}</th>
                      <th>{labels?.tables?.openOrdersTable?.stopprice}</th>
                      <th>{labels?.tables?.openOrdersTable?.pending}</th>
                      <th>{labels?.tables?.openOrdersTable?.volume}</th>
                      <th>{labels?.tables?.openOrdersTable?.action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {token && activeTab.id === 0 ? (
                      <>
                        {currentItems
                          .filter(
                            (order, index, self) =>
                              index ===
                              self.findIndex(
                                (o) => o.order_id === order.order_id
                              )
                          )
                          .filter((order) => order.order_id !== deletedOrderId)
                          .map((open, index) => {
                            const pairString = open?.base + "_" + open?.quote;
                            const actionType = open?.side.toLocaleLowerCase();
                            return (
                              <tr key={index} className="small">
                                <td>
                                  <div className="date ps-2">
                                    {splitDateTime(open.timestamp).date}
                                    <span className="d-block ms-0">
                                      {splitDateTime(open.timestamp).time}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="pair d-flex align-items-center gap-2">
                                    <img
                                      src={`/images/coins/${open?.base.toLowerCase()}.svg`}
                                      alt=""
                                    />
                                    {open?.base}/{open?.quote}
                                  </div>
                                </td>
                                <td>{open.type}</td>
                                <td
                                  className={
                                    open.side === "BUY"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {labels?.forms?.postOffer?.[
                                    actionType
                                  ].toUpperCase()}
                                </td>
                                <td>{open.rate}</td>
                                <td>{open.stop_price}</td>
                                <td>{open.pending}</td>
                                <td>{open.volume}</td>
                                <td>
                                  {!isInstitutional ? (
                                    <button
                                      className="btn trade-btn"
                                      onClick={() =>
                                        cancelOrder(
                                          open.order_id,
                                          open.side,
                                          pairString
                                        )
                                      }
                                    >
                                      {labels?.buttons?.cancel}
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {tab?.name === labels?.exchange?.orderHistory && (
              <div className="table-responsive table-trade-wrapper table-trade-history">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                    <tr className="small">
                      <th style={{ width: "5%" }}></th>
                      <th>{labels?.tables?.tradeHistory?.date}</th>
                      <th>{labels?.tables?.tradeHistory?.pair}</th>
                      <th>{labels?.tables?.tradeHistory?.type}</th>
                      <th>{labels?.tables?.tradeHistory?.price}</th>
                      <th>{labels?.tables?.tradeHistory?.size}</th>
                      <th>{labels?.tables?.tradeHistory?.volume}</th>
                      <th>{labels?.tables?.tradeHistory?.amount}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {token && activeTab.id === 1 ? (
                      <>
                        {currentItems?.map((trade, index) => {
                          const actionType = trade.side.toLowerCase();
                          return (
                            <React.Fragment>
                              <tr key={index} className="small">
                                <td style={{ width: 60 }}>
                                  <button
                                    onClick={() => toggleRow(trade.orderId)}
                                    className="bg-transparent border-0"
                                  >
                                    <span
                                      className={
                                        expandedRows[trade.orderId]
                                          ? "chevron-rotated"
                                          : "chevron"
                                      }
                                    >
                                      <img src="/images/icons/chevron-down.svg" />
                                    </span>
                                  </button>
                                </td>
                                <td>
                                  <div className="date ps-2">
                                    {splitDateTime(trade?.date).date}
                                    <span className="d-block ms-0">
                                      {splitDateTime(trade?.date).time}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="pair d-flex align-items-center gap-2">
                                    <img
                                      src={`/images/coins/${trade?.trade.toLowerCase()}.svg`}
                                      width={32}
                                      height={32}
                                      alt=""
                                    />
                                    {trade?.trade}/{trade?.market}
                                  </div>
                                </td>
                                <td
                                  className={
                                    trade.side === "BUY"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {labels?.forms?.postOffer?.[
                                    actionType
                                  ].toUpperCase()}
                                </td>
                                <td>
                                  {formatNumberWithComma(
                                    trade?.rate,
                                    selectedNumberFormat
                                  )}
                                </td>
                                <td>{trade?.serviceCharge}</td>
                                <td>{trade?.volume}</td>
                                <td>{trade?.amount}</td>
                              </tr>
                              {expandedRows[trade.orderId] && (
                                <tr className="table-accordion-wrapper">
                                  <td colSpan="10">
                                    <div className="accordion-content">
                                      <span>Order ID: {trade.orderId}</span>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {tab?.name === labels?.exchange?.portfolio && (
              <div className="table-responsive table-trade-wrapper table-portfolio">
                <table className="table table-sm table-open-orders mb-0">
                  <thead className="w-100 border-bottom custom-border-color border-secondary pb-3">
                    <tr className="small">
                      <th onClick={() => handleSort("currency")}>
                        <span className="pe-2">
                          {labels?.tables?.NDWalletOverview?.currency}
                        </span>
                        <span className={getSortClass("currency")}>
                          <img
                            src="./images/icons/top.png"
                            width={16}
                            height={16}
                          />
                        </span>
                      </th>
                      <th onClick={() => handleSort("balance")}>
                        <span className="pe-2">
                          {labels?.tables?.NDWalletOverview?.balance}
                        </span>
                        <span className={getSortClass("balance")}>
                          <img
                            src="./images/icons/top.png"
                            width={16}
                            height={16}
                          />
                        </span>
                      </th>
                      <th onClick={() => handleSort("balanceInTrade")}>
                        <span className="pe-2">
                          {labels?.tables?.NDWalletOverview?.inOrders}
                        </span>
                        <span className={getSortClass("balanceInTrade")}>
                          <img
                            src="./images/icons/top.png"
                            width={16}
                            height={16}
                          />
                        </span>
                      </th>
                      <th onClick={() => handleSort("total")}>
                        <span className="pe-2">{labels?.exchange?.total}</span>
                        <span className={getSortClass("total")}>
                          <img
                            src="./images/icons/top.png"
                            width={16}
                            height={16}
                          />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {token && activeTab.id === 2 ? (
                      <>
                        {sortedPortfolioData?.map((item, index) => {
                          const formattedBalance1 =
                            item?.balanceInUsdt * selectedCurrencyRate;
                          return (
                            <tr key={index}>
                              <td className="px-0 px-lg-3">
                                <div className="pair d-flex flex-column flex-lg-row align-items-start align-items-lg-center gap-2 ps-2">
                                  <img
                                    src={`/images/coins/${item.ShortName.toLowerCase()}.svg`}
                                    width={32}
                                    height={32}
                                  />
                                  {item.LongName} / {item.ShortName}
                                </div>
                              </td>
                              <td>
                                <div className="date">
                                  <span className="text-success test-format d-none">
                                    {formatNumberWithComma(
                                      82478392528496,
                                      selectedNumberFormat
                                    )}
                                  </span>
                                  {item.balance && !hideBalances
                                    ? formatNumberWithComma(
                                        item.balance,
                                        selectedNumberFormat
                                      )
                                    : ""}{" "}
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.balance ? "0" : ""}
                                  &nbsp;{item.ShortName} <br />
                                  <span className="ml0">
                                    {selectedFiatEstimation?.name}&nbsp;
                                    {item?.balanceInUsdt && !hideBalances
                                      ? formatNumberWithComma(
                                          formattedBalance1,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.balanceInUsdt
                                      ? "0"
                                      : ""}
                                  </span>
                                  <br />
                                  <span className="ml0">
                                    {item?.balanceInBtc && !hideBalances
                                      ? formatNumberWithComma(
                                          item?.balanceInBtc,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.balanceInBtc
                                      ? "0"
                                      : ""}
                                    &nbsp;BTC
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="date">
                                  {item.balanceInTrade && !hideBalances
                                    ? formatNumberWithComma(
                                        item?.balanceInTrade,
                                        selectedNumberFormat
                                      )
                                    : ""}{" "}
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.balanceInTrade
                                    ? "0"
                                    : ""}
                                  &nbsp;{item.ShortName}
                                  <br />
                                  <span className="ml0">
                                    {selectedFiatEstimation?.name}&nbsp;
                                    {item.balanceInUsdt && !hideBalances
                                      ? formatNumberWithComma(
                                          formattedBalance1,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.balanceInTradeInUsdt
                                      ? "0"
                                      : ""}
                                  </span>
                                  <br />
                                  <span className="ml0">
                                    {item?.balanceInTradeInBtc && !hideBalances
                                      ? formatNumberWithComma(
                                          item?.balanceInTradeInBtc,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.balanceInTradeInBtc
                                      ? "0"
                                      : ""}
                                    &nbsp;BTC
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="date">
                                  {item.total && !hideBalances
                                    ? formatNumberWithComma(
                                        item?.total,
                                        selectedNumberFormat
                                      )
                                    : ""}{" "}
                                  {hideBalances ? "******" : ""}
                                  {!hideBalances && !item.total ? "0" : ""}
                                  &nbsp;{item.ShortName}
                                  <br />
                                  <span className="ml0">
                                    {selectedFiatEstimation?.name}&nbsp;
                                    {item.totalInUsdt && !hideBalances
                                      ? formatNumberWithComma(
                                          item.totalInUsdt *
                                            selectedCurrencyRate,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.totalInUsdt
                                      ? "0"
                                      : ""}
                                  </span>
                                  <br />
                                  <span className="ml0">
                                    {item?.totalInBtc && !hideBalances
                                      ? formatNumberWithComma(
                                          item?.totalInBtc,
                                          selectedNumberFormat
                                        )
                                      : ""}
                                    {hideBalances ? "******" : ""}
                                    {!hideBalances && !item.totalInBtc
                                      ? "0"
                                      : ""}
                                    &nbsp;BTC
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <ul className="pagination pagination-container">
              {((activeTab.id === 0 && userOpenOrders.length > 10) ||
                (activeTab.id === 1 && tradeHistoryData.length > 10)) && (
                <>
                  <li>
                    {currentPage > 1 ? (
                      <button
                        className="btn btn-pagination"
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        {labels?.pagination?.prev}
                      </button>
                    ) : (
                      <button className="btn btn-pagination disabled" disabled>
                        {labels?.pagination?.prev}
                      </button>
                    )}
                  </li>
                  <li>
                    {activeTab.id === 0 &&
                    indexOfLastItem < userOpenOrders.length ? (
                      <button
                        className="btn btn-pagination"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        {labels?.pagination?.next}
                      </button>
                    ) : activeTab.id === 1 &&
                      indexOfLastItem < tradeHistoryData.length ? (
                      <button
                        className="btn btn-pagination"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        {labels?.pagination?.next}
                      </button>
                    ) : (
                      <button className="btn btn-pagination disabled" disabled>
                        {labels?.pagination?.next}
                      </button>
                    )}
                  </li>
                </>
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
