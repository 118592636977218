import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./FirstVerification.css";
import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";
import axios from "axios";
import { useSelector } from "react-redux";

function FirstVerification() {
  const labels = useSelector((state) => state.labels.data);
  const { id } = useParams();

  useEffect(() => {
    const checkValidation = async () => {
      const response = await axios.post(
        process.env.REACT_APP_API_REST_URL + "/api/VerifyAccount",
        {
          otp: id,
        }
      );
      // console.log(id + "....." + JSON.stringify(response) )
    };

    checkValidation();
  }, []);

  return (
    <>
      <Header />

      <div className="row">
        <div
          className="col-md-12 mx-auto"
          style={{ paddingTop: 100, paddingBottom: 100, color: "#ccc" }}
        >
          <h3 align="center">{labels?.forms?.signUp?.success?.heading}</h3>
          <br />
          <br />
          <h6 align="center">
            <Link to="/login">{labels?.navbar?.links?.login}</Link>
          </h6>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FirstVerification;
