import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const NotFound = () => {
  const labels = useSelector((state) => state.labels.data);

  return (
    <div className="page-not-found">
      <h1>{labels?.errorBoundary?.notFound?.title}</h1>
      <p>{labels?.errorPages?.pageNotFound}</p>
      <Link to="/">{labels?.errorBoundary?.backHome}</Link>
    </div>
  );
};

export default NotFound;
