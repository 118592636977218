import { Link, useNavigate } from "react-router-dom";
import Header from '../../../Components/Header';
import PriceBar from '../../../Components/PriceBar/';
import Footer from '../../../Components/Footer/';
import { useModalContext } from "../../../Context/ModalContext";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function AdditionalInformationRequired() {
  const navigate = useNavigate(); 
  const labels = useSelector(state => state.labels.data);


  const { selectedTheme, additionalInformation, setAdditionalInformation } = useModalContext();
  const [formValues, setFormValues] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState({}); 

  const handleChange = (id, value) => {
    const safeCharactersRegex = /^[a-zA-Z0-9\s.,]*$/;
  
    if (!safeCharactersRegex.test(value)) {
      return;
    }
  
    setFormValues({ ...formValues, [id]: value });
  };
  

  const handleDropdownToggle = (id) => {
    setDropdownOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleOptionSelect = (id, value) => {
    handleChange(id, value);
    setDropdownOpen((prev) => ({ ...prev, [id]: false })); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    const payload = {
      data: Object.keys(formValues).map(key => ({
        fieldID: key,
        fieldValue: formValues[key]
      }))
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_REST_URL}/api/Customer_AddtionalFields`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      if (responseData?.status === "Success") {
        const message = labels?.forms?.additionalInformation?.successMessage;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }); 
        localStorage.removeItem('additionalInformation');
        setAdditionalInformation(null);
        navigate('/home');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      <PriceBar />

      <div className="row section-additional-info">
        <div className="col-md-12 centered-items">
          <form className="additional-information-form p-5" style={{ maxWidth: 580 }} onSubmit={handleSubmit}>
            <Link to="/" className="logo">
              <img className="destop-logo"
                   src={selectedTheme?.name === "light" ? "/images/logo-black.svg" : "/images/logo.svg"}
                   alt="" />
            </Link>
            <h3 className="form-title fs-4 mt-4">{labels?.forms?.additionalInformation?.heading}</h3>
            <p className="form-text">{labels?.forms?.additionalInformation?.description}</p>

            {additionalInformation?.map(field => {
              switch (field.fieldType) {
                case "TextBox":
                  return (
                    <div className="form-group mt-3" key={field.id}>
                      <label htmlFor={`input-${field.id}`}>{field.fieldLabel}</label>
                      <input
                        className={`form-control mt-2`}
                        type="text"
                        id={`input-${field.id}`}
                        placeholder={field.placeholder || ''}
                        onChange={(e) => handleChange(field.id, e.target.value)}
                        required={field.requiredValidation}
                      />
                    </div>
                  );

                  case "DropDownList":
                    const options = field.placeholder ? field.placeholder.split(',') : []; 
                    return (
                      <div className="form-group mt-3" key={field.id}>
                        <label>{field.fieldLabel}</label>
                        <div className="dropdown mt-2">
                          <button
                            className="btn btn-secondary dropdown-toggle w-100"
                            type="button"
                            onClick={() => handleDropdownToggle(field.id)}
                            aria-expanded={dropdownOpen[field.id]}
                          >
                            {formValues[field.id] || "Select..."}
                          </button>
                          <ul className={`dropdown-menu ${dropdownOpen[field.id] ? 'show' : ''}`}>
                            {options.map((option, index) => (
                              <li key={index}>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleOptionSelect(field.id, option.trim())} 
                                >
                                  {option.trim()} 
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    );
                  
                  

                case "CheckBox":
                  return (
                  <div className="form-group mt-3" key={field.id}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${field.id}`} 
                        onChange={(e) => handleChange(field.id, e.target.checked)}
                        required={field.requiredValidation}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${field.id}`}>
                        {field.fieldLabel}
                      </label>
                    </div>
                  </div>
                  );

                default:
                  return null;
              }
            })}

            <button type="submit" className="btn btn-primary mt-3 w-100">{labels?.buttons?.submit}</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AdditionalInformationRequired;
