import React from 'react';
import { useSelector } from "react-redux";

function SelectDepositType({ value, onChange }) {
  const labels = useSelector((state) => state.labels.data);

  const handleOnChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <select
      className="form-control"
      onChange={handleOnChange}
    >
      <option value="SELECT">{labels?.forms?.fiatDeposit?.paymentMethod}</option>
      <option value="bankTransfer" selected={value === "bankTransfer"} >{labels?.forms?.fiatDeposit?.bank}</option>
      <option value="card" selected={value === "card"}>{labels?.forms?.fiatDeposit?.online}</option>
    </select>
  )
}

export default SelectDepositType;
