import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "./TradingRules.css";

import Header from "../../../Components/Header";
import SideBar from "../../../Components/SideBar";
import Footer from "../../../Components/Footer/";

import { splitDateTime, formatNumberWithComma } from "../../../Common/Helpers";

import axios from "axios";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../Context/WebSocketContext";

function TradingRules() {
  const labels = useSelector((state) => state.labels.data);

  const [isLoading, setIsLoading] = useState(true);


  const { MKData } = useContext(WebSocketContext);
  const [tableShowData, setTableShowData] = useState(MKData);
  const [searchWord, setSearchWord] = useState();
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!isSearching) {
      setTableShowData(MKData)
    }
  }, [MKData, isSearching])

  /*
   * Pagination and Orderby
   */

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // const [sortBy, setSortBy] = useState({ key: null, ascending: true });
  const [sortBy, setSortBy] = useState({ key: "startedOn", ascending: false });

  // Function to sort data based on column key and sort order
  const sortedData = tableShowData?.sort((a, b) => {
    if (sortBy.key) {
      const valueA = a[sortBy.key];
      const valueB = b[sortBy.key];
      if (valueA < valueB) return sortBy.ascending ? -1 : 1;
      if (valueA > valueB) return sortBy.ascending ? 1 : -1;
    }
    return 0;
  });

  // Function to handle column header click for sorting
  const handleSort = (key) => {
    if (sortBy.key === key) {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else {
      setSortBy({ key, ascending: true });
    }
  };

  // Function to handle pagination
  const totalItems = sortedData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [itemsPerPage, totalItems, totalPages]);

  let indexOfLastItem = currentPage * itemsPerPage;

  if (indexOfLastItem > totalItems) {
    indexOfLastItem = totalItems;
  }

  let indexOfFirstItem = indexOfLastItem - itemsPerPage;

  if (indexOfFirstItem < 0) {
    indexOfFirstItem = 0;
  }

  const currentItems = sortedData?.slice(indexOfFirstItem, indexOfLastItem);

  /*
   * Pagination and Orderby Ended
   */

  const handleSearch = (e) => {
    let newKeyword = e.target.value;
    setSearchWord(newKeyword);

    if (newKeyword) {
      setIsSearching(true);
      let tmpSearchWord = newKeyword;

      let filteredShowArray = tableShowData.filter((item) =>
        item.base
          .toLocaleLowerCase("en-US")
          .includes(tmpSearchWord.toLocaleLowerCase("en-US"))
      );

      setTableShowData(filteredShowArray);
    } else {
      setIsSearching(false);
    }
  };

  return (
    <>
      <Header />
      {/* Main */}
      <section className="main-page">
        <div className="main-content">
          <h4 className="title">{labels?.tables?.tradingRules?.title}</h4>
          <div className="row">
            <div className="col-lg-12 mb20">
              <form className="form-flex">
                <div className="search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.75 15.75L11.25 11.25M12.75 7.5C12.75 8.18944 12.6142 8.87213 12.3504 9.50909C12.0865 10.146 11.6998 10.7248 11.2123 11.2123C10.7248 11.6998 10.146 12.0865 9.50909 12.3504C8.87213 12.6142 8.18944 12.75 7.5 12.75C6.81056 12.75 6.12787 12.6142 5.49091 12.3504C4.85395 12.0865 4.2752 11.6998 3.78769 11.2123C3.30018 10.7248 2.91347 10.146 2.64963 9.50909C2.3858 8.87213 2.25 8.18944 2.25 7.5C2.25 6.10761 2.80312 4.77226 3.78769 3.78769C4.77226 2.80312 6.10761 2.25 7.5 2.25C8.89239 2.25 10.2277 2.80312 11.2123 3.78769C12.1969 4.77226 12.75 6.10761 12.75 7.5Z"
                      stroke="#9CA3AF"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder={labels?.forms?.common?.search}
                    value={searchWord}
                    onChange={handleSearch}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={13}
                    height={14}
                    viewBox="0 0 13 14"
                    fill="none"
                  >
                    <path
                      d="M6.49996 6.78779L6.14641 6.43424L3.35601 3.64384C3.35599 3.64382 3.35597 3.64381 3.35596 3.64379C3.32783 3.6157 3.28971 3.59992 3.24996 3.59992C3.21023 3.59992 3.17213 3.61568 3.14402 3.64373C3.14398 3.64377 3.14394 3.64381 3.14391 3.64384M6.49996 6.78779L3.14391 3.64384M6.49996 6.78779L6.85351 6.43424L9.64396 3.64379L9.64402 3.64384L9.65005 3.63759C9.66389 3.62326 9.68044 3.61184 9.69874 3.60398C9.71704 3.59611 9.73673 3.59198 9.75664 3.5918C9.77656 3.59163 9.79631 3.59542 9.81475 3.60297C9.83318 3.61051 9.84993 3.62165 9.86401 3.63573C9.8781 3.64981 9.88923 3.66656 9.89678 3.685C9.90432 3.70343 9.90811 3.72319 9.90794 3.7431C9.90777 3.76302 9.90363 3.7827 9.89577 3.801L10.35 3.99612L9.89577 3.801C9.88791 3.8193 9.87648 3.83585 9.86215 3.84969L9.8621 3.84964L9.85596 3.85578L7.06551 6.64623L6.71195 6.99978L7.06551 7.35334L9.85181 10.1396C9.8779 10.1677 9.8923 10.2047 9.89197 10.2431C9.89162 10.2824 9.87585 10.3201 9.84804 10.3479C9.82023 10.3757 9.7826 10.3914 9.74328 10.3918C9.70488 10.3921 9.66786 10.3777 9.63981 10.3516L6.85351 7.56533L6.49996 7.21178L6.14641 7.56533L3.36011 10.3516C3.33205 10.3777 3.29503 10.3921 3.25664 10.3918C3.21731 10.3914 3.17969 10.3757 3.15188 10.3479C3.12407 10.3201 3.10829 10.2824 3.10795 10.2431C3.10762 10.2047 3.12201 10.1677 3.14811 10.1396L5.93441 7.35334L6.28797 6.99978L5.93441 6.64623L3.14402 3.85583M6.49996 6.78779L3.14402 3.85583M3.14391 3.64384C3.11585 3.67196 3.1001 3.71006 3.1001 3.74978C3.1001 3.78953 3.11587 3.82766 3.14396 3.85578M3.14391 3.64384L3.14396 3.85578M3.14396 3.85578C3.14398 3.8558 3.144 3.85582 3.14402 3.85583M3.14396 3.85578L3.14402 3.85583"
                      fill="#111928"
                      stroke="#9CA3AF"
                    />
                  </svg>
                </div>
              </form>
            </div>
          </div>
          <div className="mt5">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("base")}
                    >
                      {sortBy.key === "base" ? (
                        <u>{labels?.tables?.tradingRules?.tradingPair}</u>
                      ) : (
                        labels?.tables?.tradingRules?.tradingPair
                      )}
                      {sortBy.key === "base" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "base" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("min_trade_amount")}
                    >
                      {sortBy.key === "min_trade_amount" ? (
                        <u>{labels?.tables?.tradingRules?.minTradeAmount}</u>
                      ) : (
                        labels?.tables?.tradingRules?.minTradeAmount
                      )}
                      {sortBy.key === "min_trade_amount" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "min_trade_amount" &&
                      !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("min_tick_size")}
                    >
                      {sortBy.key === "min_tick_size" ? (
                        <u>{labels?.tables?.tradingRules?.minTickSize}</u>
                      ) : (
                        labels?.tables?.tradingRules?.minTickSize
                      )}
                      {sortBy.key === "min_tick_size" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "min_tick_size" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th
                      style={{
                        width: "18%",
                        maxWidth: "18%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleSort("min_order_value")}
                    >
                      {sortBy.key === "min_order_value" ? (
                        <u>{labels?.tables?.tradingRules?.minOrderValue}</u>
                      ) : (
                        labels?.tables?.tradingRules?.minOrderValue
                      )}
                      {sortBy.key === "min_order_value" && sortBy.ascending ? (
                        <b>&nbsp; &#9650;</b>
                      ) : (
                        <></>
                      )}
                      {sortBy.key === "min_order_value" && !sortBy.ascending ? (
                        <b>&nbsp; &#9660;</b>
                      ) : (
                        <></>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <div className="pair">
                            <img
                              src={`/images/coins/${item.base.toLowerCase()}.svg`}
                              alt=""
                            />
                            {item?.base}/{item?.quote}
                          </div>
                        </td>
                        <td>
                          {item?.min_trade_amount} {item?.base}
                        </td>
                        <td>
                          {item?.min_tick_size} {item?.quote}
                        </td>
                        <td>
                          {item?.min_order_value} {item?.quote}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {isLoading ? (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <img src="/images/loading.gif" width={50} />
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.loading}</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key="1" style={{ height: 200 }}>
                          <td colSpan={6} align="center">
                            <br />
                            <br />
                            <span style={{ color: "#ccc" }}>{labels?.generics?.noData}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <ul className="pagination">
              <li>
                {currentPage > 1 ? (
                  <Link onClick={() => setCurrentPage(currentPage - 1)}>
                    {labels?.pagination?.prev}
                  </Link>
                ) : (
                  <Link style={{ color: "#555" }}  className="disabled">
                    {" "}
                    {labels?.pagination?.prev}
                  </Link>
                )}
              </li>
              <li>
                {indexOfLastItem < tableShowData?.length ? (
                  <Link onClick={() => setCurrentPage(currentPage + 1)}>
                    {labels?.pagination?.next}
                  </Link>
                ) : (
                  <Link style={{ color: "#555" }} className="disabled">
                    {" "}
                    {labels?.pagination?.next}
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default TradingRules;
