import axios from "axios";

const getSettings = async () => {
  const response = await axios.get(
    `${process?.env?.REACT_APP_API_REST_URL}/api/GetSettings`
  );
  return response?.data;
};

const settingsService = {
  getSettings,
};

export default settingsService;
