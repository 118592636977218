import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";

import Header from "../../../Components/Header";
import PriceBar from "../../../Components/PriceBar";
import Footer from "../../../Components/Footer";
import { WebSocketContext } from "../../../Context/WebSocketContext";
import { useCoinContext } from "../../../Context/CoinContext";
import { useDispatch, useSelector } from "react-redux";
import { getMarketSummary } from "../../../Features/Market/marketSlice";
import { toast } from "react-toastify";

import axios from "axios";
import { getBalance } from "../../../Components/Wallet";
import {
  cancelOrder,
  getOrderHistory,
  placeOrder,
} from "../../../Features/Order/orderSlice";
import { getTradeHistory } from "../../../Features/Trade/tradeSlice";
import "./index.css";
function Title({ title }) {
  return <h6>{title}</h6>;
}
function Market() {
  const [marketData, setMarketData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredMarketData, setFilteredMarketData] = useState([]);
  const [defaultPair, setDefaultPair] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { orderHistory } = useSelector((state) => state.order);
  const { tradeHistory } = useSelector((state) => state.trade);
  const [tradeSettingsData, setTradeSettingsData] = useState(null);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [quantity, setQuantity] = useState(1);
  const [pricePrecision, setPricePrecision] = useState(0);
  const [minTradeAmount, setMinTradeAmount] = useState(0);
  const [marketBalance, setMarketBalance] = useState(0);
  const [coinBalance, setCoinBalance] = useState(0);
  const [balanceData, setBalanceData] = useState([]);
  const [stepsInitialArray, setStepsInitialArray] = useState([]);
  const { asks, bids } = useContext(WebSocketContext);
  const { setSelectedPair } = useContext(WebSocketContext);
  const { userOpenOrders } = useContext(WebSocketContext);
  const [tableShowData, setTableShowData] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [tradeHistoryPages, setTradeHistoryPages] = useState(0);
  const [orderHistoryPages, setOrderHistoryPages] = useState(0);
  const [orderHistoryPage, setOrderHistoryPage] = useState(1);
  const [tradeHistoryPage, setTradeHistoryPage] = useState(1);
  const [showPlaceOrderButton, setShowPlaceOrderButton] = useState(false);
  const [orderSide, setOrderSide] = useState("SELL");
  const [orderType, setOrderType] = useState("FOK");
  const [quantityPrecision, setQuantityPrecision] = useState(0);
  const { isError } = useSelector((state) => state?.order);
  const [bps, setBPS] = useState("1");
  const [tableTab, setTableTab] = useState("open-orders");
  const [rfqQuote, setRFQQuote] = useState("BTC");
  const [rfqMarket, setRFQMarket] = useState("USDT");
  const [rfqSide, setRFQSide] = useState("buy");
  const [rfqQuantity, setRFQQuantity] = useState(0);
  const [rfqPrice, setRFQPrice] = useState(0);
  const [showExecute, setShowExecute] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const { newCoin, activeMarket, setNewCoin, setActiveMarket } =
    useCoinContext();

  useEffect(() => {
    dispatch(getOrderHistory({ orderHistoryPage, pageSize })).then((d) => {
      const data = d?.payload?.data?.pageInfo?.totalRows;
      setOrderHistoryPages(Math.ceil(data / pageSize));
    });
  }, [tableTab, orderHistoryPage]);

  useEffect(() => {
    dispatch(getTradeHistory({ tradeHistoryPage, pageSize })).then((d) => {
      const data = d?.payload?.data?.pageInfo?.totalRows;
      setTradeHistoryPages(Math.ceil(data / pageSize));
    });
  }, [tableTab, tradeHistoryPage]);

  const extractData = useCallback((data) => {
    return Object.entries(data).map(
      ([pairName, { LowestAsk, HeighestBid }]) => ({
        pairName,
        LowestAsk,
        HeighestBid,
      })
    );
  }, []);

  const getSettings = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_REST_URL}/api/GetSettings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDefaultPair(response?.data?.data?.default_Pair);
        setTradeSettingsData(response?.data?.data?.trade_setting);
      })
      .catch((error) => {
        toast.error(error?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const fetchMarketSummary = useCallback(() => {
    dispatch(getMarketSummary()).then((response) => {
      const data = extractData(response?.payload?.data);

      setMarketData(data);

      const regex = new RegExp(searchValue, "i");
      const filtered = data.filter((item) => regex.test(item.pairName));
      setFilteredMarketData(filtered);
    });
  }, [dispatch, extractData, searchValue]);

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    fetchMarketSummary();
    getBalance().then((d) => {
      setBalanceData(d?.data?.data);
    });

    const interval = setInterval(() => {
      fetchMarketSummary();
      getBalance().then((d) => {
        setBalanceData(d?.data?.data);
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchMarketSummary, getBalance]);

  useEffect(() => {
    for (let i = 0; i < balanceData.length; i++) {
      if (balanceData[i]?.currency === newCoin) {
        setCoinBalance(balanceData[i]?.balance);
      }
      if (balanceData[i]?.currency === activeMarket) {
        setMarketBalance(balanceData[i]?.balance);
      }
    }
  }, [balanceData, newCoin, activeMarket]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const regex = new RegExp(value, "i");
    const filtered = marketData.filter((item) => regex.test(item.pairName));
    setFilteredMarketData(filtered);
  };

  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleSpotView = (pairName) => {
    const pair = pairName.split("_").reverse().join("_");
    setDefaultPair(pair);
  };

  useEffect(() => {
    const nc = defaultPair?.split("_")[0];
    const nm = defaultPair?.split("_")[1];
    setNewCoin(nc);
    setActiveMarket(nm);
    if (newCoin && activeMarket) {
      const pair = `OB.${nc}_${nm}`;
      setSelectedPair(pair);
    }
  }, [newCoin, activeMarket, setSelectedPair, setNewCoin, defaultPair]);

  function calculateAveragePrice(orderBook, quantity = 0) {
    let totalCost = 0;
    let seekQty = quantity;
    let aggregatedQty = 0;
    let lastPrice = 0;
    for (let i = 0; i < orderBook.length; i++) {
      const [price, availableQty] = orderBook[i];
      if (availableQty >= seekQty) {
        totalCost += seekQty * price;
        lastPrice = price;
        aggregatedQty = quantity;
        break;
      } else {
        totalCost += availableQty * price;
        lastPrice = price;
        aggregatedQty += availableQty;
        seekQty -= availableQty;
      }
    }

    if (aggregatedQty >= quantity) {
      const obj = {
        lastPrice,
        averagePrice: totalCost / quantity,
      };
      return obj;
    }

    const obj = {
      lastPrice: 0,
      averagePrice: 0,
    };

    return obj;
  }

  const averageAskPrice = useMemo(
    () => calculateAveragePrice(asks?.toReversed(), quantity),
    [asks, quantity]
  );
  const averageBidPrice = useMemo(
    () => calculateAveragePrice(bids, quantity),
    [bids, quantity]
  );

  const averageAskPrices = useMemo(() => {
    const askCopy = asks?.toReversed();
    return [
      calculateAveragePrice(askCopy, stepsInitialArray[0]),
      calculateAveragePrice(askCopy, stepsInitialArray[1]),
      calculateAveragePrice(askCopy, stepsInitialArray[2]),
      calculateAveragePrice(askCopy, stepsInitialArray[3]),
    ];
  }, [asks, stepsInitialArray]);

  const averageBidPrices = useMemo(() => {
    const bidsCopy = bids;
    return [
      calculateAveragePrice(bidsCopy, stepsInitialArray[0]),
      calculateAveragePrice(bidsCopy, stepsInitialArray[1]),
      calculateAveragePrice(bidsCopy, stepsInitialArray[2]),
      calculateAveragePrice(bidsCopy, stepsInitialArray[3]),
    ];
  }, [bids, stepsInitialArray]);

  const getPricePrecision = (minTickSize) => {
    let precision = 0;
    while (minTickSize < 1.0) {
      minTickSize *= 10.0;
      precision++;
    }
    return precision;
  };
  useEffect(() => {
    const initialArray = [0.01, 0.05, 0.2, 0.5];
    const minTickSize = tradeSettingsData
      ?.filter((d) => d?.coinName + d?.marketName === newCoin + activeMarket)
      ?.map((d) => d?.minTickSize)[0];

    const tradeAmount = tradeSettingsData
      ?.filter((d) => d?.coinName + d?.marketName === newCoin + activeMarket)
      ?.map((d) => d?.minTradeAmount)[0];
    setMinTradeAmount(tradeAmount);
    setQuantityPrecision(getPricePrecision(tradeAmount));
    let pricePrec = getPricePrecision(minTickSize);
    setPricePrecision(pricePrec);
    let levelsPrecision = pricePrec;

    if (activeMarket === "BTC") levelsPrecision = levelsPrecision - 3;
    for (let i = 0; i < levelsPrecision; i++) {
      initialArray[0] *= 10;
      initialArray[1] *= 10;
      initialArray[2] *= 10;
      initialArray[3] *= 10;
    }
    setStepsInitialArray(initialArray);
  }, [newCoin, activeMarket, setSelectedPair, setNewCoin, defaultPair]);

  useEffect(() => {
    setTableShowData(userOpenOrders);
  }, [userOpenOrders]);

  function formatAmount(value, pair) {
    const [base, market] = pair.split("-");

    let number = parseFloat(value.replace(` ${market}`, ""));

    return number.toString();
  }

  const [expandedRow, setExpandedRow] = useState(null);
  const handleClick = (orderId) => {
    setExpandedRow(expandedRow === orderId ? null : orderId);
  };

  const handleTradeHistoryNext = () => {
    if (tradeHistoryPage < tradeHistoryPages) {
      setTradeHistoryPage(tradeHistoryPage + 1);
    }
  };

  const handleTradeHistoryBack = () => {
    if (tradeHistoryPage > 1) {
      setTradeHistoryPage(tradeHistoryPage - 1);
    }
  };

  const handleOrderHistoryNext = () => {
    if (orderHistoryPage < orderHistoryPages) {
      setOrderHistoryPage(orderHistoryPage + 1);
    }
  };

  const handleOrderHistoryBack = () => {
    if (orderHistoryPage > 1) {
      setOrderHistoryPage(orderHistoryPage - 1);
    }
  };

  const handleSELLOrder = () => {
    dispatch(
      placeOrder({
        market: activeMarket,
        rate: (averageBidPrice?.lastPrice * (1 - bps / 10000)).toFixed(
          pricePrecision
        ),
        side: "SELL",
        trade: newCoin,
        type: orderType === "MARKET" ? "MARKET" : "LIMIT",
        timeInForce:
          orderType === "LIMIT"
            ? "GTC"
            : orderType === "MARKET"
            ? "GTC"
            : orderType === "IOC"
            ? "IOC"
            : "FOK",
        volume: quantity, //quantity precision --> minTradeAmount
      })
    ).then((d) => {
      setShowPlaceOrderButton(false);
      if (isError) {
        toast.error("Order cannot be placed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (d?.payload?.status === "Error") {
        toast.error(d?.payload?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (d?.payload?.status === "Success") {
        toast.success("Order Placed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleBUYOrder = () => {
    dispatch(
      placeOrder({
        market: activeMarket,
        rate: (averageAskPrice?.lastPrice * (1 + bps / 10000)).toFixed(
          pricePrecision
        ),
        side: "BUY",
        trade: newCoin,
        type: orderType === "MARKET" ? "MARKET" : "LIMIT",
        timeInForce:
          orderType === "LIMIT"
            ? "GTC"
            : orderType === "MARKET"
            ? "GTC"
            : orderType === "IOC"
            ? "IOC"
            : "FOK",
        volume: quantity,
      })
    ).then((d) => {
      setShowPlaceOrderButton(false);
      if (isError) {
        toast.error("Order cannot be placed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (d?.payload?.status === "Error") {
        toast.error(d?.payload?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (d?.payload?.status === "Success") {
        toast.success("Order Placed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleBoxClick = (side) => {
    setOrderSide(side);
    setShowPlaceOrderButton(true);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const precision = quantityPrecision;
    const regex = new RegExp(`^-?\\d+(\\.\\d{1,${precision}})?$`);
    if (regex.test(inputValue)) {
      setQuantity(inputValue);
    } else {
      const truncatedValue = parseFloat(inputValue).toFixed(precision);
      setQuantity(truncatedValue);
    }
  };

  const handleOrderCancel = (d) => {
    const { base, quote, side } = d;
    dispatch(
      cancelOrder({
        orderId: d?.order_id,
        pair: `${base}_${quote}`,
        timestamp: Date.now(),
        recvWindow: 10,
        side,
      })
    ).then((d) => {
      if (d?.payload?.status === "Success") {
        toast.success("Order Canceled!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const handleRFQOptionChange = (e) => {
    const quote = e.target.value.split("-")[0];
    const market = e.target.value.split("-")[1];
    setRFQQuote(quote);
    setRFQMarket(market);
  };

  const handleRFQTab = (tab) => {
    setRFQSide(tab);
  };

  const [timer, setTimer] = useState(0);

  const getQuote = async () => {
    const data = {
      coin: rfqQuote,
      market: rfqMarket,
      side: rfqSide,
      quantity: rfqQuantity,
    };

    await axios
      .post(`${process.env.REACT_APP_API_REST_URL}/otc/GetQuote`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response?.data?.status === "Success") {
          setShowExecute(true);
          setTimer(5);
          setRFQPrice(response?.data?.data?.price);
          setQuoteId(response?.data?.data?.quoteID);
        }
        if (response?.data?.status === "Error") {
          toast.error(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (timer === 0 && showExecute) {
      setShowExecute(false);
      setRFQPrice(0);
    }
  }, [timer]);

  const handleRFQExecute = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_REST_URL}/otc/ExecuteQuote/${quoteId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.data?.status === "Success") {
          setShowExecute(false);
          setRFQPrice(0);
          toast.success("Executed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (response?.data?.status === "Error") {
          setShowExecute(false);
          setRFQPrice(0);
          toast.error(response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  return (
    <>
      <Header />
      <PriceBar />
      <section className="e-otc">
        <div className="container">
          <div className="row gap-2" style={{ marginTop: "50px" }}>
            <div className="col-12 col-md-6 col-lg-4 e-otc-cards spot-trader">
              <Title title="Spot Trader" />
              <div className="row justify-content-between align-items-center">
                <div className="col-5">
                  <div className="pairName">
                    {defaultPair.split("_").join("/")}
                  </div>
                </div>
                <div className="col-7">
                  <div className="d-flex gap-3 justify-content-end align-items-center">
                    <p>Qty</p>
                    <span className="quantity highlights">
                      <input
                        type="number"
                        placeholder="1"
                        defaultValue={quantity}
                        value={quantity}
                        min={0}
                        step={minTradeAmount}
                        onChange={handleInputChange}
                      />

                      <span
                        style={{
                          color: "lightgray",
                          paddingLeft: "3px",
                        }}
                      >
                        {newCoin}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row slippage mt-3">
                <div className="col-6 pe-1">
                  <div
                    className="buy w-full"
                    onClick={() => handleBoxClick("SELL")}
                  >
                    <h3 style={{ fontSize: "30px" }}>
                      {quantity === 0 || averageBidPrice?.averagePrice === 0
                        ? "---"
                        : averageBidPrice?.averagePrice
                            .toFixed(pricePrecision)
                            .split(".")[0]}
                    </h3>
                    <p>
                      .
                      {quantity === 0
                        ? 0
                        : averageBidPrice?.averagePrice
                            .toFixed(pricePrecision)
                            .split(".")[1]}
                    </p>
                  </div>
                </div>
                <div className="col-6 ps-1">
                  <div
                    className="sell w-full"
                    onClick={() => handleBoxClick("BUY")}
                  >
                    <h3 style={{ fontSize: "30px" }}>
                      {quantity === 0 || averageAskPrice?.averagePrice === 0
                        ? "---"
                        : averageAskPrice?.averagePrice
                            .toFixed(pricePrecision)
                            .split(".")[0]}
                    </h3>
                    <p>
                      .
                      {quantity === 0
                        ? 0
                        : averageAskPrice?.averagePrice
                            .toFixed(pricePrecision)
                            .split(".")[1]}
                    </p>
                  </div>
                </div>

                <div className="volume">
                  <span style={{ textWrap: "wrap" }}>
                    {quantity === 0
                      ? "---"
                      : (
                          (averageAskPrice?.averagePrice +
                            averageBidPrice?.averagePrice) /
                          2
                        ).toFixed(pricePrecision)}
                  </span>
                </div>
              </div>
              {showPlaceOrderButton && (
                <div className="d-flex align-items-center justify-content-between orderBtn py-4">
                  <p>Are you sure you want to place order?</p>
                  <div className="flex">
                    <button
                      onClick={
                        orderSide === "SELL" ? handleSELLOrder : handleBUYOrder
                      }
                      style={{ marginTop: "10px" }}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => {
                        setShowPlaceOrderButton(false);
                      }}
                      style={{
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
              <div className="row mt-3 justify-content-between">
                <div className="col-6 d-flex align-items-center gap-2">
                  <div className="d-flex gap-3 justify-content-end align-items-center">
                    <p>Slippage</p>
                    <span className="quantity highlights">
                      <input
                        type="numeric"
                        placeholder="0"
                        defaultValue={1}
                        value={bps}
                        onChange={(e) => setBPS(e.target.value)}
                      />

                      <span
                        style={{
                          color: "lightgray",
                          paddingLeft: "3px",
                        }}
                      >
                        bps
                      </span>
                    </span>
                  </div>
                </div>
                <div className="col-6 d-flex align-items-center justify-content-end gap-2">
                  <p>Type</p>
                  <select onChange={(e) => setOrderType(e.target.value)}>
                    <option value="FOK">FOK</option>
                    <option value="IOC">IOC</option>
                    <option value="MARKET">MARKET</option>
                    <option value="LIMIT">LIMIT</option>
                  </select>
                </div>
              </div>
              <br />
              <div className="d-flex align-items-center gap-3 position">
                <p>{newCoin} Balance</p>
                <span className="d-flex align-items-center gap-2">
                  <span style={{ color: "#00e4c4" }}>{coinBalance}</span>
                </span>
              </div>
              <div className="d-flex align-items-center gap-3 position">
                <p>{activeMarket} Balance</p>
                <span className="d-flex align-items-center gap-2">
                  <span style={{ color: "#00e4c4" }}>{marketBalance}</span>
                </span>
              </div>
              <div className="pricing mt-2">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th class="head-qty">Qty</th>
                      <th class="head-bid">Bid</th>
                      <th class="head-offer">Ask</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="qty">
                        {stepsInitialArray[0]} {newCoin}
                      </td>
                      <td className="bid">
                        {averageBidPrices[0]?.averagePrice === 0
                          ? "---"
                          : (averageBidPrices[0]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                      <td className="offer">
                        {averageAskPrices[0]?.averagePrice === 0
                          ? "---"
                          : (averageAskPrices[0]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                    </tr>
                    <tr>
                      <td className="qty">
                        {stepsInitialArray[1]} {newCoin}
                      </td>
                      <td className="bid">
                        {averageBidPrices[1]?.averagePrice === 0
                          ? "---"
                          : (averageBidPrices[1]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                      <td className="offer">
                        {averageAskPrices[1]?.averagePrice === 0
                          ? "---"
                          : (averageAskPrices[1]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                    </tr>
                    <tr>
                      <td className="qty">
                        {stepsInitialArray[2]} {newCoin}
                      </td>
                      <td className="bid">
                        {averageBidPrices[2]?.averagePrice === 0
                          ? "---"
                          : (averageBidPrices[2]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                      <td className="offer">
                        {averageAskPrices[2]?.averagePrice === 0
                          ? "---"
                          : (averageAskPrices[2]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                    </tr>
                    <tr>
                      <td className="qty">
                        {stepsInitialArray[3]} {newCoin}
                      </td>
                      <td className="bid">
                        {averageBidPrices[3]?.averagePrice === 0
                          ? "---"
                          : (averageBidPrices[3]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                      <td className="offer">
                        {averageAskPrices[3]?.averagePrice === 0
                          ? "---"
                          : (averageAskPrices[3]?.averagePrice || 0).toFixed(
                              pricePrecision
                            )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 e-otc-cards instruments">
              <Title title="Instruments" />
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  onChange={handleChange}
                />
              </div>
              <div className="pricing mt-4">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th class="head-qty" onClick={handleSort}>
                        Pair
                      </th>
                      <th class="head-bid"> Bid</th>
                      <th class="head-offer">Ask</th>
                      <th class="head-offer"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredMarketData?.length > 0 ? (
                      filteredMarketData
                        ?.sort((a, b) => {
                          const aPart = a.pairName.split("_")[1];
                          const bPart = b.pairName.split("_")[1];
                          return sortOrder === "asc"
                            ? aPart.localeCompare(bPart)
                            : bPart.localeCompare(aPart);
                        })
                        ?.map((pairData, index) => (
                          <tr key={index}>
                            <td
                              className="qty"
                              style={{ fontSize: "14px", textAlign: "left" }}
                            >
                              {pairData?.pairName
                                .split("_")
                                .reverse()
                                .join("/")}
                            </td>
                            <td className="bid" style={{ fontSize: "14px" }}>
                              {pairData?.HeighestBid}
                            </td>
                            <td className="offer" style={{ fontSize: "14px" }}>
                              {pairData?.LowestAsk}
                            </td>
                            <td className="add">
                              <button
                                onClick={() =>
                                  handleSpotView(pairData?.pairName)
                                }
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <h4 className="qty mt-4">No data found</h4>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-12 col-md-6 col-lg-4 e-otc-cards rfq-card">
              <Title title="RFQ" />

              <ul className="d-flex">
                <li
                  className={`${
                    rfqSide === "buy" && "success text-black"
                  } w-1/2 text-center p-2 rounded`}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRFQTab("buy")}
                >
                  Buy
                </li>
                <li
                  className={`${
                    rfqSide === "sell" && "danger text-black"
                  } w-1/2 text-center p-2 rounded pointer`}
                  onClick={() => handleRFQTab("sell")}
                  style={{ cursor: "pointer" }}
                >
                  Sell
                </li>
              </ul>

              <div>
                <div className="d-flex gap-5 justify-content-start align-items-center mt-4">
                  <p className="rfq-pair">Pair</p>
                  <select onChange={handleRFQOptionChange}>
                    <option value="BTC-USDT" selected>
                      BTC-USDT
                    </option>
                    <option value="ETH-USDT">ETH-USDT</option>
                    <option value="XRP-USDT">XRP-USDT</option>
                  </select>
                </div>
                <div className="nominal mt-4">
                  <input
                    type="number"
                    style={{
                      background: "transparent",
                      width: "100%",
                      border: "none",
                    }}
                    className="rfq-quantity-input"
                    placeholder="Quantity"
                    onChange={(e) => setRFQQuantity(e?.target?.value)}
                    disabled={showExecute}
                  />
                </div>
                <div className="mt-3">
                  <div className="d-flex align-items-center">
                    <p className="title">Price</p>
                    <p className="rfq-txt font-bold ms-2">${rfqPrice}</p>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <p className="title">Total</p>
                    <p className="rfq-txt font-bold d-flex ms-2">
                      <span>${rfqPrice * rfqQuantity}</span>
                    </p>
                  </div>
                </div>
                <div className="actions d-flex align-items-center gap-2 mt-4">
                  {showExecute ? (
                    <button
                      className="executeBtn"
                      style={{ width: "100%" }}
                      onClick={handleRFQExecute}
                    >
                      <span className="timer">{timer}</span>EXECUTE
                    </button>
                  ) : (
                    <button
                      className="default"
                      style={{ width: "100%" }}
                      onClick={getQuote}
                    >
                      Get Quote
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="row gap-2 e-otc-orders-table"
            style={{ marginTop: "50px" }}
          >
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {/* Open Orders Tab */}
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="live-orders-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#live-orders"
                  type="button"
                  role="tab"
                  aria-controls="live-orders"
                  aria-selected="true"
                >
                  Open Orders
                </button>
              </li>
              {/* Order History Tab */}
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="order-history-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#order-history"
                  type="button"
                  role="tab"
                  aria-controls="order-history"
                  aria-selected="false"
                  onClick={() => setTableTab("order-history")}
                >
                  Order History
                </button>
              </li>
              {/* Trade History Tab */}
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="trades-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#trades"
                  type="button"
                  role="tab"
                  aria-controls="trades"
                  aria-selected="false"
                  onClick={() => setTableTab("trade-history")}
                >
                  Trades History
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="live-orders"
                role="tabpanel"
                aria-labelledby="live-orders-tab"
              >
                <div className="mt-2 table-responsive">
                  <table class="table">
                    <thead className="sticky-top">
                      <tr>
                        <th class="head ">Action</th>
                        <th class="head">Status</th>
                        <th class="head">Date</th>
                        <th class="head">Time</th>
                        <th class="head">Order Id</th>
                        <th class="head">Instrument</th>
                        <th class="head">Type</th>
                        <th class="head">Side</th>
                        <th class="head">Price</th>
                        <th class="head">Qty</th>
                        <th class="head">Exec Qty</th>
                        <th class="head">Order Type</th>
                        <th class="head">Time in Force</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableShowData?.map((d) => (
                        <tr key={d?.order_id}>
                          <td>
                            <button
                              className="themeButton"
                              onClick={() => handleOrderCancel(d)}
                            >
                              Cancel
                            </button>
                          </td>
                          <td
                            className={
                              d?.status === false ? "dangerTxt" : "successTxt"
                            }
                          >
                            {d?.status === false ? "Pending" : "Filled"}
                          </td>
                          <td className="">
                            <td>
                              {
                                new Date(d?.timestamp)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            </td>
                          </td>
                          <td className="">
                            <td>
                              {
                                new Date(d?.timestamp)
                                  .toISOString()
                                  .split("T")[1]
                                  .split(".")[0]
                              }
                            </td>
                          </td>
                          <td className="">{d?.order_id}</td>
                          <td className="infoTxt">
                            {d?.base}-{d?.quote}
                          </td>
                          <td className="successTxt">Spot</td>
                          <td
                            className={
                              d?.side === "SELL" ? "dangerTxt" : "successTxt"
                            }
                          >
                            {d?.side}
                          </td>
                          <td className="">{d?.rate}</td>
                          <td className="">{d?.volume}</td>
                          <td>{d?.pending}</td>
                          <td className="">{d?.type}</td>
                          <td className="">{d?.tif}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="order-history"
                role="tabpanel"
                aria-labelledby="order-history-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead className="sticky-top">
                      <tr>
                        <th className="head" style={{ width: "30px" }}></th>
                        <th className="head">Status</th>
                        <th className="head">Date</th>
                        <th className="head">Time</th>
                        <th className="head">Order Id</th>
                        <th className="head">Instrument</th>
                        <th className="head">Order Type</th>
                        <th className="head">Side</th>
                        <th className="head">TIF</th>
                        <th className="head">Trade Price</th>
                        <th className="head">Average Price</th>
                        <th className="head">Size</th>
                        <th className="head">Exec Value</th>
                        <th className="head">Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderHistory?.data?.rows?.map((d) => (
                        <>
                          {/* Main row */}
                          <tr>
                            <td
                              style={{
                                width: "30px",
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleClick(d?.orderId)}
                            >
                              {expandedRow === d?.orderId ? "-" : "+"}
                            </td>

                            <td
                              className={
                                d?.orderStatus === "Filled"
                                  ? "successTxt"
                                  : d?.orderStatus === "Pending"
                                  ? "infoTxt"
                                  : "dangerTxt"
                              }
                            >
                              {d?.orderStatus}
                            </td>
                            <td>
                              {new Date(d?.date).toISOString().split("T")[0]}
                            </td>
                            <td>
                              {
                                new Date(d?.date)
                                  .toISOString()
                                  .split("T")[1]
                                  .split(".")[0]
                              }
                            </td>
                            <td>{d?.orderId}</td>
                            <td className="infoTxt">{d?.currencyPair}</td>
                            <td>{d?.tradeType}</td>
                            <td
                              className={
                                d?.side === "SELL" ? "dangerTxt" : "successTxt"
                              }
                            >
                              {d?.side}
                            </td>
                            <td>{d?.timeInForce ? d?.timeInForce : "-"}</td>
                            <td
                              style={{ width: "100px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={formatAmount(
                                d?.tradePrice,
                                d?.currencyPair
                              )}
                            >
                              {formatAmount(d?.tradePrice, d?.currencyPair)}
                            </td>
                            <td
                              style={{ width: "100px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title={formatAmount(
                                d?.averagePrice,
                                d?.currencyPair
                              )}
                            >
                              {formatAmount(d?.averagePrice, d?.currencyPair)}
                            </td>
                            <td>{formatAmount(d?.size, d?.currencyPair)}</td>
                            <td>
                              {formatAmount(
                                d?.totalExecutedValue,
                                d?.currencyPair
                              )}
                            </td>
                            <td>{formatAmount(d?.feePaid, d?.currencyPair)}</td>
                          </tr>

                          {expandedRow === d?.orderId &&
                            d?.mOrders?.length > 0 && (
                              <table
                                className="table"
                                style={{
                                  background: "",
                                  height: "auto",
                                  padding: "20px 0",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th className="head">Date</th>
                                    <th className="head">Time</th>
                                    <th className="head">Order Id</th>
                                    <th className="head">Instrument</th>
                                    <th className="head">Price</th>
                                    <th className="head">Volume</th>
                                    <th className="head">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {d?.mOrders?.map((data) => (
                                    <tr
                                      style={{
                                        backgroundColor: "",
                                        margin: "10px 0",
                                      }}
                                    >
                                      <td>
                                        {
                                          new Date(data?.date)
                                            .toISOString()
                                            .split("T")[0]
                                        }
                                      </td>
                                      <td>
                                        {
                                          new Date(data?.date)
                                            .toISOString()
                                            .split("T")[1]
                                            .split(".")[0]
                                        }
                                      </td>
                                      <td>{data?.orderId}</td>
                                      <td className="infoTxt">
                                        {data?.trade}-{data?.market}
                                      </td>

                                      <td>{data?.rate}</td>
                                      <td>{data?.volume}</td>
                                      <td>{data?.amount}</td>
                                      {/* <td>
                                        {formatAmount(
                                          data?.tradePrice,
                                          data?.currencyPair
                                        )}
                                      </td>
                                      <td>
                                        {formatAmount(
                                          data?.size,
                                          data?.currencyPair
                                        )}
                                      </td>
                                      <td>
                                        {formatAmount(
                                          data?.totalExecutedValue,
                                          data?.currencyPair
                                        )}
                                      </td>
                                      <td>
                                        {formatAmount(
                                          data?.feePaid,
                                          data?.currencyPair
                                        )}
                                      </td> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                        </>
                      ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                    <button
                      className="button paginationButton"
                      onClick={handleOrderHistoryBack}
                      disabled={orderHistoryPage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className="button paginationButton"
                      onClick={handleOrderHistoryNext}
                      disabled={orderHistoryPage === orderHistoryPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="trades"
                role="tabpanel"
                aria-labelledby="trades-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead className="sticky-top">
                      <tr>
                        <th class="head">Date</th>
                        <th class="head">Time</th>
                        <th class="head">Order Id</th>
                        <th class="head">Instrument</th>
                        <th class="head">Side</th>
                        <th class="head">Price</th>
                        <th class="head">Volume</th>
                        <th class="head">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tradeHistory?.data?.rows?.map((d) => (
                        <tr>
                          <td>
                            {new Date(d?.date).toISOString().split("T")[0]}
                          </td>
                          <td>
                            {
                              new Date(d?.date)
                                .toISOString()
                                .split("T")[1]
                                .split(".")[0]
                            }
                          </td>
                          <td>{d?.orderId}</td>
                          <td className="infoTxt">
                            {d?.trade}-{d?.market}
                          </td>

                          <td
                            className={
                              d?.side === "SELL" ? "dangerTxt" : "successTxt"
                            }
                          >
                            {d?.side}
                          </td>
                          <td>{d?.rate}</td>
                          <td>{d?.volume}</td>
                          <td>{d?.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                    <button
                      className="button paginationButton"
                      onClick={handleTradeHistoryBack}
                      disabled={tradeHistoryPage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className="button paginationButton"
                      onClick={handleTradeHistoryNext}
                      disabled={tradeHistoryPage === tradeHistoryPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Market;
