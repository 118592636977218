import _ from 'lodash';
import moment from 'moment';
import historyProvider from './historyProvider.js';
import store from '../../../store.js';

const websocketUrl = process.env.REACT_APP_API_WS_URL;


class WebSocketConnection {
  constructor(url) {
    this.url = url;
    this.socket = null;
    this.eventListeners = {};
    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
    //  console.log('WebSocket connected, readyState:', this.socket.readyState);
    };

    this.socket.onmessage = (message) => {
      try {
      //  console.log('Received data:', message);
        const data = JSON.parse(message.data);

        // Check if the event corresponds to the subscribed event (CH.BTC_USDT.1)
        if (data.event && data.event.startsWith('CH')) {
        //  console.log("Updated...")
          const channel = data.event.split('.')[0]; // Extract channel
          if (this.eventListeners[channel]) {
            this.eventListeners[channel].forEach(callback => callback(data));
          }
        }

      //  console.log('Parsed data:', data);
      } catch (e) {
        console.error('Error parsing WebSocket message:', e, message.data);
      }
    };

    this.socket.onclose = () => {
    // console.log('WebSocket disconnected, attempting to reconnect...');
      setTimeout(() => this.connect(), 5000); // Reconnect after 5 seconds
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  subscribe(event, data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
     // console.log('WebSocket is open, subscribing to event:', event, data);
      // `events` dizisini sadece kanal adıyla gönderiyoruz
      this.socket.send(JSON.stringify({ method: 'subscribe', events: [event] }));
    } else {
      console.warn('WebSocket is not open. Waiting to subscribe...');
      this.socket.addEventListener('open', () => {
   //     console.log('WebSocket is now open. Proceeding with subscription to event:', event, data);
        this.socket.send(JSON.stringify({ method: 'subscribe', events: [event] }));
      }, { once: true });
    }
  }

  on(event, callback) {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(callback);
  //  console.log(`Listener added for event: ${event}`);
  }
}

// Instantiate the WebSocket connection
const socket = new WebSocketConnection(websocketUrl);

let subscription = {};

const invokeTradingPair = (baseCurrency, quoteCurrency, resolution) => {
 // console.log('Subscribing to:', `${baseCurrency}_${quoteCurrency}`, resolution); // Log for initial subscription
  socket.subscribe('CH.' + `${baseCurrency}_${quoteCurrency}.1`, resolution); // Corrected the event format
};

// Ensure correct subscription data structure and reconnect logic
const streamProvider = {
  subscribeBars: (symbolInfo, resolution, updateCb, uid, resetCache) => {
    const [baseCurrency, quoteCurrency] = symbolInfo.name.split('/');
    const lastBar = _.get(
      historyProvider,
      `history.${symbolInfo.name}.lastBar`,
      {
        time: moment().startOf('m').valueOf(),
        open: 0,
        close: 0,
        high: 0,
        low: 0,
        volume: 0,
      }
    );

    invokeTradingPair(baseCurrency, quoteCurrency, resolution);

    subscription = {
      channel: `${baseCurrency}${quoteCurrency}1`,
      uid,
      resolution,
      symbolInfo,
      listener: updateCb,
      lastBar,
    };


    // Periodically update the price every 5 seconds
    setInterval(() => {
      const { latestTradePrice } = store.getState().exchange.tradingPairStats;
      updatePrice(latestTradePrice);
    }, 5000); // Update every 5 seconds

  },

  unsubscribeBars: () => {
    // Handle unsubscribe logic here if needed
  },
};

// Listen to the 'CH' event for incoming data
socket.on('CH', data => {
 // console.log('Received data on CH:', data); // Log the data first

  const barData = data.data; // Extract the relevant chart data
  // console.log("barData", barData);
  
  barData.close = barData.open; // Modify data as needed
  barData.high = barData.open;

  if (subscription) {
    if (barData.time < subscription.lastBar.time) {
      return;
    }

    const lastBar = updateBar(barData, subscription);
    subscription.listener(lastBar);
    subscription.lastBar = lastBar; // Update our record of lastBar
  }
});


export const updateVolume = ticker => {
  if (!_.isEmpty(subscription)) {
    let lastBar = subscription.lastBar;

    lastBar.volume += ticker.Volume;
    subscription.listener(lastBar);
  }
};

export const updatePrice = price => {
  if (!_.isEmpty(subscription)) {
    let lastBar = subscription.lastBar;
    if (price !== 0) {
      if (price < lastBar.low) {
        lastBar.low = price;
      } else if (price > lastBar.high) {
        lastBar.high = price;
      }

      lastBar.close = price;

      subscription.listener(lastBar);
    }
  }
};

// Function to update the bar with new data
const updateBar = (barData, subscription) => {
  let lastBar = subscription.lastBar;
  let resolution = subscription.resolution;

  if (resolution.includes('D')) {
    resolution = 1440; // 1 day in minutes
  } else if (resolution.includes('W')) {
    resolution = 10080; // 1 week in minutes
  }

  let coeff = resolution * 60 * 1000;
  let isSameInterval =
    Math.floor(barData.time / coeff) === Math.floor(lastBar.time / coeff);

  let _lastBar;
  if (!isSameInterval) {
    _lastBar = {
      time: barData.time,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: barData.close,
      volume: barData.volume,
    };
  } else {
    if (barData.close < lastBar.low) {
      lastBar.low = barData.close;
    } else if (barData.close > lastBar.high) {
      lastBar.high = barData.close;
    }

    lastBar.volume += barData.volume;
    lastBar.close = barData.close;
    _lastBar = lastBar;
  }

  return _lastBar;
};

export default streamProvider;
