import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const KeyModal = ({ showModal2, handleCloseModal2, privateKey, publicKey }) => {
  const labels = useSelector((state) => state.labels.data);
  return (
    <Modal show={showModal2} onHide={handleCloseModal2}>
      <Modal.Header closeButton>
        <Modal.Title>{labels?.account?.apiKeys?.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className="row"
          style={{ paddingBottom: "15px", paddingTop: "15px" }}
        >
          <div className="col-md-10 mx-auto">
            <div className="form-group">
              {labels?.messages?.keyGenerated.split(";")[2]}
              <br />
              <br />
              <b>
                {labels?.messages?.keyGenerated
                  .split(";")[0]
                  .replace("{{ publicKey }}", publicKey)
                  .replace("&#13", " ")}
              </b>
              <br />
              <br />
              <b>
                {labels?.messages?.keyGenerated
                  .split(";")[1]
                  .replace("{{ privateKey }}", privateKey)
                  .replace("&#13", " ")}
              </b>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal2}>
          {labels?.tables?.myOffers?.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeyModal;
