import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCoinContext } from "../../Context/CoinContext";

export default function SideBar(props) {
  const labels = useSelector((state) => state.labels.data);
  const [selected, setSelected] = useState(
    props.mainmenu ? props.mainmenu : ""
  );

  const { exchangeToken } = useCoinContext();

  // Function to handle click event
  const handleClick = (index) => {
    if (selected === index) {
      setSelected(null); // If the clicked div is already selected, deselect it
    } else {
      setSelected(index); // Otherwise, select the clicked div
    }
  };

  return (
    <>
      <div className="sidebar">
        <div
          index="Account"
          className={`item ${selected === "Account" ? "selected" : ""}`}
          onClick={() => handleClick("Account")}
        >
          <div className="head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9996 10.8C12.9544 10.8 13.8701 10.4207 14.5452 9.74556C15.2203 9.07043 15.5996 8.15476 15.5996 7.19998C15.5996 6.2452 15.2203 5.32952 14.5452 4.65439C13.8701 3.97926 12.9544 3.59998 11.9996 3.59998C11.0448 3.59998 10.1292 3.97926 9.45402 4.65439C8.77889 5.32952 8.39961 6.2452 8.39961 7.19998C8.39961 8.15476 8.77889 9.07043 9.45402 9.74556C10.1292 10.4207 11.0448 10.8 11.9996 10.8ZM3.59961 21.6C3.59961 20.4969 3.81688 19.4046 4.23902 18.3854C4.66116 17.3663 5.2799 16.4403 6.05991 15.6603C6.83992 14.8803 7.76593 14.2615 8.78507 13.8394C9.8042 13.4172 10.8965 13.2 11.9996 13.2C13.1027 13.2 14.195 13.4172 15.2141 13.8394C16.2333 14.2615 17.1593 14.8803 17.9393 15.6603C18.7193 16.4403 19.3381 17.3663 19.7602 18.3854C20.1823 19.4046 20.3996 20.4969 20.3996 21.6H3.59961Z"
                fill="none"
              />
            </svg>
            <span>{labels?.navbar?.links?.account}</span>
            <svg
              className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={28}
              height={28}
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4099 10.2101C7.67244 9.94766 8.02847 9.80022 8.3997 9.80022C8.77093 9.80022 9.12696 9.94766 9.3895 10.2101L13.9997 14.8203L18.6099 10.2101C18.739 10.0764 18.8935 9.96975 19.0643 9.89638C19.2351 9.823 19.4188 9.78438 19.6047 9.78277C19.7906 9.78115 19.975 9.81657 20.147 9.88697C20.3191 9.95736 20.4754 10.0613 20.6069 10.1928C20.7383 10.3242 20.8423 10.4805 20.9127 10.6526C20.983 10.8246 21.0185 11.009 21.0169 11.1949C21.0152 11.3808 20.9766 11.5645 20.9032 11.7353C20.8299 11.9061 20.7232 12.0606 20.5895 12.1897L14.9895 17.7897C14.727 18.0522 14.3709 18.1996 13.9997 18.1996C13.6285 18.1996 13.2724 18.0522 13.0099 17.7897L7.4099 12.1897C7.14744 11.9272 7 11.5711 7 11.1999C7 10.8287 7.14744 10.4727 7.4099 10.2101Z"
                fill="none"
              />
            </svg>
          </div>
          <div className="content">
            <ul>
              <li
                className={`${
                  props.submenu === "AccountOverview" ? "active" : ""
                }`}
              >
                <Link to="/account/overview">
                  {labels?.account?.overview?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountVerification" ? "active" : ""
                }`}
              >
                <Link to="/account/verification">
                  {labels?.account?.verification?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountSecurity" ? "active" : ""
                }`}
              >
                <Link to="/account/security">
                  {labels?.account?.security?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountIpWhiteListing" ? "active" : ""
                }`}
              >
                <Link to="/account/ip-white-listing">
                  {labels?.account?.["ip-whitelisting"]?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountAffiliates" ? "active" : ""
                }`}
              >
                <Link to="/account/affiliates">
                  {labels?.account?.affiliates?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountApiKeys" ? "active" : ""
                }`}
              >
                <Link to="/account/api-keys">
                  {labels?.account?.apiKeys?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountExchangeToken" ? "active" : ""
                }`}
              >
                <Link to="/account/exchange-token">
                  {labels?.account?.feeDiscount?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountVolumeDiscount" ? "active" : ""
                }`}
              >
                <Link to="/account/volume-discount">
                  {labels?.account?.tradingDiscount?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountChangePassword" ? "active" : ""
                }`}
              >
                <Link to="/account/change-password">
                  {labels?.forms?.changePassword?.title}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "AccountWhitelistedDevices" ? "active" : ""
                }`}
              >
                <Link to="/account/whitelisted-devices">
                  {labels?.account?.deviceWhitelisting?.title}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          index="Orders"
          className={`item ${selected === "Orders" ? "selected" : ""}`}
          onClick={() => handleClick("Orders")}
        >
          <div className="head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9.60033 6C9.28207 6 8.97685 6.12643 8.7518 6.35147C8.52676 6.57652 8.40033 6.88174 8.40033 7.2C8.40033 7.51826 8.52676 7.82348 8.7518 8.04853C8.97685 8.27357 9.28207 8.4 9.60033 8.4H16.3035L14.7519 9.9516C14.5333 10.1779 14.4124 10.481 14.4151 10.7957C14.4179 11.1103 14.5441 11.4113 14.7665 11.6338C14.989 11.8563 15.29 11.9825 15.6046 11.9852C15.9193 11.9879 16.2224 11.867 16.4487 11.6484L20.0487 8.0484C20.2737 7.82337 20.4001 7.5182 20.4001 7.2C20.4001 6.8818 20.2737 6.57663 20.0487 6.3516L16.4487 2.7516C16.338 2.63699 16.2056 2.54557 16.0592 2.48268C15.9128 2.41979 15.7553 2.38668 15.596 2.3853C15.4367 2.38391 15.2787 2.41428 15.1312 2.47461C14.9837 2.53495 14.8497 2.62405 14.7371 2.73673C14.6244 2.8494 14.5353 2.98338 14.4749 3.13085C14.4146 3.27833 14.3842 3.43635 14.3856 3.59568C14.387 3.75502 14.4201 3.91248 14.483 4.05889C14.5459 4.20529 14.6373 4.3377 14.7519 4.4484L16.3035 6H9.60033ZM14.4003 18C14.7186 18 15.0238 17.8736 15.2489 17.6485C15.4739 17.4235 15.6003 17.1183 15.6003 16.8C15.6003 16.4817 15.4739 16.1765 15.2489 15.9515C15.0238 15.7264 14.7186 15.6 14.4003 15.6H7.69713L9.24873 14.0484C9.36334 13.9377 9.45476 13.8053 9.51765 13.6589C9.58054 13.5125 9.61365 13.355 9.61503 13.1957C9.61642 13.0363 9.58605 12.8783 9.52572 12.7309C9.46538 12.5834 9.37628 12.4494 9.2636 12.3367C9.15093 12.2241 9.01695 12.135 8.86947 12.0746C8.722 12.0143 8.56398 11.9839 8.40465 11.9853C8.24531 11.9867 8.08785 12.0198 7.94144 12.0827C7.79504 12.1456 7.66263 12.237 7.55193 12.3516L3.95193 15.9516C3.72696 16.1766 3.60059 16.4818 3.60059 16.8C3.60059 17.1182 3.72696 17.4234 3.95193 17.6484L7.55193 21.2484C7.77825 21.467 8.08138 21.5879 8.39601 21.5852C8.71065 21.5825 9.01162 21.4563 9.23411 21.2338C9.4566 21.0113 9.5828 20.7103 9.58554 20.3957C9.58827 20.081 9.46732 19.7779 9.24873 19.5516L7.69713 18H14.4003Z"
                fill="none"
              />
            </svg>
            <span>{labels?.navbar?.links?.orders}</span>
            <svg
              className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={28}
              height={28}
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4099 10.2101C7.67244 9.94766 8.02847 9.80022 8.3997 9.80022C8.77093 9.80022 9.12696 9.94766 9.3895 10.2101L13.9997 14.8203L18.6099 10.2101C18.739 10.0764 18.8935 9.96975 19.0643 9.89638C19.2351 9.823 19.4188 9.78438 19.6047 9.78277C19.7906 9.78115 19.975 9.81657 20.147 9.88697C20.3191 9.95736 20.4754 10.0613 20.6069 10.1928C20.7383 10.3242 20.8423 10.4805 20.9127 10.6526C20.983 10.8246 21.0185 11.009 21.0169 11.1949C21.0152 11.3808 20.9766 11.5645 20.9032 11.7353C20.8299 11.9061 20.7232 12.0606 20.5895 12.1897L14.9895 17.7897C14.727 18.0522 14.3709 18.1996 13.9997 18.1996C13.6285 18.1996 13.2724 18.0522 13.0099 17.7897L7.4099 12.1897C7.14744 11.9272 7 11.5711 7 11.1999C7 10.8287 7.14744 10.4727 7.4099 10.2101Z"
                fill="none"
              />
            </svg>
          </div>
          <div className="content">
            <ul>
              <li
                className={`${
                  props.submenu === "OrdersOpenOrders" ? "active" : ""
                }`}
              >
                <Link to="/order/open-orders">
                  {labels?.orders?.openOrders?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "OrdersOrderHistory" ? "active" : ""
                }`}
              >
                <Link to="/order/order-history">
                  {labels?.orders?.orderHistory?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "OrdersTradeHistory" ? "active" : ""
                }`}
              >
                <Link to="/order/trade-history">
                  {labels?.orders?.tradeHistory?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "OrdersInstaTrades" ? "active" : ""
                }`}
              >
                <Link to="/order/insta-trades">
                  {labels?.wallet?.instaTrade?.link}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          index="Wallet"
          className={`item ${selected === "Wallet" ? "selected" : ""}`}
          onClick={() => handleClick("Wallet")}
        >
          <div className="head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_690_1956)">
                <path
                  d="M15.8333 2.5H4.16667C3.062 2.50132 2.00296 2.94073 1.22185 3.72185C0.440735 4.50296 0.00132321 5.562 0 6.66667H20C19.9987 5.562 19.5593 4.50296 18.7782 3.72185C17.997 2.94073 16.938 2.50132 15.8333 2.5Z"
                  fill="none"
                />
                <path
                  d="M0 13.3332C0.00132321 14.4379 0.440735 15.4969 1.22185 16.2781C2.00296 17.0592 3.062 17.4986 4.16667 17.4999H15.8333C16.938 17.4986 17.997 17.0592 18.7782 16.2781C19.5593 15.4969 19.9987 14.4379 20 13.3332V8.33325H0V13.3332ZM5.83333 12.9166C5.83333 13.1638 5.76002 13.4055 5.62267 13.611C5.48532 13.8166 5.2901 13.9768 5.06169 14.0714C4.83328 14.166 4.58195 14.1908 4.33947 14.1426C4.09699 14.0943 3.87427 13.9753 3.69945 13.8005C3.52463 13.6256 3.40558 13.4029 3.35735 13.1604C3.30912 12.918 3.33387 12.6666 3.42848 12.4382C3.52309 12.2098 3.68331 12.0146 3.88887 11.8772C4.09443 11.7399 4.33611 11.6666 4.58333 11.6666C4.91485 11.6666 5.2328 11.7983 5.46722 12.0327C5.70164 12.2671 5.83333 12.5851 5.83333 12.9166Z"
                  fill="none"
                />
              </g>
              <defs>
                <clipPath id="clip0_690_1956">
                  <rect width={20} height={20} fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{labels?.navbar?.links?.wallet}</span>
            <img src="/images/icons/badge.svg" alt="" />
            <svg
              className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={28}
              height={28}
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.4099 10.2101C7.67244 9.94766 8.02847 9.80022 8.3997 9.80022C8.77093 9.80022 9.12696 9.94766 9.3895 10.2101L13.9997 14.8203L18.6099 10.2101C18.739 10.0764 18.8935 9.96975 19.0643 9.89638C19.2351 9.823 19.4188 9.78438 19.6047 9.78277C19.7906 9.78115 19.975 9.81657 20.147 9.88697C20.3191 9.95736 20.4754 10.0613 20.6069 10.1928C20.7383 10.3242 20.8423 10.4805 20.9127 10.6526C20.983 10.8246 21.0185 11.009 21.0169 11.1949C21.0152 11.3808 20.9766 11.5645 20.9032 11.7353C20.8299 11.9061 20.7232 12.0606 20.5895 12.1897L14.9895 17.7897C14.727 18.0522 14.3709 18.1996 13.9997 18.1996C13.6285 18.1996 13.2724 18.0522 13.0099 17.7897L7.4099 12.1897C7.14744 11.9272 7 11.5711 7 11.1999C7 10.8287 7.14744 10.4727 7.4099 10.2101Z"
                fill="none"
              />
            </svg>
          </div>
          <div className="content">
            <ul>
              <li
                className={`${
                  props.submenu === "WalletOverview" ? "active" : ""
                }`}
              >
                <Link to="/wallet/overview">
                  {" "}
                  {labels?.NDWallet?.overview?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "WalletDeposits" ? "active" : ""
                }`}
              >
                <Link to="/wallet/deposits">
                  {" "}
                  {labels?.wallet?.deposits?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "WalletWithdrawals" ? "active" : ""
                }`}
              >
                <Link to="/wallet/withdrawals">
                  {labels?.NDWallet?.withdrawals?.link}
                </Link>
              </li>
              <li
                className={`${
                  props.submenu === "WalletAddressBook" ? "active" : ""
                }`}
              >
                <Link to="/wallet/addressbook">
                  {" "}
                  {labels?.wallet?.addressBook?.link}
                </Link>
              </li>
              <li
                className={`${props.submenu === "WalletBanks" ? "active" : ""}`}
              >
                <Link to="/wallet/banks"> {labels?.wallet?.banks?.link}</Link>
              </li>
              <li
                className={`${
                  props.submenu === "WalletConvertFunds" ? "active" : ""
                }`}
              >
                <Link to="/wallet/convert-leftover-funds">
                  {labels?.wallet?.convertFunds?.link.replace(
                    "{{exchangeToken}}",
                    exchangeToken
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
